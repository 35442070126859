import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Slide from '@mui/material/Slide'
import { useHistory, useParams } from 'react-router-dom'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import Button from '@mui/material/Button'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import CheckIcon from '@mui/icons-material/Check'
import { toast } from 'react-toastify'
import YellowSwitch from '../../common/YellowSwitch'
import DashboardLayout from '../layouts/DashboardLayout'
import ColorButton from '../../common/ColorButton'
import getFirestoreDocById from '../../common/getFirestoreDocById'
import { doc, updateDoc, db } from '../../config/firebaseConfig'
import FullPageLoader from '../../common/FullPageLoader'

import { UrlParams } from '../../types'
import useAuth from '../../hooks/useAuth'
import color1ConsoleLog from '../../common/color1ConsoleLog'

const ReviseStep7 = () => {
  console.log('ReviseStep7')

  const { user } = useAuth()
  console.log('%cuser : %s', color1ConsoleLog, user?.uid)

  const { id, area } = useParams<UrlParams>()
  console.log('%cThe id is : %s', color1ConsoleLog, id)
  console.log('%cThe area is : %s', color1ConsoleLog, area)

  const history = useHistory()

  const goToReviseStep6 = () => {
    history.push(`/revision/${area}/6/${id}`)
  }

  const goToReviseStep8 = () => {
    history.push(`/revision/${area}/8/${id}`)
  }

  const [formData, setFormData]: any = useState()
  const [loading, setLoading] = useState(true)
  const [isAllowed, setIsAllowed] = useState(false)
  const [key, setKey] = useState('')
  // eslint-disable-next-line
  const [areaUrlParam, setAreaUrlParam] = useState('')
  const [approvedAt, setApprovedAt] = useState('')

  useEffect(() => {
    (async () => {
      if (id) {
        const res = await getFirestoreDocById(id)
        if (res) {
          setFormData(res)
          if (area === 'tsu') {
            console.log('res?.p16workSupervisor', res?.p16workSupervisor)
            console.log('%cp16workSupervisor has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16workSupervisorApproved7')
            setAreaUrlParam('tsu')
            setApprovedAt('p16workSupervisorApprovedAt7')
          }
          if (area === 'pep') {
            console.log('%cp16pepCoordinator has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16pepCoordinatorApproved7')
            setAreaUrlParam('pep')
            setApprovedAt('p16pepCoordinatorApprovedAt7')
          }
          if (area === 'aco') {
            console.log('%cp16areaCoordinator has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16areaCoordinatorApproved7')
            setAreaUrlParam('aco')
            setApprovedAt('p16areaCoordinatorApprovedAt7')
          }
          if (area === 'qt') {
            console.log('%cp16quality has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16qualityApproved7')
            setAreaUrlParam('qt')
            setApprovedAt('p16qualityApprovedAt7')
          }
          if (area === 'ta') {
            console.log('%cp16technicalArea has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16technicalAreaApproved7')
            setAreaUrlParam('ta')
            setApprovedAt('p16technicalAreaApprovedAt7')
          }
          if (area === 'ps') {
            console.log('%cp16patrimonialSecurity has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16patrimonialSecurityApproved7')
            setAreaUrlParam('ps')
            setApprovedAt('p16patrimonialSecurityApprovedAt7')
          }
          if (area === 'ep') {
            console.log('%cp16externalPrevencionist has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16externalPrevencionistApproved7')
            setAreaUrlParam('ep')
            setApprovedAt('p16externalPrevencionistApprovedAt7')
          }
          if (area === 'sass') {
            console.log('%cp16sass has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16sassApproved7')
            setAreaUrlParam('sass')
            setApprovedAt('p16sassApprovedAt7')
          }
          setLoading(false)
        } else {
          setLoading(false)
        }
      }
    })()
  }, [])

  if (loading) {
    <Typography>Cargando</Typography>
  }
  console.log(formData)

  const approve = async (e: any) => {
    e.preventDefault()
    const approveformRef = doc(db, 'forms', id)
    const obj: any = {}
    obj[key] = true
    obj[approvedAt] = new Date()
    try {
      await updateDoc(approveformRef, obj)
      console.log('approved', key, approvedAt)
      toast.success('La aprobación se actualizó correctamente')
    } catch (updateError) {
      toast.error(`Error: ${updateError}`)
    }
  }

  const disapprove = async (e: any) => {
    e.preventDefault()
    const approveformRef = doc(db, 'forms', id)
    const obj: any = {}
    obj[key] = false
    obj[approvedAt] = new Date()
    try {
      await updateDoc(approveformRef, obj)
      console.log('disapproved', key, approvedAt)
      toast.success('La desaprobación se actualizó correctamente')
    } catch (updateError) {
      toast.error(`Error: ${updateError}`)
    }
  }

  return (
    <DashboardLayout>
      {loading ? <FullPageLoader /> : (
        <Slide direction="right" in mountOnEnter unmountOnExit>
          <Grid sx={{ mt: 4 }} container justifyContent="center" spacing={2}>
            <Grid item xs={10} sm={9} md={5} lg={7}>
              {
                (formData?.p16workSupervisor === user?.uid)
                  || (formData?.p16pepCoordinator === user?.uid)
                  || (formData?.p16areaCoordinator === user?.uid)
                  || (formData?.p16quality === user?.uid)
                  || (formData?.p16technicalArea === user?.uid)
                  || (formData?.p16patrimonialSecurity === user?.uid)
                  || (formData?.p16externalPrevencionist === user?.uid)
                  || (formData?.p16sass === user?.uid)
                  || (formData?.p16sassAllowedList?.includes(user?.uid))
                  ? (
                    <>
                      <Typography
                        sx={{ mb: 2 }}
                        align="center"
                        variant="h6"
                        color="text.secondary"
                        gutterBottom
                      >
                        ESPACIOS CONFINADOS
                      </Typography>

                      <Grid display="flex" justifyContent="center" item xs={12}>
                        <FormControlLabel
                          control={(
                            <YellowSwitch
                              checked={formData?.p7Show}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          )}
                          label={formData?.p7Show ? 'Esta página aplica' : 'Esta página no aplica'}
                        />
                      </Grid>
                      <Box
                        component="div"
                        sx={{
                          display: formData?.p7Show ? 'block' : 'none',
                        }}
                      >
                        <TextField
                          sx={{ mb: 2, mt: 4 }}
                          fullWidth
                          label="Llave de acceso número"
                          variant="outlined"
                          value={formData?.p7keyNumber}
                        />
                        <Typography>Medición 1:</Typography>
                        <Grid display="flex">
                          <TextField
                            sx={{ mb: 2 }}
                            fullWidth
                            label="O2"
                            variant="outlined"
                            value={formData?.p7measure1o2}
                          />
                          <TextField
                            sx={{ mb: 2, ml: 1 }}
                            fullWidth
                            label="H2S"
                            variant="outlined"
                            value={formData?.p7measure1h2s}
                          />
                          <TextField
                            sx={{ mb: 2, ml: 1 }}
                            fullWidth
                            label="CO"
                            variant="outlined"
                            value={formData?.p7measure1co}
                          />
                        </Grid>
                        <Typography>Medición 2:</Typography>
                        <Grid display="flex">
                          <TextField
                            sx={{ mb: 2 }}
                            fullWidth
                            label="O2"
                            variant="outlined"
                            value={formData?.p7measure2o2}
                          />
                          <TextField
                            sx={{ mb: 2, ml: 1 }}
                            fullWidth
                            label="H2S"
                            variant="outlined"
                            value={formData?.p7measure2h2s}
                          />
                          <TextField
                            sx={{ mb: 2, ml: 1 }}
                            fullWidth
                            label="CO"
                            variant="outlined"
                            value={formData?.p7measure2co}
                          />
                        </Grid>

                        <Grid display="flex" justifyContent="center">
                          <Typography>
                            Condiciones Aceptables Oxigeno 19,5 a 23,5%
                          </Typography>
                        </Grid>
                        <Grid display="flex" justifyContent="center">
                          <Typography>
                            CO al 0% , H2S al 0%
                          </Typography>
                        </Grid>
                        <Grid display="flex" justifyContent="center">
                          <Typography>
                            Temperatura máxima 35°C - LEL máximo 10%
                          </Typography>
                        </Grid>

                        <Box sx={{
                          display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                        }}
                        >
                          <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                            <FormLabel component="legend">
                              Mantiene analizador de gases?
                            </FormLabel>
                            <RadioGroup
                              sx={{ flex: '1', flexDirection: 'row' }}
                              aria-label="condicionesclimaticas"
                              name="condicionesclimaticas"
                              value={formData?.p7gasAn}
                            >
                              <FormControlLabel value="SI" control={<Radio checked={formData?.p7gasAn === 'SI'} />} label="SI" />
                              <FormControlLabel value="NO" control={<Radio checked={formData?.p7gasAn === 'NO'} />} label="NO" />
                              <FormControlLabel value="NA" control={<Radio checked={formData?.p7gasAn === 'NA'} />} label="N/A" />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                        <Box sx={{
                          display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                        }}
                        >
                          <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                            <FormLabel component="legend">
                              Dispositivos de ventilación forzada
                            </FormLabel>
                            <RadioGroup
                              sx={{ flex: '1', flexDirection: 'row' }}
                              aria-label="condicionesclimaticas"
                              name="condicionesclimaticas"
                              value={formData?.p7deviceOfVentForced}
                            >
                              <FormControlLabel value="SI" control={<Radio checked={formData?.p7deviceOfVentForced === 'SI'} />} label="SI" />
                              <FormControlLabel value="NO" control={<Radio checked={formData?.p7deviceOfVentForced === 'NO'} />} label="NO" />
                              <FormControlLabel value="NA" control={<Radio checked={formData?.p7deviceOfVentForced === 'NA'} />} label="N/A" />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                        <Box sx={{
                          display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                        }}
                        >
                          <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                            <FormLabel component="legend">
                              Equipos de respiración autónoma
                            </FormLabel>
                            <RadioGroup
                              sx={{ flex: '1', flexDirection: 'row' }}
                              aria-label="condicionesclimaticas"
                              name="condicionesclimaticas"
                              value={formData?.p7deviceOfAutonBreathing}
                            >
                              <FormControlLabel value="SI" control={<Radio checked={formData?.p7deviceOfAutonBreathing === 'SI'} />} label="SI" />
                              <FormControlLabel value="NO" control={<Radio checked={formData?.p7deviceOfAutonBreathing === 'NO'} />} label="NO" />
                              <FormControlLabel value="NA" control={<Radio checked={formData?.p7deviceOfAutonBreathing === 'NA'} />} label="N/A" />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                        <Box sx={{
                          display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                        }}
                        >
                          <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                            <FormLabel component="legend">
                              Radio de comunicación
                            </FormLabel>
                            <RadioGroup
                              sx={{ flex: '1', flexDirection: 'row' }}
                              aria-label="condicionesclimaticas"
                              name="condicionesclimaticas"
                              value={formData?.p7comRadio}
                            >
                              <FormControlLabel value="SI" control={<Radio checked={formData?.p7comRadio === 'SI'} />} label="SI" />
                              <FormControlLabel value="NO" control={<Radio checked={formData?.p7comRadio === 'NO'} />} label="NO" />
                              <FormControlLabel value="NA" control={<Radio checked={formData?.p7comRadio === 'NA'} />} label="N/A" />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                        <Box sx={{
                          display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                        }}
                        >
                          <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                            <FormLabel component="legend">
                              Puesta tierra temporal en el lugar
                            </FormLabel>
                            <RadioGroup
                              sx={{ flex: '1', flexDirection: 'row' }}
                              aria-label="condicionesclimaticas"
                              name="condicionesclimaticas"
                              value={formData?.p7landTempInPlace}
                            >
                              <FormControlLabel value="SI" control={<Radio checked={formData?.p7landTempInPlace === 'SI'} />} label="SI" />
                              <FormControlLabel value="NO" control={<Radio checked={formData?.p7landTempInPlace === 'NO'} />} label="NO" />
                              <FormControlLabel value="NA" control={<Radio checked={formData?.p7landTempInPlace === 'NA'} />} label="N/A" />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                        <Box sx={{
                          display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                        }}
                        >
                          <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                            <FormLabel component="legend">
                              Equipo de iluminación adicional
                            </FormLabel>
                            <RadioGroup
                              sx={{ flex: '1', flexDirection: 'row' }}
                              aria-label="condicionesclimaticas"
                              name="condicionesclimaticas"
                              value={formData?.p7equipmentOfAdLight}
                            >
                              <FormControlLabel value="SI" control={<Radio checked={formData?.p7equipmentOfAdLight === 'SI'} />} label="SI" />
                              <FormControlLabel value="NO" control={<Radio checked={formData?.p7equipmentOfAdLight === 'NO'} />} label="NO" />
                              <FormControlLabel value="NA" control={<Radio checked={formData?.p7equipmentOfAdLight === 'NA'} />} label="N/A" />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                        <Box sx={{
                          display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                        }}
                        >
                          <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                            <FormLabel component="legend">
                              Requiere equipo de rescate
                            </FormLabel>
                            <RadioGroup
                              sx={{ flex: '1', flexDirection: 'row' }}
                              aria-label="condicionesclimaticas"
                              name="condicionesclimaticas"
                              value={formData?.p7requireRescueDevice}
                            >
                              <FormControlLabel value="SI" control={<Radio checked={formData?.p7requireRescueDevice === 'SI'} />} label="SI" />
                              <FormControlLabel value="NO" control={<Radio checked={formData?.p7requireRescueDevice === 'NO'} />} label="NO" />
                              <FormControlLabel value="NA" control={<Radio checked={formData?.p7requireRescueDevice === 'NA'} />} label="N/A" />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                        <Box sx={{
                          display: 'flex', justifyContent: 'center', mt: 4, mb: 10,
                        }}
                        >
                          <FormControl sx={{ display: 'flex', mb: 10 }} component="fieldset">
                            <FormLabel component="legend">
                              Condición climatica aceptable
                            </FormLabel>
                            <RadioGroup
                              sx={{ flex: '1', flexDirection: 'row' }}
                              aria-label="condicionesclimaticas"
                              name="condicionesclimaticas"
                              value={formData?.p7acceptableWeatherCond}
                            >
                              <FormControlLabel value="SI" control={<Radio checked={formData?.p7acceptableWeatherCond === 'SI'} />} label="SI" />
                              <FormControlLabel value="NO" control={<Radio checked={formData?.p7acceptableWeatherCond === 'NO'} />} label="NO" />
                              <FormControlLabel value="NA" control={<Radio checked={formData?.p7acceptableWeatherCond === 'NA'} />} label="N/A" />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Box>

                      <Grid
                        display={isAllowed ? 'flex' : 'none'}
                        justifyContent="space-evenly"
                        sx={{ mt: 4 }}
                      >
                        <Button
                          startIcon={<HighlightOffIcon />}
                          sx={{ mb: 10, bgcolor: '#db1414' }}
                          color="error"
                          variant="contained"
                          onClick={(e) => disapprove(e)}
                        >
                          Desaprobar
                        </Button>
                        <Button
                          startIcon={<CheckIcon />}
                          sx={{ mb: 10, bgcolor: '#42b50e' }}
                          color="success"
                          variant="contained"
                          onClick={(e) => approve(e)}
                        >
                          Aprobar
                        </Button>
                      </Grid>

                      <Grid sx={{ mb: 2 }} container justifyContent="space-between">
                        <Grid
                          xs={12}
                          md={5}
                          sx={{ mb: 2 }}
                        >
                          <ColorButton
                            fullWidth
                            startIcon={<ArrowLeftIcon />}
                            onClick={goToReviseStep6}
                          >
                            Ir a la página anterior
                          </ColorButton>
                        </Grid>
                        <Grid
                          xs={12}
                          md={5}
                        >
                          <ColorButton
                            fullWidth
                            endIcon={<ArrowRightIcon />}
                            onClick={goToReviseStep8}
                          >
                            Ir a la página siguiente
                          </ColorButton>
                        </Grid>

                      </Grid>
                    </>
                  )
                  : 'Por favor inicie sesión para visualizar su documento a revisar'
              }

              {user ? (
                <Typography color="green">Usted ha iniciado sesión.</Typography>
              )
                : (
                  <Typography color="red" sx={{ mt: 2 }}>Usted no ha iniciado sesión</Typography>
                )}

            </Grid>
          </Grid>
        </Slide>
      )}

    </DashboardLayout>
  )
}

export default ReviseStep7