import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Slide from '@mui/material/Slide'
import Box from '@mui/material/Box'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { useParams, useHistory } from 'react-router-dom'
import Button from '@mui/material/Button'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import CheckIcon from '@mui/icons-material/Check'
import { toast } from 'react-toastify'
import YellowSwitch from '../../common/YellowSwitch'
import DashboardLayout from '../layouts/DashboardLayout'
import ColorButton from '../../common/ColorButton'
import getFirestoreDocById from '../../common/getFirestoreDocById'
import { UrlParams } from '../../types'
import { doc, updateDoc, db } from '../../config/firebaseConfig'
import useAuth from '../../hooks/useAuth'
import color1ConsoleLog from '../../common/color1ConsoleLog'
import FullPageLoader from '../../common/FullPageLoader'

const ReviseStep10 = () => {
  console.log('ReviseStep10')

  const { user } = useAuth()
  console.log('%cuser : %s', color1ConsoleLog, user?.uid)

  const { id, area } = useParams<UrlParams>()
  console.log('%cThe id is : %s', color1ConsoleLog, id)
  console.log('%cThe area is : %s', color1ConsoleLog, area)

  const history = useHistory()

  const goToReviseStep9 = () => {
    history.push(`/revision/${area}/9/${id}`)
  }

  const goToReviseStep11 = () => {
    history.push(`/revision/${area}/11/${id}`)
  }

  const [formData, setFormData]: any = useState()
  const [loading, setLoading] = useState(true)
  const [isAllowed, setIsAllowed] = useState(false)
  const [key, setKey] = useState('')
  // eslint-disable-next-line
  const [areaUrlParam, setAreaUrlParam] = useState('')
  const [approvedAt, setApprovedAt] = useState('')

  useEffect(() => {
    (async () => {
      if (id) {
        const res = await getFirestoreDocById(id)
        if (res) {
          setFormData(res)
          if (area === 'tsu') {
            console.log('res?.p16workSupervisor', res?.p16workSupervisor)
            console.log('%cp16workSupervisor has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16workSupervisorApproved10')
            setAreaUrlParam('tsu')
            setApprovedAt('p16workSupervisorApprovedAt10')
          }
          if (area === 'pep') {
            console.log('%cp16pepCoordinator has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16pepCoordinatorApproved10')
            setAreaUrlParam('pep')
            setApprovedAt('p16pepCoordinatorApprovedAt10')
          }
          if (area === 'aco') {
            console.log('%cp16areaCoordinator has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16areaCoordinatorApproved10')
            setAreaUrlParam('aco')
            setApprovedAt('p16areaCoordinatorApprovedAt10')
          }
          if (area === 'qt') {
            console.log('%cp16quality has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16qualityApproved10')
            setAreaUrlParam('qt')
            setApprovedAt('p16qualityApprovedAt10')
          }
          if (area === 'ta') {
            console.log('%cp16technicalArea has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16technicalAreaApproved10')
            setAreaUrlParam('ta')
            setApprovedAt('p16technicalAreaApprovedAt10')
          }
          if (area === 'ps') {
            console.log('%cp16patrimonialSecurity has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16patrimonialSecurityApproved10')
            setAreaUrlParam('ps')
            setApprovedAt('p16patrimonialSecurityApprovedAt10')
          }
          if (area === 'ep') {
            console.log('%cp16externalPrevencionist has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16externalPrevencionistApproved10')
            setAreaUrlParam('ep')
            setApprovedAt('p16externalPrevencionistApprovedAt10')
          }
          if (area === 'sass') {
            console.log('%cp16sass has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16sassApproved10')
            setAreaUrlParam('sass')
            setApprovedAt('p16sassApprovedAt10')
          }
          setLoading(false)
        } else {
          setLoading(false)
        }
      }
    })()
  }, [])

  if (loading) {
    <Typography>Cargando</Typography>
  }
  console.log('formData step 10', formData)

  const approve = async (e: any) => {
    e.preventDefault()
    const approveformRef = doc(db, 'forms', id)
    const obj: any = {}
    obj[key] = true
    obj[approvedAt] = new Date()
    try {
      await updateDoc(approveformRef, obj)
      console.log('approved', key, approvedAt)
      toast.success('La aprobación se actualizó correctamente')
    } catch (updateError) {
      toast.error(`Error: ${updateError}`)
    }
  }

  const disapprove = async (e: any) => {
    e.preventDefault()
    const approveformRef = doc(db, 'forms', id)
    const obj: any = {}
    obj[key] = false
    obj[approvedAt] = new Date()
    try {
      await updateDoc(approveformRef, obj)
      console.log('disapproved', key, approvedAt)
      toast.success('La desaprobación se actualizó correctamente')
    } catch (updateError) {
      toast.error(`Error: ${updateError}`)
    }
  }

  return (
    <DashboardLayout>
      {loading ? <FullPageLoader /> : (
        <Slide direction="right" in mountOnEnter unmountOnExit>
          <Grid sx={{ mt: 4 }} container justifyContent="center" spacing={2}>
            <Grid item xs={10} sm={9} md={5} lg={7}>
              {
                (formData?.p16workSupervisor === user?.uid)
                  || (formData?.p16pepCoordinator === user?.uid)
                  || (formData?.p16areaCoordinator === user?.uid)
                  || (formData?.p16quality === user?.uid)
                  || (formData?.p16technicalArea === user?.uid)
                  || (formData?.p16patrimonialSecurity === user?.uid)
                  || (formData?.p16externalPrevencionist === user?.uid)
                  || (formData?.p16sass === user?.uid)
                  || (formData?.p16sassAllowedList?.includes(user?.uid))
                  ? (
                    <>
                      <Typography
                        sx={{ mb: 2 }}
                        align="center"
                        variant="h6"
                        color="text.secondary"
                        gutterBottom
                      >
                        USO DE QUÍMICOS
                      </Typography>

                      <Grid display="flex" justifyContent="center" item xs={12}>
                        <FormControlLabel
                          control={(
                            <YellowSwitch
                              checked={formData?.p10show}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          )}
                          label={formData?.p10show ? 'Esta página aplica' : 'Esta página no aplica'}
                        />
                      </Grid>

                      <Box
                        component="div"
                        sx={{
                          display: formData?.p10show ? 'block' : 'none',
                        }}
                      >
                        <TextField
                          sx={{ mb: 2 }}
                          fullWidth
                          label="1 - Nombre del quimico a utilizar"
                          variant="outlined"
                          value={formData?.p10chemName1}
                        />
                        <Grid display="flex">
                          <TextField
                            sx={{ mb: 2 }}
                            fullWidth
                            label="Salud"
                            variant="outlined"
                            value={formData?.p10chemName1health}
                          />
                          <TextField
                            sx={{ mb: 2, ml: 2 }}
                            fullWidth
                            label="Inflamable"
                            variant="outlined"
                            value={formData?.p10chemName1inflam}
                          />
                        </Grid>
                        <Grid display="flex">
                          <TextField
                            sx={{ mb: 2 }}
                            fullWidth
                            label="Reactividad"
                            variant="outlined"
                            value={formData?.p10chemName1reactivity}
                          />
                          <TextField
                            sx={{ mb: 2, ml: 2 }}
                            fullWidth
                            label="Específico"
                            variant="outlined"
                            value={formData?.p10chemName1specific}
                          />
                        </Grid>

                        <TextField
                          sx={{ mt: 4, mb: 2 }}
                          fullWidth
                          label="2 - Nombre del quimico a utilizar"
                          variant="outlined"
                          value={formData?.p10chemName2}
                        />
                        <Grid display="flex">
                          <TextField
                            sx={{ mb: 2 }}
                            fullWidth
                            label="Salud"
                            variant="outlined"
                            value={formData?.p10chemName2health}
                          />
                          <TextField
                            sx={{ mb: 2, ml: 2 }}
                            fullWidth
                            label="Inflamable"
                            variant="outlined"
                            value={formData?.p10chemName2inflam}
                          />
                        </Grid>
                        <Grid display="flex">
                          <TextField
                            sx={{ mb: 2 }}
                            fullWidth
                            label="Reactividad"
                            variant="outlined"
                            value={formData?.p10chemName2reactivity}
                          />
                          <TextField
                            sx={{ mb: 2, ml: 2 }}
                            fullWidth
                            label="Específico"
                            variant="outlined"
                            value={formData?.p10chemName2specific}
                          />
                        </Grid>

                        <TextField
                          sx={{ mt: 4, mb: 2 }}
                          fullWidth
                          label="3 - Nombre del quimico a utilizar"
                          variant="outlined"
                          value={formData?.p10chemName3}
                        />
                        <Grid display="flex">
                          <TextField
                            sx={{ mb: 2 }}
                            fullWidth
                            label="Salud"
                            variant="outlined"
                            value={formData?.p10chemName3health}
                          />
                          <TextField
                            sx={{ mb: 2, ml: 2 }}
                            fullWidth
                            label="Inflamable"
                            variant="outlined"
                            value={formData?.p10chemName3inflam}
                          />
                        </Grid>
                        <Grid display="flex">
                          <TextField
                            sx={{ mb: 2 }}
                            fullWidth
                            label="Reactividad"
                            variant="outlined"
                            value={formData?.p10chemName3reactivity}
                          />
                          <TextField
                            sx={{ mb: 2, ml: 2 }}
                            fullWidth
                            label="Específico"
                            variant="outlined"
                            value={formData?.p10chemName3specific}
                          />
                        </Grid>

                        <TextField
                          sx={{ mt: 4, mb: 2 }}
                          fullWidth
                          label="4 - Nombre del quimico a utilizar"
                          variant="outlined"
                          value={formData?.p10chemName4}
                        />
                        <Grid display="flex">
                          <TextField
                            sx={{ mb: 2 }}
                            fullWidth
                            label="Salud"
                            variant="outlined"
                            value={formData?.p10chemName4health}
                          />
                          <TextField
                            sx={{ mb: 2, ml: 2 }}
                            fullWidth
                            label="Inflamable"
                            variant="outlined"
                            value={formData?.p10chemName4inflam}
                          />
                        </Grid>
                        <Grid display="flex">
                          <TextField
                            sx={{ mb: 2 }}
                            fullWidth
                            label="Reactividad"
                            variant="outlined"
                            value={formData?.p10chemName4reactivity}
                          />
                          <TextField
                            sx={{ mb: 2, ml: 2 }}
                            fullWidth
                            label="Específico"
                            variant="outlined"
                            value={formData?.p10chemName4specific}
                          />
                        </Grid>

                        <TextField
                          sx={{ mt: 4, mb: 2 }}
                          fullWidth
                          label="5 - Nombre del quimico a utilizar"
                          variant="outlined"
                          value={formData?.p10chemName5}
                        />
                        <Grid display="flex">
                          <TextField
                            sx={{ mb: 2 }}
                            fullWidth
                            label="Salud"
                            variant="outlined"
                            value={formData?.p10chemName5health}
                          />
                          <TextField
                            sx={{ mb: 2, ml: 2 }}
                            fullWidth
                            label="Inflamable"
                            variant="outlined"
                            value={formData?.p10chemName5inflam}
                          />
                        </Grid>
                        <Grid display="flex" sx={{ mb: 10 }}>
                          <TextField
                            sx={{ mb: 2 }}
                            fullWidth
                            label="Reactividad"
                            variant="outlined"
                            value={formData?.p10chemName5reactivity}
                          />
                          <TextField
                            sx={{ mb: 2, ml: 2 }}
                            fullWidth
                            label="Específico"
                            variant="outlined"
                            value={formData?.p10chemName5specific}
                          />
                        </Grid>

                      </Box>

                      <Grid
                        display={isAllowed ? 'flex' : 'none'}
                        justifyContent="space-evenly"
                        sx={{ mt: 4 }}
                      >
                        <Button
                          startIcon={<HighlightOffIcon />}
                          sx={{ mb: 10, bgcolor: '#db1414' }}
                          color="error"
                          variant="contained"
                          onClick={(e) => disapprove(e)}
                        >
                          Desaprobar
                        </Button>
                        <Button
                          startIcon={<CheckIcon />}
                          sx={{ mb: 10, bgcolor: '#42b50e' }}
                          color="success"
                          variant="contained"
                          onClick={(e) => approve(e)}
                        >
                          Aprobar
                        </Button>
                      </Grid>

                      <Grid sx={{ mb: 2 }} container justifyContent="space-between">
                        <Grid
                          xs={12}
                          md={5}
                          sx={{ mb: 2 }}
                        >
                          <ColorButton
                            fullWidth
                            startIcon={<ArrowLeftIcon />}
                            onClick={goToReviseStep9}
                          >
                            Ir a la página anterior
                          </ColorButton>
                        </Grid>
                        <Grid
                          xs={12}
                          md={5}
                        >
                          <ColorButton
                            fullWidth
                            endIcon={<ArrowRightIcon />}
                            onClick={goToReviseStep11}
                          >
                            Ir a la página siguiente
                          </ColorButton>
                        </Grid>

                      </Grid>
                    </>
                  )
                  : 'Por favor inicie sesión para visualizar su documento a revisar'
              }

              {user ? (
                <Typography color="green">Usted ha iniciado sesión.</Typography>
              )
                : (
                  <Typography color="red" sx={{ mt: 2 }}>Usted no ha iniciado sesión</Typography>
                )}

            </Grid>
          </Grid>
        </Slide>
      )}

    </DashboardLayout>
  )
}

export default ReviseStep10