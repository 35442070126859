import React from 'react'
import { Children } from '../types'

type RedirectContextT = {
  newRedirect: string | null;
  setNewRedirect: React.Dispatch<React.SetStateAction<string | null>>;
  redirectActivator: boolean;
  setRedirectActivator: React.Dispatch<React.SetStateAction<boolean>>;
}
const defaultData: RedirectContextT = {
  newRedirect: null,
  setNewRedirect: () => { },
  redirectActivator: false,
  setRedirectActivator: () => { },
}

const RedirectContext = React.createContext<RedirectContextT>(defaultData)

const RedirectProvider = ({ children }: Children) => {
  const [
    newRedirect, setNewRedirect] = React.useState<string | null>(null)
  const [
    redirectActivator, setRedirectActivator] = React.useState<boolean>(false)

  React.useEffect(() => {
    (async () => {
      if (redirectActivator) {
        setNewRedirect('')
        setRedirectActivator(false)
      }
    })()
  }, [redirectActivator])

  return (
    // eslint-disable-next-line
    <RedirectContext.Provider value={{
      newRedirect,
      setNewRedirect,
      redirectActivator,
      setRedirectActivator,
    }}
    >
      {children}
    </RedirectContext.Provider>
  )
}

export { RedirectProvider, RedirectContext }