const technicalAreaList = [
  {
    email: '',
    uid: '',
    name: '',
    company: '',
    rolName: '',
  },
  {
    email: 'alejandro.lillo@pepsico.com',
    uid: 'I6njXcH9g8P67MHgVa8p8TzFX872',
    name: 'Alejandro Lillo',
    company: 'swer17wA10eNmlIhbQUv',
    rolName: 'technicalArea',
  },
  {
    name: 'Alvaro Mora',
    email: 'alvaro.mora@pepsico.com',
    uid: 'G87yBlMYL7U4gggOwSVrB7cdW5Q2',
    company: 'swer17wA10eNmlIhbQUv',
    rolName: 'technicalArea',
  },
  {
    name: 'Aran Rubilar',
    email: 'aran.rubilar@pepsico.com',
    uid: 'pjs6w7XIdmSS9MPLPAGkkqAAjJw1',
    company: 'swer17wA10eNmlIhbQUv',
    rolName: 'technicalArea',
  },
  {
    rolName: 'technicalArea',
    email: 'BEATRIZ.CARABANO@pepsico.com',
    company: 'swer17wA10eNmlIhbQUv',
    uid: 'bW6dgycVdtP81mULDWW3WKi9FPt1',

    name: 'Beatriz Carabano',
  },
  {
    name: 'Carlos Torres',
    email: 'CarlosOsvaldo.Torres@pepsico.com',
    uid: '8RMq0UWaTJY5OU4TfJXE13ivZ9Y2',

    company: 'swer17wA10eNmlIhbQUv',
    rolName: 'technicalArea',
  },
  {
    rolName: 'technicalArea',
    name: 'Cesar Kuncar',
    company: 'swer17wA10eNmlIhbQUv',
    uid: 'EbRD2TdiRWbtV8NnqmIFXk0NXxF3',

    email: 'cesar.kuncar@pepsico.com',
  },
  {
    uid: 'qYlVPy0XiDWElZrmDCSTTsENeCL2',

    name: 'Cesar Moreira',
    email: 'Cesar.Moreira@pepsico.com',
    rolName: 'technicalArea',
    company: 'swer17wA10eNmlIhbQUv',
  },
  {
    email: 'EDUARD.ARAUJOSANDIA@pepsico.com',
    name: 'Eduardo Araujo',
    uid: 'jd9fBJFoB1XPCrKctDP2USzxsCL2',
    rolName: 'technicalArea',
    company: 'swer17wA10eNmlIhbQUv',
  },
  {
    email: 'ESTEBAN.ARCE@pepsico.com',
    uid: 'HEjDjG5DWxPr0EGTeA6Zv8Sz7xk1',
    rolName: 'technicalArea',
    name: 'Esteban Arce',
    company: 'swer17wA10eNmlIhbQUv',
  },
  {
    uid: 'iCBvMBpuKeShJ5GEwlXAe2OapUN2',
    email: 'FABIAN.RAMIREZVIVANCO@pepsico.com',
    rolName: 'technicalArea',

    name: 'Fabian Ramirez',
    company: 'swer17wA10eNmlIhbQUv',
  },
  {
    uid: '0CBxYE8GxUZOlLJk9wsjLlVIe382',
    name: 'Franz Torrellas',
    rolName: 'technicalArea',

    company: 'swer17wA10eNmlIhbQUv',
    email: 'franz.torrellas@pepsico.com',
  },
  {
    email: 'GERARDO.VASQUEZ1@pepsico.com',
    uid: 'iILnLSVSEvOly3MY0GFcdTEq7Cs1',
    rolName: 'technicalArea',
    company: 'swer17wA10eNmlIhbQUv',
    name: 'Gerardo Vasquez',
  },
  {
    rolName: 'technicalArea',
    name: 'German Lazo',

    uid: 'CtCSlNqu86RjYY1A1gqYKvxtMVo2',
    company: 'swer17wA10eNmlIhbQUv',
    email: 'german.lazo@pepsico.com',
  },
  {
    uid: 'ilCMcWjIIGWibwQF5mdycriHDcB3',
    name: 'Gonzalo Morgado',
    email: 'gonzalo.morgado@pepsico.com',
    company: 'swer17wA10eNmlIhbQUv',
    rolName: 'technicalArea',
  },
  {
    name: 'Gonzalo Perez',
    email: 'gonzalo.perez1@pepsico.com',
    uid: '8AeaQ5m4RdWSojivuQO3m76o1YP2',
    company: 'swer17wA10eNmlIhbQUv',
    rolName: 'technicalArea',
  },
  {
    rolName: 'technicalArea',
    uid: 'EVL05bFWgUcearLlluJThc1GnF83',
    name: 'Gregorio Cardoza',

    company: 'swer17wA10eNmlIhbQUv',
    email: 'gregorio.cardoza@pepsico.com',
  },
  {
    name: 'Gustavo Bracamonte',
    email: 'gustavodioxomberg.bracamonteorellano@pepsico.com',
    uid: 'bmn18uwMnlYp1Ftl69xj0uG9Kje2',
    company: 'swer17wA10eNmlIhbQUv',
    rolName: 'technicalArea',
  },
  {
    rolName: 'technicalArea',
    name: 'Hugo Rivera',
    uid: 'YJqaNJUqzRVipVkFMPXiaONTSSa2',

    email: 'Hugo.RiveraFica@pepsico.com',
    company: 'swer17wA10eNmlIhbQUv',
  },
  {
    name: 'Italo Vega',
    email: 'italo.vegabriceno@pepsico.com',
    uid: 'ffWVuzRxYAVvLfGLbpO8lX98vo92',
    company: 'swer17wA10eNmlIhbQUv',
    rolName: 'technicalArea',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    uid: 'Nz5vKm6Um9ZZcmOXp8p8CGcM73l2',
    email: 'JESUS.INFANTEMIRANDA@pepsico.com',
    rolName: 'technicalArea',
    name: 'Jesus Infante',
  },
  {
    name: 'Jonathan Fuentes',
    email: 'jonathanjosue.fuentesplaza@pepsico.com',
    uid: 'IqLtXijPE8gjUsjsdncwFcVWofP2',
    company: 'swer17wA10eNmlIhbQUv',
    rolName: 'technicalArea',
  },
  {
    name: 'Jose Inostroza',
    email: 'jose.inostroza@pepsico.com',
    uid: 'RgTD593CXLcPuM2xo1nRJQW9EpF3',
    company: 'swer17wA10eNmlIhbQUv',
    rolName: 'technicalArea',
  },
  {
    name: 'Leonardo Arenas',
    company: 'swer17wA10eNmlIhbQUv',
    rolName: 'technicalArea',

    uid: 'Co0ryQjOYoSLYMNjSKlvziwvBFZ2',
    email: 'Leonardo.ArenasSoto@pepsico.com',
  },
  {
    email: 'luis.figueroa1@pepsico.com',
    rolName: 'technicalArea',
    name: 'Luis Figueroa',
    company: 'swer17wA10eNmlIhbQUv',
    uid: 'VfAUFtKicJZzijEDDiW3JQUNDUs1',
  },
  {
    name: 'Nicolas Altamirano',
    email: 'nicolas.altamirano@pepsico.com',
    uid: '8uec78JvtEc4mSZc2kpuVasfWAX2',
    company: 'swer17wA10eNmlIhbQUv',
    rolName: 'technicalArea',
  },
  {
    rolName: 'technicalArea',
    email: 'NICOLAS.DUQUES@pepsico.com',
    company: 'swer17wA10eNmlIhbQUv',
    uid: '2PfcT2Hl3RWf7ZgiGNCxlLfK9eq1',

    name: 'Nicolas Duques',
  },
  {
    email: 'sebastian.alvarado1@pepsico.com',

    rolName: 'technicalArea',
    company: 'swer17wA10eNmlIhbQUv',
    name: 'Sebastian Alvarado',
    uid: 'CkuJflB5Z8gCfotC5aBxiu9dzR03',
  },
  {
    email: 'SEBASTIAN.FERNANDEZARAYA@pepsico.com',
    uid: '43sQouyOerTzzSD4p8BhypqpwG42',
    name: 'Sebastian Fernandez',
    rolName: 'technicalArea',
    company: 'swer17wA10eNmlIhbQUv',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    rolName: 'technicalArea',
    uid: 'xxpqsE1rgVORUdkqAAaCzN5vVNr2',
    name: 'Sergio Guajardo',
    email: 'SERGIO.GUAJARDO@pepsico.com',
  },
  {
    name: 'Stefani Cataldo',
    email: 'stefani.cataldo@pepsico.com',
    uid: 'S9J4zoCOZEMFjnhSBb2nOWm7EEB2',
    company: 'swer17wA10eNmlIhbQUv',
    rolName: 'technicalArea',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    rolName: 'technicalArea',
    email: 'VICTORANDRES.CHAVEZALLENDE@pepsico.com',

    uid: 'Vx2ubTtENCVOtFdlzomfo1kwX6r1',
    name: 'Victor Chavez',
  },
]
export default technicalAreaList