const sassList = [
  {
    id: '',
    rolName: '',
    email: '',
    uid: '',

    name: '',
    company: '',
  },
  {
    id: '6vE95NzA0JSFIdwLFQypvg6YY0q1NE9I5',
    rolName: 'sass',
    email: 'aaron.calderon.contractor@pepsico.com',
    uid: 'j5ZHSzwXm0XHRfLGy5Grz6TwiuA2',

    name: 'Aaron Calderon',
    company: 'swer17wA10eNmlIhbQUv',
  },
  {
    id: 'S0gQUQBdR7jlP2AYU4Qv',
    rolName: 'sass',
    email: 'CESARENRIQUE.SALASCARRERO@pepsico.com',
    uid: '0g85M2QTKBSfgIm19MfFK6msdl63',

    name: 'Cesar Salas',
    company: 'swer17wA10eNmlIhbQUv',
  },
  {
    id: 'SSZUw8yLNJM5ey6bwo1Wbj0XUgr2',
    rolName: 'sass',
    email: 'cybil.fuentes@pepsico.com',
    uid: 'SSZUw8yLNJM5ey6bwo1Wbj0XUgr2',

    name: 'Cybil Fuentes',
    company: 'swer17wA10eNmlIhbQUv',
  },
  {
    id: 'pzqcv2ENjOJkci9b9zT6',
    name: 'Diego Neira',
    email: 'DIEGOIGNACIO.NEIRAROCUANT@pepsico.com',
    rolName: 'sass',
    company: 'swer17wA10eNmlIhbQUv',
    uid: 'qNaGcdkV74ft1PptYcBrSqCWB0P2',
  },
  {
    id: 's6SUa251soQwvyC6RAkh',
    name: 'Gonzalo Perez',
    email: 'GONZALO.PEREZ1@pepsico.com',

    company: 'swer17wA10eNmlIhbQUv',
    uid: '8AeaQ5m4RdWSojivuQO3m76o1YP2',
    rolName: 'sass',
  },
  {
    id: 'IAt7Hz3pxfk8H015M3Lr',
    uid: 'ziObrv7ctgUsomFvC95GIscvjEb2',
    name: 'Jaime Fonseca',
    company: 'swer17wA10eNmlIhbQUv',
    email: 'JAIMESEBASTIAN.FONSECAMARCHANT@pepsico.com',

    rolName: 'sass',
  },
  {
    id: 'lJi6bGvCE7yuHHlOcxhn',

    name: 'John Cabrera',
    company: 'swer17wA10eNmlIhbQUv',
    email: 'drjohncabrera@gmail.com',
    rolName: 'sass',
    uid: 'MYXW4y4JAUXqIMLleZP12PEb0O63',
  },
  {
    id: '8e6CQWuPKaeK6MKwZsUj',

    uid: 'QJxTXd7mHghpL1crOfxvFm1NZmh1',
    name: 'Lucia Mardones',
    email: 'lucia.mardones@pepsico.com',
    rolName: 'sass',
    company: 'swer17wA10eNmlIhbQUv',
  },
  {
    id: 'F3zt3mHc9hv3yuX09Gb9',

    rolName: 'sass',
    email: 'mariana.lemus@pepsico.com',
    name: 'Mariana Lemus',
    company: 'swer17wA10eNmlIhbQUv',
    uid: 'SJ59ObQ38mZZ5kXUmPN7l1b04N02',
  },
  {
    id: 'H9JoQL5B7Mou5pPlgFFx',
    uid: 'GtDdJPjZ8sUZR1vdXk8htSG8ycN2',
    name: 'Marlite Torres',

    email: 'marlite.torres@pepsico.com',
    rolName: 'sass',
    company: 'swer17wA10eNmlIhbQUv',
  },
  {
    id: 'DSjk8pkmxPmzHJL609Qi',
    company: 'swer17wA10eNmlIhbQUv',
    email: 'MATIAS.LIRAWATKINS@pepsico.com',
    name: 'Matias Lira',

    uid: 'D6iXBu9P0kYeWZlzSir3Ohww1Ff2',
    rolName: 'sass',
  },
  {
    id: 'jIXrQJOcis6Bp7akhOFW',
    rolName: 'sass',
    uid: 'FI98jy4JtsQu9pBevQniWf5p3ct2',
    email: 'naltamirano.l@gmail.com',

    company: 'swer17wA10eNmlIhbQUv',
    name: 'Nicolas Altamirano',
  },
  {
    id: 'PYJSAfIU28DFilZhjMuO',

    email: 's.cataldoramos@gmail.com',
    rolName: 'sass',
    uid: '9MvTXZMycHc0mfRPhsfHVkMrMAw1',
    name: 'Stefani Cataldo',
    company: 'swer17wA10eNmlIhbQUv',
  },
  {
    id: 'MqnlIqrbpxIPA8K8ZCt2',
    rolName: 'sass',
    name: 'Yesenia Morales',

    company: 'swer17wA10eNmlIhbQUv',
    email: 'yesenia.morales@pepsico.com',
    uid: 'aOVTsxZNwQW6QNRyFgojO074iwC2',
  },
  {
    id: '',
    name: 'Max Vergara',
    email: 'max.vergara.contractor@pepsico.com',
    rolName: 'sass',
    company: 'swer17wA10eNmlIhbQUv',
    uid: 'LoXlGxY0xjU44onK4SzreCgTEO43',
  },
]
export default sassList