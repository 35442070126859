import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import formReducer from '../features/form/formSlice'

export const store = configureStore({
  reducer: {
    form: formReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

export const entireStore = (state: RootState) => state.form
export const selectP2CompanyName = (state: RootState) => state.form.p2companyName