import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Slide from '@mui/material/Slide'
import { Link } from 'react-router-dom'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import DashboardLayout from '../layouts/DashboardLayout'
import ColorButton from './ColorButton'
import useNewFormId from '../../hooks/useNewFormId'

const CreateStep1 = () => {
  console.log('CreateStep1')
  const { newFormId } = useNewFormId()

  return (
    <DashboardLayout>
      <Slide
        direction="right"
        in
        mountOnEnter
        unmountOnExit
      >
        <Grid sx={{ mt: 4 }} container justifyContent="center">
          <Grid item xs={10} sm={9} md={5} lg={7}>
            <Card sx={{ p: 2, mb: 10 }}>
              <CardContent>
                <Typography
                  variant="h6"
                  color="text.secondary"
                  gutterBottom
                >
                  PERMISO DE TRABAJO INTEGRAL
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                >
                  El permiso
                  de trabajo representa
                  una guía para la identificación
                  de peligros y riesgos del
                  trabajo a ejecutar dentro
                  del área concesionada. Esta
                  guía no reemplaza las
                  obligaciones de la
                  normativa legal vigente
                  establecidas para los temas
                  relacionados en
                  Seguridad, Salud Ocupacional.
                </Typography>
              </CardContent>
            </Card>
            <Grid container justifyContent="space-between">
              <Grid
                xs={12}
                md={5}
              >
                <Link
                  to="/"
                  style={{ color: 'white', textDecoration: 'none' }}
                >
                  <ColorButton
                    sx={{ mt: 4 }}
                    fullWidth
                    startIcon={<ArrowLeftIcon />}
                  >
                    Ir al inicio
                  </ColorButton>
                </Link>
              </Grid>
              <Grid
                xs={12}
                md={5}
              >
                <Link
                  to={`/create/2/${newFormId}`}
                  style={{ color: 'white', textDecoration: 'none' }}
                >
                  <ColorButton
                    sx={{ mt: 4 }}
                    fullWidth
                    endIcon={<ArrowRightIcon />}
                  >
                    Ir a la página siguiente
                  </ColorButton>
                </Link>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </DashboardLayout>
  )
}

export default CreateStep1