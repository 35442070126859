import React from 'react'
import { Children } from '../types'

type DateContextT = {
  p2startTime: Date | null;
  p2finishTime: Date | null;
  setp2startTime: React.Dispatch<React.SetStateAction<Date | null>>;
  setp2finishTime: React.Dispatch<React.SetStateAction<Date | null>>;
}
const defaultDate: DateContextT = {
  p2startTime: new Date() || null,
  p2finishTime: new Date() || null,
  setp2startTime: () => { },
  setp2finishTime: () => { },
}

const DateContext = React.createContext<DateContextT>(defaultDate)

const DateProvider = ({ children }: Children) => {
  const [
    p2startTime, setp2startTime,
  ] = React.useState<Date | null>(null)
  const [
    p2finishTime, setp2finishTime,
  ] = React.useState<Date | null>(null)

  return (
    // eslint-disable-next-line
    <DateContext.Provider value={{
      p2startTime,
      setp2startTime,
      p2finishTime,
      setp2finishTime,
    }}
    >
      {children}
    </DateContext.Provider>
  )
}

export { DateProvider, DateContext }