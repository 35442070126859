import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Slide from '@mui/material/Slide'
// import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { toast } from 'react-toastify'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { entireStore } from '../../app/store'
import { allFormActions } from '../../features/form/formSlice'
import YellowSwitch from '../../common/YellowSwitch'
import DashboardLayout from '../layouts/DashboardLayout'
import ColorButton from './ColorButton'
import useNewFormId from '../../hooks/useNewFormId'
import useStorage from '../../hooks/useStorage'
import { db, doc, updateDoc } from '../../config/firebaseConfig'

const CreateStep9 = () => {
  console.log('CreateStep9')

  const imageTypes = ['image/png', 'image/jpeg', 'image/jpg']

  const [file, setFile] = React.useState<File | null>(null)
  const [fileID, setFileID] = React.useState<String | null>(null)
  const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(false)

  const { newFormId } = useNewFormId()

  const { url, progress, error } = useStorage(file)

  const customIdFileUploadFinish = 'custom-id-file-upload-finish'
  React.useEffect(() => {
    if (progress > 1) {
      setButtonDisabled(true)
    }
    if (progress === 100) {
      setButtonDisabled(false)
      toast.success('La imagen ha sido subida correctamente', {
        toastId: customIdFileUploadFinish,
      })
      console.log('fileid', fileID)

      localStorage.setItem(`${fileID}`, url)
      console.log(url)
    }
    if (error) {
      // error es any
      setButtonDisabled(false)
      toast.error(error)
    }
  }, [progress, url, file])

  const {
    p9Show,
    p9eslingasCheck,
    p9ancPointCheck,
    p9securityPerimeter,
    p9riggerParticipation,
    p9transitAreas,
    p9informBlockAreasToJef,
    p9areaDelimitation,
    p9truckPrevCheck,

  } = useAppSelector(entireStore)
  const {
    setp9Show,
    setp9eslingasCheck,
    setp9ancPointCheck,
    setp9securityPerimeter,
    setp9riggerParticipation,
    setp9transitAreas,
    setp9informBlockAreasToJef,
    setp9areaDelimitation,
    setp9truckPrevCheck,

  } = allFormActions
  const dispatch = useAppDispatch()

  const history = useHistory()

  const goToCreateStep8 = () => {
    history.push(`/create/8/${newFormId}`)
  }

  const goToCreateStep10 = async () => {
    // history.push(`/create/10/${newFormId}`)
    const formRef = doc(db, 'forms', newFormId as string)
    try {
      await updateDoc(formRef, {
        newFormp9FilledOut: true,
        newFormp9FilledOutDate: new Date(),
        p9Show,
        p9eslingasCheck,
        p9ancPointCheck,
        p9securityPerimeter,
        p9riggerParticipation,
        p9transitAreas,
        p9informBlockAreasToJef,
        p9areaDelimitation,
        p9truckPrevCheck,
      })
      toast.success('El permiso de trabajo se actualizó correctamente')
      history.push(`/create/10/${newFormId}`)
    } catch (updateError) {
      toast.error(`Error: ${updateError}`)
    }
  }

  const Input = styled('input')({
    display: 'none',
  })

  return (
    <DashboardLayout>
      <Slide direction="right" in mountOnEnter unmountOnExit>
        <Grid sx={{ mt: 4 }} container justifyContent="center" spacing={2}>
          <Grid item xs={10} sm={9} md={5} lg={7}>
            <Typography
              sx={{ mb: 2 }}
              align="center"
              variant="h6"
              color="text.secondary"
              gutterBottom
            >
              IZAJE
            </Typography>

            <Grid display="flex" justifyContent="center" item xs={12}>
              <FormControlLabel
                control={(
                  <YellowSwitch
                    checked={p9Show}
                    onChange={(event) => dispatch(setp9Show(event.target.checked))}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                )}
                label={p9Show ? 'Esta página aplica' : 'Esta página no aplica'}
              />
            </Grid>

            <Box
              component="div"
              sx={{
                display: p9Show ? 'block' : 'none',
              }}
            >
              <Box sx={{
                display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
              }}
              >
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">Revisión Eslingas</FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="atrapamiento"
                    name="atrapamiento"
                    value={p9eslingasCheck}
                    onChange={(event) => dispatch(setp9eslingasCheck(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>

                { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(e) => {
                      if (e.target.files) {
                        const selectedFile = e?.target?.files[0]
                        console.table(selectedFile)
                        if (selectedFile && imageTypes.includes(selectedFile.type)) {
                          setFile(selectedFile)
                        } else {
                          toast.error('El archivo no es una imagen, por favor intente nuevamente')
                        }
                      }
                    }}
                  />
                  <Button
                    sx={{ bgcolor: '#fbc02d', mb: 2 }}
                    variant="contained"
                    component="span"
                    disabled={buttonDisabled}
                    onClick={() => setFileID('p9eslingasCheckImage')}
                  >
                    {buttonDisabled
                      ? 'Subiendo imagen'
                      : 'Subir imagen'}
                  </Button>
                </label>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">Revisión de puntos de anclaje</FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="aplastamiento"
                    name="aplastamiento"
                    value={p9ancPointCheck}
                    onChange={(event) => dispatch(setp9ancPointCheck(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Grid display="flex" justifyContent="center" sx={{ mb: 2 }} item xs={12}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">Establecer perímetro de seguridad (indicar área)</FormLabel>
                  <Grid display="flex" justifyContent="center" item xs={12}>
                    <RadioGroup
                      sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                      aria-label="golpeadorpor"
                      name="golpeadorpor"
                      value={p9securityPerimeter}
                      onChange={(event) => dispatch(setp9securityPerimeter(event.target.value))}
                    >
                      <FormControlLabel value="SI" control={<Radio />} label="SI" />
                      <FormControlLabel value="NO" control={<Radio />} label="NO" />
                      <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                    </RadioGroup>
                  </Grid>
                </FormControl>
              </Grid>

              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">Participación RIGGER</FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="golpeadocon"
                    name="golpeadocon"
                    value={p9riggerParticipation}
                    onChange={(event) => dispatch(setp9riggerParticipation(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">
                    Requiere bloquear áreas de tránsito
                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="caida"
                    name="caida"
                    value={p9transitAreas}
                    onChange={(event) => dispatch(setp9transitAreas(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">
                    Informa a jefaturas de áreas a bloquear
                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="contactoconobjetoscortantes"
                    name="contactoconobjetoscortantes"
                    value={p9informBlockAreasToJef}
                    onChange={(event) => dispatch(setp9informBlockAreasToJef(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">
                    Delimitación del área
                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="contactoconobjetoscalientes"
                    name="contactoconobjetoscalientes"
                    value={p9areaDelimitation}
                    onChange={(event) => dispatch(setp9areaDelimitation(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 10 }} component="fieldset">
                  <FormLabel component="legend">
                    Chequeo previo del camión
                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="proyecciondeparticulas"
                    name="proyecciondeparticulas"
                    value={p9truckPrevCheck}
                    onChange={(event) => dispatch(setp9truckPrevCheck(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>

                { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(e) => {
                      if (e.target.files) {
                        const selectedFile = e?.target?.files[0]
                        console.table(selectedFile)
                        if (selectedFile && imageTypes.includes(selectedFile.type)) {
                          setFile(selectedFile)
                        } else {
                          toast.error('El archivo no es una imagen, por favor intente nuevamente')
                        }
                      }
                    }}
                  />
                  <Button
                    sx={{ bgcolor: '#fbc02d', mb: 2 }}
                    variant="contained"
                    component="span"
                    disabled={buttonDisabled}
                    onClick={() => setFileID('p9truckPrevCheckImage')}
                  >
                    {buttonDisabled
                      ? 'Subiendo imagen'
                      : 'Subir imagen'}
                  </Button>
                </label>
              </Box>
            </Box>
            <Grid sx={{ mb: 2 }} container justifyContent="space-between">
              <Grid
                xs={12}
                md={5}
                sx={{ mb: 2 }}
              >
                <ColorButton
                  fullWidth
                  startIcon={<ArrowLeftIcon />}
                  onClick={goToCreateStep8}
                >
                  Ir a la página anterior
                </ColorButton>
              </Grid>
              <Grid
                xs={12}
                md={5}
              >
                <ColorButton
                  fullWidth
                  endIcon={<ArrowRightIcon />}
                  onClick={goToCreateStep10}
                >
                  Ir a la página siguiente
                </ColorButton>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </DashboardLayout>
  )
}

export default CreateStep9