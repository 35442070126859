import React from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Slide from '@mui/material/Slide'
import Checkbox from '@mui/material/Checkbox'
import DateTimePicker from '@mui/lab/DateTimePicker'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { entireStore } from '../../app/store'
import { allFormActions } from '../../features/form/formSlice'
import YellowSwitch from '../../common/YellowSwitch'
import DashboardLayout from '../layouts/DashboardLayout'
import ColorButton from './ColorButton'
import useNewFormId from '../../hooks/useNewFormId'
import { db, doc, updateDoc } from '../../config/firebaseConfig'

const CreateStep15 = () => {
  console.log('CreateStep15')

  const { newFormId } = useNewFormId()

  // const [personName, setPersonName] = React.useState<string[]>([])

  // console.log('person name:', personName)

  const {
    p15show,
    p15blockingPersonName,
    p15dateTimeLotoStart,
    p15dateTimeLotoFinish,
    // p15blockingEnType,
    p15dangEnergiesControl,
    p15knowEnergySourceMachine,
    p15concLoto,
    p15hasLockIdCardBlockDevice,
    p15dielectricGlove,
    p15dielectricSecurityShoe,
    p15dielectricSecurityGlass,
    p15blockingEnTypeElectrical,
    p15blockingEnTypeHidraulical,
    p15blockingEnMechanical,
    p15blockingEnPotencial,
  } = useAppSelector(entireStore)
  const {
    setp15show,
    setp15blockingPersonName,
    setp15dateTimeLotoStart,
    setp15dateTimeLotoFinish,
    // setp15blockingEnType,
    setp15dangEnergiesControl,
    setp15knowEnergySourceMachine,
    setp15concLoto,
    setp15hasLockIdCardBlockDevice,
    setp15dielectricGlove,
    setp15dielectricSecurityShoe,
    setp15dielectricSecurityGlass,
    setp15blockingEnTypeElectrical,
    setp15blockingEnTypeHidraulical,
    setp15blockingEnMechanical,
    setp15blockingEnPotencial,
  } = allFormActions
  const dispatch = useAppDispatch()

  const history = useHistory()

  const goToCreateStep14 = () => {
    history.push(`/create/14/${newFormId}`)
  }

  const goToCreateStep16 = async () => {
    if (p15show && !p15blockingPersonName) {
      toast.error('Por favor ingrese el nombre del personal a cargo del bloqueo')
    } else if (p15show && !p15dateTimeLotoStart) {
      toast.error('Por favor ingrese Fecha / Hora inicio del LOTO')
    } else if (p15show && !p15dateTimeLotoFinish) {
      toast.error('Por favor ingrese Fecha / Hora finalización del LOTO')
    } else if (
      p15show
      && !p15blockingEnTypeElectrical
      && !p15blockingEnTypeHidraulical
      && !p15blockingEnMechanical
      && !p15blockingEnPotencial) {
      toast.error('Por favor seleccione un tipo de energía a bloquear')
    } else {
      const formRef = doc(db, 'forms', newFormId as string)
      try {
        await updateDoc(formRef, {
          newFormp15FilledOut: true,
          newFormp15FilledOutDate: new Date(),
          p15show,
          p15blockingPersonName,
          p15dateTimeLotoStart,
          p15dateTimeLotoFinish,
          p15dangEnergiesControl,
          p15knowEnergySourceMachine,
          p15concLoto,
          p15hasLockIdCardBlockDevice,
          p15dielectricGlove,
          p15dielectricSecurityShoe,
          p15dielectricSecurityGlass,
          p15blockingEnTypeElectrical,
          p15blockingEnTypeHidraulical,
          p15blockingEnMechanical,
          p15blockingEnPotencial,
        })
        toast.success('El permiso de trabajo se actualizó correctamente')
        history.push(`/create/16/${newFormId}`)
      } catch (updateError) {
        toast.error(`Error: ${updateError}`)
      }
    }
  }

  return (
    <DashboardLayout>
      <Slide direction="right" in mountOnEnter unmountOnExit>
        <Grid sx={{ mt: 4 }} container justifyContent="center" spacing={2}>
          <Grid item xs={10} sm={9} md={5} lg={7}>
            <Typography
              sx={{ mb: 2 }}
              align="center"
              variant="h6"
              color="text.secondary"
              gutterBottom
            >
              BLOQUEO DE ENERGÍAS
            </Typography>

            <Grid display="flex" justifyContent="center" item xs={12}>
              <FormControlLabel
                control={(
                  <YellowSwitch
                    checked={p15show}
                    onChange={(event) => dispatch(setp15show(event.target.checked))}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                )}
                label={p15show ? 'Esta página aplica' : 'Esta página no aplica'}
              />
            </Grid>

            <Box
              component="div"
              sx={{
                display: p15show ? 'block' : 'none',
              }}
            >
              <Grid display="flex" justifyContent="center" item xs={12}>
                <TextField
                  sx={{ mt: 2, mb: 2, width: 300 }}
                  fullWidth
                  label="Nombre del personal a cargo del bloqueo : "
                  variant="outlined"
                  value={p15blockingPersonName}
                  onChange={(e) => dispatch(setp15blockingPersonName(e.target.value))}
                />
              </Grid>
              <Grid sx={{ mt: 2 }} display="flex" justifyContent="center" alignItems="center">
                <DateTimePicker
                  // eslint-disable-next-line
                  renderInput={(props) => <TextField sx={{ width: 300 }} {...props} />}
                  label="Fecha / Hora inicio del LOTO : "
                  value={p15dateTimeLotoStart}
                  onChange={(newValue) => {
                    dispatch(setp15dateTimeLotoStart(newValue))
                  }}
                />
              </Grid>
              <Grid sx={{ mt: 4 }} display="flex" justifyContent="center" alignItems="center">
                <DateTimePicker
                  // eslint-disable-next-line
                  renderInput={(props) => <TextField sx={{ width: 300 }} {...props} />}
                  label="Fecha / Hora finalización del LOTO : "
                  value={p15dateTimeLotoFinish}
                  onChange={(newValue) => {
                    dispatch(setp15dateTimeLotoFinish(newValue))
                  }}
                />
              </Grid>
              <Typography
                sx={{ mt: 4 }}
                textAlign="center"
              >
                Tipo de Energía a Bloquear:
              </Typography>
              <Grid container sx={{ mt: 1 }}>

                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography>Eléctrica</Typography>
                  <Checkbox
                    checked={p15blockingEnTypeElectrical}
                    onChange={
                      (event) => dispatch(setp15blockingEnTypeElectrical(event.target.checked))
                    }
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography>Hidraulica</Typography>
                  <Checkbox
                    checked={p15blockingEnTypeHidraulical}
                    onChange={(event) => dispatch(setp15blockingEnTypeHidraulical(
                      event.target.checked,
                    ))}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography>Mecánica</Typography>
                  <Checkbox
                    checked={p15blockingEnMechanical}
                    onChange={(event) => dispatch(setp15blockingEnMechanical(event.target.checked))}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography>Potencial</Typography>
                  <Checkbox
                    checked={p15blockingEnPotencial}
                    onChange={(event) => dispatch(setp15blockingEnPotencial(event.target.checked))}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </Grid>
              </Grid>

              <Box sx={{
                display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
              }}
              >
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">Capacitacion control de energias peligrosas</FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                    aria-label="atrapamiento"
                    name="atrapamiento"
                    value={p15dangEnergiesControl}
                    onChange={(event) => dispatch(setp15dangEnergiesControl(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">Conoce las fuentes de energias de la maquina</FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                    aria-label="aplastamiento"
                    name="aplastamiento"
                    value={p15knowEnergySourceMachine}
                    onChange={
                      (event) => dispatch(setp15knowEnergySourceMachine(event.target.value))
                    }
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">Conoc del LOTO</FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                    aria-label="golpeadorpor"
                    name="golpeadorpor"
                    value={p15concLoto}
                    onChange={(event) => dispatch(setp15concLoto(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend" sx={{ textAlign: 'center' }}>Posee candado de bloqueo, tarjeta de identificacion y dispositivo de bloqueo</FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                    aria-label="golpeadorpor"
                    name="golpeadorpor"
                    value={p15hasLockIdCardBlockDevice}
                    onChange={
                      (event) => dispatch(setp15hasLockIdCardBlockDevice(event.target.value))
                    }
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">Guantes dielectricos</FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                    aria-label="golpeadorpor"
                    name="golpeadorpor"
                    value={p15dielectricGlove}
                    onChange={(event) => dispatch(setp15dielectricGlove(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">Zapatos de seguridad dielectrico</FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                    aria-label="golpeadorpor"
                    name="golpeadorpor"
                    value={p15dielectricSecurityShoe}
                    onChange={(event) => dispatch(setp15dielectricSecurityShoe(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 10 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">Lentes de seguridad dielectrico</FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                    aria-label="golpeadorpor"
                    name="golpeadorpor"
                    value={p15dielectricSecurityGlass}
                    onChange={
                      (event) => dispatch(setp15dielectricSecurityGlass(event.target.value))
                    }
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
            <Grid sx={{ mb: 2 }} container justifyContent="space-between">
              <Grid
                item
                xs={12}
                md={5}
                sx={{ mb: 2 }}
              >
                <ColorButton
                  fullWidth
                  startIcon={<ArrowLeftIcon />}
                  onClick={goToCreateStep14}
                >
                  Ir a la página anterior
                </ColorButton>
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
              >
                <ColorButton
                  fullWidth
                  endIcon={<ArrowRightIcon />}
                  onClick={goToCreateStep16}
                >
                  Ir a la página siguiente
                </ColorButton>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </DashboardLayout>
  )
}

export default CreateStep15