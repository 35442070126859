import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Slide from '@mui/material/Slide'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { entireStore } from '../../app/store'
import { allFormActions } from '../../features/form/formSlice'
import DashboardLayout from '../layouts/DashboardLayout'
import useNewFormId from '../../hooks/useNewFormId'
import { db, doc, updateDoc } from '../../config/firebaseConfig'

import ColorButton from './ColorButton'

const CreateStep5 = () => {
  console.log('CreateStep5')

  const { newFormId } = useNewFormId()

  const {
    p5atrapam,
    p5aplastam,
    p5golpeadopor,
    p5golpeadocon,
    p5caida,
    p5contConObjCort,
    p5contConObjCal,
    p5proyPartic,
    p5contactEnElectric,
    p5radiacionUv,
    p5contQuimico,
    p5exposicPolvo,
    p5exposicRuido,
    p5atropello,
    p5caidaMatAltura,
    p5estresTerm,
    p5colisionChoque,
    p5deficienciao2,
    p5explosion,
    p5incendio,
    p5derrumbe,
    p5exposicGas,
    p5immersion,
    p5fatigMaterial,
  } = useAppSelector(entireStore)
  const {
    setp5atrapam,
    setp5aplastam,
    setp5golpeadopor,
    setp5golpeadocon,
    setp5caida,
    setp5contConObjCort,
    setp5contConObjCal,
    setp5proyPartic,
    setp5contactEnElectric,
    setp5radiacionUv,
    setp5contQuimico,
    setp5exposicPolvo,
    setp5exposicRuido,
    setp5atropello,
    setp5caidaMatAltura,
    setp5estresTerm,
    setp5colisionChoque,
    setp5deficienciao2,
    setp5explosion,
    setp5incendio,
    setp5derrumbe,
    setp5exposicGas,
    setp5immersion,
    setp5fatigMaterial,
  } = allFormActions
  const dispatch = useAppDispatch()

  const history = useHistory()

  const goToCreateStep4 = () => {
    history.push(`/create/4/${newFormId}`)
  }

  const goToCreateStep6 = async () => {
    const formRef = doc(db, 'forms', newFormId as string)
    try {
      await updateDoc(formRef, {
        newFormp5FilledOut: true,
        newFormp5FilledOutDate: new Date(),
        p5atrapam,
        p5aplastam,
        p5golpeadopor,
        p5golpeadocon,
        p5caida,
        p5contConObjCort,
        p5contConObjCal,
        p5proyPartic,
        p5contactEnElectric,
        p5radiacionUv,
        p5contQuimico,
        p5exposicPolvo,
        p5exposicRuido,
        p5atropello,
        p5caidaMatAltura,
        p5estresTerm,
        p5colisionChoque,
        p5deficienciao2,
        p5explosion,
        p5incendio,
        p5derrumbe,
        p5exposicGas,
        p5immersion,
        p5fatigMaterial,
      })
      toast.success('El permiso de trabajo se actualizó correctamente')
      history.push(`/create/6/${newFormId}`)
    } catch (updateError) {
      toast.error(`Error: ${updateError}`)
    }
  }

  return (
    <DashboardLayout>
      <Slide direction="right" in mountOnEnter unmountOnExit>
        <Grid sx={{ mt: 4 }} container justifyContent="center" spacing={2}>
          <Grid item xs={10} sm={9} md={5} lg={7}>
            <Typography
              sx={{ mb: 2 }}
              align="center"
              variant="h6"
              color="text.secondary"
              gutterBottom
            >
              RIESGOS ASOCIADOS
            </Typography>

            <Box sx={{
              display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
            }}
            >
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Atrapamiento</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="atrapamiento"
                  name="atrapamiento"
                  value={p5atrapam}
                  onChange={(e) => dispatch(setp5atrapam(e.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Aplastamiento</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="aplastamiento"
                  name="aplastamiento"
                  value={p5aplastam}
                  onChange={(e) => dispatch(setp5aplastam(e.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Golpeado por</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="golpeadorpor"
                  name="golpeadorpor"
                  value={p5golpeadopor}
                  onChange={(e) => dispatch(setp5golpeadopor(e.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Golpeado con</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="golpeadocon"
                  name="golpeadocon"
                  value={p5golpeadocon}
                  onChange={(e) => dispatch(setp5golpeadocon(e.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Caída (mismo y distinto nivel)</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="caida"
                  name="caida"
                  value={p5caida}
                  onChange={(e) => dispatch(setp5caida(e.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">
                  Contacto con objetos cortantes
                </FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="contactoconobjetoscortantes"
                  name="contactoconobjetoscortantes"
                  value={p5contConObjCort}
                  onChange={(e) => dispatch(setp5contConObjCort(e.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">
                  Contacto con objetos calientes
                </FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="contactoconobjetoscalientes"
                  name="contactoconobjetoscalientes"
                  value={p5contConObjCal}
                  onChange={(e) => dispatch(setp5contConObjCal(e.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">
                  Proyección de particulas
                </FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="proyecciondeparticulas"
                  name="proyecciondeparticulas"
                  value={p5proyPartic}
                  onChange={(e) => dispatch(setp5proyPartic(e.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">
                  Contacto con energía eléctrica
                </FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="contactoconenergiaelectrica"
                  name="contactoconenergiaelectrica"
                  value={p5contactEnElectric}
                  onChange={(e) => dispatch(setp5contactEnElectric(e.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Radiación UV</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="radiacionuv"
                  name="radiacionuv"
                  value={p5radiacionUv}
                  onChange={(e) => dispatch(setp5radiacionUv(e.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Contacto con químicos</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="contactoconquimicos"
                  name="contactoconquimicos"
                  value={p5contQuimico}
                  onChange={(e) => dispatch(setp5contQuimico(e.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Exposición a Polvo</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="exposicionapolvo"
                  name="exposicionapolvo"
                  value={p5exposicPolvo}
                  onChange={(e) => dispatch(setp5exposicPolvo(e.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Exposición a Ruido</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="exposicionaruido"
                  name="exposicionaruido"
                  value={p5exposicRuido}
                  onChange={(e) => dispatch(setp5exposicRuido(e.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Atropello</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="atropello"
                  name="atropello"
                  value={p5atropello}
                  onChange={(e) => dispatch(setp5atropello(e.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Caída de materiales de altura</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="caidadematerialesdealtura"
                  name="caidadematerialesdealtura"
                  value={p5caidaMatAltura}
                  onChange={(e) => dispatch(setp5caidaMatAltura(e.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Estrés Térmico</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="estrestermico"
                  name="estrestermico"
                  value={p5estresTerm}
                  onChange={(e) => dispatch(setp5estresTerm(e.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Colisión / Choque</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="colisionchoque"
                  name="colisionchoque"
                  value={p5colisionChoque}
                  onChange={(e) => dispatch(setp5colisionChoque(e.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Deficiencia de O2</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="deficienciade02"
                  name="deficienciade02"
                  value={p5deficienciao2}
                  onChange={(e) => dispatch(setp5deficienciao2(e.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Explosión</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="explosion"
                  name="explosion"
                  value={p5explosion}
                  onChange={(e) => dispatch(setp5explosion(e.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Incendio</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="incendio"
                  name="incendio"
                  value={p5incendio}
                  onChange={(e) => dispatch(setp5incendio(e.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Derrumbe / Desprendimiento</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="derrumbedesprendimiento"
                  name="derrumbedesprendimiento"
                  value={p5derrumbe}
                  onChange={(e) => dispatch(setp5derrumbe(e.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Exposición a Gases</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="exposicionagases"
                  name="exposicionagases"
                  value={p5exposicGas}
                  onChange={(e) => dispatch(setp5exposicGas(e.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Inmersión</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="inmersion"
                  name="inmersion"
                  value={p5immersion}
                  onChange={(e) => dispatch(setp5immersion(e.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 10 }} component="fieldset">
                <FormLabel component="legend">Fatiga de materiales</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="fatigademateriales"
                  name="fatigademateriales"
                  value={p5fatigMaterial}
                  onChange={(e) => dispatch(setp5fatigMaterial(e.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>
            <Grid sx={{ mb: 2 }} container justifyContent="space-between">
              <Grid
                xs={12}
                md={5}
                sx={{ mb: 2 }}
              >
                <ColorButton
                  fullWidth
                  startIcon={<ArrowLeftIcon />}
                  onClick={goToCreateStep4}
                >
                  Ir a la página anterior
                </ColorButton>
              </Grid>
              <Grid
                xs={12}
                md={5}
              >
                <ColorButton
                  fullWidth
                  endIcon={<ArrowRightIcon />}
                  onClick={goToCreateStep6}
                >
                  Ir a la página siguiente
                </ColorButton>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </DashboardLayout>
  )
}

export default CreateStep5