import Button, { ButtonProps } from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { yellow } from '@mui/material/colors'

// eslint-disable-next-line
const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: 'white',
  backgroundColor: '#fbc02d',
  '&:hover': {
    backgroundColor: yellow[700],
  },
}))

export default ColorButton