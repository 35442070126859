import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import CssBaseline from '@mui/material/CssBaseline'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import esLocale from 'date-fns/locale/es'
import { ToastContainer } from 'react-toastify'
import App from './App'
import { store } from './app/store'
import * as serviceWorker from './serviceWorker'
import { AuthProvider } from './context/AuthContext'
import theme from './theme'
import 'react-toastify/dist/ReactToastify.css'
import { DateProvider } from './context/DateContext'
import { NewFormIdProvider } from './context/NewFormIdContext'
import { RedirectProvider } from './context/RedirectContext'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <LocalizationProvider
        locale={esLocale}
        dateAdapter={AdapterDateFns}
      >
        <BrowserRouter>
          <Provider store={store}>
            <AuthProvider>
              <DateProvider>
                <NewFormIdProvider>
                  <RedirectProvider>
                    <CssBaseline />
                    <App />
                    <ToastContainer />
                  </RedirectProvider>
                </NewFormIdProvider>
              </DateProvider>
            </AuthProvider>
          </Provider>
        </BrowserRouter>
      </LocalizationProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()