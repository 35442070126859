const areaCoordinatorList = [
  {
    name: '',
    email: '',
    uid: '',
  },
  {
    email: 'aaron.calderon.contractor@pepsico.com',
    uid: 'j5ZHSzwXm0XHRfLGy5Grz6TwiuA2',
    name: 'Aaron Calderon',
  },
  {
    email: 'Alejandro.AlfaroAlvarado.Contractor@pepsico.com',
    name: 'Alejandro Alfaro',
    uid: 'p1HCLTatHQRvJU54Phw9dvGHp1C3',
  },
  {
    email: 'alejandro.lillo@pepsico.com',
    name: 'Alejandro Lillo',
    uid: 'I6njXcH9g8P67MHgVa8p8TzFX872',
  },
  {
    uid: 'IKJpjIK3ceaEBpV4KIjB1FtpWsI2',
    email: 'ALISONFERNANDA.BARRAZAMOROSO@pepsico.com',
    name: 'Alison Barraza',
  },
  {
    uid: 'G87yBlMYL7U4gggOwSVrB7cdW5Q2',
    email: 'alvaro.mora@pepsico.com',
    name: 'Alvaro Mora',
  },
  {
    email: 'ANDREADELCARMEN.LULIONCASTILLO@pepsico.com',
    uid: 'Ekfo996qD0XkjGKfZLoxFzCw1t93',
    name: 'Andrea Lulion',
  },
  {
    email: 'Angel.ValladaresRomero@pepsico.com',
    name: 'Angel Valladares',
    uid: 'XOqW69V6VPWVMhMXwTtoCCzyEzt1',
  },
  {
    uid: '7Clb78z0gWNujQ5srEhFeNSuIfH3',
    name: 'Antonio Castillo',
    email: 'antonio.castillo@pepsico.com',
  },
  {
    uid: 'pjs6w7XIdmSS9MPLPAGkkqAAjJw1',
    name: 'Aran Rubilar',
    email: 'Aran.Rubilar@pepsico.com',
  },
  {
    name: 'Beatriz Carabano',
    email: 'BEATRIZ.CARABANO@pepsico.com',
    uid: 'bW6dgycVdtP81mULDWW3WKi9FPt1',
  },
  {
    uid: 'k0UhdESzbfZg2aEOwQlXPJyCUPo1',
    email: 'Brenda.Illanes@pepsico.com',
    name: 'Brenda Illanes',
  },
  {
    uid: 'hR4nxDY9jfe2ScgU0To0wqkBq6y2',
    email: 'CARLOSLUIS.CAVALCANTEPEREZ@pepsico.com',
    name: 'Carlos Cavalcante',
  },
  {
    email: 'CarlosOsvaldo.Torres@pepsico.com',
    uid: '8RMq0UWaTJY5OU4TfJXE13ivZ9Y2',
    name: 'Carlos Torres',
  },
  {
    email: 'Carolina.Anabalon.Contractor@pepsico.com',
    name: 'Carolina Anabalon',
    uid: '59BYsQcLZ4ZQDRp5FwYK9tFDZR63',
  },
  {
    email: 'cesar.kuncar@pepsico.com',
    name: 'Cesar Kuncar',
    uid: 'EbRD2TdiRWbtV8NnqmIFXk0NXxF3',
  },
  {
    email: 'Cesar.Moreira@pepsico.com',
    name: 'Cesar Moreira',
    uid: 'qYlVPy0XiDWElZrmDCSTTsENeCL2',
  },
  {
    email: 'CESARENRIQUE.SALASCARRERO@pepsico.com',
    uid: '0g85M2QTKBSfgIm19MfFK6msdl63',
    name: 'Cesar Salas',
  },
  {
    email: 'CHARLYJHONATHAN.QUINTEROHIDALGO@pepsico.com',
    name: 'Charli Quintero',
    uid: 'AMwxrB0X5jXcvodYpKwwjtljlMk2',
  },
  {
    email: 'cristianalejandro.fernandez@pepsico.com',
    name: 'Cristian Fernandez',
    uid: 'nDl5E3zTAeavWPwmNaZr4Bbx8bX2',
  },
  {
    email: 'cristian.kaiser@pepsico.com',
    name: 'Cristian Kaiser',
    uid: 'cIUP6CcbqEemDXVbNbUV4FFf3YE3',
  },
  {
    name: 'Daiana Reeberg',
    email: 'Daiana.REEBERG@pepsico.com',
    uid: 'LDvD5rU5mTWvYZek7wkF1iCNQmK2',
  },
  {
    email: 'daniel.caceres@pepsico.com',
    uid: 'sp5icfQepdTOWmhHJkTRet738PU2',
    name: 'Daniel Caceres',
  },
  {
    name: 'Daniel Hernandez',
    email: 'daniel.hernandez2@pepsico.com',
    uid: 'Gj8GNUix78ejGA7xmv8HIj16W6F3',
  },
  {
    name: 'Debora Muñoz',
    uid: '2GdPBpAFCEfgz8MgJCCxvPINqnB2',
    email: 'debora.munoz@pepsico.com',
  },
  {
    uid: 'ISPoUPxQo7PFbx8slNaIdKXJrBe2',
    email: 'DIEGOALFONSO.DIAZMORAGA@pepsico.com',
    name: 'Diego Diaz',
  },
  {
    email: 'DIEGOIGNACIO.NEIRAROCUANT@pepsico.com',
    name: 'Diego Neira',
    uid: 'qNaGcdkV74ft1PptYcBrSqCWB0P2',
  },
  {
    name: 'Edison Carroza',
    uid: 'IK0SsniYcqUzHAqof2yowRggfLo2',
    email: 'Edison.Carroza@pepsico.com',
  },
  {
    email: 'EDUARD.ARAUJOSANDIA@pepsico.com',
    name: 'Eduardo Araujo',
    uid: 'jd9fBJFoB1XPCrKctDP2USzxsCL2',
  },
  {
    uid: 'jh41kzIsIShgT8QABwAjrpk0TTx1',
    email: 'elvis.puebla1@pepsico.com',
    name: 'Elvis Puebla',
  },
  {
    name: 'Eric Berrios',
    email: 'eric.berrios@pepsico.com',
    uid: 'B4jV3SpUGmb4vT5jITD8SEGNX5A3',
  },
  {
    uid: '82KTJd3nlyaeEACc8WYfqnp2I3V2',
    email: 'Ericka.Lopez@pepsico.com',
    name: 'Ericka Lopez',
  },
  {
    name: 'Esteban Arce',
    email: 'ESTEBAN.ARCE@pepsico.com',
    uid: 'HEjDjG5DWxPr0EGTeA6Zv8Sz7xk1',
  },
  {
    name: 'Fabian Ramirez',
    uid: 'iCBvMBpuKeShJ5GEwlXAe2OapUN2',
    email: 'FABIAN.RAMIREZVIVANCO@pepsico.com',
  },
  {
    name: 'Felipe Rojas',
    email: 'felipe.rojas1@pepsico.com',
    uid: 'EEuMsRhUSpN3KG0Q8wFktNwKpPf1',
  },
  {
    email: 'Florines.Meneses.Contractor@pepsico.com',
    name: 'Florines Meneses',
    uid: 'iOAWngGgJdaG4tU9lVUVqqjLUtG2',
  },
  {
    name: 'Francisca Cataneo',
    uid: 'nqRHQQXRNFgl4jhMUlaY3HfY0Kh1',
    email: 'FRANCISCAGABRIELA.CATTANEOVALENZUELA@pepsico.com',
  },
  {
    name: 'Franz Torrellas',
    email: 'franz.torrellas@pepsico.com',
    uid: '0CBxYE8GxUZOlLJk9wsjLlVIe382',
  },
  {
    email: 'GERALDOROBINSON.BURGOSVALENZUELA@pepsico.com',
    name: 'Geraldo Burgos',
    uid: 'Ob9knryxIdSXlaCWRTNcMdVDGWd2',
  },
  {
    name: 'Gerardo Vasquez',
    uid: 'iILnLSVSEvOly3MY0GFcdTEq7Cs1',
    email: 'GERARDO.VASQUEZ1@pepsico.com',
  },
  {
    name: 'German Lazo',
    uid: 'CtCSlNqu86RjYY1A1gqYKvxtMVo2',
    email: 'german.lazo@pepsico.com',
  },
  {
    email: 'GIOVANNI.OPAZOMEDINA.Contractor@pepsico.com',
    name: 'Giovanni Opazo',
    uid: '16SwOPVpaBUzOiEU2ZboXUXOGiB2',
  },
  {
    uid: 'ilCMcWjIIGWibwQF5mdycriHDcB3',
    email: 'gonzalo.morgado@pepsico.com',
    name: 'Gonzalo Morgado',
  },
  {
    uid: '8AeaQ5m4RdWSojivuQO3m76o1YP2',
    name: 'Gonzalo Perez',
    email: 'GONZALO.PEREZ1@pepsico.com',
  },
  {
    email: 'gregorio.cardoza@pepsico.com',
    name: 'Gregorio Cardoza',
    uid: 'EVL05bFWgUcearLlluJThc1GnF83',
  },
  {
    email: 'guillermo.fuentes@pepsico.com',
    uid: 'YDeJUDilP7h9ZOAB5yiG4JMQZUy2',
    name: 'Guillermo Fuentes',
  },
  {
    email: 'GUSTAVODIOXOMBERG.BRACAMONTEORELLANO@pepsico.com',
    uid: 'bmn18uwMnlYp1Ftl69xj0uG9Kje2',
    name: 'Gustavo Bracamonte',
  },
  {
    uid: 'LS9VCoiyuwhZKRxLUadSntfmNRq1',
    email: 'gustavo.lorca@pepsico.com',
    name: 'Gustavo Lorca',
  },
  {
    uid: 'MgkZCadkhhSNuktRzm5E8EjXVI82',
    name: 'Heinstein Barrera',
    email: 'HEINSTEINWROWER.BARRERACONTRERAS@pepsico.com',
  },
  {
    uid: 'YJqaNJUqzRVipVkFMPXiaONTSSa2',
    name: 'Hugo Rivera',
    email: 'Hugo.RiveraFica@pepsico.com',
  },
  {
    name: 'Isaac Valenzuela',
    email: 'ISAACHUGO.VALENZUELAGONZALEZ@pepsico.com',
    uid: 'nT3TcBb77IgTT8V5nh7UBDnPxdI3',
  },
  {
    uid: 'vFJVRZWkOxdJdmhdt3fsDQaHCn93',
    name: 'Isauris Rodriguez',
    email: 'isauris.rodriguez@pepsico.com',
  },
  {
    email: 'ITALO.VEGABRICENO@pepsico.com',
    uid: 'ffWVuzRxYAVvLfGLbpO8lX98vo92',
    name: 'Italo Vega',
  },
  {
    email: 'JAIMESEBASTIAN.FONSECAMARCHANT@pepsico.com',
    uid: 'ziObrv7ctgUsomFvC95GIscvjEb2',
    name: 'Jaime Fonseca',
  },
  {
    name: 'Jaime Lienan',
    uid: 'yWkz2MaVcJXHATYU2ldyZwjYrJJ3',
    email: 'jaime.lienan@pepsico.com',
  },
  {
    uid: 'TcbZ1M2r5TP3f49Fpe0AfgwIiIj2',
    email: 'jeannette.soto@pepsico.com',
    name: 'Jeannette Soto',
  },
  {
    uid: 'Nz5vKm6Um9ZZcmOXp8p8CGcM73l2',
    email: 'JESUS.INFANTEMIRANDA@pepsico.com',
    name: 'Jesus Infante',
  },
  {
    email: 'jimmy.ortega@pepsico.com',
    uid: 'QL0DGi2UtoRLhx3kNeErhkB4OpN2',
    name: 'Jimmy Ortega',
  },
  {
    name: 'John Cabrera',
    email: 'drjohncabrera@gmail.com',
    uid: 'MYXW4y4JAUXqIMLleZP12PEb0O63',
  },
  {
    uid: 'l5Xy6uyCsZY6OfhUDfZfgzA6PfU2',
    email: 'jose.diaz@pepsico.com',
    name: 'Jose Diaz',
  },
  {
    uid: 'RgTD593CXLcPuM2xo1nRJQW9EpF3',
    name: 'Jose Inostroza',
    email: 'Jose.Inostroza@pepsico.com',
  },
  {
    uid: '5DPEM2eri2Pt060SiEbTQGvCuYt1',
    email: 'JoseGregorio.PachecoSalcedo@pepsico.com',
    name: 'Jose Pacheco',
  },
  {
    email: 'JOSEANGEL.PULIDOCOLINA1@pepsico.com',
    uid: '4U24kze4rUcBalGvyxMsZkrv43e2',
    name: 'Jose Pulido',
  },
  {
    email: 'JOSEANTONIO.RIVASSUAREZ@pepsico.com',
    uid: 'CuNTeZC5W7R3MQnw2PN6ayPrmR02',
    name: 'Jose Rivas',
  },
  {
    uid: 'QIFqBBugB4YAViDuuFQPfGZqfgG2',
    name: 'Jose Tapia',
    email: 'jose.tapia@pepsico.com',
  },
  {
    uid: '4OiGdF1Qh0e43iuR0Goo220vhZy2',
    name: 'Juan Abarca',
    email: 'juan.abarca@pepsico.com',
  },
  {
    email: 'Juan.MartinezCaceres@pepsico.com',
    uid: 'cWLBv4lTSMNZcd7rt9qCPtr8ExY2',
    name: 'Juan Martines',
  },
  {
    uid: 'JsnijR6P5jYk1OnFLNX7ZKQDh672',
    name: 'Juan Paillan',
    email: 'juan.paillan@pepsico.com',
  },
  {
    name: 'Juan Palmera',
    uid: 'qFc6vh431OaUkcvxKI4j1abxldV2',
    email: 'JUANJOSE.PALMERATIMAURE@pepsico.com',
  },
  {
    email: 'JUANDIEGO.VALEBELTRAN@pepsico.com',
    name: 'Juan Vale',
    uid: 'JaIQ3KiUNybtdQwmL9rMzLt8mmr2',
  },
  {
    name: 'Juan Vallejos',
    email: 'Juan.VallejosAguilera@pepsico.com',
    uid: '0uCKRoLg0VRMqdkisLa28YewpYA2',
  },
  {
    name: 'Karla Caraballo',
    email: 'karladelvalle.caraballo@pepsico.com',
    uid: 'H9fSpkvINSYhqVD6PArJMFgnP463',
  },
  {
    email: 'KENNYJOSE.CORDOBAAREVALO@pepsico.com',
    uid: 'DYtLou6VLaWAwNV7HPoHYQZjGDs2',
    name: 'Kenny Cordoba',
  },
  {
    email: 'kevin.toledo@pepsico.com',
    uid: 'iFkd2ni7FxghXg8nhRNwqFngbN33',
    name: 'Kevin Toledo',
  },
  {
    uid: '5OfCMAFtEyW01Cr0ndjyjmoWX9w2',
    name: 'Leidy Sanchez',
    email: 'LeidyLeomary.SanchezRamirez@pepsico.com',
  },
  {
    email: 'Leonardo.ArenasSoto@pepsico.com',
    uid: 'Co0ryQjOYoSLYMNjSKlvziwvBFZ2',
    name: 'Leonardo Arenas',
  },
  {
    uid: 'QJxTXd7mHghpL1crOfxvFm1NZmh1',
    email: 'lucia.mardones@pepsico.com',
    name: 'Lucia Mardones',
  },
  {
    name: 'Luciano Bobadilla',
    uid: 'pjxzuL97dbWcAAoxDAfd26YLIts1',
    email: 'luciano.bobadilla@pepsico.com',
  },
  {
    email: 'luis.cuellar@pepsico.com',
    uid: 'ab7zvv7LUWPzjax3wOaWv3r3Kf72',
    name: 'Luis Cuellar',
  },
  {
    uid: 'VfAUFtKicJZzijEDDiW3JQUNDUs1',
    email: 'luis.figueroa1@pepsico.com',
    name: 'Luis Figueroa',
  },
  {
    name: 'Luis Nuñez',
    uid: 'rT2lORo6SnQvRY8lzSN3wDlZLIK2',
    email: 'luis.nunez1@pepsico.com',
  },
  {
    name: 'Luis Poblete',
    email: 'Luis.PobletePacheco@pepsico.com',
    uid: 'spDpfTkW2PZm2yKVGsqTwdSiZoY2',
  },
  {
    email: 'MARCELAALEJANDRA.ATABALESJORQUERA@pepsico.com',
    uid: 'obKpP6PsvLOMLgUYAk25Cb4gkOv2',
    name: 'Marcela Atabales',
  },
  {
    email: 'mariana.lemus@pepsico.com',
    uid: 'SJ59ObQ38mZZ5kXUmPN7l1b04N02',
    name: 'Mariana Lemus',
  },
  {
    name: 'Mario Tobar',
    email: 'mario.tobar@pepsico.com',
    uid: 'ZIGJYY28oiMNOECVbuWkSPfrINn1',
  },
  {
    email: 'marlite.torres@pepsico.com',
    uid: 'GtDdJPjZ8sUZR1vdXk8htSG8ycN2',
    name: 'Marlite Torres',
  },
  {
    email: 'MATIAS.LIRAWATKINS@pepsico.com',
    uid: 'D6iXBu9P0kYeWZlzSir3Ohww1Ff2',
    name: 'Matias Lira',
  },
  {
    email: 'mauricio.andrade@pepsico.com',
    uid: 'jMbUSLN1LueCb8xhFr3IYKQYRlC3',
    name: 'Mauricio Andrade',
  },
  {
    email: 'mauricio.luna@pepsico.com',
    name: 'Mauricio Luna',
    uid: 'XEd0jE4dlePvkJL1OTbgrqAL3hB2',
  },
  {
    uid: '3JC1Ep8GfhXVKeOHxn2Ne71gNZl1',
    name: 'Mavila Suarez',
    email: 'MAVILACATALINA.SUAREZCONSTANZO1@pepsico.com',
  },
  {
    name: 'Michel Rivera',
    uid: 'euq9ochGpxUSA3iB0nzVy6Qczqy2',
    email: 'MICHELANDRES.RIVERAAGUILERA@pepsico.com',
  },
  {
    email: 'MIGUEL.ALVARADOMOLINA@pepsico.com',
    uid: 'UbXkVfQMGQNARRWiJp2hUQ6DwvM2',
    name: 'Miguel Alvarado',
  },
  {
    uid: 'iBPWGnHakMcbMKQY8WQPthQ130Q2',
    email: 'Nahum.Barrueto@pepsico.com',
    name: 'Nahum Barrueto',
  },
  {
    uid: 'KgpOQZVrhxdtTBxCcDsSkHD8Yfx1',
    name: 'Nerio Gonzalez',
    email: 'NERIORAMON.GONZALEZPETIT@pepsico.com',
  },
  {
    uid: 'FI98jy4JtsQu9pBevQniWf5p3ct2',
    email: 'naltamirano.l@gmail.com',
    name: 'Nicolas Altamirano',
  },
  {
    email: 'nicolas.cartagena@pepsico.com',
    name: 'Nicolas Cartagena',
    uid: 'CrzL0C2JkdSsh720Mopw771QD1x2',
  },
  {
    uid: '2PfcT2Hl3RWf7ZgiGNCxlLfK9eq1',
    email: 'NICOLAS.DUQUES@pepsico.com',
    name: 'Nicolas Duques',
  },
  {
    email: 'Nicolas.Lagos.Contractor@pepsico.com',
    uid: 'evo4EneEIyfAWNwklCJi01IZWo62',
    name: 'Nicolas Lagos',
  },
  {
    uid: 'Vam4mXggRDY4PSgZKbftbUj02sH2',
    name: 'Nicolas Oporto',
    email: 'Nicolas.Oportus.Contractor@pepsico.com',
  },
  {
    name: 'Orlando Castillo',
    email: 'ORLANDOJOSE.CASTILLOMATUTE@pepsico.com',
    uid: 'HWrjQ2CXQHc21wiIABzFfojigaP2',
  },
  {
    name: 'Oscar Diaz',
    email: 'oscar.diaz@pepsico.com',
    uid: 'TINeqvpVwlbELLdoLlANdzxUcu43',
  },
  {
    email: 'pablocesar.cruz@pepsico.com',
    uid: 'PZcrgvmJy9aMEvhlBJi1VtgA5g23',
    name: 'Pablo Cruz',
  },
  {
    uid: 'nE1qYxIwBzbhaMg6epcgTCAFdS33',
    name: 'Pamela Orellana',
    email: 'PAMELA.ORELLANA@pepsico.com',
  },
  {
    name: 'Paola Candia ',
    email: 'paola.candia@pepsico.com',
    uid: '19SW2tpbZthzALUnPQN5wF7WAeC2',
  },
  {
    email: 'paula.avello@pepsico.com',
    name: 'Paula Avello',
    uid: '3tPt6Mg0soZXIhfV4JCbLmW9Qdt2',
  },
  {
    email: 'PERLAANDREA.URRIOLAARAOS@pepsico.com',
    name: 'Perla Urriola',
    uid: '6gfxV69mafVc7GdyRiFB8zynP4q2',
  },
  {
    name: 'Ramon Angulo',
    uid: 'UIOUjDwjTtf2H6zCuJkaPOxLWwP2',
    email: 'ramon.angulo@pepsico.com',
  },
  {
    name: 'Raul Morales',
    email: 'raul.moralesmunoz@pepsico.com',
    uid: 'kIORk1UAh6c89CdQvNOAFJu47nE3',
  },
  {
    uid: 'ouSUVBC3C5czyyOAAt3e8PGGqfe2',
    name: 'Raul Osorio',
    email: 'RAULALEXANDER.OSORIOMAESTRE@pepsico.com',
  },
  {
    name: 'Ricardo Torres',
    email: 'RICARDO.TORRESVARGAS@pepsico.com',
    uid: 'IaNcFdGdZCcxAkUMQT3ckHwdncR2',
  },
  {
    name: 'Richard Flores',
    email: 'richard.flores@pepsico.com',
    uid: 'WKezh1HV3IUzcDjehl7uZf7NQHR2',
  },
  {
    uid: '2ycJih2MgwQsT9zmI3ZS7Eoq5Ao1',
    email: 'rosa.moraga@pepsico.com',
    name: 'Rosa Moraga',
  },
  {
    email: 'rubenosbaldo.diazcontreras@pepsico.com',
    name: 'Ruben Diaz',
    uid: 'ta4m6PebtIaLMh7xk3kQObLJJyf2',
  },
  {
    email: 'sandra.vera@pepsico.com',
    uid: 'BEeFsqVQdCMS6iOF4Kv1X1sLNfp2',
    name: 'Sandra Vera',
  },
  {
    email: 'SARAITHELOISA.TELLECHEAGUTIERREZ@pepsico.com',
    name: 'Sarai Tellechea',
    uid: 'rzH1L2dMHDRDU7IkAGwNeeOVwC53',
  },
  {
    name: 'Sebastian Alvarado',
    uid: 'CkuJflB5Z8gCfotC5aBxiu9dzR03',
    email: 'sebastian.alvarado1@pepsico.com',
  },
  {
    name: 'Sebastian Fernandez',
    uid: '43sQouyOerTzzSD4p8BhypqpwG42',
    email: 'SEBASTIAN.FERNANDEZARAYA@pepsico.com',
  },
  {
    name: 'Sergio Guajardo',
    email: 'SERGIO.GUAJARDO@pepsico.com',
    uid: 'xxpqsE1rgVORUdkqAAaCzN5vVNr2',
  },
  {
    email: 's.cataldoramos@gmail.com',
    uid: '9MvTXZMycHc0mfRPhsfHVkMrMAw1',
    name: 'Stefani Cataldo',
  },
  {
    email: 'valencia-susana@aramark.cl',
    uid: 'oSQMDARqcEcKnJbzAPNlhmZKpTI2',
    name: 'Susana Valencia',
  },
  {
    name: 'Usuario Soporte',
    email: 'hseqtechcl@gmail.com',
    uid: 'TwPqmzwYv4XqWRdklWvgMBC2COw1',
  },
  {
    uid: 'Vx2ubTtENCVOtFdlzomfo1kwX6r1',
    name: 'Victor Chavez',
    email: 'VICTORANDRES.CHAVEZALLENDE@pepsico.com',
  },
  {
    email: 'VICTORALEJANDRO.HURTADOSANDOVAL@pepsico.com',
    uid: 'cMIln0n3zYgLpG97dUpLvq3bS3C3',
    name: 'Victor Hurtado',
  },
  {
    uid: 'SdCDlBmpICP45QlBfkZm9GwIviu1',
    name: 'William Merino',
    email: 'William.Merino@pepsico.com',
  },
  {
    uid: 'aOVTsxZNwQW6QNRyFgojO074iwC2',
    name: 'Yesenia Morales',
    email: 'yesenia.morales@pepsico.com',
  },
  {
    name: 'Yuri Cristino',
    uid: 'CKJxjN17UMgnY21CtjnvIxyaFR12',
    email: 'Yuri.Cristino@pepsico.com',
  },
]

export default areaCoordinatorList