import Typography from '@mui/material/Typography'
// import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
// import ButtonGroup from '@mui/material/ButtonGroup'
import { Link } from 'react-router-dom'
import CreateIcon from '@mui/icons-material/Create'
import PageviewIcon from '@mui/icons-material/Pageview'
import HelpIcon from '@mui/icons-material/Help'
import { useEffect } from 'react'
// import { useTheme } from '@mui/material/styles'
// import useMediaQuery from '@mui/material/useMediaQuery'
import useAuth from '../hooks/useAuth'
import DashboardLayout from '../components/layouts/DashboardLayout'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { entireStore } from '../app/store'
import { clearForm } from '../features/form/formSlice'
import ColorButton from '../components/createForm/ColorButton'

const StartPage = () => {
  console.log('StartPage')

  const { user } = useAuth()
  global.user = user
  // const theme = useTheme()
  // const matchesSmScreen = useMediaQuery(theme.breakpoints.up('sm'))

  const { nodeApiBaseUrl } = useAppSelector(entireStore)

  console.log('nodeApiBaseUrl: ', nodeApiBaseUrl)

  const dispatch = useAppDispatch()
  useEffect(() => { dispatch(clearForm()) }, [])

  localStorage.setItem('p2specificWorkAreaImage', 'none')
  localStorage.setItem('p6useofLadderImage', 'none')
  localStorage.setItem('p6elevatorImage', 'none')
  localStorage.setItem('p6andamTipeEuroImage', 'none')
  localStorage.setItem('p8hasHdsImage', 'none')
  localStorage.setItem('p8extensionElectricalCheckImage', 'none')
  localStorage.setItem('p9eslingasCheckImage', 'none')
  localStorage.setItem('p9truckPrevCheckImage', 'none')
  localStorage.setItem('p11electricalExtensionCheckImage', 'none')
  localStorage.setItem('p13hotWorkFormNumberImage', 'none')
  localStorage.setItem('p13electricalExtensionCheckImage', 'none')
  localStorage.setItem('p14fourHookHarnessImage', 'none')
  localStorage.setItem('p14doubleLitailImage', 'none')
  localStorage.setItem('p14impactAbsorbImage', 'none')
  localStorage.setItem('p14escaladaMosquetonImage', 'none')

  const activateNodeEnpoint = async () => {
    try {
      const nodeEnpointRes = await fetch(
        `${nodeApiBaseUrl}/start`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      const nodeEnpointResMessage = await nodeEnpointRes.json()
      console.log(nodeEnpointResMessage)
    } catch (error) {
      console.log('No se ha activado el endpoint de Node : ', error)
    }
  }

  useEffect(() => { activateNodeEnpoint() }, [])

  return (
    <DashboardLayout>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={10} sm={9} md={5} lg={5}>
          <Typography sx={{ mt: 1, textAlign: 'center' }} variant="subtitle1" gutterBottom>
            {`Bienvenido de vuelta, ${user?.email}`}
          </Typography>
          <Typography
            variant="h5"
            sx={{ mt: 3, textAlign: 'center' }}
          >
            Menu
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{ mt: 1, textAlign: 'center' }}
          >
            Por favor seleccione una opción
          </Typography>
          {/* <ButtonGroup
            sx={{
              display: 'flex',
              margin: '20px auto',
              width: matchesSmScreen ? '60%' : '80%',
            }}
            orientation="vertical"
            aria-label="vertical outlined button group"
          >
            <Link
              to="/create/"
              style={{ textDecoration: 'none' }}
            >
              <Button
                sx={{ p: 2, width: '100%' }}
                key="one"
                startIcon={<CreateIcon />}
              >
                Crear permiso de trabajo
              </Button>
            </Link>
            <Link
              to="/created"
              style={{ textDecoration: 'none' }}
            >
              <Button
                sx={{ p: 2, width: '100%' }}
                key="two"
                startIcon={<PageviewIcon />}
              >
                Ver mis revisiones
              </Button>
            </Link>
            <Link
              to="/support"
              style={{ textDecoration: 'none' }}
            >
              <Button
                sx={{ p: 2, width: '100%' }}
                key="three"
                startIcon={<HelpIcon />}
              >
                Ayuda y soporte
              </Button>
            </Link>
          </ButtonGroup> */}

          <Link
            to="/create/"
            style={{ textDecoration: 'none' }}
          >
            <ColorButton
              sx={{
                mt: 4,
                mb: 2,
                display: 'flex',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '70%',
              }}
              startIcon={<CreateIcon />}
            >
              Crear permiso de trabajo
            </ColorButton>
          </Link>
          <Link
            to="/created"
            style={{ textDecoration: 'none' }}
          >
            <ColorButton
              sx={{
                mb: 2,
                display: 'flex',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '70%',
              }}
              startIcon={<PageviewIcon />}
            >
              Ver mis revisiones
            </ColorButton>
          </Link>
          <Link
            to="/support"
            style={{ textDecoration: 'none' }}
          >
            <ColorButton
              sx={{
                mb: 2,
                display: 'flex',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '70%',
              }}
              startIcon={<HelpIcon />}
            >
              Ayuda y soporte
            </ColorButton>
          </Link>
        </Grid>
      </Grid>
    </DashboardLayout>
  )
}

export default StartPage