const patrimonialSecurityList = [
  {
    company: '',
    rolName: '',
    name: '',
    uid: '',
    email: '',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    rolName: 'patrimonialSecurity',
    name: 'Giovanni Opazo',
    uid: '16SwOPVpaBUzOiEU2ZboXUXOGiB2',
    email: 'GIOVANNI.OPAZOMEDINA.Contractor@pepsico.com',
  },
  {
    name: 'Raul Morales',
    uid: 'kIORk1UAh6c89CdQvNOAFJu47nE3',
    email: 'raul.moralesmunoz@pepsico.com',
    rolName: 'patrimonialSecurity',
    company: 'swer17wA10eNmlIhbQUv',
  },
  {
    uid: 'iBPWGnHakMcbMKQY8WQPthQ130Q2',
    email: 'Nahum.Barrueto@pepsico.com',
    company: 'swer17wA10eNmlIhbQUv',
    rolName: 'patrimonialSecurity',
    name: 'Nahum Barrueto',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    rolName: 'patrimonialSecurity',
    email: 'juan.paillan@pepsico.com',
    uid: 'JsnijR6P5jYk1OnFLNX7ZKQDh672',
    name: 'Juan Paillan',
  },
]
export default patrimonialSecurityList