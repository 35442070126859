import { createContext, useEffect, useState } from 'react'
import { User, signOut, signInWithEmailAndPassword } from 'firebase/auth'
import { Children } from '../types'
import { auth } from '../config/firebaseConfig'
import FullPageLoader from '../common/FullPageLoader'

const AuthContext = createContext({
  user: null as User | null | undefined,
  signInWithEmailAndPassword,
  signOut,
})

const AuthProvider = ({ children }: Children) => {
  const [user, setUser] = useState(null as User | null | undefined)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const authObserver = auth.onAuthStateChanged((firebaseUser) => {
      setUser(firebaseUser)
      setLoading(false)
    })
    return authObserver
  }, [])

  return (
    // eslint-disable-next-line
    <AuthContext.Provider value={{ user, signInWithEmailAndPassword, signOut }}>
      {!loading ? children : <FullPageLoader />}
    </AuthContext.Provider>
  )
}

export { AuthProvider, AuthContext }