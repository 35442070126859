import { doc, getDoc } from 'firebase/firestore'
import {
  // getDocs,
  // collection,
  // where,
  // query,
  db,
  // Timestamp,
} from '../config/firebaseConfig'

// export function postToJSON(doc: any) {
//   const data = doc.data()
//   return {
//     ...data,
//     //  firestore timestamp NOT serializable to JSON. Must convert to milliseconds
//     // createdAt: data.createdAt.toMillis(),
//   }
// }

// export const { fromMillis } = Timestamp

// const getPosts = async (uid: string) => {
//   const formRef = collection(db, 'forms', uid)
//   const formQuery = query(formRef, where('id', '==', id))
//   const postDocs = await getDocs(formQuery)

//   return postDocs.docs.map(postToJSON)
// }

// export default getPosts

const getFirestoreDocById = async (formId: string) => {
  const docRef = doc(db, 'forms', formId)
  const docSnap = await getDoc(docRef)

  if (docSnap.exists()) {
    // console.log('Document data:', docSnap.data())
    const {
      p2companyName,
      p2contratSupervisorName,
      p2contratSupervisorPhone,
      p2erpName,
      p2erpPhone,
      p2rut,
      p2mutualidad,
      p2requestingArea,
      p2pepWorkCoordinator,
      p2specificWorkArea,
      p2date,
      p2extensionShow,
      p2extension,
      p2startTime,
      p2finishTime,
      p3description1,
      p3description2,
      p3description3,
      p3description4,
      p3description5,
      p3description6,
      p3description7,
      p3controlMeasure1,
      p3controlMeasure2,
      p3controlMeasure3,
      p3controlMeasure4,
      p3controlMeasure5,
      p3controlMeasure6,
      p3controlMeasure7,
      p4workerName1,
      p4workerName2,
      p4workerName3,
      p4workerName4,
      p4workerName5,
      p4workerName6,
      p4workerName7,
      p4workerName8,
      p4workerName9,
      p4workerName10,
      p4workerName11,
      p5atrapam,
      p5aplastam,
      p5golpeadopor,
      p5golpeadocon,
      p5caida,
      p5contConObjCort,
      p5contConObjCal,
      p5proyPartic,
      p5contactEnElectric,
      p5radiacionUv,
      p5contQuimico,
      p5exposicPolvo,
      p5exposicRuido,
      p5atropello,
      p5caidaMatAltura,
      p5estresTerm,
      p5colisionChoque,
      p5deficienciao2,
      p5explosion,
      p5incendio,
      p5derrumbe,
      p5exposicGas,
      p5immersion,
      p5fatigMaterial,
      p6Show,
      p6keyNumber,
      p6weatherConditions,
      p6trainingForHeightWork,
      p6trainingEspecWork,
      p6radioCommunication,
      p6toolBelt,
      p6useOfHookPoint,
      p6useofLadder,
      p6elevator,
      p6andamTipeEuro,
      p6healthCheck,
      p6inlineSliderVertical,
      p6heightJobLowLevel,
      p7Show,
      p7keyNumber,
      p7measure1o2,
      p7measure1h2s,
      p7measure1co,
      p7measure2o2,
      p7measure2h2s,
      p7measure2co,
      p7gasAn,
      p7deviceOfVentForced,
      p7deviceOfAutonBreathing,
      p7comRadio,
      p7landTempInPlace,
      p7equipmentOfAdLight,
      p7requireRescueDevice,
      p7acceptableWeatherCond,
      p8Show,
      p8chemEnvase,
      p8hasHds,
      p8indicateSuspel,
      p8showaGlove,
      p8faceShield,
      p8antSuit,
      p8rubberBoot,
      p8antSpillKit,
      p8extensionElectricalCheck,
      p9Show,
      p9eslingasCheck,
      p9ancPointCheck,
      p9securityPerimeter,
      p9riggerParticipation,
      p9transitAreas,
      p9informBlockAreasToJef,
      p9areaDelimitation,
      p9truckPrevCheck,
      p10show,
      p10chemName1,
      p10chemName1health,
      p10chemName1inflam,
      p10chemName1reactivity,
      p10chemName1specific,
      p10chemName2,
      p10chemName2health,
      p10chemName2inflam,
      p10chemName2reactivity,
      p10chemName2specific,
      p10chemName3,
      p10chemName3health,
      p10chemName3inflam,
      p10chemName3reactivity,
      p10chemName3specific,
      p10chemName4,
      p10chemName4health,
      p10chemName4inflam,
      p10chemName4reactivity,
      p10chemName4specific,
      p10chemName5,
      p10chemName5health,
      p10chemName5inflam,
      p10chemName5reactivity,
      p10chemName5specific,
      p11show,
      p11mask,
      p11cabritillaGlove,
      p11breather,
      p11manualLoadMax25,
      p11useoFSignal,
      p11areaDelimitation,
      p11electricalExtensionCheck,
      p11toolCheck,
      p11lightningToWork,
      p11clearArea,
      p11useOfGrua,
      p12show,
      p12evaluatorName,
      p12evaluationTime,
      p12areaClear,
      p12materialsWithinTheZone,
      p12dustGeneration,
      p12foodIn,
      p12hasBmp,
      p13show,
      p13hotWorkFormNumber,
      p13areaDelimitation,
      p13electricalExtensionCheck,
      p13notificationToBrig,
      p14securityGlass,
      p14securityShoes,
      p14earProtector,
      p14securityHeadProtection,
      p14reflectJacket,
      p14uvFilterProtection,
      p14antSuit,
      p14bootsResistant,
      p14faceShield,
      p14soldarMask,
      p14soldarSuit,
      p14leatherColeto,
      p14leatherPolaina,
      p14fourHookHarness,
      p14doubleLitail,
      p14impactAbsorb,
      p14escaladaMosqueton,
      p14filterMask,
      p14Others,
      p15show,
      p15blockingPersonName,
      p15dateTimeLotoStart,
      p15dateTimeLotoFinish,
      p15dangEnergiesControl,
      p15knowEnergySourceMachine,
      p15concLoto,
      p15hasLockIdCardBlockDevice,
      p15dielectricGlove,
      p15dielectricSecurityShoe,
      p15dielectricSecurityGlass,
      p15blockingEnTypeElectrical,
      p15blockingEnTypeHidraulical,
      p15blockingEnMechanical,
      p15blockingEnPotencial,
      entriesAdded,
      p16showWorkSupervisor,
      p16workSupervisor,
      p16workSupervisorApproved1,
      p16workSupervisorApproved2,
      p16workSupervisorApproved3,
      p16workSupervisorApproved4,
      p16workSupervisorApproved5,
      p16workSupervisorApproved6,
      p16workSupervisorApproved7,
      p16workSupervisorApproved8,
      p16workSupervisorApproved9,
      p16workSupervisorApproved10,
      p16workSupervisorApproved11,
      p16workSupervisorApproved12,
      p16workSupervisorApproved13,
      p16workSupervisorApproved14,
      p16workSupervisorApproved15,
      p16workSupervisorApprovedAt1,
      p16workSupervisorApprovedAt2,
      p16workSupervisorApprovedAt3,
      p16workSupervisorApprovedAt4,
      p16workSupervisorApprovedAt5,
      p16workSupervisorApprovedAt6,
      p16workSupervisorApprovedAt7,
      p16workSupervisorApprovedAt8,
      p16workSupervisorApprovedAt9,
      p16workSupervisorApprovedAt10,
      p16workSupervisorApprovedAt11,
      p16workSupervisorApprovedAt12,
      p16workSupervisorApprovedAt13,
      p16workSupervisorApprovedAt14,
      p16workSupervisorApprovedAt15,
      p16showPepCoordinator,
      p16pepCoordinator,
      p16pepCoordinatorApproved1,
      p16pepCoordinatorApproved2,
      p16pepCoordinatorApproved3,
      p16pepCoordinatorApproved4,
      p16pepCoordinatorApproved5,
      p16pepCoordinatorApproved6,
      p16pepCoordinatorApproved7,
      p16pepCoordinatorApproved8,
      p16pepCoordinatorApproved9,
      p16pepCoordinatorApproved10,
      p16pepCoordinatorApproved11,
      p16pepCoordinatorApproved12,
      p16pepCoordinatorApproved13,
      p16pepCoordinatorApproved14,
      p16pepCoordinatorApproved15,
      p16pepCoordinatorApprovedAt1,
      p16pepCoordinatorApprovedAt2,
      p16pepCoordinatorApprovedAt3,
      p16pepCoordinatorApprovedAt4,
      p16pepCoordinatorApprovedAt5,
      p16pepCoordinatorApprovedAt6,
      p16pepCoordinatorApprovedAt7,
      p16pepCoordinatorApprovedAt8,
      p16pepCoordinatorApprovedAt9,
      p16pepCoordinatorApprovedAt10,
      p16pepCoordinatorApprovedAt11,
      p16pepCoordinatorApprovedAt12,
      p16pepCoordinatorApprovedAt13,
      p16pepCoordinatorApprovedAt14,
      p16pepCoordinatorApprovedAt15,
      p16showAreaCoordinator,
      p16areaCoordinator,
      p16areaCoordinatorApproved1,
      p16areaCoordinatorApproved2,
      p16areaCoordinatorApproved3,
      p16areaCoordinatorApproved4,
      p16areaCoordinatorApproved5,
      p16areaCoordinatorApproved6,
      p16areaCoordinatorApproved7,
      p16areaCoordinatorApproved8,
      p16areaCoordinatorApproved9,
      p16areaCoordinatorApproved10,
      p16areaCoordinatorApproved11,
      p16areaCoordinatorApproved12,
      p16areaCoordinatorApproved13,
      p16areaCoordinatorApproved14,
      p16areaCoordinatorApproved15,
      p16areaCoordinatorApprovedAt1,
      p16areaCoordinatorApprovedAt2,
      p16areaCoordinatorApprovedAt3,
      p16areaCoordinatorApprovedAt4,
      p16areaCoordinatorApprovedAt5,
      p16areaCoordinatorApprovedAt6,
      p16areaCoordinatorApprovedAt7,
      p16areaCoordinatorApprovedAt8,
      p16areaCoordinatorApprovedAt9,
      p16areaCoordinatorApprovedAt10,
      p16areaCoordinatorApprovedAt11,
      p16areaCoordinatorApprovedAt12,
      p16areaCoordinatorApprovedAt13,
      p16areaCoordinatorApprovedAt14,
      p16areaCoordinatorApprovedAt15,
      p16showQuality,
      p16quality,
      p16qualityApproved1,
      p16qualityApproved2,
      p16qualityApproved3,
      p16qualityApproved4,
      p16qualityApproved5,
      p16qualityApproved6,
      p16qualityApproved7,
      p16qualityApproved8,
      p16qualityApproved9,
      p16qualityApproved10,
      p16qualityApproved11,
      p16qualityApproved12,
      p16qualityApproved13,
      p16qualityApproved14,
      p16qualityApproved15,
      p16qualityApprovedAt1,
      p16qualityApprovedAt2,
      p16qualityApprovedAt3,
      p16qualityApprovedAt4,
      p16qualityApprovedAt5,
      p16qualityApprovedAt6,
      p16qualityApprovedAt7,
      p16qualityApprovedAt8,
      p16qualityApprovedAt9,
      p16qualityApprovedAt10,
      p16qualityApprovedAt11,
      p16qualityApprovedAt12,
      p16qualityApprovedAt13,
      p16qualityApprovedAt14,
      p16qualityApprovedAt15,
      p16showTechnicalArea,
      p16technicalArea,
      p16technicalAreaApproved1,
      p16technicalAreaApproved2,
      p16technicalAreaApproved3,
      p16technicalAreaApproved4,
      p16technicalAreaApproved5,
      p16technicalAreaApproved6,
      p16technicalAreaApproved7,
      p16technicalAreaApproved8,
      p16technicalAreaApproved9,
      p16technicalAreaApproved10,
      p16technicalAreaApproved11,
      p16technicalAreaApproved12,
      p16technicalAreaApproved13,
      p16technicalAreaApproved14,
      p16technicalAreaApproved15,
      p16technicalAreaApprovedAt1,
      p16technicalAreaApprovedAt2,
      p16technicalAreaApprovedAt3,
      p16technicalAreaApprovedAt4,
      p16technicalAreaApprovedAt5,
      p16technicalAreaApprovedAt6,
      p16technicalAreaApprovedAt7,
      p16technicalAreaApprovedAt8,
      p16technicalAreaApprovedAt9,
      p16technicalAreaApprovedAt10,
      p16technicalAreaApprovedAt11,
      p16technicalAreaApprovedAt12,
      p16technicalAreaApprovedAt13,
      p16technicalAreaApprovedAt14,
      p16technicalAreaApprovedAt15,
      p16showPatrimonialSecurity,
      p16patrimonialSecurity,
      p16patrimonialSecurityApproved1,
      p16patrimonialSecurityApproved2,
      p16patrimonialSecurityApproved3,
      p16patrimonialSecurityApproved4,
      p16patrimonialSecurityApproved5,
      p16patrimonialSecurityApproved6,
      p16patrimonialSecurityApproved7,
      p16patrimonialSecurityApproved8,
      p16patrimonialSecurityApproved9,
      p16patrimonialSecurityApproved10,
      p16patrimonialSecurityApproved11,
      p16patrimonialSecurityApproved12,
      p16patrimonialSecurityApproved13,
      p16patrimonialSecurityApproved14,
      p16patrimonialSecurityApproved15,
      p16patrimonialSecurityApprovedAt1,
      p16patrimonialSecurityApprovedAt2,
      p16patrimonialSecurityApprovedAt3,
      p16patrimonialSecurityApprovedAt4,
      p16patrimonialSecurityApprovedAt5,
      p16patrimonialSecurityApprovedAt6,
      p16patrimonialSecurityApprovedAt7,
      p16patrimonialSecurityApprovedAt8,
      p16patrimonialSecurityApprovedAt9,
      p16patrimonialSecurityApprovedAt10,
      p16patrimonialSecurityApprovedAt11,
      p16patrimonialSecurityApprovedAt12,
      p16patrimonialSecurityApprovedAt13,
      p16patrimonialSecurityApprovedAt14,
      p16patrimonialSecurityApprovedAt15,
      p16showExternalPrevencionist,
      p16externalPrevencionist,
      p16externalPrevencionistApproved1,
      p16externalPrevencionistApproved2,
      p16externalPrevencionistApproved3,
      p16externalPrevencionistApproved4,
      p16externalPrevencionistApproved5,
      p16externalPrevencionistApproved6,
      p16externalPrevencionistApproved7,
      p16externalPrevencionistApproved8,
      p16externalPrevencionistApproved9,
      p16externalPrevencionistApproved10,
      p16externalPrevencionistApproved11,
      p16externalPrevencionistApproved12,
      p16externalPrevencionistApproved13,
      p16externalPrevencionistApproved14,
      p16externalPrevencionistApproved15,
      p16externalPrevencionistApprovedAt1,
      p16externalPrevencionistApprovedAt2,
      p16externalPrevencionistApprovedAt3,
      p16externalPrevencionistApprovedAt4,
      p16externalPrevencionistApprovedAt5,
      p16externalPrevencionistApprovedAt6,
      p16externalPrevencionistApprovedAt7,
      p16externalPrevencionistApprovedAt8,
      p16externalPrevencionistApprovedAt9,
      p16externalPrevencionistApprovedAt10,
      p16externalPrevencionistApprovedAt11,
      p16externalPrevencionistApprovedAt12,
      p16externalPrevencionistApprovedAt13,
      p16externalPrevencionistApprovedAt14,
      p16externalPrevencionistApprovedAt15,
      p16showSass,
      p16sass,
      p16sassApproved1,
      p16sassApproved2,
      p16sassApproved3,
      p16sassApproved4,
      p16sassApproved5,
      p16sassApproved6,
      p16sassApproved7,
      p16sassApproved8,
      p16sassApproved9,
      p16sassApproved10,
      p16sassApproved11,
      p16sassApproved12,
      p16sassApproved13,
      p16sassApproved14,
      p16sassApproved15,
      p16sassApprovedAt1,
      p16sassApprovedAt2,
      p16sassApprovedAt3,
      p16sassApprovedAt4,
      p16sassApprovedAt5,
      p16sassApprovedAt6,
      p16sassApprovedAt7,
      p16sassApprovedAt8,
      p16sassApprovedAt9,
      p16sassApprovedAt10,
      p16sassApprovedAt11,
      p16sassApprovedAt12,
      p16sassApprovedAt13,
      p16sassApprovedAt14,
      p16sassApprovedAt15,
      p16sassAllowedList,
      p16paramedic,
      paramedicApprovedAllWorkers,
      paramedicApprovedWorker1,
      paramedicApprovedWorker2,
      paramedicApprovedWorker3,
      paramedicApprovedWorker4,
      paramedicApprovedWorker5,
      paramedicApprovedWorker6,
      paramedicApprovedWorker7,
      paramedicApprovedWorker8,
      paramedicApprovedWorker9,
      paramedicApprovedWorker10,
      paramedicApprovedWorker11,
      newFormPersonEmailWhoDid,
      p2specificWorkAreaImage,
      p6useofLadderImage,
      p6elevatorImage,
      p6andamTipeEuroImage,
      p8hasHdsImage,
      p8extensionElectricalCheckImage,
      p9eslingasCheckImage,
      p9truckPrevCheckImage,
      p11electricalExtensionCheckImage,
      p13hotWorkFormNumberImage,
      p13electricalExtensionCheckImage,
      p14fourHookHarnessImage,
      p14doubleLitailImage,
      p14impactAbsorbImage,
      p14escaladaMosquetonImage,

    } = docSnap.data()
    return {
      p2companyName: p2companyName || null,
      p2contratSupervisorName: p2contratSupervisorName || null,
      p2contratSupervisorPhone: p2contratSupervisorPhone || null,
      p2erpName: p2erpName || null,
      p2erpPhone: p2erpPhone || null,
      p2rut: p2rut || null,
      p2mutualidad: p2mutualidad || null,
      p2requestingArea: p2requestingArea || null,
      p2pepWorkCoordinator: p2pepWorkCoordinator || null,
      p2specificWorkArea: p2specificWorkArea || null,
      p2date: p2date || null,
      p2extensionShow: p2extensionShow || null,
      p2extension: p2extension || null,
      p2startTime: p2startTime || null,
      p2finishTime: p2finishTime || null,
      p3description1: p3description1 || null,
      p3description2: p3description2 || null,
      p3description3: p3description3 || null,
      p3description4: p3description4 || null,
      p3description5: p3description5 || null,
      p3description6: p3description6 || null,
      p3description7: p3description7 || null,
      p3controlMeasure1: p3controlMeasure1 || null,
      p3controlMeasure2: p3controlMeasure2 || null,
      p3controlMeasure3: p3controlMeasure3 || null,
      p3controlMeasure4: p3controlMeasure4 || null,
      p3controlMeasure5: p3controlMeasure5 || null,
      p3controlMeasure6: p3controlMeasure6 || null,
      p3controlMeasure7: p3controlMeasure7 || null,
      p4workerName1: p4workerName1 || null,
      p4workerName2: p4workerName2 || null,
      p4workerName3: p4workerName3 || null,
      p4workerName4: p4workerName4 || null,
      p4workerName5: p4workerName5 || null,
      p4workerName6: p4workerName6 || null,
      p4workerName7: p4workerName7 || null,
      p4workerName8: p4workerName8 || null,
      p4workerName9: p4workerName9 || null,
      p4workerName10: p4workerName10 || null,
      p4workerName11: p4workerName11 || null,
      p5atrapam: p5atrapam || null,
      p5aplastam: p5aplastam || null,
      p5golpeadopor: p5golpeadopor || null,
      p5golpeadocon: p5golpeadocon || null,
      p5caida: p5caida || null,
      p5contConObjCort: p5contConObjCort || null,
      p5contConObjCal: p5contConObjCal || null,
      p5proyPartic: p5proyPartic || null,
      p5contactEnElectric: p5contactEnElectric || null,
      p5radiacionUv: p5radiacionUv || null,
      p5contQuimico: p5contQuimico || null,
      p5exposicPolvo: p5exposicPolvo || null,
      p5exposicRuido: p5exposicRuido || null,
      p5atropello: p5atropello || null,
      p5caidaMatAltura: p5caidaMatAltura || null,
      p5estresTerm: p5estresTerm || null,
      p5colisionChoque: p5colisionChoque || null,
      p5deficienciao2: p5deficienciao2 || null,
      p5explosion: p5explosion || null,
      p5incendio: p5incendio || null,
      p5derrumbe: p5derrumbe || null,
      p5exposicGas: p5exposicGas || null,
      p5immersion: p5immersion || null,
      p5fatigMaterial: p5fatigMaterial || null,
      p6Show,
      p6keyNumber,
      p6weatherConditions,
      p6trainingForHeightWork,
      p6trainingEspecWork,
      p6radioCommunication,
      p6toolBelt,
      p6useOfHookPoint,
      p6useofLadder,
      p6elevator,
      p6andamTipeEuro,
      p6healthCheck,
      p6inlineSliderVertical,
      p6heightJobLowLevel,
      p7Show,
      p7keyNumber,
      p7measure1o2,
      p7measure1h2s,
      p7measure1co,
      p7measure2o2,
      p7measure2h2s,
      p7measure2co,
      p7gasAn,
      p7deviceOfVentForced,
      p7deviceOfAutonBreathing,
      p7comRadio,
      p7landTempInPlace,
      p7equipmentOfAdLight,
      p7requireRescueDevice,
      p7acceptableWeatherCond,
      p8Show,
      p8chemEnvase,
      p8hasHds,
      p8indicateSuspel,
      p8showaGlove,
      p8faceShield,
      p8antSuit,
      p8rubberBoot,
      p8antSpillKit,
      p8extensionElectricalCheck,
      p9Show,
      p9eslingasCheck,
      p9ancPointCheck,
      p9securityPerimeter,
      p9riggerParticipation,
      p9transitAreas,
      p9informBlockAreasToJef,
      p9areaDelimitation,
      p9truckPrevCheck,
      p10show,
      p10chemName1,
      p10chemName1health,
      p10chemName1inflam,
      p10chemName1reactivity,
      p10chemName1specific,
      p10chemName2,
      p10chemName2health,
      p10chemName2inflam,
      p10chemName2reactivity,
      p10chemName2specific,
      p10chemName3,
      p10chemName3health,
      p10chemName3inflam,
      p10chemName3reactivity,
      p10chemName3specific,
      p10chemName4,
      p10chemName4health,
      p10chemName4inflam,
      p10chemName4reactivity,
      p10chemName4specific,
      p10chemName5,
      p10chemName5health,
      p10chemName5inflam,
      p10chemName5reactivity,
      p10chemName5specific,
      p11show,
      p11mask,
      p11cabritillaGlove,
      p11breather,
      p11manualLoadMax25,
      p11useoFSignal,
      p11areaDelimitation,
      p11electricalExtensionCheck,
      p11toolCheck,
      p11lightningToWork,
      p11clearArea,
      p11useOfGrua,
      p12show,
      p12evaluatorName,
      p12evaluationTime,
      p12areaClear,
      p12materialsWithinTheZone,
      p12dustGeneration,
      p12foodIn,
      p12hasBmp,
      p13show,
      p13hotWorkFormNumber,
      p13areaDelimitation,
      p13electricalExtensionCheck,
      p13notificationToBrig,
      p14securityGlass,
      p14securityShoes,
      p14earProtector,
      p14securityHeadProtection,
      p14reflectJacket,
      p14uvFilterProtection,
      p14antSuit,
      p14bootsResistant,
      p14faceShield,
      p14soldarMask,
      p14soldarSuit,
      p14leatherColeto,
      p14leatherPolaina,
      p14fourHookHarness,
      p14doubleLitail,
      p14impactAbsorb,
      p14escaladaMosqueton,
      p14filterMask,
      p14Others,
      p15show,
      p15blockingPersonName,
      p15dateTimeLotoStart,
      p15dateTimeLotoFinish,
      p15dangEnergiesControl,
      p15knowEnergySourceMachine,
      p15concLoto,
      p15hasLockIdCardBlockDevice,
      p15dielectricGlove,
      p15dielectricSecurityShoe,
      p15dielectricSecurityGlass,
      p15blockingEnTypeElectrical,
      p15blockingEnTypeHidraulical,
      p15blockingEnMechanical,
      p15blockingEnPotencial,
      entriesAdded,
      p16showWorkSupervisor,
      p16workSupervisor,
      p16workSupervisorApproved1,
      p16workSupervisorApproved2,
      p16workSupervisorApproved3,
      p16workSupervisorApproved4,
      p16workSupervisorApproved5,
      p16workSupervisorApproved6,
      p16workSupervisorApproved7,
      p16workSupervisorApproved8,
      p16workSupervisorApproved9,
      p16workSupervisorApproved10,
      p16workSupervisorApproved11,
      p16workSupervisorApproved12,
      p16workSupervisorApproved13,
      p16workSupervisorApproved14,
      p16workSupervisorApproved15,
      p16workSupervisorApprovedAt1,
      p16workSupervisorApprovedAt2,
      p16workSupervisorApprovedAt3,
      p16workSupervisorApprovedAt4,
      p16workSupervisorApprovedAt5,
      p16workSupervisorApprovedAt6,
      p16workSupervisorApprovedAt7,
      p16workSupervisorApprovedAt8,
      p16workSupervisorApprovedAt9,
      p16workSupervisorApprovedAt10,
      p16workSupervisorApprovedAt11,
      p16workSupervisorApprovedAt12,
      p16workSupervisorApprovedAt13,
      p16workSupervisorApprovedAt14,
      p16workSupervisorApprovedAt15,
      p16showPepCoordinator,
      p16pepCoordinator,
      p16pepCoordinatorApproved1,
      p16pepCoordinatorApproved2,
      p16pepCoordinatorApproved3,
      p16pepCoordinatorApproved4,
      p16pepCoordinatorApproved5,
      p16pepCoordinatorApproved6,
      p16pepCoordinatorApproved7,
      p16pepCoordinatorApproved8,
      p16pepCoordinatorApproved9,
      p16pepCoordinatorApproved10,
      p16pepCoordinatorApproved11,
      p16pepCoordinatorApproved12,
      p16pepCoordinatorApproved13,
      p16pepCoordinatorApproved14,
      p16pepCoordinatorApproved15,
      p16pepCoordinatorApprovedAt1,
      p16pepCoordinatorApprovedAt2,
      p16pepCoordinatorApprovedAt3,
      p16pepCoordinatorApprovedAt4,
      p16pepCoordinatorApprovedAt5,
      p16pepCoordinatorApprovedAt6,
      p16pepCoordinatorApprovedAt7,
      p16pepCoordinatorApprovedAt8,
      p16pepCoordinatorApprovedAt9,
      p16pepCoordinatorApprovedAt10,
      p16pepCoordinatorApprovedAt11,
      p16pepCoordinatorApprovedAt12,
      p16pepCoordinatorApprovedAt13,
      p16pepCoordinatorApprovedAt14,
      p16pepCoordinatorApprovedAt15,
      p16showAreaCoordinator,
      p16areaCoordinator,
      p16areaCoordinatorApproved1,
      p16areaCoordinatorApproved2,
      p16areaCoordinatorApproved3,
      p16areaCoordinatorApproved4,
      p16areaCoordinatorApproved5,
      p16areaCoordinatorApproved6,
      p16areaCoordinatorApproved7,
      p16areaCoordinatorApproved8,
      p16areaCoordinatorApproved9,
      p16areaCoordinatorApproved10,
      p16areaCoordinatorApproved11,
      p16areaCoordinatorApproved12,
      p16areaCoordinatorApproved13,
      p16areaCoordinatorApproved14,
      p16areaCoordinatorApproved15,
      p16areaCoordinatorApprovedAt1,
      p16areaCoordinatorApprovedAt2,
      p16areaCoordinatorApprovedAt3,
      p16areaCoordinatorApprovedAt4,
      p16areaCoordinatorApprovedAt5,
      p16areaCoordinatorApprovedAt6,
      p16areaCoordinatorApprovedAt7,
      p16areaCoordinatorApprovedAt8,
      p16areaCoordinatorApprovedAt9,
      p16areaCoordinatorApprovedAt10,
      p16areaCoordinatorApprovedAt11,
      p16areaCoordinatorApprovedAt12,
      p16areaCoordinatorApprovedAt13,
      p16areaCoordinatorApprovedAt14,
      p16areaCoordinatorApprovedAt15,
      p16showQuality,
      p16quality,
      p16qualityApproved1,
      p16qualityApproved2,
      p16qualityApproved3,
      p16qualityApproved4,
      p16qualityApproved5,
      p16qualityApproved6,
      p16qualityApproved7,
      p16qualityApproved8,
      p16qualityApproved9,
      p16qualityApproved10,
      p16qualityApproved11,
      p16qualityApproved12,
      p16qualityApproved13,
      p16qualityApproved14,
      p16qualityApproved15,
      p16qualityApprovedAt1,
      p16qualityApprovedAt2,
      p16qualityApprovedAt3,
      p16qualityApprovedAt4,
      p16qualityApprovedAt5,
      p16qualityApprovedAt6,
      p16qualityApprovedAt7,
      p16qualityApprovedAt8,
      p16qualityApprovedAt9,
      p16qualityApprovedAt10,
      p16qualityApprovedAt11,
      p16qualityApprovedAt12,
      p16qualityApprovedAt13,
      p16qualityApprovedAt14,
      p16qualityApprovedAt15,
      p16showTechnicalArea,
      p16technicalArea,
      p16technicalAreaApproved1,
      p16technicalAreaApproved2,
      p16technicalAreaApproved3,
      p16technicalAreaApproved4,
      p16technicalAreaApproved5,
      p16technicalAreaApproved6,
      p16technicalAreaApproved7,
      p16technicalAreaApproved8,
      p16technicalAreaApproved9,
      p16technicalAreaApproved10,
      p16technicalAreaApproved11,
      p16technicalAreaApproved12,
      p16technicalAreaApproved13,
      p16technicalAreaApproved14,
      p16technicalAreaApproved15,
      p16technicalAreaApprovedAt1,
      p16technicalAreaApprovedAt2,
      p16technicalAreaApprovedAt3,
      p16technicalAreaApprovedAt4,
      p16technicalAreaApprovedAt5,
      p16technicalAreaApprovedAt6,
      p16technicalAreaApprovedAt7,
      p16technicalAreaApprovedAt8,
      p16technicalAreaApprovedAt9,
      p16technicalAreaApprovedAt10,
      p16technicalAreaApprovedAt11,
      p16technicalAreaApprovedAt12,
      p16technicalAreaApprovedAt13,
      p16technicalAreaApprovedAt14,
      p16technicalAreaApprovedAt15,
      p16showPatrimonialSecurity,
      p16patrimonialSecurity,
      p16patrimonialSecurityApproved1,
      p16patrimonialSecurityApproved2,
      p16patrimonialSecurityApproved3,
      p16patrimonialSecurityApproved4,
      p16patrimonialSecurityApproved5,
      p16patrimonialSecurityApproved6,
      p16patrimonialSecurityApproved7,
      p16patrimonialSecurityApproved8,
      p16patrimonialSecurityApproved9,
      p16patrimonialSecurityApproved10,
      p16patrimonialSecurityApproved11,
      p16patrimonialSecurityApproved12,
      p16patrimonialSecurityApproved13,
      p16patrimonialSecurityApproved14,
      p16patrimonialSecurityApproved15,
      p16patrimonialSecurityApprovedAt1,
      p16patrimonialSecurityApprovedAt2,
      p16patrimonialSecurityApprovedAt3,
      p16patrimonialSecurityApprovedAt4,
      p16patrimonialSecurityApprovedAt5,
      p16patrimonialSecurityApprovedAt6,
      p16patrimonialSecurityApprovedAt7,
      p16patrimonialSecurityApprovedAt8,
      p16patrimonialSecurityApprovedAt9,
      p16patrimonialSecurityApprovedAt10,
      p16patrimonialSecurityApprovedAt11,
      p16patrimonialSecurityApprovedAt12,
      p16patrimonialSecurityApprovedAt13,
      p16patrimonialSecurityApprovedAt14,
      p16patrimonialSecurityApprovedAt15,
      p16showExternalPrevencionist,
      p16externalPrevencionist,
      p16externalPrevencionistApproved1,
      p16externalPrevencionistApproved2,
      p16externalPrevencionistApproved3,
      p16externalPrevencionistApproved4,
      p16externalPrevencionistApproved5,
      p16externalPrevencionistApproved6,
      p16externalPrevencionistApproved7,
      p16externalPrevencionistApproved8,
      p16externalPrevencionistApproved9,
      p16externalPrevencionistApproved10,
      p16externalPrevencionistApproved11,
      p16externalPrevencionistApproved12,
      p16externalPrevencionistApproved13,
      p16externalPrevencionistApproved14,
      p16externalPrevencionistApproved15,
      p16externalPrevencionistApprovedAt1,
      p16externalPrevencionistApprovedAt2,
      p16externalPrevencionistApprovedAt3,
      p16externalPrevencionistApprovedAt4,
      p16externalPrevencionistApprovedAt5,
      p16externalPrevencionistApprovedAt6,
      p16externalPrevencionistApprovedAt7,
      p16externalPrevencionistApprovedAt8,
      p16externalPrevencionistApprovedAt9,
      p16externalPrevencionistApprovedAt10,
      p16externalPrevencionistApprovedAt11,
      p16externalPrevencionistApprovedAt12,
      p16externalPrevencionistApprovedAt13,
      p16externalPrevencionistApprovedAt14,
      p16externalPrevencionistApprovedAt15,
      p16showSass,
      p16sass,
      p16sassApproved1,
      p16sassApproved2,
      p16sassApproved3,
      p16sassApproved4,
      p16sassApproved5,
      p16sassApproved6,
      p16sassApproved7,
      p16sassApproved8,
      p16sassApproved9,
      p16sassApproved10,
      p16sassApproved11,
      p16sassApproved12,
      p16sassApproved13,
      p16sassApproved14,
      p16sassApproved15,
      p16sassApprovedAt1,
      p16sassApprovedAt2,
      p16sassApprovedAt3,
      p16sassApprovedAt4,
      p16sassApprovedAt5,
      p16sassApprovedAt6,
      p16sassApprovedAt7,
      p16sassApprovedAt8,
      p16sassApprovedAt9,
      p16sassApprovedAt10,
      p16sassApprovedAt11,
      p16sassApprovedAt12,
      p16sassApprovedAt13,
      p16sassApprovedAt14,
      p16sassApprovedAt15,
      p16sassAllowedList,
      p16paramedic,
      paramedicApprovedAllWorkers,
      paramedicApprovedWorker1: paramedicApprovedWorker1 || null,
      paramedicApprovedWorker2: paramedicApprovedWorker2 || null,
      paramedicApprovedWorker3: paramedicApprovedWorker3 || null,
      paramedicApprovedWorker4: paramedicApprovedWorker4 || null,
      paramedicApprovedWorker5: paramedicApprovedWorker5 || null,
      paramedicApprovedWorker6: paramedicApprovedWorker6 || null,
      paramedicApprovedWorker7: paramedicApprovedWorker7 || null,
      paramedicApprovedWorker8: paramedicApprovedWorker8 || null,
      paramedicApprovedWorker9: paramedicApprovedWorker9 || null,
      paramedicApprovedWorker10: paramedicApprovedWorker10 || null,
      paramedicApprovedWorker11: paramedicApprovedWorker11 || null,
      newFormPersonEmailWhoDid,
      p2specificWorkAreaImage,
      p6useofLadderImage,
      p6elevatorImage,
      p6andamTipeEuroImage,
      p8hasHdsImage,
      p8extensionElectricalCheckImage,
      p9eslingasCheckImage,
      p9truckPrevCheckImage,
      p11electricalExtensionCheckImage,
      p13hotWorkFormNumberImage,
      p13electricalExtensionCheckImage,
      p14fourHookHarnessImage,
      p14doubleLitailImage,
      p14impactAbsorbImage,
      p14escaladaMosquetonImage,
    }
  }

  return console.log('No hay un documento con ese id')
}

export default getFirestoreDocById