import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Slide from '@mui/material/Slide'
import Checkbox from '@mui/material/Checkbox'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { useParams, useHistory } from 'react-router-dom'
import Button from '@mui/material/Button'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import CheckIcon from '@mui/icons-material/Check'
import { toast } from 'react-toastify'
import YellowSwitch from '../../common/YellowSwitch'
import DashboardLayout from '../layouts/DashboardLayout'
import getFirestoreDocById from '../../common/getFirestoreDocById'
import { UrlParams } from '../../types'
import ColorButton from '../../common/ColorButton'
import monthNames from '../../common/monthNames'
import { doc, updateDoc, db } from '../../config/firebaseConfig'
import useAuth from '../../hooks/useAuth'
import checkAllApproved from '../../common/checkAllApproved'
import color1ConsoleLog from '../../common/color1ConsoleLog'
import { useAppSelector } from '../../app/hooks'
import { entireStore } from '../../app/store'
import allUsers from '../../common/allUsers'
import FullPageLoader from '../../common/FullPageLoader'

const ReviseStep15 = () => {
  console.log('ReviseStep15')

  const { user } = useAuth()
  console.log('%cuser : %s', color1ConsoleLog, user?.uid)

  const { id, area } = useParams<UrlParams>()
  console.log('%cThe id is : %s', color1ConsoleLog, id)
  console.log('%cThe area is : %s', color1ConsoleLog, area)

  const { nodeApiBaseUrl } = useAppSelector(entireStore)

  const history = useHistory()

  const goToReviseStep14 = () => {
    history.push(`/revision/${area}/14/${id}`)
  }

  const goToStartpage = () => {
    history.push('/')
  }

  const [formData, setFormData]: any = useState()
  const [loading, setLoading] = useState(true)
  const [isAllowed, setIsAllowed] = useState(false)
  const [key, setKey] = useState('')
  // skipping the value since it is not used
  const [, setAreaUrlParam] = useState('')
  const [approvedAt, setApprovedAt] = useState('')
  const [loadData, setLoadData] = useState(true)
  const [
    isApprovalBtnEnabled, setIsApprovalBtnEnabled,
  ] = useState(true)

  useEffect(() => {
    if (loadData) {
      (async () => {
        if (id) {
          const res = await getFirestoreDocById(id)
          if (res) {
            setFormData(res)

            if (checkAllApproved(res)
              && (!res?.p6Show || !res?.p7Show)) {
              const approveformRef = doc(db, 'forms', id)

              try {
                setIsApprovalBtnEnabled(false)
                await updateDoc(approveformRef, {
                  peopleApprovedAllPages: true,
                })
                toast.success('La aprobación se actualizó correctamente')
              } catch (updateError) {
                setIsApprovalBtnEnabled(true)
                toast.error(`Error: ${updateError}`)
              }

              console.log('All is approved')
              console.log('id before approvedform : ', id)
              const foundUser = allUsers
                .find((element) => element?.userKey === formData?.newFormPersonWhoDid)

              const approvalRes = await fetch(
                `${nodeApiBaseUrl}/approvedform`,
                {
                  method: 'POST',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    email: res?.newFormPersonEmailWhoDid, userName: foundUser?.userName, formId: id,
                  }),
                },
              )
              const approvalResMessage = await approvalRes.json()
              console.log('Envío de mensaje de aprobación del formulario a la persona que creó el permiso de trabajo : ', approvalResMessage)
              if (approvalResMessage) {
                toast.success('El email de aprobación se envió correctamente')
              }

              const pdfRes = await fetch(
                `${nodeApiBaseUrl}/createpdfreview`,
                {
                  method: 'POST',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    formId: id,
                  }),
                },
              )
              const pdfResMessage = await pdfRes.json()
              console.log('Envío de mensaje de generación de pdf : ', pdfResMessage)
              if (pdfResMessage) {
                toast.success('El pdf se ha generado correctamente y se ha enviado a su email')
                history.push('/')
              }
            }

            if (checkAllApproved(res)
              && res?.paramedicApprovedAllWorkers) {
              const approveformRef = doc(db, 'forms', id)

              try {
                setIsApprovalBtnEnabled(false)
                await updateDoc(approveformRef, {
                  peopleApprovedAllPages: true,
                })
                toast.success('La aprobación se actualizó correctamente')
              } catch (updateError) {
                setIsApprovalBtnEnabled(true)
                toast.error(`Error: ${updateError}`)
              }

              console.log('All is approved')
              console.log('id before approvedform : ', id)
              const foundUser = allUsers
                .find((element) => element?.userKey === formData?.newFormPersonWhoDid)

              const approvalRes = await fetch(
                `${nodeApiBaseUrl}/approvedform`,
                {
                  method: 'POST',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    email: res?.newFormPersonEmailWhoDid, userName: foundUser?.userName, formId: id,
                  }),
                },
              )
              const approvalResMessage = await approvalRes.json()
              console.log('Envío de mensaje de aprobación del formulario a la persona que creó el permiso de trabajo : ', approvalResMessage)
              if (approvalResMessage) {
                toast.success('El email de aprobación se envió correctamente')
              }

              const pdfRes = await fetch(
                `${nodeApiBaseUrl}/createpdfreview`,
                {
                  method: 'POST',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    formId: id,
                  }),
                },
              )
              const pdfResMessage = await pdfRes.json()
              console.log('Envío de mensaje de generación de pdf : ', pdfResMessage)
              if (pdfResMessage) {
                toast.success('El pdf se ha generado correctamente y se ha enviado a su email')
                history.push('/')
              }
            }
            if (area === 'tsu') {
              console.log('res?.p16workSupervisor', res?.p16workSupervisor)
              console.log('%cp16workSupervisor has id', color1ConsoleLog)
              setIsAllowed(true)
              setKey('p16workSupervisorApproved15')
              setAreaUrlParam('tsu')
              setApprovedAt('p16workSupervisorApprovedAt15')
            }
            if (area === 'pep') {
              console.log('%cp16pepCoordinator has id', color1ConsoleLog)
              setIsAllowed(true)
              setKey('p16pepCoordinatorApproved15')
              setAreaUrlParam('pep')
              setApprovedAt('p16pepCoordinatorApprovedAt15')
            }
            if (area === 'aco') {
              console.log('%cp16areaCoordinator has id', color1ConsoleLog)
              setIsAllowed(true)
              setKey('p16areaCoordinatorApproved15')
              setAreaUrlParam('aco')
              setApprovedAt('p16areaCoordinatorApprovedAt15')
            }
            if (area === 'qt') {
              console.log('%cp16quality has id', color1ConsoleLog)
              setIsAllowed(true)
              setKey('p16qualityApproved15')
              setAreaUrlParam('qt')
              setApprovedAt('p16qualityApprovedAt15')
            }
            if (area === 'ta') {
              console.log('%cp16technicalArea has id', color1ConsoleLog)
              setIsAllowed(true)
              setKey('p16technicalAreaApproved15')
              setAreaUrlParam('ta')
              setApprovedAt('p16technicalAreaApprovedAt15')
            }
            if (area === 'ps') {
              console.log('%cp16patrimonialSecurity has id', color1ConsoleLog)
              setIsAllowed(true)
              setKey('p16patrimonialSecurityApproved15')
              setAreaUrlParam('ps')
              setApprovedAt('p16patrimonialSecurityApprovedAt15')
            }
            if (area === 'ep') {
              console.log('%cp16externalPrevencionist has id', color1ConsoleLog)
              setIsAllowed(true)
              setKey('p16externalPrevencionistApproved15')
              setAreaUrlParam('ep')
              setApprovedAt('p16externalPrevencionistApprovedAt15')
            }
            if (area === 'sass') {
              console.log('%cp16sass has id', color1ConsoleLog)
              setIsAllowed(true)
              setKey('p16sassApproved15')
              setAreaUrlParam('sass')
              setApprovedAt('p16sassApprovedAt15')
            }
            setLoading(false)
          } else {
            setLoading(false)
          }
        }
        setLoadData(false)
      })()
    }
  }, [loadData])

  if (loading) {
    <Typography>Cargando</Typography>
  }
  console.log('formData step 15', formData)

  const approve = async (e: any) => {
    e.preventDefault()
    const approveformRef = doc(db, 'forms', id)
    const obj: any = {}
    obj[key] = true
    obj[approvedAt] = new Date()
    try {
      await updateDoc(approveformRef, obj)
      console.log('approved', key, approvedAt)
      // console.log('All the selected people approved the form')
      setLoadData(true)
      toast.success('La aprobación se actualizó correctamente')
    } catch (updateError) {
      toast.error(`Error: ${updateError}`)
    }
  }

  const disapprove = async (e: any) => {
    e.preventDefault()
    const approveformRef = doc(db, 'forms', id)
    const obj: any = {}
    obj[key] = false
    obj[approvedAt] = new Date()
    try {
      await updateDoc(approveformRef, obj)
      console.log('disapproved', key, approvedAt)
      setLoadData(true)
      toast.success('La desaprobación se actualizó correctamente')
    } catch (updateError) {
      toast.error(`Error: ${updateError}`)
    }
  }

  return (
    <DashboardLayout>
      {loading ? <FullPageLoader /> : (
        <Slide direction="right" in mountOnEnter unmountOnExit>
          <Grid sx={{ mt: 4 }} container justifyContent="center" spacing={2}>
            <Grid item xs={10} sm={9} md={5} lg={7}>
              {
                (formData?.p16workSupervisor === user?.uid)
                  || (formData?.p16pepCoordinator === user?.uid)
                  || (formData?.p16areaCoordinator === user?.uid)
                  || (formData?.p16quality === user?.uid)
                  || (formData?.p16technicalArea === user?.uid)
                  || (formData?.p16patrimonialSecurity === user?.uid)
                  || (formData?.p16externalPrevencionist === user?.uid)
                  || (formData?.p16sass === user?.uid)
                  || (formData?.p16sassAllowedList?.includes(user?.uid))
                  ? (
                    <>
                      <Typography
                        sx={{ mb: 2 }}
                        align="center"
                        variant="h6"
                        color="text.secondary"
                        gutterBottom
                      >
                        BLOQUEO DE ENERGÍAS
                      </Typography>

                      <Grid display="flex" justifyContent="center" item xs={12}>
                        <FormControlLabel
                          control={(
                            <YellowSwitch
                              checked={formData?.p15show && formData?.p15show}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          )}
                          label={formData?.p15show && formData?.p15show ? 'Esta página aplica' : 'Esta página no aplica'}
                        />
                      </Grid>

                      <Box
                        component="div"
                        sx={{
                          display: formData?.p15show ? 'block' : 'none',
                        }}
                      >
                        <Grid display="flex" justifyContent="center" item xs={12}>
                          <TextField
                            sx={{ mt: 2, mb: 2, width: 300 }}
                            fullWidth
                            label="Nombre del personal a cargo del bloqueo : "
                            variant="outlined"
                            value={
                              formData?.p15blockingPersonName && formData?.p15blockingPersonName
                            }
                          />
                        </Grid>
                        <Grid display="flex" justifyContent="center" item xs={12}>
                          <TextField
                            sx={{ mt: 2, mb: 2, width: 300 }}
                            fullWidth
                            label="Fecha / Hora inicio del LOTO : "
                            variant="outlined"
                            value={`${formData?.p15dateTimeLotoStart && formData?.p15dateTimeLotoStart.toDate()
                              .getDate()}-${monthNames[formData?.p15dateTimeLotoStart && formData?.p15dateTimeLotoStart.toDate().getMonth()]}-${formData?.p15dateTimeLotoStart && formData?.p15dateTimeLotoStart.toDate().getFullYear()} / ${formData?.p15dateTimeLotoStart && formData?.p15dateTimeLotoStart.toDate().getHours()}:${formData?.p15dateTimeLotoStart && formData?.p15dateTimeLotoStart.toDate().getMinutes() < 10 ? '0' : ''}${formData?.p15dateTimeLotoStart && formData?.p15dateTimeLotoStart.toDate().getMinutes()}`}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid display="flex" justifyContent="center" item xs={12}>
                          <TextField
                            sx={{ mt: 2, mb: 2, width: 300 }}
                            fullWidth
                            label="Fecha / Hora finalización del LOTO : "
                            variant="outlined"
                            value={`${formData?.p15dateTimeLotoFinish && formData?.p15dateTimeLotoFinish.toDate()
                              .getDate()}-${monthNames[formData?.p15dateTimeLotoFinish && formData?.p15dateTimeLotoFinish.toDate().getMonth()]}-${formData?.p15dateTimeLotoFinish && formData?.p15dateTimeLotoFinish.toDate().getFullYear()} / ${formData?.p15dateTimeLotoFinish && formData?.p15dateTimeLotoFinish.toDate().getHours()}:${formData?.p15dateTimeLotoFinish && formData?.p15dateTimeLotoFinish.toDate().getMinutes() < 10 ? '0' : ''}${formData?.p15dateTimeLotoFinish && formData?.p15dateTimeLotoFinish.toDate().getMinutes()}`}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>

                        <Typography
                          sx={{ mt: 4 }}
                          textAlign="center"
                        >
                          Tipo de Energía a Bloquear:
                        </Typography>
                        <Grid sx={{ mt: 1 }} display="flex" justifyContent="center" alignItems="center">

                          <Typography>Eléctrica</Typography>
                          <Checkbox
                            checked={formData?.p15blockingEnTypeElectrical === true}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                          <Typography>Hidraulica</Typography>
                          <Checkbox
                            checked={formData?.p15blockingEnTypeHidraulical === true}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                          <Typography>Mecánica</Typography>
                          <Checkbox
                            checked={formData?.p15blockingEnMechanical === true}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                          <Typography>Potencial</Typography>
                          <Checkbox
                            checked={formData?.p15blockingEnPotencial === true}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </Grid>
                        {/* </div> */}

                        <Box sx={{
                          display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                        }}
                        >
                          <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                            <FormLabel component="legend">Capacitacion control de energias peligrosas</FormLabel>
                            <RadioGroup
                              sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                              aria-label="atrapamiento"
                              name="atrapamiento"
                              value={formData?.p15dangEnergiesControl}
                            >
                              <FormControlLabel value="SI" control={<Radio checked={formData?.p15dangEnergiesControl === 'SI'} />} label="SI" />
                              <FormControlLabel value="NO" control={<Radio checked={formData?.p15dangEnergiesControl === 'NO'} />} label="NO" />
                              <FormControlLabel value="NA" control={<Radio checked={formData?.p15dangEnergiesControl === 'NA'} />} label="N/A" />
                            </RadioGroup>
                          </FormControl>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                          <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                            <FormLabel component="legend">Conoce las fuentes de energias de la maquina</FormLabel>
                            <RadioGroup
                              sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                              aria-label="aplastamiento"
                              name="aplastamiento"
                              value={formData?.p15knowEnergySourceMachine}
                            >
                              <FormControlLabel value="SI" control={<Radio checked={formData?.p15knowEnergySourceMachine === 'SI'} />} label="SI" />
                              <FormControlLabel value="NO" control={<Radio checked={formData?.p15knowEnergySourceMachine === 'NO'} />} label="NO" />
                              <FormControlLabel value="NA" control={<Radio checked={formData?.p15knowEnergySourceMachine === 'NA'} />} label="N/A" />
                            </RadioGroup>
                          </FormControl>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                          <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                            <FormLabel component="legend">Conoc del LOTO</FormLabel>
                            <RadioGroup
                              sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                              aria-label="golpeadorpor"
                              name="golpeadorpor"
                              value={formData?.p15concLoto}
                            >
                              <FormControlLabel value="SI" control={<Radio checked={formData?.p15concLoto === 'SI'} />} label="SI" />
                              <FormControlLabel value="NO" control={<Radio checked={formData?.p15concLoto === 'NO'} />} label="NO" />
                              <FormControlLabel value="NA" control={<Radio checked={formData?.p15concLoto === 'NA'} />} label="N/A" />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                          <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                            <FormLabel component="legend">Posee candado de bloqueo, tarjeta de identificacion y dispositivo de bloqueo</FormLabel>
                            <RadioGroup
                              sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                              aria-label="golpeadorpor"
                              name="golpeadorpor"
                              value={formData?.p15hasLockIdCardBlockDevice}
                            >
                              <FormControlLabel value="SI" control={<Radio checked={formData?.p15hasLockIdCardBlockDevice === 'SI'} />} label="SI" />
                              <FormControlLabel value="NO" control={<Radio checked={formData?.p15hasLockIdCardBlockDevice === 'NO'} />} label="NO" />
                              <FormControlLabel value="NA" control={<Radio checked={formData?.p15hasLockIdCardBlockDevice === 'NA'} />} label="N/A" />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                          <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                            <FormLabel component="legend">Guantes dielectricos</FormLabel>
                            <RadioGroup
                              sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                              aria-label="golpeadorpor"
                              name="golpeadorpor"
                              value={formData?.p15dielectricGlove}
                            >
                              <FormControlLabel value="SI" control={<Radio checked={formData?.p15dielectricGlove === 'SI'} />} label="SI" />
                              <FormControlLabel value="NO" control={<Radio checked={formData?.p15dielectricGlove === 'NO'} />} label="NO" />
                              <FormControlLabel value="NA" control={<Radio checked={formData?.p15dielectricGlove === 'NA'} />} label="N/A" />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                          <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                            <FormLabel component="legend">Zapatos de seguridad dielectrico</FormLabel>
                            <RadioGroup
                              sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                              aria-label="golpeadorpor"
                              name="golpeadorpor"
                              value={formData?.p15dielectricSecurityShoe}
                            >
                              <FormControlLabel value="SI" control={<Radio checked={formData?.p15dielectricSecurityShoe === 'SI'} />} label="SI" />
                              <FormControlLabel value="NO" control={<Radio checked={formData?.p15dielectricSecurityShoe === 'NO'} />} label="NO" />
                              <FormControlLabel value="NA" control={<Radio checked={formData?.p15dielectricSecurityShoe === 'NA'} />} label="N/A" />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 10 }}>
                          <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                            <FormLabel component="legend">Lentes de seguridad dielectrico</FormLabel>
                            <RadioGroup
                              sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                              aria-label="golpeadorpor"
                              name="golpeadorpor"
                              value={formData?.p15dielectricSecurityGlass}
                            >
                              <FormControlLabel value="SI" control={<Radio checked={formData?.p15dielectricSecurityGlass === 'SI'} />} label="SI" />
                              <FormControlLabel value="NO" control={<Radio checked={formData?.p15dielectricSecurityGlass === 'NO'} />} label="NO" />
                              <FormControlLabel value="NA" control={<Radio checked={formData?.p15dielectricSecurityGlass === 'NA'} />} label="N/A" />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Box>

                      <Grid
                        display={isAllowed ? 'flex' : 'none'}
                        justifyContent="space-evenly"
                        sx={{ mt: 4 }}
                      >
                        <Button
                          startIcon={<HighlightOffIcon />}
                          sx={{ mb: 10, bgcolor: '#db1414' }}
                          color="error"
                          variant="contained"
                          onClick={(e) => disapprove(e)}
                        >
                          Desaprobar
                        </Button>
                        <Button
                          startIcon={<CheckIcon />}
                          sx={{ mb: 10, bgcolor: '#42b50e' }}
                          color="success"
                          variant="contained"
                          onClick={(e) => approve(e)}
                          disabled={!isApprovalBtnEnabled}
                        >
                          Aprobar
                        </Button>
                      </Grid>

                      <Grid sx={{ mb: 2 }} container justifyContent="space-between">
                        <Grid
                          xs={12}
                          md={5}
                          sx={{ mb: 2 }}
                        >
                          <ColorButton
                            fullWidth
                            startIcon={<ArrowLeftIcon />}
                            onClick={goToReviseStep14}
                          >
                            Ir a la página anterior
                          </ColorButton>
                        </Grid>
                        <Grid
                          xs={12}
                          md={5}
                        >
                          <ColorButton
                            fullWidth
                            endIcon={<ArrowRightIcon />}
                            onClick={goToStartpage}
                          >
                            Ir al inicio
                          </ColorButton>
                        </Grid>

                      </Grid>
                    </>
                  )
                  : 'Por favor inicie sesión para visualizar su documento a revisar'
              }

              {user ? (
                <Typography color="green">Usted ha iniciado sesión.</Typography>
              )
                : (
                  <Typography color="red" sx={{ mt: 2 }}>Usted no ha iniciado sesión</Typography>
                )}

            </Grid>
          </Grid>
        </Slide>
      )}

    </DashboardLayout>
  )
}

export default ReviseStep15