import React from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Slide from '@mui/material/Slide'
import InfoIcon from '@mui/icons-material/Info'
import { useHistory } from 'react-router-dom'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { toast } from 'react-toastify'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import YellowSwitch from '../../common/YellowSwitch'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { entireStore } from '../../app/store'
import { allFormActions } from '../../features/form/formSlice'
import DashboardLayout from '../layouts/DashboardLayout'
import ColorButton from './ColorButton'
import useNewFormId from '../../hooks/useNewFormId'
import useStorage from '../../hooks/useStorage'
import { db, doc, updateDoc } from '../../config/firebaseConfig'

const CreateStep6 = () => {
  console.log('CreateStep6')

  const imageTypes = ['image/png', 'image/jpeg', 'image/jpg']

  const [file, setFile] = React.useState<File | null>(null)
  const [fileID, setFileID] = React.useState<String | null>(null)
  const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(false)
  const { newFormId } = useNewFormId()

  const { url, progress, error } = useStorage(file)
  console.log('fileid2', fileID)

  const customIdFileUploadFinish = 'custom-id-file-upload-finish'
  React.useEffect(() => {
    if (progress > 1) {
      setButtonDisabled(true)
    }
    if (progress === 100) {
      setButtonDisabled(false)
      toast.success('La imagen ha sido subida correctamente', {
        toastId: customIdFileUploadFinish,
      })
      console.log('fileid', fileID)

      localStorage.setItem(`${fileID}`, url)
      console.log(url)
    }
    if (error) {
      // error es any
      setButtonDisabled(false)
      toast.error(error)
    }
  }, [progress, url, file])

  const {
    p6Show,
    p6keyNumber,
    p6weatherConditions,
    p6trainingForHeightWork,
    p6trainingEspecWork,
    p6radioCommunication,
    p6toolBelt,
    p6useOfHookPoint,
    p6useofLadder,
    p6elevator,
    p6andamTipeEuro,
    p6healthCheck,
    p6inlineSliderVertical,
    p6heightJobLowLevel,
  } = useAppSelector(entireStore)
  const {
    setp6Show,
    setp6keyNumber,
    setp6weatherConditions,
    setp6trainingForHeightWork,
    setp6trainingEspecWork,
    setp6radioCommunication,
    setp6toolBelt,
    setp6useOfHookPoint,
    setp6useofLadder,
    setp6elevator,
    setp6andamTipeEuro,
    setp6healthCheck,
    setp6inlineSliderVertical,
    setp6heightJobLowLevel,
  } = allFormActions
  const dispatch = useAppDispatch()

  const history = useHistory()

  const goToCreateStep5 = () => {
    history.push(`/create/5/${newFormId}`)
  }

  const goToCreateStep7 = async () => {
    const formRef = doc(db, 'forms', newFormId as string)
    try {
      await updateDoc(formRef, {
        newFormp6FilledOut: true,
        newFormp6FilledOutDate: new Date(),
        p6Show,
        p6keyNumber,
        p6weatherConditions,
        p6trainingForHeightWork,
        p6trainingEspecWork,
        p6radioCommunication,
        p6toolBelt,
        p6useOfHookPoint,
        p6useofLadder,
        p6elevator,
        p6andamTipeEuro,
        p6healthCheck,
        p6inlineSliderVertical,
        p6heightJobLowLevel,
      })
      toast.success('El permiso de trabajo se actualizó correctamente')
      history.push(`/create/7/${newFormId}`)
    } catch (updateError) {
      toast.error(`Error: ${updateError}`)
    }
  }

  const Input = styled('input')({
    display: 'none',
  })

  return (
    <DashboardLayout>
      <Slide direction="right" in mountOnEnter unmountOnExit>
        <Grid sx={{ mt: 4 }} container justifyContent="center" spacing={2}>
          <Grid item xs={10} sm={9} md={5} lg={7}>
            <Typography
              sx={{ mb: 2 }}
              align="center"
              variant="h6"
              color="text.secondary"
              gutterBottom
            >
              TRABAJO EN ALTURA
            </Typography>
            <Grid display="flex" justifyContent="center" item xs={12}>
              <FormControlLabel
                control={(
                  <YellowSwitch
                    checked={p6Show}
                    onChange={(event) => dispatch(setp6Show(event.target.checked))}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                )}
                label={p6Show ? 'Esta página aplica' : 'Esta página no aplica'}
              />
            </Grid>

            <Grid
              container
              display="flex"
              justifyContent="center"
              sx={{ mt: 2, mb: 2 }}

            >
              <Grid
                item
                display="flex"
                xs={9}
                md={7}
              >
                <InfoIcon sx={{ color: '#fbc02d' }} />
                <Typography align="center" sx={{ ml: 1 }}>
                  Si selecciona Aplica,
                  se le enviará
                  un email de aprobación al Paramédico al guardar
                  el permiso de trabajo
                </Typography>
              </Grid>
            </Grid>

            <Box
              component="div"
              sx={{
                display: p6Show ? 'block' : 'none',
              }}
            >
              <Grid display="flex" justifyContent="center" item xs={12}>
                <TextField
                  sx={{ mb: 2, mt: 2, width: 300 }}
                  fullWidth
                  label="Llave de acceso número"
                  variant="outlined"
                  value={p6keyNumber}
                  onChange={(e) => dispatch(setp6keyNumber(e.target.value))}
                />
              </Grid>

              <Grid display="flex" justifyContent="center" item xs={12}>
                <Box sx={{
                  display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                }}
                >
                  <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                    <FormLabel component="legend">Condiciones climáticas adversas (lluvia, neblina, etc)</FormLabel>
                    <RadioGroup
                      sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                      aria-label="condicionesclimaticas"
                      name="condicionesclimaticas"
                      value={p6weatherConditions}
                      onChange={(e) => dispatch(setp6weatherConditions(e.target.value))}
                    >
                      <FormControlLabel value="SI" control={<Radio />} label="SI" />
                      <FormControlLabel value="NO" control={<Radio />} label="NO" />
                      <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Box sx={{
                display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
              }}
              >
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">
                    Capacitación para trabajo en altura
                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="capacitaciontrabajoenaltura"
                    name="capacitaciontrabajoenaltura"
                    value={p6trainingForHeightWork}
                    onChange={(e) => dispatch(setp6trainingForHeightWork(e.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box sx={{
                display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
              }}
              >
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">
                    Capacitación específica del trabajo
                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="capacitacionespecifica"
                    name="capacitacionespecifica"
                    value={p6trainingEspecWork}
                    onChange={(e) => dispatch(setp6trainingEspecWork(e.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box sx={{
                display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
              }}
              >
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">
                    Radios de comunicación
                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="radiosdecom"
                    name="radiosdecom"
                    value={p6radioCommunication}
                    onChange={(e) => dispatch(setp6radioCommunication(e.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box sx={{
                display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
              }}
              >
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">
                    Cinturón de Herramientas
                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="cinturondeherramientas"
                    name="cinturondeherramientas"
                    value={p6toolBelt}
                    onChange={(e) => dispatch(setp6toolBelt(e.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box sx={{
                display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
              }}
              >
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">
                    Uso de puntos de anclaje
                    {' '}

                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="usodepuntosdeanclaje"
                    name="usodepuntosdeanclaje"
                    value={p6useOfHookPoint}
                    onChange={(e) => dispatch(setp6useOfHookPoint(e.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box sx={{
                display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
              }}
              >
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">
                    Uso de escalas y/o plataformas
                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="usodeescalasyoplat"
                    name="usodeescalasyoplat"
                    value={p6useofLadder}
                    onChange={(e) => dispatch(setp6useofLadder(e.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>

                { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(e) => {
                      if (e.target.files) {
                        const selectedFile = e?.target?.files[0]
                        console.table(selectedFile)
                        if (selectedFile && imageTypes.includes(selectedFile.type)) {
                          setFile(selectedFile)
                        } else {
                          toast.error('El archivo no es una imagen, por favor intente nuevamente')
                        }
                      }
                    }}
                  />
                  <Button
                    sx={{ bgcolor: '#fbc02d', mb: 2 }}
                    variant="contained"
                    component="span"
                    disabled={buttonDisabled}
                    onClick={() => setFileID('p6useofLadderImage')}
                  >
                    {buttonDisabled
                      ? 'Subiendo imagen'
                      : 'Subir imagen'}
                  </Button>
                </label>
              </Box>
              <Box sx={{
                display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
              }}
              >
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">
                    Elevadores y Jaulas
                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="elevadoresyjaulas"
                    name="elevadoresyjaulas"
                    value={p6elevator}
                    onChange={(e) => dispatch(setp6elevator(e.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>

                { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(e) => {
                      if (e.target.files) {
                        const selectedFile = e?.target?.files[0]
                        console.table(selectedFile)
                        if (selectedFile && imageTypes.includes(selectedFile.type)) {
                          setFile(selectedFile)
                        } else {
                          toast.error('El archivo no es una imagen, por favor intente nuevamente')
                        }
                      }
                    }}
                  />
                  <Button
                    sx={{ bgcolor: '#fbc02d', mb: 2 }}
                    variant="contained"
                    component="span"
                    onClick={() => { setFileID('p6elevatorImage') }}
                    disabled={buttonDisabled}
                  >
                    {buttonDisabled
                      ? 'Subiendo imagen'
                      : 'Subir imagen'}
                  </Button>
                </label>
              </Box>
              <Box sx={{
                display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
              }}
              >
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">Andamio Tipo euro (soporte y anclaje)</FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="andamiotipoeuro"
                    name="andamiotipoeuro"
                    value={p6andamTipeEuro}
                    onChange={(e) => dispatch(setp6andamTipeEuro(e.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>

                { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(e) => {
                      if (e.target.files) {
                        const selectedFile = e?.target?.files[0]
                        console.table(selectedFile)
                        if (selectedFile && imageTypes.includes(selectedFile.type)) {
                          setFile(selectedFile)
                        } else {
                          toast.error('El archivo no es una imagen, por favor intente nuevamente')
                        }
                      }
                    }}
                  />
                  <Button
                    sx={{ bgcolor: '#fbc02d', mb: 2 }}
                    variant="contained"
                    component="span"
                    disabled={buttonDisabled}
                    onClick={() => setFileID('p6andamTipeEuroImage')}
                  >
                    {buttonDisabled
                      ? 'Subiendo imagen'
                      : 'Subir imagen'}
                  </Button>
                </label>
              </Box>
              <Box sx={{
                display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
              }}
              >
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">Examen de Salud (Exámen de Altura)</FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="examendesaludeda"
                    name="examendesaludeda"
                    value={p6healthCheck}
                    onChange={(e) => dispatch(setp6healthCheck(e.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box sx={{
                display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
              }}
              >
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">Deslizador en línea vertical</FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="deslizadorenlinea"
                    name="deslizadorenlinea"
                    value={p6inlineSliderVertical}
                    onChange={(e) => dispatch(setp6inlineSliderVertical(e.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box sx={{
                display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
              }}
              >
                <FormControl sx={{ display: 'flex', mb: 10 }} component="fieldset">
                  <FormLabel component="legend">Trabajo en altura bajo nivel</FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="trabajoenaltbn"
                    name="trabajoenaltbn"
                    value={p6heightJobLowLevel}
                    onChange={(e) => dispatch(setp6heightJobLowLevel(e.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
            <Grid sx={{ mb: 2 }} container justifyContent="space-between">
              <Grid
                xs={12}
                md={5}
                sx={{ mb: 2 }}
              >
                <ColorButton
                  fullWidth
                  startIcon={<ArrowLeftIcon />}
                  onClick={goToCreateStep5}
                >
                  Ir a la página anterior
                </ColorButton>
              </Grid>
              <Grid
                xs={12}
                md={5}
              >
                <ColorButton
                  fullWidth
                  endIcon={<ArrowRightIcon />}
                  onClick={goToCreateStep7}
                >
                  Ir a la página siguiente
                </ColorButton>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </DashboardLayout>
  )
}

export default CreateStep6