import { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Link } from 'react-router-dom'
import DashboardLayout from '../layouts/DashboardLayout'
import {
  collection, getDocs, orderBy, query, db,
} from '../../config/firebaseConfig'
import {
  Grid, Typography, Table, TableBody,
  TableCell, TableContainer, TableHead,
  TableRow, Paper, color1ConsoleLog,
  FullPageLoader, ColorButton,
} from './importedComponents'
import allUsers from '../../common/allUsers'

const Created = () => {
  const theme = useTheme()
  const matchesSmScreen = useMediaQuery(theme.breakpoints.up('sm'))
  const user = global?.user
  console.log('%cuser : %s', color1ConsoleLog, user?.uid)

  const [docIsLoading, setDocIsLoading] = useState(true)
  const [tablerows, setTableRows]: any = useState([])
  const [nonadminTablerows, setNonadminTableRows]: any = useState([])
  // Aaron es admin1
  // eslint-disable-next-line
  const [isAdmin1, setIsAdmin1] = useState('j5ZHSzwXm0XHRfLGy5Grz6TwiuA2')
  // Marlite es admin2
  // eslint-disable-next-line
  const [isAdmin2, setIsAdmin2] = useState('GtDdJPjZ8sUZR1vdXk8htSG8ycN2')
  // Diego es admin3
  // eslint-disable-next-line
  const [isAdmin3, setIsAdmin3] = useState('qNaGcdkV74ft1PptYcBrSqCWB0P2')

  const [isAdmin4] = useState('LoXlGxY0xjU44onK4SzreCgTEO43')

  const getApprovedForms = async () => {
    const formsRef = collection(db, 'forms')
    try {
      const q = query(formsRef, orderBy('newFormCreatedAt', 'desc'))
      const querySnapshot = await getDocs(q)
      const rows: any = []
      querySnapshot.forEach((doc) => {
        if (doc?.data()?.newFormp1FilledOut
          && doc?.data()?.newFormp2FilledOut
          && doc?.data()?.newFormp3FilledOut
          && doc?.data()?.newFormp4FilledOut
          && doc?.data()?.newFormp5FilledOut
          && doc?.data()?.newFormp6FilledOut
          && doc?.data()?.newFormp7FilledOut
          && doc?.data()?.newFormp8FilledOut
          && doc?.data()?.newFormp9FilledOut
          && doc?.data()?.newFormp10FilledOut
          && doc?.data()?.newFormp11FilledOut
          && doc?.data()?.newFormp12FilledOut
          && doc?.data()?.newFormp13FilledOut
          && doc?.data()?.newFormp14FilledOut
          && doc?.data()?.newFormp15FilledOut
          && doc?.data()?.newFormp16FilledOut) {
          // console.log('All the pages in the form are filled out')
          rows.push(doc?.data())
        }
      })
      setTableRows(rows)
      const newNonadminRows = rows
        .filter((element: any) => (element.newFormPersonEmailWhoDid === user?.email)
          || (element?.p16workSupervisor === user?.uid)
          || (element?.p16pepCoordinator === user?.uid)
          || (element?.p16areaCoordinator === user?.uid)
          || (element?.p16quality === user?.uid)
          || (element?.p16technicalArea === user?.uid)
          || (element?.p16patrimonialSecurity === user?.uid)
          || (element?.p16externalPrevencionist === user?.uid)
          || (element?.p16sass === user?.uid))
      setNonadminTableRows(newNonadminRows)
      console.log('nonadminTablerows : ', nonadminTablerows)
      setDocIsLoading(false)
    } catch (error) {
      console.error('error')
    }
  }

  console.log('tablerows', tablerows)
  const p16sassAllowedListMapped = tablerows?.map(
    (item: any) => console.log(item.p16sassAllowedList),
  )
  console.log('tablerows p16sassAllowedList', p16sassAllowedListMapped)

  console.log('the user is in p16sassAllowedListMapped ', p16sassAllowedListMapped?.includes(user?.uid))

  useEffect(() => {
    getApprovedForms()
  }, [])

  return (
    <DashboardLayout>
      {docIsLoading ? <FullPageLoader /> : (
        <>
          <Grid container justifyContent="center">
            <Typography
              sx={{ mt: 4, mb: 4 }}
              textAlign="center"
              variant="body1"
            >
              Mis revisiones
            </Typography>
          </Grid>
          <TableContainer
            sx={{
              width: matchesSmScreen ? '70%' : '80%',
              display: 'block',
              margin: '0 auto 60px auto',
            }}
            component={Paper}
          >
            {user && (
              (user?.uid === isAdmin1)
              || (user?.uid === isAdmin2)
              || (user?.uid === isAdmin3)
              || (user?.uid === isAdmin4)
            )
              ? (
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Identificador del permiso de trabajo
                      </TableCell>
                      <TableCell>
                        Fecha Creacion
                      </TableCell>
                      <TableCell align="center">
                        Creado Por
                      </TableCell>
                      <TableCell align="center">
                        Asignado A
                      </TableCell>
                      <TableCell align="center">
                        Aprobado por paramédico
                      </TableCell>
                      <TableCell align="center">
                        Aprobado por asignados
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tablerows.length > 0 && tablerows.map((row: any) => {
                      const person = allUsers.find((a) => a.email === row.newFormPersonEmailWhoDid)
                      const list = ['']
                      list.pop()
                      allUsers.find((a) => {
                        if (a.userKey === row?.p16workSupervisor
                          || a.userKey === row?.p16workSupervisor
                          || a.userKey === row?.p16pepCoordinator
                          || a.userKey === row?.p16areaCoordinator
                          || a.userKey === row?.p16quality
                          || a.userKey === row?.p16technicalArea
                          || a.userKey === row?.p16patrimonialSecurity
                          || a.userKey === row?.p16externalPrevencionist
                          || a.userKey === row?.p16sassAllowedList
                          || a.userKey === row?.p16sass
                          || row?.p16sassAllowedList.includes(a.userKey)) {
                          list.push(a.userName.toString())
                        }
                        return false
                      })
                      return (
                        <TableRow
                          key={row?.newFormIdentifier}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {`${row?.newFormIdentifier}`}
                            {(row?.p16workSupervisor === user?.uid)
                              && (
                                <Link
                                  to={`/revision/tsu/1/${row?.newFormIdentifier}`}
                                  style={{ color: 'white', textDecoration: 'none' }}
                                >
                                  <ColorButton sx={{ ml: 1, mt: 1 }}>
                                    Revisar como supervisor del trabajo
                                  </ColorButton>
                                </Link>
                              )}
                            {(row?.p16pepCoordinator === user?.uid)
                              && (
                                <Link
                                  to={`/revision/pep/1/${row?.newFormIdentifier}`}
                                  style={{ color: 'white', textDecoration: 'none' }}
                                >
                                  <ColorButton sx={{ ml: 1, mt: 1 }}>
                                    Revisar como coordinador Pepsico
                                  </ColorButton>
                                </Link>
                              )}
                            {(row?.p16areaCoordinator === user?.uid)
                              && (
                                <Link
                                  to={`/revision/aco/1/${row?.newFormIdentifier}`}
                                  style={{ color: 'white', textDecoration: 'none' }}
                                >
                                  <ColorButton sx={{ ml: 1, mt: 1 }}>
                                    Revisar como coordinador de área
                                  </ColorButton>
                                </Link>
                              )}
                            {(row?.p16quality === user?.uid)
                              && (
                                <Link
                                  to={`/revision/qt/1/${row?.newFormIdentifier}`}
                                  style={{ color: 'white', textDecoration: 'none' }}
                                >
                                  <ColorButton sx={{ ml: 1, mt: 1 }}>
                                    Revisar como calidad
                                  </ColorButton>
                                </Link>
                              )}
                            {(row?.p16technicalArea === user?.uid)
                              && (
                                <Link
                                  to={`/revision/ta/1/${row?.newFormIdentifier}`}
                                  style={{ color: 'white', textDecoration: 'none' }}
                                >
                                  <ColorButton sx={{ ml: 1, mt: 1 }}>
                                    Revisar como área técnica
                                  </ColorButton>
                                </Link>
                              )}
                            {(row?.p16patrimonialSecurity === user?.uid)
                              && (
                                <Link
                                  to={`/revision/ps/1/${row?.newFormIdentifier}`}
                                  style={{ color: 'white', textDecoration: 'none' }}
                                >
                                  <ColorButton sx={{ ml: 1, mt: 1 }}>
                                    Revisar como seguridad patrimonial
                                  </ColorButton>
                                </Link>
                              )}
                            {(row?.p16externalPrevencionist === user?.uid)
                              && (
                                <Link
                                  to={`/revision/ep/1/${row?.newFormIdentifier}`}
                                  style={{ color: 'white', textDecoration: 'none' }}
                                >
                                  <ColorButton sx={{ ml: 1, mt: 1 }}>
                                    Revisar como prevencionista externo
                                  </ColorButton>
                                </Link>
                              )}
                            {(
                              (row?.p16sassAllowedList.includes(user?.uid))
                              || (p16sassAllowedListMapped.includes(user?.uid))
                            )
                              && (
                                <Link
                                  to={`/revision/sass/1/${row?.newFormIdentifier}`}
                                  style={{ color: 'white', textDecoration: 'none' }}
                                >
                                  <ColorButton sx={{ ml: 1, mt: 1 }}>
                                    Revisar como SASS
                                  </ColorButton>
                                </Link>
                              )}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {`${row?.newFormStoredAt.toDate().toLocaleDateString('es-CL')} a las ${row?.newFormStoredAt.toDate().toLocaleTimeString('es-CL', { hour: '2-digit', minute: '2-digit' })}`}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {
                              person?.userName
                            }
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Table aria-label="simple table">
                              <TableBody>
                                {list.length > 0 && list.map((_row: any) => (
                                  <TableRow
                                    key={_row?.newFormIdentifier}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                    <TableCell component="th" scope="row">
                                      {
                                        _row
                                      }
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableCell>
                          <TableCell
                            sx={{
                              color:
                                row?.paramedicApprovedAllWorkers.toString() === 'true'
                                  ? 'green'
                                  : 'red',
                            }}
                            // align="right"
                            component="th"
                            scope="row"
                          >
                            {row?.paramedicApprovedAllWorkers.toString() === 'true'
                              ? 'Aprobado' : 'No aprobado'}
                          </TableCell>
                          <TableCell>
                            {row?.peopleApprovedAllPages.toString() === 'true'
                              ? 'Aprobado' : 'No aprobado'}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              )
              : null}

            {user && (user?.uid !== isAdmin1) && (user?.uid !== isAdmin2)
              ? (
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Identificador del permiso de trabajo
                      </TableCell>
                      <TableCell>
                        Fecha Creacion
                      </TableCell>
                      <TableCell align="center">
                        Creado Por
                      </TableCell>
                      <TableCell align="center">
                        Asignado A
                      </TableCell>
                      <TableCell align="center">
                        Aprobado por paramédico
                      </TableCell>
                      <TableCell align="center">
                        Aprobado por asignados
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {nonadminTablerows.length > 0 && nonadminTablerows.map((row: any) => {
                      const person = allUsers.find((a) => a.email === row.newFormPersonEmailWhoDid)
                      const list = ['']
                      list.pop()
                      allUsers.find((a) => {
                        if (a.userKey === row?.p16workSupervisor
                          || a.userKey === row?.p16workSupervisor
                          || a.userKey === row?.p16pepCoordinator
                          || a.userKey === row?.p16areaCoordinator
                          || a.userKey === row?.p16quality
                          || a.userKey === row?.p16technicalArea
                          || a.userKey === row?.p16patrimonialSecurity
                          || a.userKey === row?.p16externalPrevencionist
                          || a.userKey === row?.p16sassAllowedList
                          || a.userKey === row?.p16sass
                          || row?.p16sassAllowedList.includes(a.userKey)) {
                          list.push(a.userName.toString())
                        }
                        return false
                      })
                      console.log('row: ', list, row, row?.p16workSupervisor, row?.p16pepCoordinator, row?.p16areaCoordinator, row?.p16quality, row?.p16technicalArea, row?.p16patrimonialSecurity, row?.p16externalPrevencionist, row?.p16sassAllowedList, row?.p16sass)
                      return (
                        <TableRow
                          key={row?.newFormIdentifier}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {`${row?.newFormIdentifier}`}
                            {(row?.p16workSupervisor === user?.uid)
                              && (
                                <Link
                                  to={`/revision/tsu/1/${row?.newFormIdentifier}`}
                                  style={{ color: 'white', textDecoration: 'none' }}
                                >
                                  <ColorButton sx={{ ml: 1, mt: 1 }}>
                                    Revisar como supervisor del trabajo
                                  </ColorButton>
                                </Link>
                              )}
                            {(row?.p16pepCoordinator === user?.uid)
                              && (
                                <Link
                                  to={`/revision/pep/1/${row?.newFormIdentifier}`}
                                  style={{ color: 'white', textDecoration: 'none' }}
                                >
                                  <ColorButton sx={{ ml: 1, mt: 1 }}>
                                    Revisar como coordinador Pepsico
                                  </ColorButton>
                                </Link>
                              )}
                            {(row?.p16areaCoordinator === user?.uid)
                              && (
                                <Link
                                  to={`/revision/aco/1/${row?.newFormIdentifier}`}
                                  style={{ color: 'white', textDecoration: 'none' }}
                                >
                                  <ColorButton sx={{ ml: 1, mt: 1 }}>
                                    Revisar como coordinador de área
                                  </ColorButton>
                                </Link>
                              )}
                            {(row?.p16quality === user?.uid)
                              && (
                                <Link
                                  to={`/revision/qt/1/${row?.newFormIdentifier}`}
                                  style={{ color: 'white', textDecoration: 'none' }}
                                >
                                  <ColorButton sx={{ ml: 1, mt: 1 }}>
                                    Revisar como calidad
                                  </ColorButton>
                                </Link>
                              )}
                            {(row?.p16technicalArea === user?.uid)
                              && (
                                <Link
                                  to={`/revision/ta/1/${row?.newFormIdentifier}`}
                                  style={{ color: 'white', textDecoration: 'none' }}
                                >
                                  <ColorButton sx={{ ml: 1, mt: 1 }}>
                                    Revisar como área técnica
                                  </ColorButton>
                                </Link>
                              )}
                            {(row?.p16patrimonialSecurity === user?.uid)
                              && (
                                <Link
                                  to={`/revision/ps/1/${row?.newFormIdentifier}`}
                                  style={{ color: 'white', textDecoration: 'none' }}
                                >
                                  <ColorButton sx={{ ml: 1, mt: 1 }}>
                                    Revisar como seguridad patrimonial
                                  </ColorButton>
                                </Link>
                              )}
                            {(row?.p16externalPrevencionist === user?.uid)
                              && (
                                <Link
                                  to={`/revision/ep/1/${row?.newFormIdentifier}`}
                                  style={{ color: 'white', textDecoration: 'none' }}
                                >
                                  <ColorButton sx={{ ml: 1, mt: 1 }}>
                                    Revisar como prevencionista externo
                                  </ColorButton>
                                </Link>
                              )}
                            {(
                              (row?.p16sassAllowedList.includes(user?.uid))
                              || (p16sassAllowedListMapped.includes(user?.uid))
                            )
                              && (
                                <Link
                                  to={`/revision/sass/1/${row?.newFormIdentifier}`}
                                  style={{ color: 'white', textDecoration: 'none' }}
                                >
                                  <ColorButton sx={{ ml: 1, mt: 1 }}>
                                    Revisar como SASS
                                  </ColorButton>
                                </Link>
                              )}

                          </TableCell>
                          <TableCell component="th" scope="row">
                            {`${row?.newFormStoredAt.toDate().toLocaleDateString('es-CL')} a las ${row?.newFormStoredAt.toDate().toLocaleTimeString('es-CL', { hour: '2-digit', minute: '2-digit' })}`}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {
                              person?.userName
                            }
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Table aria-label="simple table">
                              <TableBody>
                                {list.length > 0 && list.map((_row: any) => (
                                  <TableRow
                                    key={_row?.newFormIdentifier}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                    <TableCell component="th" scope="row">
                                      {
                                        _row
                                      }
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableCell>
                          <TableCell
                            sx={{
                              color:
                                row?.paramedicApprovedAllWorkers.toString() === 'true'
                                  ? 'green'
                                  : 'red',
                            }}
                            // align="right"
                            component="th"
                            scope="row"
                          >
                            {row?.paramedicApprovedAllWorkers.toString() === 'true'
                              ? 'Aprobado' : 'No aprobado'}
                          </TableCell>
                          <TableCell>
                            {row?.peopleApprovedAllPages.toString() === 'true'
                              ? 'Aprobado' : 'No aprobado'}
                          </TableCell>

                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              )
              : (
                <h2>
                  No tiene acceso a esta página
                </h2>
              )}

          </TableContainer>

        </>
      )}

    </DashboardLayout>
  )
}

export default Created