import styled from '@emotion/styled'
import CssBaseline from '@mui/material/CssBaseline'
import { useEffect, useRef, useState } from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import WAVES from 'vanta/dist/vanta.waves.min'
import { Children } from '../../types'

const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`

const AuthLayout = ({ children }: Children) => {
  const [vantaEffect, setVantaEffect] = useState(0)
  const myRef = useRef(null)
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(WAVES({
        el: myRef.current,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.00,
        minWidth: 200.00,
        scale: 1.00,
        scaleMobile: 1.00,
        color: 0xb3b3b3,
        shininess: 150.00,
        waveHeight: 26.00,
        waveSpeed: 0.15,
        zoom: 1.23,
      }))
    }
    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (vantaEffect) vantaEffect.destroy()
    }
  }, [vantaEffect])

  return (
    <div ref={myRef} style={{ height: '100vh' }}>
      <CssBaseline />
      <Root>
        {children}
      </Root>
    </div>
  )
}

export default AuthLayout