import Typography from '@mui/material/Typography'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import useNewFormId from '../../hooks/useNewFormId'
import DashboardLayout from '../layouts/DashboardLayout'

const NewFormId = () => {
  console.log('NewFormId')

  const { newFormId, setFormIdActivator } = useNewFormId()

  console.log('newFormId : ', newFormId)

  useEffect(() => {
    setFormIdActivator(true)
  }, [])

  const history = useHistory()
  useEffect(() => {
    if (newFormId) {
      setTimeout(() => {
        history.push(`/create/1/${newFormId}`)
      }, 1000)
    }
  }, [newFormId])

  return (
    <DashboardLayout>
      <Grid container justifyContent="center">
        <Typography
          sx={{ mt: 4, mb: 4 }}
          textAlign="center"
          variant="body1"
        >
          Creando identificador de permiso de trabajo
        </Typography>
      </Grid>
      <Grid container justifyContent="center">
        <Typography
          variant="body1"
          textAlign="center"
        >
          En unos momentos será redireccionado.
        </Typography>
      </Grid>
    </DashboardLayout>
  )
}

export default NewFormId