const externPrevencionistList = [
  {
    name: '',
    email: '',
    uid: '',
  },
  {
    name: 'Karla Castro',
    email: 'karla.castro1@cbre.com',
    uid: 't93UVspI1deShGSKr7yNtcga8KH3',
  },
  {
    uid: 'gNgUYiKvm5a53JYc7KmbaSiJGog1',
    email: 'caldyquem.prevencion@gmail.com',
    name: 'Paulina San Cristobal',
  },
  {
    uid: 'jc5osEh7S0WHUWq3sQoLpdcr0Hg1',
    email: 'carballocris20@gmail.com',
    name: 'Cristopher Carballo',
  },
  {
    name: 'Pedro Sepulveda',
    email: 'caldyquem@gmail.com',
    uid: 'hbzu14qVKKXaRnNjlP08NSGqOrw1',
  },
  {
    uid: 'oIyHDxu6VGVv68tEiZCsgXMsuFH2',
    email: 'danae.arriagada@cbre.cl',
    name: 'Danae Arriagada',
  },
  {
    uid: 'cREGc3CMWBWSBuV8oNUDrsAMcau2',
    email: 'olavedarling@gmail.com',
    name: 'Darling Olave',
  },
  {
    uid: 'rvgU8mbSSHSHrNfFdWXxJhERfxg2',
    email: 'ottonesegovia.pr@gmail.com',
    name: 'Pietro Ottone',
  },
  {
    uid: 'n21j2wQOlHNwSBdxFhV4Typv1H42',
    email: 'priscilla.alvarado.prevencion@gmail.com',
    name: 'Priscilla Alvarado',
  },
  {
    uid: 'pVfkFSfzMfg4578FkP2Fu84auus1',
    email: 'rlopeza@ecoriles.cl',
    name: 'Rodrigo Lopez Antillanca',
  },
  {
    uid: 'NTj7uVdlzsZsK7QBQP49ShdCeKP2',
    email: 'sebastian.mondaca@cbre.com',
    name: 'Sebastian Mondaca',
  },
  {
    uid: 'FUcz5KxoughygQ3bqYkNgUJadN02',
    email: 'tcayupi@royalamerica.cl',
    name: 'Teresa Cayupi',
  },
  {
    uid: 'CcVRrJRHWMePNsPiwyE3jX3icuv1',
    email: 'prevencionista@climacor.cl',
    name: 'Jorge Fuica Contreras',
  },
  {
    uid: '2nQFyKc5cxSzQrBklxb5KANYuAs2',
    email: 'gvictoriano@tekchile.cl',
    name: 'Gonzalo Victoriano',
  },
  {
    uid: 'BS47pIJ4HVdKAAQRYdAFbFZu2wJ3',
    email: 'jondarza@rentokil.cl',
    name: 'Julio Ondarza',
  },
  {
    uid: 'RoUExF5FnAhPdaVv2PVtcZ8WKq93',
    email: 'marisol.salvatierra@innovacontrol.cl',
    name: 'Marisol Salvatierra',
  },
  {
    uid: 'f8C1GZVplshJC4dClZlrDdBik8K2',
    email: 'lobato.mariluzc21@gmail.com',
    name: 'Mariluz Lobato',
  },
  {
    name: 'Susana Valencia',
    email: 'valencia-susana@aramark.cl',
    uid: 'oSQMDARqcEcKnJbzAPNlhmZKpTI2',
  },
  {
    name: 'Marisela Jimenez',
    email: 'marisela.jimenez@tecsam.cl',
    uid: '0t9yMPQNJHTJoZxZ43x9kJdGDNW2',
  },
  {
    name: 'Almendra Riquelme',
    email: 'prevencionista@inox-man.cl',
    uid: 'JETQcQi6YZR2NFBn2IpW9gNWIvl1',
  },
  {
    name: 'Camilo Huerta',
    email: 'camilo.germanhuerta@gmail.com',
    uid: 'AibPraDzo3Va0pwMUTnLHSXEbgm2',
  },
  {
    name: 'Miguel Zuñiga',
    email: 'mzuniga@genesismi.cl',
    uid: 'LGYmEbSUwARzhISsJfNB7VG7fie2',
  },
  {
    name: 'Rodrigo Contreras',
    email: 'contrerasrodrigo20@gmail.com',
    uid: 'HRGRm3J6zMgI7jPdsYNTMg52wzE2',
  },
  {
    name: 'Francisca Reyes',
    email: 'freyes@ingemess.cl',
    uid: 'WKILmbuQmHXQ499NwMetqBYMbvU2',
  },
  {
    name: 'Estefania Klausen',
    email: 'eklausen@ingemess.cl',
    uid: 'zhTC2g78IngkyKGtB7ATTEbuGOj1',
  },
  {
    name: 'Karen Saldias',
    email: 'prevencionista@mingatec.com',
    uid: 'KlzbY85J75fGdgnQTjMHRLrNTGh1',
  },
  {
    name: 'Camila Palacios',
    email: 'cpalacios@climacor.cl',
    uid: 'Lw9GdlJC6mYopUECxUXTm3rBihj2',
  },
  {
    uid: 'RcSiBidyKcRCxRTyZBreFBn0MtD2',
    email: 'vinko.simunovic@innovacontrol.cl',
    name: 'Vinko Simunovic',
  },
]
export default externPrevencionistList