import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Slide from '@mui/material/Slide'
// import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import Button from '@mui/material/Button'
import { toast } from 'react-toastify'
import { styled } from '@mui/material/styles'
import YellowSwitch from '../../common/YellowSwitch'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { entireStore } from '../../app/store'
import { allFormActions } from '../../features/form/formSlice'
import DashboardLayout from '../layouts/DashboardLayout'
import ColorButton from './ColorButton'
import useNewFormId from '../../hooks/useNewFormId'
import useStorage from '../../hooks/useStorage'
import { db, doc, updateDoc } from '../../config/firebaseConfig'

const CreateStep11 = () => {
  console.log('CreateStep11')

  const imageTypes = ['image/png', 'image/jpeg', 'image/jpg']

  const [file, setFile] = React.useState<File | null>(null)
  const [fileID, setFileID] = React.useState<String | null>(null)
  const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(false)

  const { newFormId } = useNewFormId()

  const { url, progress, error } = useStorage(file)

  const customIdFileUploadFinish = 'custom-id-file-upload-finish'
  React.useEffect(() => {
    if (progress > 1) {
      setButtonDisabled(true)
    }
    if (progress === 100) {
      setButtonDisabled(false)
      toast.success('La imagen ha sido subida correctamente', {
        toastId: customIdFileUploadFinish,
      })
      console.log('fileid', fileID)

      localStorage.setItem(`${fileID}`, url)
      console.log(url)
    }
    if (error) {
      // error es any
      setButtonDisabled(false)
      toast.error(error)
    }
  }, [progress, url, file])

  const {
    p11show,
    p11mask,
    p11cabritillaGlove,
    p11breather,
    p11manualLoadMax25,
    p11useoFSignal,
    p11areaDelimitation,
    p11electricalExtensionCheck,
    p11toolCheck,
    p11lightningToWork,
    p11clearArea,
    p11useOfGrua,
  } = useAppSelector(entireStore)
  const {
    setp11show,
    setp11mask,
    setp11cabritillaGlove,
    setp11breather,
    setp11manualLoadMax25,
    setp11useoFSignal,
    setp11areaDelimitation,
    setp11electricalExtensionCheck,
    setp11toolCheck,
    setp11lightningToWork,
    setp11clearArea,
    setp11useOfGrua,
  } = allFormActions
  const dispatch = useAppDispatch()

  const history = useHistory()

  const goToCreateStep10 = () => {
    history.push(`/create/10/${newFormId}`)
  }

  const goToCreateStep12 = async () => {
    // history.push(`/create/12/${newFormId}`)
    const formRef = doc(db, 'forms', newFormId as string)
    try {
      await updateDoc(formRef, {
        newFormp11FilledOut: true,
        newFormp11FilledOutDate: new Date(),
        p11show,
        p11mask,
        p11cabritillaGlove,
        p11breather,
        p11manualLoadMax25,
        p11useoFSignal,
        p11areaDelimitation,
        p11electricalExtensionCheck,
        p11toolCheck,
        p11lightningToWork,
        p11clearArea,
        p11useOfGrua,
      })
      toast.success('El permiso de trabajo se actualizó correctamente')
      history.push(`/create/12/${newFormId}`)
    } catch (updateError) {
      toast.error(`Error: ${updateError}`)
    }
  }

  const Input = styled('input')({
    display: 'none',
  })

  return (
    <DashboardLayout>
      <Slide direction="right" in mountOnEnter unmountOnExit>
        <Grid sx={{ mt: 4 }} container justifyContent="center" spacing={2}>
          <Grid item xs={10} sm={9} md={5} lg={7}>
            <Typography
              sx={{ mb: 2 }}
              align="center"
              variant="h6"
              color="text.secondary"
              gutterBottom
            >
              MANTENIMIENTO Y SS.GG.
            </Typography>

            <Grid display="flex" justifyContent="center" item xs={12}>
              <FormControlLabel
                control={(
                  <YellowSwitch
                    checked={p11show}
                    onChange={(event) => dispatch(setp11show(event.target.checked))}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                )}
                label={p11show ? 'Esta página aplica' : 'Esta página no aplica'}
              />
            </Grid>

            <Box
              component="div"
              sx={{
                display: p11show ? 'block' : 'none',
              }}
            >
              <Box sx={{
                display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
              }}
              >
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">Mascarilla</FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="atrapamiento"
                    name="atrapamiento"
                    value={p11mask}
                    onChange={(event) => dispatch(setp11mask(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">Guantes de Cabritilla</FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="aplastamiento"
                    name="aplastamiento"
                    value={p11cabritillaGlove}
                    onChange={(event) => dispatch(setp11cabritillaGlove(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">Respiradores</FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="golpeadorpor"
                    name="golpeadorpor"
                    value={p11breather}
                    onChange={(event) => dispatch(setp11breather(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">Carga manual Max. 25 Kg.</FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="golpeadocon"
                    name="golpeadocon"
                    value={p11manualLoadMax25}
                    onChange={(event) => dispatch(setp11manualLoadMax25(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">
                    Utilización de señalética especial
                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="caida"
                    name="caida"
                    value={p11useoFSignal}
                    onChange={(event) => dispatch(setp11useoFSignal(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">
                    Delimitación del área
                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="contactoconobjetoscortantes"
                    name="contactoconobjetoscortantes"
                    value={p11areaDelimitation}
                    onChange={(event) => dispatch(setp11areaDelimitation(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">
                    Extensiones eléctricas chequeadas
                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="contactoconobjetoscalientes"
                    name="contactoconobjetoscalientes"
                    value={p11electricalExtensionCheck}
                    onChange={
                      (event) => dispatch(setp11electricalExtensionCheck(event.target.value))
                    }
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>

                { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(e) => {
                      if (e.target.files) {
                        const selectedFile = e?.target?.files[0]
                        console.table(selectedFile)
                        if (selectedFile && imageTypes.includes(selectedFile.type)) {
                          setFile(selectedFile)
                        } else {
                          toast.error('El archivo no es una imagen, por favor intente nuevamente')
                        }
                      }
                    }}
                  />
                  <Button
                    sx={{ bgcolor: '#fbc02d', mb: 2 }}
                    variant="contained"
                    component="span"
                    disabled={buttonDisabled}
                    onClick={() => setFileID('p11electricalExtensionCheckImage')}
                  >
                    {buttonDisabled
                      ? 'Subiendo imagen'
                      : 'Subir imagen'}
                  </Button>
                </label>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">
                    Revisión de herramientas
                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="proyecciondeparticulas"
                    name="proyecciondeparticulas"
                    value={p11toolCheck}
                    onChange={(event) => dispatch(setp11toolCheck(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">
                    Iluminación adecuada para el trabajo
                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="proyecciondeparticulas"
                    name="proyecciondeparticulas"
                    value={p11lightningToWork}
                    onChange={(event) => dispatch(setp11lightningToWork(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">
                    Área despejada
                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="proyecciondeparticulas"
                    name="proyecciondeparticulas"
                    value={p11clearArea}
                    onChange={(event) => dispatch(setp11clearArea(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 10 }} component="fieldset">
                  <FormLabel component="legend">
                    Utilización de grúas
                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="proyecciondeparticulas"
                    name="proyecciondeparticulas"
                    value={p11useOfGrua}
                    onChange={(event) => dispatch(setp11useOfGrua(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
            <Grid sx={{ mb: 2 }} container justifyContent="space-between">
              <Grid
                xs={12}
                md={5}
                sx={{ mb: 2 }}
              >
                <ColorButton
                  fullWidth
                  startIcon={<ArrowLeftIcon />}
                  onClick={goToCreateStep10}
                >
                  Ir a la página anterior
                </ColorButton>
              </Grid>
              <Grid
                xs={12}
                md={5}
              >
                <ColorButton
                  fullWidth
                  endIcon={<ArrowRightIcon />}
                  onClick={goToCreateStep12}
                >
                  Ir a la página siguiente
                </ColorButton>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </DashboardLayout>
  )
}

export default CreateStep11