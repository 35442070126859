import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Slide from '@mui/material/Slide'
import { useHistory, useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import CheckIcon from '@mui/icons-material/Check'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { toast } from 'react-toastify'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import DashboardLayout from '../layouts/DashboardLayout'
import { UrlParams } from '../../types'
import getFirestoreDocById from '../../common/getFirestoreDocById'
import ColorButton from '../../common/ColorButton'
import useAuth from '../../hooks/useAuth'
import { doc, updateDoc, db } from '../../config/firebaseConfig'
import color1ConsoleLog from '../../common/color1ConsoleLog'
import FullPageLoader from '../../common/FullPageLoader'

const ReviseStep2 = () => {
  console.log('ReviseStep2')

  const { user } = useAuth()
  console.log('%cuser : %s', color1ConsoleLog, user?.uid)

  const { id, area } = useParams<UrlParams>()
  console.log('%cThe id is : %s', color1ConsoleLog, id)
  console.log('%cThe area is : %s', color1ConsoleLog, area)

  const [formData, setFormData]: any = useState()
  const [loading, setLoading] = useState(true)
  // eslint-disable-next-line
  const [isAllowed, setIsAllowed] = useState(false)
  const [key, setKey] = useState('')
  // eslint-disable-next-line
  const [areaUrlParam, setAreaUrlParam] = useState('')
  const [approvedAt, setApprovedAt] = useState('')

  useEffect(() => {
    (async () => {
      if (id) {
        const res = await getFirestoreDocById(id)
        if (res) {
          setFormData(res)
          if (area === 'tsu') {
            console.log('res?.p16workSupervisor', res?.p16workSupervisor)
            console.log('%cp16workSupervisor has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16workSupervisorApproved2')
            setAreaUrlParam('tsu')
            setApprovedAt('p16workSupervisorApprovedAt2')
          }
          if (area === 'pep') {
            console.log('%cp16pepCoordinator has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16pepCoordinatorApproved2')
            setAreaUrlParam('pep')
            setApprovedAt('p16pepCoordinatorApprovedAt2')
          }
          if (area === 'aco') {
            console.log('%cp16areaCoordinator has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16areaCoordinatorApproved2')
            setAreaUrlParam('aco')
            setApprovedAt('p16areaCoordinatorApprovedAt2')
          }
          if (area === 'qt') {
            console.log('%cp16quality has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16qualityApproved2')
            setAreaUrlParam('qt')
            setApprovedAt('p16qualityApprovedAt2')
          }
          if (area === 'ta') {
            console.log('%cp16technicalArea has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16technicalAreaApproved2')
            setAreaUrlParam('ta')
            setApprovedAt('p16technicalAreaApprovedAt2')
          }
          if (area === 'ps') {
            console.log('%cp16patrimonialSecurity has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16patrimonialSecurityApproved2')
            setAreaUrlParam('ps')
            setApprovedAt('p16patrimonialSecurityApprovedAt2')
          }
          if (area === 'ep') {
            console.log('%cp16externalPrevencionist has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16externalPrevencionistApproved2')
            setAreaUrlParam('ep')
            setApprovedAt('p16externalPrevencionistApprovedAt2')
          }
          if (area === 'sass') {
            console.log('%cp16sass has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16sassApproved2')
            setAreaUrlParam('sass')
            setApprovedAt('p16sassApprovedAt2')
          }
          setLoading(false)
        } else {
          setLoading(false)
        }
      }
    })()
  }, [])

  if (loading) {
    <Typography>Cargando</Typography>
  }

  console.log('formData step 2', formData)

  console.log('formData list : ', formData?.p16sassAllowedList)

  const history = useHistory()

  const goToReviseStep1 = () => {
    history.push(`/revision/${area}/1/${id}`)
  }
  const goToReviseStep3 = () => {
    history.push(`/revision/${area}/3/${id}`)
  }

  const approve = async (e: any) => {
    e.preventDefault()
    const approveformRef = doc(db, 'forms', id)
    const obj: any = {}
    obj[key] = true
    obj[approvedAt] = new Date()
    // await updateDoc(approveformRef, obj)
    // console.log('approve', key, approvedAt)
    // console.log('approved')
    try {
      await updateDoc(approveformRef, obj)
      console.log('approved', key, approvedAt)
      toast.success('La aprobación se actualizó correctamente')
    } catch (updateError) {
      toast.error(`Error: ${updateError}`)
    }
  }

  const disapprove = async (e: any) => {
    e.preventDefault()
    const approveformRef = doc(db, 'forms', id)
    const obj: any = {}
    obj[key] = false
    obj[approvedAt] = new Date()
    // await updateDoc(approveformRef, obj)
    // console.log('disapprove', key, approvedAt)
    // console.log('disapproved')
    try {
      await updateDoc(approveformRef, obj)
      console.log('disapproved', key, approvedAt)
      toast.success('La desaprobación se actualizó correctamente')
    } catch (updateError) {
      toast.error(`Error: ${updateError}`)
    }
  }

  return (
    <DashboardLayout>
      {loading ? <FullPageLoader /> : (
        <Slide direction="right" in mountOnEnter unmountOnExit>
          <Grid sx={{ mt: 4 }} container justifyContent="center" spacing={2}>
            <Grid item xs={10} sm={9} md={5} lg={7}>
              {(formData?.p16workSupervisor === user?.uid)
                || (formData?.p16pepCoordinator === user?.uid)
                || (formData?.p16areaCoordinator === user?.uid)
                || (formData?.p16quality === user?.uid)
                || (formData?.p16technicalArea === user?.uid)
                || (formData?.p16patrimonialSecurity === user?.uid)
                || (formData?.p16externalPrevencionist === user?.uid)
                || (formData?.p16sass === user?.uid)
                || (formData?.p16sassAllowedList?.includes(user?.uid))
                ? (
                  <>
                    <Typography
                      sx={{ mb: 2 }}
                      align="center"
                      variant="h6"
                      color="text.secondary"
                      gutterBottom
                    >
                      ANTECEDENTES
                    </Typography>
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Nombre de la Empresa"
                      variant="outlined"
                      value={formData?.p2companyName}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Nombre Supervisor Contratista"
                      variant="outlined"
                      value={formData?.p2contratSupervisorName}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Teléfono Supervisor Contratista"
                      variant="outlined"
                      value={formData?.p2contratSupervisorPhone}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Nombre ERP"
                      variant="outlined"
                      value={formData?.p2erpName}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Teléfono ERP"
                      variant="outlined"
                      value={formData?.p2erpPhone}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Rut"
                      variant="outlined"
                      value={formData?.p2rut}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Mutualidad"
                      variant="outlined"
                      value={formData?.p2mutualidad}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Área que solicita el trabajo"
                      variant="outlined"
                      value={formData?.p2requestingArea}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Coordinador de trabajo Pepsico"
                      variant="outlined"
                      value={formData?.p2pepWorkCoordinator}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Área de trabajo específica"
                      variant="outlined"
                      value={formData?.p2specificWorkArea}
                      InputLabelProps={{ shrink: true }}
                    />

                    {(formData?.p2specificWorkAreaImage?.length > 1 && formData?.p2specificWorkAreaImage !== 'none')
                      && (
                        <Card sx={{ maxWidth: 345, mt: 2, mb: 4 }}>
                          <CardMedia
                            component="img"
                            height="140"
                            image={
                              formData?.p2specificWorkAreaImage
                              && formData?.p2specificWorkAreaImage
                            }
                            alt="area de trabajo especifica"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="subtitle1" component="div">
                              Área de trabajo específica
                            </Typography>
                          </CardContent>
                        </Card>
                      )}

                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Fecha"
                      variant="outlined"
                      value={formData?.p2date && formData?.p2date}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Hora inicial"
                      variant="outlined"
                      value={`${formData?.p2startTime.toDate().getHours()}:${formData?.p2startTime && formData?.p2startTime.toDate().getMinutes() < 10 ? '0' : ''}${formData?.p2startTime && formData?.p2startTime.toDate().getMinutes()}`}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Hora final"
                      variant="outlined"
                      value={`${formData?.p2finishTime.toDate().getHours()}:${formData?.p2finishTime && formData?.p2finishTime.toDate().getMinutes() < 10 ? '0' : ''}${formData?.p2finishTime && formData?.p2finishTime.toDate().getMinutes()}`}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      disabled
                      sx={{ mb: 4 }}
                      fullWidth
                      label="Extension"
                      variant="outlined"
                      value={formData?.p2extensionShow ? formData?.p2extensionShow : 'La extensión no aplica en esta página'}
                      InputLabelProps={{ shrink: true }}
                    />

                    <Grid
                      display="flex"
                      justifyContent="space-evenly"
                      sx={{ mt: 4 }}
                    >
                      <Button
                        startIcon={<HighlightOffIcon />}
                        sx={{ mb: 10, bgcolor: '#db1414' }}
                        color="error"
                        variant="contained"
                        onClick={(e) => disapprove(e)}
                      >
                        Desaprobar
                      </Button>
                      <Button
                        startIcon={<CheckIcon />}
                        sx={{ mb: 10, bgcolor: '#42b50e' }}
                        color="success"
                        variant="contained"
                        onClick={(e) => approve(e)}
                      >
                        Aprobar
                      </Button>
                    </Grid>

                    <Grid sx={{ mb: 2 }} container justifyContent="space-between">
                      <Grid
                        item
                        xs={12}
                        md={5}
                        sx={{ mb: 2 }}
                      >
                        <ColorButton
                          fullWidth
                          startIcon={<ArrowLeftIcon />}
                          onClick={goToReviseStep1}
                        >
                          Ir a la página anterior
                        </ColorButton>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={5}
                      >
                        <ColorButton
                          fullWidth
                          endIcon={<ArrowRightIcon />}
                          onClick={goToReviseStep3}
                        >
                          Ir a la página siguiente
                        </ColorButton>
                      </Grid>

                    </Grid>
                  </>
                )
                : 'Por favor inicie sesión para visualizar su documento a revisar'}

              {user ? (
                <Typography color="green">Usted ha iniciado sesión.</Typography>
              )
                : (
                  <Typography color="red" sx={{ mt: 2 }}>Usted no ha iniciado sesión</Typography>
                )}

            </Grid>
          </Grid>
        </Slide>
      )}

    </DashboardLayout>
  )
}

export default ReviseStep2