import {
  styled, createTheme, ThemeProvider,
} from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { Link, useHistory } from 'react-router-dom'
import Button, { ButtonProps } from '@mui/material/Button'
import LogoutIcon from '@mui/icons-material/Logout'
import { yellow } from '@mui/material/colors'
import { Children } from '../../types'
import { auth, signOut } from '../../config/firebaseConfig'
import useAuth from '../../hooks/useAuth'

// eslint-disable-next-line
const SignoutButton = styled(Button)<ButtonProps>(({ theme }) => ({
  // padding: '4px',
  outline: '1px solid white',
  color: 'white',
  backgroundColor: '#fbc02d',
  '&:hover': {
    backgroundColor: yellow[700],
  },
}))

// eslint-disable-next-line
const SigninButton = styled(Button)<ButtonProps>(({ theme }) => ({
  // padding: '4px',
  outline: '1px solid white',
  color: 'white',
  backgroundColor: '#fbc02d',
  '&:hover': {
    backgroundColor: yellow[700],
  },
}))

const drawerWidth: number = 240

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const mdTheme = createTheme()

const DashboardLayout = ({ children }: Children) => {
  console.log('DashboardLayout')

  const { user } = useAuth()

  const history = useHistory()
  const signout = () => {
    signOut(auth).then(() => {
      // console.log('signout correct')
      history.push('/')
    }).catch((error) => {
      console.log('signout error:', error)
    })
  }
  return (
    <ThemeProvider
      theme={mdTheme}
    >
      <Box sx={{ display: 'flex' }}>
        <AppBar color="primary" position="absolute">
          <Toolbar
            sx={{
              backgroundColor: '#fbc02d',
              pr: '24px',
            }}
          >

            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              <Link
                to="/start"
                style={{ color: 'white', textDecoration: 'none' }}
              >
                HSEQTechnology
              </Link>
              {' '}

            </Typography>

            {user
              ? (
                <SignoutButton onClick={signout} startIcon={<LogoutIcon />}>
                  Cerrar sesión
                </SignoutButton>
              )
              : (
                <SigninButton onClick={signout} startIcon={<LogoutIcon />}>
                  Iniciar sesión
                </SigninButton>
              )}

          </Toolbar>
        </AppBar>
        <Box
          component="main"
          sx={{
            // backgroundColor: (theme) => (theme.palette.mode === 'light'
            //   ? theme.palette.grey[100]
            //   : theme.palette.grey[900]),
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          {/*  Esto es el contenido de la página */}
          {children}
        </Box>

      </Box>
    </ThemeProvider>
  )
}

export default DashboardLayout