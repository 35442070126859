import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import CheckIcon from '@mui/icons-material/Check'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Slide from '@mui/material/Slide'
import { useParams, useHistory } from 'react-router-dom'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { toast } from 'react-toastify'
import { UrlParams } from '../../types'
import DashboardLayout from '../layouts/DashboardLayout'
import ColorButton from '../../common/ColorButton'
import { doc, updateDoc, db } from '../../config/firebaseConfig'
import useAuth from '../../hooks/useAuth'
import getFirestoreDocById from '../../common/getFirestoreDocById'
import color1ConsoleLog from '../../common/color1ConsoleLog'
import FullPageLoader from '../../common/FullPageLoader'

const ReviseStep3 = () => {
  console.log('ReviseStep3')

  const { user } = useAuth()
  console.log('%cuser : %s', color1ConsoleLog, user?.uid)

  const { id, area } = useParams<UrlParams>()
  console.log('%cThe id is : %s', color1ConsoleLog, id)
  console.log('%cThe area is : %s', color1ConsoleLog, area)

  const [formData, setFormData]: any = useState()
  const [loading, setLoading] = useState(true)
  // eslint-disable-next-line
  const [isAllowed, setIsAllowed] = useState(false)
  const [key, setKey] = useState('')
  // eslint-disable-next-line
  const [areaUrlParam, setAreaUrlParam] = useState('')
  const [approvedAt, setApprovedAt] = useState('')

  useEffect(() => {
    (async () => {
      if (id) {
        const res = await getFirestoreDocById(id)
        if (res) {
          setFormData(res)
          if (area === 'tsu') {
            console.log('res?.p16workSupervisor', res?.p16workSupervisor)
            console.log('%cp16workSupervisor has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16workSupervisorApproved3')
            setAreaUrlParam('tsu')
            setApprovedAt('p16workSupervisorApprovedAt3')
          }
          if (area === 'pep') {
            console.log('%cp16pepCoordinator has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16pepCoordinatorApproved3')
            setAreaUrlParam('pep')
            setApprovedAt('p16pepCoordinatorApprovedAt3')
          }
          if (area === 'aco') {
            console.log('%cp16areaCoordinator has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16areaCoordinatorApproved3')
            setAreaUrlParam('aco')
            setApprovedAt('p16areaCoordinatorApprovedAt3')
          }
          if (area === 'qt') {
            console.log('%cp16quality has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16qualityApproved3')
            setAreaUrlParam('qt')
            setApprovedAt('p16qualityApprovedAt3')
          }
          if (area === 'ta') {
            console.log('%cp16technicalArea has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16technicalAreaApproved3')
            setAreaUrlParam('ta')
            setApprovedAt('p16technicalAreaApprovedAt3')
          }
          if (area === 'ps') {
            console.log('%cp16patrimonialSecurity has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16patrimonialSecurityApproved3')
            setAreaUrlParam('ps')
            setApprovedAt('p16patrimonialSecurityApprovedAt3')
          }
          if (area === 'ep') {
            console.log('%cp16externalPrevencionist has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16externalPrevencionistApproved3')
            setAreaUrlParam('ep')
            setApprovedAt('p16externalPrevencionistApprovedAt3')
          }
          if (area === 'sass') {
            console.log('%cp16sass has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16sassApproved3')
            setAreaUrlParam('sass')
            setApprovedAt('p16sassApprovedAt3')
          }
          setLoading(false)
        } else {
          setLoading(false)
        }
      }
    })()
  }, [])

  if (loading) {
    <Typography>Cargando</Typography>
  }

  console.log('formData step 3', formData)

  const history = useHistory()

  const goToReviseStep2 = () => {
    history.push(`/revision/${area}/2/${id}`)
  }
  const goToReviseStep4 = () => {
    history.push(`/revision/${area}/4/${id}`)
  }

  const approve = async (e: any) => {
    e.preventDefault()
    const approveformRef = doc(db, 'forms', id)
    const obj: any = {}
    obj[key] = true
    obj[approvedAt] = new Date()
    try {
      await updateDoc(approveformRef, obj)
      console.log('approved', key, approvedAt)
      toast.success('La aprobación se actualizó correctamente')
    } catch (updateError) {
      toast.error(`Error: ${updateError}`)
    }
  }

  const disapprove = async (e: any) => {
    e.preventDefault()
    const approveformRef = doc(db, 'forms', id)
    const obj: any = {}
    obj[key] = false
    obj[approvedAt] = new Date()
    try {
      await updateDoc(approveformRef, obj)
      console.log('disapproved', key, approvedAt)
      toast.success('La desaprobación se actualizó correctamente')
    } catch (updateError) {
      toast.error(`Error: ${updateError}`)
    }
  }

  return (
    <DashboardLayout>
      {loading ? <FullPageLoader /> : (
        <Slide direction="right" in mountOnEnter unmountOnExit>
          <Grid sx={{ mt: 4 }} container justifyContent="center" spacing={2}>
            <Grid item xs={10} sm={9} md={5} lg={7}>
              {(formData?.p16workSupervisor === user?.uid)
                || (formData?.p16pepCoordinator === user?.uid)
                || (formData?.p16areaCoordinator === user?.uid)
                || (formData?.p16quality === user?.uid)
                || (formData?.p16technicalArea === user?.uid)
                || (formData?.p16patrimonialSecurity === user?.uid)
                || (formData?.p16externalPrevencionist === user?.uid)
                || (formData?.p16sass === user?.uid)
                || (formData?.p16sassAllowedList?.includes(user?.uid))
                ? (
                  <>
                    <Typography
                      sx={{ mb: 2 }}
                      align="center"
                      variant="h6"
                      color="text.secondary"
                      gutterBottom
                    >
                      ANÁLISIS SEGURO DE TRABAJO
                    </Typography>
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="1 - Descripción del trabajo (paso a paso)"
                      variant="outlined"
                      value={formData?.p3description1}
                      InputLabelProps={{ shrink: true }}
                    />

                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="1 - Medidas de control para cada paso"
                      variant="outlined"
                      value={formData?.p3controlMeasure1}
                      InputLabelProps={{ shrink: true }}
                    />

                    <TextField
                      sx={{ mb: 2 }}
                      style={
                        formData?.p3description2
                          ? { display: 'flex' }
                          : { display: 'none' }
                      }
                      fullWidth
                      label="2 - Descripción del trabajo (paso a paso)"
                      variant="outlined"
                      value={formData?.p3description2}
                      InputLabelProps={{ shrink: true }}
                    />

                    <TextField
                      sx={{ mb: 2 }}
                      style={
                        formData?.p3controlMeasure2
                          ? { display: 'flex' }
                          : { display: 'none' }
                      }
                      fullWidth
                      label="2 - Medidas de control para cada paso"
                      variant="outlined"
                      value={formData?.p3controlMeasure2}
                      InputLabelProps={{ shrink: true }}
                    />

                    <TextField
                      sx={{ mb: 2 }}
                      style={
                        formData?.p3description3
                          ? { display: 'flex' }
                          : { display: 'none' }
                      }
                      fullWidth
                      label="3 - Descripción del trabajo (paso a paso)"
                      variant="outlined"
                      value={formData?.p3description3}
                      InputLabelProps={{ shrink: true }}
                    />

                    <TextField
                      sx={{ mb: 2 }}
                      style={
                        formData?.p3controlMeasure3
                          ? { display: 'flex' }
                          : { display: 'none' }
                      }
                      fullWidth
                      label="3 - Medidas de control para cada paso"
                      variant="outlined"
                      value={formData?.p3controlMeasure3}
                      InputLabelProps={{ shrink: true }}
                    />

                    <TextField
                      sx={{ mb: 2 }}
                      style={
                        formData?.p3description4
                          ? { display: 'flex' }
                          : { display: 'none' }
                      }
                      fullWidth
                      label="4 - Descripción del trabajo (paso a paso)"
                      variant="outlined"
                      value={formData?.p3description4}
                      InputLabelProps={{ shrink: true }}
                    />

                    <TextField
                      sx={{ mb: 2 }}
                      style={
                        formData?.p3controlMeasure4
                          ? { display: 'flex' }
                          : { display: 'none' }
                      }
                      fullWidth
                      label="4 - Medidas de control para cada paso"
                      variant="outlined"
                      value={formData?.p3controlMeasure4}
                      InputLabelProps={{ shrink: true }}
                    />

                    <TextField
                      sx={{ mb: 2 }}
                      style={
                        formData?.p3description5
                          ? { display: 'flex' }
                          : { display: 'none' }
                      }
                      fullWidth
                      label="5 - Descripción del trabajo (paso a paso)"
                      variant="outlined"
                      value={formData?.p3description5}
                      InputLabelProps={{ shrink: true }}
                    />

                    <TextField
                      sx={{ mb: 2 }}
                      style={
                        formData?.p3controlMeasure5
                          ? { display: 'flex' }
                          : { display: 'none' }
                      }
                      fullWidth
                      label="5 - Medidas de control para cada paso"
                      variant="outlined"
                      value={formData?.p3controlMeasure5}
                      InputLabelProps={{ shrink: true }}
                    />

                    <TextField
                      sx={{ mb: 2 }}
                      style={
                        formData?.p3description6
                          ? { display: 'flex' }
                          : { display: 'none' }
                      }
                      fullWidth
                      label="6 - Descripción del trabajo (paso a paso)"
                      variant="outlined"
                      value={formData?.p3description6}
                      InputLabelProps={{ shrink: true }}
                    />

                    <TextField
                      sx={{ mb: 2 }}
                      style={
                        formData?.p3controlMeasure6
                          ? { display: 'flex' }
                          : { display: 'none' }
                      }
                      fullWidth
                      label="6 - Medidas de control para cada paso"
                      variant="outlined"
                      value={formData?.p3controlMeasure6}
                      InputLabelProps={{ shrink: true }}
                    />

                    <TextField
                      sx={{ mb: 2 }}
                      style={
                        formData?.p3description7
                          ? { display: 'flex' }
                          : { display: 'none' }
                      }
                      fullWidth
                      label="7 - Descripción del trabajo (paso a paso)"
                      variant="outlined"
                      value={formData?.p3description7}
                      InputLabelProps={{ shrink: true }}
                    />

                    <TextField
                      sx={{ mb: 2 }}
                      style={
                        formData?.p3controlMeasure7
                          ? { display: 'flex' }
                          : { display: 'none' }
                      }
                      fullWidth
                      label="7 - Medidas de control para cada paso"
                      variant="outlined"
                      value={formData?.p3controlMeasure7}
                      InputLabelProps={{ shrink: true }}
                    />

                    <Grid
                      display="flex"
                      justifyContent="space-evenly"
                      sx={{ mt: 4 }}
                    >
                      <Button
                        startIcon={<HighlightOffIcon />}
                        sx={{ mb: 10, bgcolor: '#db1414' }}
                        color="error"
                        variant="contained"
                        onClick={(e) => disapprove(e)}
                      >
                        Desaprobar
                      </Button>
                      <Button
                        startIcon={<CheckIcon />}
                        sx={{ mb: 10, bgcolor: '#42b50e' }}
                        color="success"
                        variant="contained"
                        onClick={(e) => approve(e)}
                      >
                        Aprobar
                      </Button>
                    </Grid>

                    <Grid sx={{ mb: 2 }} container justifyContent="space-between">
                      <Grid
                        item
                        xs={12}
                        md={5}
                        sx={{ mb: 2 }}
                      >
                        <ColorButton
                          fullWidth
                          startIcon={<ArrowLeftIcon />}
                          onClick={goToReviseStep2}
                        >
                          Ir a la página anterior
                        </ColorButton>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={5}
                      >
                        <ColorButton
                          fullWidth
                          endIcon={<ArrowRightIcon />}
                          onClick={goToReviseStep4}
                        >
                          Ir a la página siguiente
                        </ColorButton>
                      </Grid>

                    </Grid>
                  </>
                )
                : 'Por favor inicie sesión para visualizar su documento a revisar'}

              {user ? (
                <Typography color="green">Usted ha iniciado sesión.</Typography>
              )
                : (
                  <Typography color="red" sx={{ mt: 2 }}>Usted no ha iniciado sesión</Typography>
                )}

            </Grid>
          </Grid>
        </Slide>
      )}

    </DashboardLayout>
  )
}

export default ReviseStep3