import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Slide from '@mui/material/Slide'
import { Link, useParams } from 'react-router-dom'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import DashboardLayout from '../layouts/DashboardLayout'
import ColorButton from '../../common/ColorButton'
import { UrlParams } from '../../types'
import color1ConsoleLog from '../../common/color1ConsoleLog'
import useAuth from '../../hooks/useAuth'

const ReviseStep1 = () => {
  console.log('ReviseStep1')

  const { user } = useAuth()
  console.log('%cuser : %s', color1ConsoleLog, user?.uid)

  const { id, area } = useParams<UrlParams>()
  console.log('%cThe id is : %s', color1ConsoleLog, id)
  console.log('%cThe area is : %s', color1ConsoleLog, area)

  return (
    <DashboardLayout>
      <Slide
        direction="right"
        in
        mountOnEnter
        unmountOnExit
      >
        <Grid sx={{ mt: 4 }} container justifyContent="center">
          <Grid item xs={10} sm={9} lg={7}>
            <Card sx={{ p: 2, mb: 10 }}>
              <CardContent>
                <Typography
                  variant="h6"
                  color="text.secondary"
                  gutterBottom
                >
                  REVISIÓN DE PERMISO DE TRABAJO INTEGRAL
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                >
                  El permiso
                  de trabajo representa
                  una guía para la identificación
                  de peligros y riesgos del
                  trabajo a ejecutar dentro
                  del área concesionada. Esta
                  guía no reemplaza las
                  obligaciones de la
                  normativa legal vigente
                  establecidas para los temas
                  relacionados en
                  Seguridad, Salud Ocupacional.
                </Typography>
              </CardContent>
            </Card>
            <Grid container justifyContent="space-between">
              <Grid
                item
                // sx={{ mx: 3 }}
                xs={12}
                md={5}
              >
                <Link
                  to="/"
                  style={{ color: 'white', textDecoration: 'none' }}
                >

                  <ColorButton
                    sx={{ mt: 4 }}
                    fullWidth
                    startIcon={<ArrowLeftIcon />}
                  >
                    Ir al inicio
                  </ColorButton>
                </Link>
              </Grid>
              <Grid
                // sx={{ mx: 3 }}
                item
                xs={12}
                md={5}
              >
                <Link
                  to={`/revision/${area}/2/${id}`}
                  style={{ color: 'white', textDecoration: 'none' }}
                >
                  <ColorButton
                    sx={{ mt: 4 }}
                    fullWidth
                    endIcon={<ArrowRightIcon />}
                  >
                    Ir a la página siguiente
                  </ColorButton>
                </Link>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </DashboardLayout>
  )
}

export default ReviseStep1