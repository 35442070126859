import React from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Slide from '@mui/material/Slide'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { useParams, useHistory } from 'react-router-dom'
import Button from '@mui/material/Button'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import CheckIcon from '@mui/icons-material/Check'
import { toast } from 'react-toastify'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import YellowSwitch from '../../common/YellowSwitch'
import DashboardLayout from '../layouts/DashboardLayout'
import ColorButton from '../../common/ColorButton'
import getFirestoreDocById from '../../common/getFirestoreDocById'
import { UrlParams } from '../../types'
import { doc, updateDoc, db } from '../../config/firebaseConfig'
import useAuth from '../../hooks/useAuth'
import color1ConsoleLog from '../../common/color1ConsoleLog'
import FullPageLoader from '../../common/FullPageLoader'

const ReviseStep6 = () => {
  console.log('ReviseStep6')

  const { user } = useAuth()
  console.log('%cuser : %s', color1ConsoleLog, user?.uid)

  const { id, area } = useParams<UrlParams>()
  console.log('%cThe id is : %s', color1ConsoleLog, id)
  console.log('%cThe area is : %s', color1ConsoleLog, area)

  const [formData, setFormData]: any = React.useState()
  const [loading, setLoading] = React.useState(true)
  const [isAllowed, setIsAllowed] = React.useState(false)
  const [key, setKey] = React.useState('')
  // eslint-disable-next-line
  const [areaUrlParam, setAreaUrlParam] = React.useState('')
  const [approvedAt, setApprovedAt] = React.useState('')

  React.useEffect(() => {
    (async () => {
      if (id) {
        const res = await getFirestoreDocById(id)
        if (res) {
          setFormData(res)
          if (area === 'tsu') {
            console.log('res?.p16workSupervisor', res?.p16workSupervisor)
            console.log('%cp16workSupervisor has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16workSupervisorApproved6')
            setAreaUrlParam('tsu')
            setApprovedAt('p16workSupervisorApprovedAt6')
          }
          if (area === 'pep') {
            console.log('%cp16pepCoordinator has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16pepCoordinatorApproved6')
            setAreaUrlParam('pep')
            setApprovedAt('p16pepCoordinatorApprovedAt6')
          }
          if (area === 'aco') {
            console.log('%cp16areaCoordinator has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16areaCoordinatorApproved6')
            setAreaUrlParam('aco')
            setApprovedAt('p16areaCoordinatorApprovedAt6')
          }
          if (area === 'qt') {
            console.log('%cp16quality has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16qualityApproved6')
            setAreaUrlParam('qt')
            setApprovedAt('p16qualityApprovedAt6')
          }
          if (area === 'ta') {
            console.log('%cp16technicalArea has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16technicalAreaApproved6')
            setAreaUrlParam('ta')
            setApprovedAt('p16technicalAreaApprovedAt6')
          }
          if (area === 'ps') {
            console.log('%cp16patrimonialSecurity has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16patrimonialSecurityApproved6')
            setAreaUrlParam('ps')
            setApprovedAt('p16patrimonialSecurityApprovedAt6')
          }
          if (area === 'ep') {
            console.log('%cp16externalPrevencionist has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16externalPrevencionistApproved6')
            setAreaUrlParam('ep')
            setApprovedAt('p16externalPrevencionistApprovedAt6')
          }
          if (area === 'sass') {
            console.log('%cp16sass has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16sassApproved6')
            setAreaUrlParam('sass')
            setApprovedAt('p16sassApprovedAt6')
          }
          setLoading(false)
        } else {
          setLoading(false)
        }
      }
    })()
  }, [])

  if (loading) {
    <Typography>Cargando</Typography>
  }
  console.log('formdata 6', formData)

  const history = useHistory()

  const goToReviseStep5 = () => {
    history.push(`/revision/${area}/5/${id}`)
  }

  const goToReviseStep7 = () => {
    history.push(`/revision/${area}/7/${id}`)
  }

  const approve = async (e: any) => {
    e.preventDefault()
    const approveformRef = doc(db, 'forms', id)
    const obj: any = {}
    obj[key] = true
    obj[approvedAt] = new Date()
    try {
      await updateDoc(approveformRef, obj)
      console.log('approved', key, approvedAt)
      toast.success('La aprobación se actualizó correctamente')
    } catch (updateError) {
      toast.error(`Error: ${updateError}`)
    }
  }

  const disapprove = async (e: any) => {
    e.preventDefault()
    const approveformRef = doc(db, 'forms', id)
    const obj: any = {}
    obj[key] = false
    obj[approvedAt] = new Date()
    try {
      await updateDoc(approveformRef, obj)
      console.log('disapproved', key, approvedAt)
      toast.success('La desaprobación se actualizó correctamente')
    } catch (updateError) {
      toast.error(`Error: ${updateError}`)
    }
  }

  return (
    <DashboardLayout>
      {loading ? <FullPageLoader /> : (
        <Slide direction="right" in mountOnEnter unmountOnExit>
          <Grid sx={{ mt: 4 }} container justifyContent="center" spacing={2}>
            <Grid item xs={10} sm={9} md={5} lg={7}>
              {(formData?.p16workSupervisor === user?.uid)
                || (formData?.p16pepCoordinator === user?.uid)
                || (formData?.p16areaCoordinator === user?.uid)
                || (formData?.p16quality === user?.uid)
                || (formData?.p16technicalArea === user?.uid)
                || (formData?.p16patrimonialSecurity === user?.uid)
                || (formData?.p16externalPrevencionist === user?.uid)
                || (formData?.p16sass === user?.uid)
                || (formData?.p16sassAllowedList?.includes(user?.uid))
                ? (
                  <>
                    <Typography
                      sx={{ mb: 2 }}
                      align="center"
                      variant="h6"
                      color="text.secondary"
                      gutterBottom
                    >
                      {`TRABAJO EN ALTURA ${!formData?.p6Show
                        ? '(página no aplica)' : ''}`}
                    </Typography>
                    <Grid display={formData?.p6Show ? 'flex' : 'none'} justifyContent="center" item xs={12}>
                      <FormControlLabel
                        control={(
                          <YellowSwitch
                            checked={formData?.p6Show}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        )}
                        label={formData?.p6Show ? 'Esta página aplica' : 'Esta página no aplica'}
                      />
                    </Grid>
                    <Box
                      component="div"
                      sx={{
                        display: formData?.p6Show ? 'block' : 'none',
                      }}
                    >
                      <Grid display="flex" justifyContent="center" item xs={12}>
                        <TextField
                          sx={{ mb: 2, mt: 4, width: 300 }}
                          fullWidth
                          label="Llave de acceso número"
                          variant="outlined"
                          value={formData?.p6keyNumber}
                        />
                      </Grid>

                      <Grid display="flex" justifyContent="center" item xs={12}>
                        <Box sx={{
                          display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                        }}
                        >
                          <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                            <FormLabel component="legend">Condiciones climáticas adversas (lluvia, neblina, etc)</FormLabel>
                            <RadioGroup
                              sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                              aria-label="condicionesclimaticas"
                              name="condicionesclimaticas"
                              value={formData?.p6weatherConditions}
                            >
                              <FormControlLabel value="SI" control={<Radio checked={formData?.p6weatherConditions === 'SI'} />} label="SI" />
                              <FormControlLabel value="NO" control={<Radio checked={formData?.p6weatherConditions === 'NO'} />} label="NO" />
                              <FormControlLabel value="NA" control={<Radio checked={formData?.p6weatherConditions === 'NA'} />} label="N/A" />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Box sx={{
                        display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                      }}
                      >
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend">
                            Capacitación para trabajo en altura
                          </FormLabel>
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row' }}
                            aria-label="capacitaciontrabajoenaltura"
                            name="capacitaciontrabajoenaltura"
                            value={formData?.p6trainingForHeightWork}
                          >
                            <FormControlLabel value="SI" control={<Radio checked={formData?.p6trainingForHeightWork === 'SI'} />} label="SI" />
                            <FormControlLabel value="NO" control={<Radio checked={formData?.p6trainingForHeightWork === 'NO'} />} label="NO" />
                            <FormControlLabel value="NA" control={<Radio checked={formData?.p6trainingForHeightWork === 'NA'} />} label="N/A" />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      <Box sx={{
                        display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                      }}
                      >
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend">
                            Capacitación específica del trabajo
                          </FormLabel>
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row' }}
                            aria-label="capacitacionespecifica"
                            name="capacitacionespecifica"
                            value={formData?.p6trainingEspecWork}
                          >
                            <FormControlLabel value="SI" control={<Radio checked={formData?.p6trainingEspecWork === 'SI'} />} label="SI" />
                            <FormControlLabel value="NO" control={<Radio checked={formData?.p6trainingEspecWork === 'NO'} />} label="NO" />
                            <FormControlLabel value="NA" control={<Radio checked={formData?.p6trainingEspecWork === 'NA'} />} label="N/A" />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      <Box sx={{
                        display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                      }}
                      >
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend">
                            Radios de comunicación
                          </FormLabel>
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row' }}
                            aria-label="radiosdecom"
                            name="radiosdecom"
                            value={formData?.p6radioCommunication}
                          >
                            <FormControlLabel value="SI" control={<Radio checked={formData?.p6radioCommunication === 'SI'} />} label="SI" />
                            <FormControlLabel value="NO" control={<Radio checked={formData?.p6radioCommunication === 'NO'} />} label="NO" />
                            <FormControlLabel value="NA" control={<Radio checked={formData?.p6radioCommunication === 'NA'} />} label="N/A" />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      <Box sx={{
                        display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                      }}
                      >
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend">
                            Cinturón de Herramientas
                          </FormLabel>
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row' }}
                            aria-label="cinturondeherramientas"
                            name="cinturondeherramientas"
                            value={formData?.p6toolBelt}
                          >
                            <FormControlLabel value="SI" control={<Radio checked={formData?.p6toolBelt === 'SI'} />} label="SI" />
                            <FormControlLabel value="NO" control={<Radio checked={formData?.p6toolBelt === 'NO'} />} label="NO" />
                            <FormControlLabel value="NA" control={<Radio checked={formData?.p6toolBelt === 'NA'} />} label="N/A" />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      <Box sx={{
                        display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                      }}
                      >
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend">
                            Uso de puntos de anclaje
                            {' '}

                          </FormLabel>
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row' }}
                            aria-label="usodepuntosdeanclaje"
                            name="usodepuntosdeanclaje"
                            value={formData?.p6useOfHookPoint}
                          >
                            <FormControlLabel value="SI" control={<Radio checked={formData?.p6useOfHookPoint === 'SI'} />} label="SI" />
                            <FormControlLabel value="NO" control={<Radio checked={formData?.p6useOfHookPoint === 'NO'} />} label="NO" />
                            <FormControlLabel value="NA" control={<Radio checked={formData?.p6useOfHookPoint === 'NA'} />} label="N/A" />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      <Box sx={{
                        display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                      }}
                      >
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend">
                            Uso de escalas y/o plataformas
                          </FormLabel>
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row' }}
                            aria-label="usodeescalasyoplat"
                            name="usodeescalasyoplat"
                            value={formData?.p6useofLadder}
                          >
                            <FormControlLabel value="SI" control={<Radio checked={formData?.p6useofLadder === 'SI'} />} label="SI" />
                            <FormControlLabel value="NO" control={<Radio checked={formData?.p6useofLadder === 'NO'} />} label="NO" />
                            <FormControlLabel value="NA" control={<Radio checked={formData?.p6useofLadder === 'NA'} />} label="N/A" />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      {(formData?.p6useofLadderImage?.length > 1 && formData?.p6useofLadderImage !== 'none')
                        && (
                          <Card sx={{ maxWidth: 345, mt: 2, mb: 4 }}>
                            <CardMedia
                              component="img"
                              height="140"
                              image={
                                formData?.p6useofLadderImage
                                && formData?.p6useofLadderImage
                              }
                              alt="uso de escalas yo plataformas"
                            />
                            <CardContent>
                              <Typography gutterBottom variant="subtitle1" component="div">
                                Uso de escalas y/o plataformas
                              </Typography>
                            </CardContent>
                          </Card>
                        )}

                      <Box sx={{
                        display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                      }}
                      >
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend">
                            Elevadores y Jaulas
                          </FormLabel>
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row' }}
                            aria-label="elevadoresyjaulas"
                            name="elevadoresyjaulas"
                            value={formData?.p6elevator}
                          >
                            <FormControlLabel value="SI" control={<Radio checked={formData?.p6elevator === 'SI'} />} label="SI" />
                            <FormControlLabel value="NO" control={<Radio checked={formData?.p6elevator === 'NO'} />} label="NO" />
                            <FormControlLabel value="NA" control={<Radio checked={formData?.p6elevator === 'NA'} />} label="N/A" />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      {(formData?.p6elevatorImage?.length > 1 && formData?.p6elevatorImage !== 'none')
                        && (
                          <Card sx={{ maxWidth: 345, mt: 2, mb: 4 }}>
                            <CardMedia
                              component="img"
                              height="140"
                              image={
                                formData?.p6elevatorImage
                                && formData?.p6elevatorImage
                              }
                              alt="elevadores y jaulas"
                            />
                            <CardContent>
                              <Typography gutterBottom variant="subtitle1" component="div">
                                Elevadores y Jaulas
                              </Typography>
                            </CardContent>
                          </Card>
                        )}

                      <Box sx={{
                        display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                      }}
                      >
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend">Andamio Tipo euro (soporte y anclaje)</FormLabel>
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row' }}
                            aria-label="andamiotipoeuro"
                            name="andamiotipoeuro"
                            value={formData?.p6andamTipeEuro}
                          >
                            <FormControlLabel value="SI" control={<Radio checked={formData?.p6andamTipeEuro === 'SI'} />} label="SI" />
                            <FormControlLabel value="NO" control={<Radio checked={formData?.p6andamTipeEuro === 'NO'} />} label="NO" />
                            <FormControlLabel value="NA" control={<Radio checked={formData?.p6andamTipeEuro === 'NA'} />} label="N/A" />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      {(formData?.p6andamTipeEuroImage?.length > 1 && formData?.p6andamTipeEuroImage !== 'none')
                        && (
                          <Card sx={{ maxWidth: 345, mt: 2, mb: 4 }}>
                            <CardMedia
                              component="img"
                              height="140"
                              image={
                                formData?.p6andamTipeEuroImage
                                && formData?.p6andamTipeEuroImage
                              }
                              alt="andamio tipo euro (soporte y anclaje)"
                            />
                            <CardContent>
                              <Typography gutterBottom variant="subtitle1" component="div">
                                Andamio Tipo euro (soporte y anclaje)
                              </Typography>
                            </CardContent>
                          </Card>
                        )}

                      <Box sx={{
                        display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                      }}
                      >
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend">Examen de Salud (Exámen de Altura)</FormLabel>
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row' }}
                            aria-label="examendesaludeda"
                            name="examendesaludeda"
                            value={formData?.p6healthCheck}
                          >
                            <FormControlLabel value="SI" control={<Radio checked={formData?.p6healthCheck === 'SI'} />} label="SI" />
                            <FormControlLabel value="NO" control={<Radio checked={formData?.p6healthCheck === 'NO'} />} label="NO" />
                            <FormControlLabel value="NA" control={<Radio checked={formData?.p6healthCheck === 'NA'} />} label="N/A" />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      <Box sx={{
                        display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                      }}
                      >
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend">Deslizador en línea vertical</FormLabel>
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row' }}
                            aria-label="deslizadorenlinea"
                            name="deslizadorenlinea"
                            value={formData?.p6inlineSliderVertical}
                          >
                            <FormControlLabel value="SI" control={<Radio checked={formData?.p6inlineSliderVertical === 'SI'} />} label="SI" />
                            <FormControlLabel value="NO" control={<Radio checked={formData?.p6inlineSliderVertical === 'NO'} />} label="NO" />
                            <FormControlLabel value="NA" control={<Radio checked={formData?.p6inlineSliderVertical === 'NA'} />} label="N/A" />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      <Box sx={{
                        display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                      }}
                      >
                        <FormControl sx={{ display: 'flex', mb: 10 }} component="fieldset">
                          <FormLabel component="legend">Trabajo en altura bajo nivel</FormLabel>
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row' }}
                            aria-label="trabajoenaltbn"
                            name="trabajoenaltbn"
                            value={formData?.p6heightJobLowLevel}
                          >
                            <FormControlLabel value="SI" control={<Radio checked={formData?.p6heightJobLowLevel === 'SI'} />} label="SI" />
                            <FormControlLabel value="NO" control={<Radio checked={formData?.p6heightJobLowLevel === 'NO'} />} label="NO" />
                            <FormControlLabel value="NA" control={<Radio checked={formData?.p6heightJobLowLevel === 'NA'} />} label="N/A" />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Box>

                    <Grid
                      display={isAllowed ? 'flex' : 'none'}
                      justifyContent="space-evenly"
                      sx={{ mt: 4 }}
                    >
                      <Button
                        startIcon={<HighlightOffIcon />}
                        sx={{ mb: 10, bgcolor: '#db1414' }}
                        color="error"
                        variant="contained"
                        onClick={(e) => disapprove(e)}
                      >
                        Desaprobar
                      </Button>
                      <Button
                        startIcon={<CheckIcon />}
                        sx={{ mb: 10, bgcolor: '#42b50e' }}
                        color="success"
                        variant="contained"
                        onClick={(e) => approve(e)}
                      >
                        Aprobar
                      </Button>
                    </Grid>

                    <Grid sx={{ mb: 2 }} container justifyContent="space-between">
                      <Grid
                        xs={12}
                        md={5}
                        sx={{ mb: 2 }}
                      >
                        <ColorButton
                          fullWidth
                          startIcon={<ArrowLeftIcon />}
                          onClick={goToReviseStep5}
                        >
                          Ir a la página anterior
                        </ColorButton>
                      </Grid>
                      <Grid
                        xs={12}
                        md={5}
                      >
                        <ColorButton
                          fullWidth
                          endIcon={<ArrowRightIcon />}
                          onClick={goToReviseStep7}
                        >
                          Ir a la página siguiente
                        </ColorButton>
                      </Grid>

                    </Grid>
                  </>
                )
                : 'Por favor inicie sesión para visualizar su documento a revisar'}

              {user ? (
                <Typography color="green">Usted ha iniciado sesión.</Typography>
              )
                : (
                  <Typography color="red" sx={{ mt: 2 }}>Usted no ha iniciado sesión</Typography>
                )}

            </Grid>
          </Grid>
        </Slide>
      )}

    </DashboardLayout>
  )
}

export default ReviseStep6