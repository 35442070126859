import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Slide from '@mui/material/Slide'
import { useHistory } from 'react-router-dom'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { toast } from 'react-toastify'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import YellowSwitch from '../../common/YellowSwitch'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { entireStore } from '../../app/store'
import { allFormActions } from '../../features/form/formSlice'
import DashboardLayout from '../layouts/DashboardLayout'
import ColorButton from './ColorButton'
import useNewFormId from '../../hooks/useNewFormId'
import useStorage from '../../hooks/useStorage'
import { db, doc, updateDoc } from '../../config/firebaseConfig'

const CreateStep8 = () => {
  console.log('CreateStep8')

  const imageTypes = ['image/png', 'image/jpeg', 'image/jpg']

  const [file, setFile] = React.useState<File | null>(null)
  const [fileID, setFileID] = React.useState<String | null>(null)
  const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(false)

  const { newFormId } = useNewFormId()

  const { url, progress, error } = useStorage(file)

  const customIdFileUploadFinish = 'custom-id-file-upload-finish'
  React.useEffect(() => {
    if (progress > 1) {
      setButtonDisabled(true)
    }
    if (progress === 100) {
      setButtonDisabled(false)
      toast.success('La imagen ha sido subida correctamente', {
        toastId: customIdFileUploadFinish,
      })
      console.log('fileid', fileID)

      localStorage.setItem(`${fileID}`, url)
      console.log(url)
    }
    if (error) {
      // error es any
      setButtonDisabled(false)
      toast.error(error)
    }
  }, [progress, url, file])

  const {
    p8Show,
    p8chemEnvase,
    p8hasHds,
    p8indicateSuspel,
    p8showaGlove,
    p8faceShield,
    p8antSuit,
    p8rubberBoot,
    p8antSpillKit,
    p8extensionElectricalCheck,

  } = useAppSelector(entireStore)
  const {
    setp8Show,
    setp8chemEnvase,
    setp8hasHds,
    setp8indicateSuspel,
    setp8showaGlove,
    setp8faceShield,
    setp8antSuit,
    setp8rubberBoot,
    setp8antSpillKit,
    setp8extensionElectricalCheck,
  } = allFormActions
  const dispatch = useAppDispatch()

  const history = useHistory()

  const goToCreateStep7 = () => {
    history.push(`/create/7/${newFormId}`)
  }

  const goToCreateStep9 = async () => {
    // history.push(`/create/9/${newFormId}`)
    const formRef = doc(db, 'forms', newFormId as string)
    try {
      await updateDoc(formRef, {
        newFormp8FilledOut: true,
        newFormp8FilledOutDate: new Date(),
        p8Show,
        p8chemEnvase,
        p8hasHds,
        p8indicateSuspel,
        p8showaGlove,
        p8faceShield,
        p8antSuit,
        p8rubberBoot,
        p8antSpillKit,
        p8extensionElectricalCheck,
      })
      toast.success('El permiso de trabajo se actualizó correctamente')
      history.push(`/create/9/${newFormId}`)
    } catch (updateError) {
      toast.error(`Error: ${updateError}`)
    }
  }

  const Input = styled('input')({
    display: 'none',
  })

  return (
    <DashboardLayout>
      <Slide direction="right" in mountOnEnter unmountOnExit>
        <Grid sx={{ mt: 4 }} container justifyContent="center" spacing={2}>
          <Grid item xs={10} sm={9} md={5} lg={7}>
            <Typography
              sx={{ mb: 2 }}
              align="center"
              variant="h6"
              color="text.secondary"
              gutterBottom
            >
              ASEOS
            </Typography>

            <Grid display="flex" justifyContent="center" item xs={12}>
              <FormControlLabel
                control={(
                  <YellowSwitch
                    checked={p8Show}
                    onChange={(event) => dispatch(setp8Show(event.target.checked))}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                )}
                label={p8Show ? 'Esta página aplica' : 'Esta página no aplica'}
              />
            </Grid>
            <Box
              component="div"
              sx={{
                display: p8Show ? 'block' : 'none',
              }}
            >
              <Box sx={{
                display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
              }}
              >
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">Envases químicos rotulados</FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="atrapamiento"
                    name="atrapamiento"
                    value={p8chemEnvase}
                    onChange={(event) => dispatch(setp8chemEnvase(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">Cuenta con HDS</FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="aplastamiento"
                    name="aplastamiento"
                    value={p8hasHds}
                    onChange={(event) => dispatch(setp8hasHds(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>

                { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(e) => {
                      if (e.target.files) {
                        const selectedFile = e?.target?.files[0]
                        console.table(selectedFile)
                        if (selectedFile && imageTypes.includes(selectedFile.type)) {
                          setFile(selectedFile)
                        } else {
                          toast.error('El archivo no es una imagen, por favor intente nuevamente')
                        }
                      }
                    }}
                  />
                  <Button
                    sx={{ bgcolor: '#fbc02d', mb: 2 }}
                    variant="contained"
                    component="span"
                    disabled={buttonDisabled}
                    onClick={() => setFileID('p8hasHdsImage')}
                  >
                    {buttonDisabled
                      ? 'Subiendo imagen'
                      : 'Subir imagen'}
                  </Button>
                </label>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">indicar  SUSPEL</FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="golpeadorpor"
                    name="golpeadorpor"
                    value={p8indicateSuspel}
                    onChange={(event) => dispatch(setp8indicateSuspel(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">Guantes Showa</FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="golpeadocon"
                    name="golpeadocon"
                    value={p8showaGlove}
                    onChange={(event) => dispatch(setp8showaGlove(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">
                    Careta Facial
                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="caida"
                    name="caida"
                    value={p8faceShield}
                    onChange={(event) => dispatch(setp8faceShield(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">
                    Traje Antiácido
                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="contactoconobjetoscortantes"
                    name="contactoconobjetoscortantes"
                    value={p8antSuit}
                    onChange={(event) => dispatch(setp8antSuit(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">
                    Bota de goma
                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="contactoconobjetoscalientes"
                    name="contactoconobjetoscalientes"
                    value={p8rubberBoot}
                    onChange={(event) => dispatch(setp8rubberBoot(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">
                    Kit Antiderrame
                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="proyecciondeparticulas"
                    name="proyecciondeparticulas"
                    value={p8antSpillKit}
                    onChange={(event) => dispatch(setp8antSpillKit(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 10 }} component="fieldset">
                  <FormLabel component="legend">
                    Extensiones eléctricas chequeadas
                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="proyecciondeparticulas"
                    name="proyecciondeparticulas"
                    value={p8extensionElectricalCheck}
                    onChange={
                      (event) => dispatch(setp8extensionElectricalCheck(event.target.value))
                    }
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>

                { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(e) => {
                      if (e.target.files) {
                        const selectedFile = e?.target?.files[0]
                        console.table(selectedFile)
                        if (selectedFile && imageTypes.includes(selectedFile.type)) {
                          setFile(selectedFile)
                        } else {
                          toast.error('El archivo no es una imagen, por favor intente nuevamente')
                        }
                      }
                    }}
                  />
                  <Button
                    sx={{ bgcolor: '#fbc02d', mb: 2 }}
                    variant="contained"
                    component="span"
                    disabled={buttonDisabled}
                    onClick={() => setFileID('p8extensionElectricalCheckImage')}
                  >
                    {buttonDisabled
                      ? 'Subiendo imagen'
                      : 'Subir imagen'}
                  </Button>
                </label>
              </Box>
            </Box>
            <Grid sx={{ mb: 2 }} container justifyContent="space-between">
              <Grid
                xs={12}
                md={5}
                sx={{ mb: 2 }}
              >
                <ColorButton
                  fullWidth
                  startIcon={<ArrowLeftIcon />}
                  onClick={goToCreateStep7}
                >
                  Ir a la página anterior
                </ColorButton>
              </Grid>
              <Grid
                xs={12}
                md={5}
              >
                <ColorButton
                  fullWidth
                  endIcon={<ArrowRightIcon />}
                  onClick={goToCreateStep9}
                >
                  Ir a la página siguiente
                </ColorButton>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </DashboardLayout>
  )
}

export default CreateStep8