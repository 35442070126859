import React from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Slide from '@mui/material/Slide'
import { toast } from 'react-toastify'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useAppSelector } from '../../app/hooks'
import { entireStore } from '../../app/store'
import DashboardLayout from '../layouts/DashboardLayout'
import { db, doc, updateDoc } from '../../config/firebaseConfig'
import getFirestoreDocById from '../../common/getFirestoreDocById'
import { UrlParams } from '../../types'
import FullPageLoader from '../../common/FullPageLoader'
import useAuth from '../../hooks/useAuth'
import useRedirect from '../../hooks/useRedirect'
import allUsers from '../../common/allUsers'

const ParamedicStep1 = () => {
  console.log('ParamedicStep1')
  const { user } = useAuth()
  const { setNewRedirect } = useRedirect()
  const { id } = useParams<UrlParams>()
  const history = useHistory()
  const location = useLocation()
  const paramedicWorker1Ref = React.useRef<HTMLDivElement>(null)
  const paramedicWorker2Ref = React.useRef<HTMLDivElement>(null)
  const paramedicWorker3Ref = React.useRef<HTMLDivElement>(null)
  const paramedicWorker4Ref = React.useRef<HTMLDivElement>(null)
  const paramedicWorker5Ref = React.useRef<HTMLDivElement>(null)
  const paramedicWorker6Ref = React.useRef<HTMLDivElement>(null)
  const paramedicWorker7Ref = React.useRef<HTMLDivElement>(null)
  const paramedicWorker8Ref = React.useRef<HTMLDivElement>(null)
  const paramedicWorker9Ref = React.useRef<HTMLDivElement>(null)
  const paramedicWorker10Ref = React.useRef<HTMLDivElement>(null)
  const paramedicWorker11Ref = React.useRef<HTMLDivElement>(null)

  const { nodeApiBaseUrl } = useAppSelector(entireStore)

  console.log('ref 1 : ', paramedicWorker1Ref.current)
  console.log('ref 11 : ', paramedicWorker11Ref.current)

  console.log('history : ', history)
  console.log('location : ', location)
  console.log('user id : ', user?.uid)

  const [formData, setFormData]: any = React.useState()
  const [loading, setLoading] = React.useState(false)
  const [
    paramedicApprovedWorker1,
    setParamedicApprovedWorker1] = React.useState<string>('')
  const [
    paramedicApprovedWorker2,
    setParamedicApprovedWorker2] = React.useState<string>('')
  const [
    paramedicApprovedWorker3,
    setParamedicApprovedWorker3] = React.useState<string>('')
  const [
    paramedicApprovedWorker4,
    setParamedicApprovedWorker4] = React.useState<string>('')
  const [
    paramedicApprovedWorker5,
    setParamedicApprovedWorker5] = React.useState<string>('')
  const [
    paramedicApprovedWorker6,
    setParamedicApprovedWorker6] = React.useState<string>('')
  const [
    paramedicApprovedWorker7,
    setParamedicApprovedWorker7] = React.useState<string>('')
  const [
    paramedicApprovedWorker8,
    setParamedicApprovedWorker8] = React.useState<string>('')
  const [
    paramedicApprovedWorker9,
    setParamedicApprovedWorker9] = React.useState<string>('')
  const [
    paramedicApprovedWorker10,
    setParamedicApprovedWorker10] = React.useState<string>('')
  const [
    paramedicApprovedWorker11,
    setParamedicApprovedWorker11] = React.useState<string>('')

  console.log('paramedicApprovedWorker1 :', paramedicApprovedWorker1)
  console.log('formData :', formData)
  console.log('formData paramedicApprovedWorker1 :', formData?.paramedicApprovedWorker1)
  console.log('formData name of who did', formData?.newFormPersonWhoDid)

  const formRef = doc(db, 'forms', id as string)

  React.useEffect(() => {
    if (!user) {
      setNewRedirect(location.pathname)
      history.push('/')
    } else {
      setNewRedirect(null)
      console.log('El usuario ha iniciado sesión')
    }
  }, [user])

  React.useEffect(() => {
    (async () => {
      if (id) {
        setLoading(true)
        const res = await getFirestoreDocById(id)
        if (res) {
          setFormData(res)
          setParamedicApprovedWorker1(res.paramedicApprovedWorker1)
          setParamedicApprovedWorker2(res.paramedicApprovedWorker2)
          setParamedicApprovedWorker3(res.paramedicApprovedWorker3)
          setParamedicApprovedWorker4(res.paramedicApprovedWorker4)
          setParamedicApprovedWorker5(res.paramedicApprovedWorker5)
          setParamedicApprovedWorker6(res.paramedicApprovedWorker6)
          setParamedicApprovedWorker7(res.paramedicApprovedWorker7)
          setParamedicApprovedWorker8(res.paramedicApprovedWorker8)
          setParamedicApprovedWorker9(res.paramedicApprovedWorker9)
          setParamedicApprovedWorker10(res.paramedicApprovedWorker10)
          setParamedicApprovedWorker11(res.paramedicApprovedWorker11)
          setLoading(false)
        } else {
          setLoading(false)
        }
      }
    })()
  }, [])

  const paramedicApproveAll = async () => {
    if (formData?.paramedicApprovedAllWorkers) {
      return
    }

    const approveformRef = doc(db, 'forms', id)
    const theFoundUserKey = allUsers
      .find(
        (element) => element.userKey === formData?.newFormPersonWhoDid,
      )
    console.log('the found user key', theFoundUserKey)
    try {
      await updateDoc(approveformRef, {
        paramedicApprovedAllWorkers: true,
      })
      const paramedicResponse = await fetch(
        `${nodeApiBaseUrl}/approvedparamedic`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: formData?.newFormPersonEmailWhoDid,
            permitId: id,
          }),
        },
      )
      const responseParamedicMessage = await paramedicResponse.json()
      if (responseParamedicMessage) {
        toast.success('El email de aprobación ha sido enviado a la persona que creó el permiso de trabajo correctamente')
      }
      setTimeout(() => {
        window.location.reload()
      }, 3500)
    } catch (error) {
      toast.error('Error guardando la aprobación')
    }
  }
  const paramedicDisapproveAll = async () => {
    const disapproveformRef = doc(db, 'forms', id)
    try {
      await updateDoc(disapproveformRef, {
        paramedicApprovedAllWorkers: false,
      })
    } catch (error) {
      toast.error('Error guardando la desaprobación')
    }
  }

  const preventDuplicate1 = 'prevent-Duplicate1'
  const preventDuplicate2 = 'prevent-Duplicate2'
  const preventDuplicate3 = 'prevent-Duplicate3'
  const preventDuplicate4 = 'prevent-Duplicate4'
  const preventDuplicate5 = 'prevent-Duplicate5'
  const preventDuplicate6 = 'prevent-Duplicate6'
  const preventDuplicate7 = 'prevent-Duplicate7'
  const preventDuplicate8 = 'prevent-Duplicate8'
  const preventDuplicate9 = 'prevent-Duplicate9'
  const preventDuplicate10 = 'prevent-Duplicate10'
  const preventDuplicate11 = 'prevent-Duplicate11'
  const preventDuplicate12 = 'prevent-Duplicate12'
  const preventDuplicate13 = 'prevent-Duplicate13'
  const preventDuplicate14 = 'prevent-Duplicate14'
  const preventDuplicate15 = 'prevent-Duplicate15'
  const preventDuplicate16 = 'prevent-Duplicate16'
  const preventDuplicate17 = 'prevent-Duplicate17'
  const preventDuplicate18 = 'prevent-Duplicate18'
  const preventDuplicate19 = 'prevent-Duplicate19'
  const preventDuplicate20 = 'prevent-Duplicate20'
  const preventDuplicate21 = 'prevent-Duplicate21'
  const preventDuplicate22 = 'prevent-Duplicate22'
  const preventDuplicate23 = 'prevent-Duplicate23'
  const preventDuplicate24 = 'prevent-Duplicate24'
  const preventDuplicate25 = 'prevent-Duplicate25'
  const preventDuplicate26 = 'prevent-Duplicate26'
  const preventDuplicate27 = 'prevent-Duplicate27'
  const preventDuplicate28 = 'prevent-Duplicate28'
  const preventDuplicate29 = 'prevent-Duplicate29'
  const preventDuplicate30 = 'prevent-Duplicate30'
  const preventDuplicate31 = 'prevent-Duplicate31'
  const preventDuplicate32 = 'prevent-Duplicate32'
  const preventDuplicate33 = 'prevent-Duplicate33'

  React.useEffect(() => {
    // Trabajador 1
    if (formData?.p4workerName1 !== null
      && paramedicApprovedWorker1 === 'SI'
      && formData?.p4workerName2 === null) {
      paramedicApproveAll()
      toast.success('El trabajador ha sido aprobado', {
        toastId: preventDuplicate1,
      })
    }
    if (formData?.p4workerName1 !== null
      && paramedicApprovedWorker1 === 'NO'
      && formData?.p4workerName2 === null) {
      paramedicDisapproveAll()
      toast.success('El trabajador ha sido desaprobado', {
        toastId: preventDuplicate2,
      })
    }
    if (formData?.p4workerName1 !== null
      && paramedicApprovedWorker1 === 'NA'
      && formData?.p4workerName2 === null) {
      paramedicDisapproveAll()
      toast.success('El trabajador ha sido desaprobado', {
        toastId: preventDuplicate3,
      })
    }
    // Trabajador 2
    if (formData?.p4workerName1 !== null
      && formData?.p4workerName2 !== null
      && paramedicApprovedWorker1 === 'SI'
      && paramedicApprovedWorker2 === 'SI'
      && formData?.p4workerName3 === null) {
      paramedicApproveAll()
      toast.success('Todos los trabajadores han sido aprobados', {
        toastId: preventDuplicate4,
      })
    }
    if (formData?.p4workerName1 !== null
      && formData?.p4workerName2 !== null
      && (paramedicApprovedWorker1 === 'NO'
        || paramedicApprovedWorker2 === 'NO')
      && formData?.p4workerName3 === null) {
      paramedicDisapproveAll()
      toast.success('Todos los trabajadores están desaprobados', {
        toastId: preventDuplicate5,
      })
    }
    if (formData?.p4workerName1 !== null
      && formData?.p4workerName2 !== null
      && (paramedicApprovedWorker1 === 'NA'
        || paramedicApprovedWorker2 === 'NA')
      && formData?.p4workerName3 === null) {
      paramedicDisapproveAll()
      toast.success('Todos los trabajadores están desaprobados', {
        toastId: preventDuplicate6,
      })
    }
    // Trabajador 3
    if (formData?.p4workerName1 !== null
      && formData?.p4workerName2 !== null
      && formData?.p4workerName3 !== null
      && paramedicApprovedWorker1 === 'SI'
      && paramedicApprovedWorker2 === 'SI'
      && paramedicApprovedWorker3 === 'SI'
      && formData?.p4workerName4 === null) {
      paramedicApproveAll()
      toast.success('Todos los trabajadores han sido aprobados', {
        toastId: preventDuplicate7,
      })
    }
    if (formData?.p4workerName1 !== null
      && formData?.p4workerName2 !== null
      && formData?.p4workerName3 !== null
      && (paramedicApprovedWorker1 === 'NO'
        || paramedicApprovedWorker2 === 'NO'
        || paramedicApprovedWorker3 === 'NO')
      && formData?.p4workerName4 === null) {
      paramedicDisapproveAll()
      toast.success('Todos los trabajadores están desaprobados', {
        toastId: preventDuplicate8,
      })
    }
    if (formData?.p4workerName1 !== null
      && formData?.p4workerName2 !== null
      && formData?.p4workerName3 !== null
      && (paramedicApprovedWorker1 === 'NA'
        || paramedicApprovedWorker2 === 'NA'
        || paramedicApprovedWorker3 === 'NA')
      && formData?.p4workerName4 === null) {
      paramedicDisapproveAll()
      toast.success('Todos los trabajadores están desaprobados', {
        toastId: preventDuplicate9,
      })
    }
    // Trabajador 4
    if (formData?.p4workerName1 !== null
      && formData?.p4workerName2 !== null
      && formData?.p4workerName3 !== null
      && formData?.p4workerName4 !== null
      && paramedicApprovedWorker1 === 'SI'
      && paramedicApprovedWorker2 === 'SI'
      && paramedicApprovedWorker3 === 'SI'
      && paramedicApprovedWorker4 === 'SI'
      && formData?.p4workerName5 === null) {
      paramedicApproveAll()
      toast.success('Todos los trabajadores han sido aprobados', {
        toastId: preventDuplicate10,
      })
    }
    if (formData?.p4workerName1 !== null
      && formData?.p4workerName2 !== null
      && formData?.p4workerName3 !== null
      && formData?.p4workerName4 !== null
      && (paramedicApprovedWorker1 === 'NO'
        || paramedicApprovedWorker2 === 'NO'
        || paramedicApprovedWorker3 === 'NO'
        || paramedicApprovedWorker4 === 'NO')
      && formData?.p4workerName5 === null) {
      paramedicDisapproveAll()
      toast.success('Todos los trabajadores están desaprobados', {
        toastId: preventDuplicate11,
      })
    }
    if (formData?.p4workerName1 !== null
      && formData?.p4workerName2 !== null
      && formData?.p4workerName3 !== null
      && formData?.p4workerName4 !== null
      && (paramedicApprovedWorker1 === 'NA'
        || paramedicApprovedWorker2 === 'NA'
        || paramedicApprovedWorker3 === 'NA'
        || paramedicApprovedWorker4 === 'NA')
      && formData?.p4workerName5 === null) {
      paramedicDisapproveAll()
      toast.success('Todos los trabajadores están desaprobados', {
        toastId: preventDuplicate12,
      })
    }
    // Trabajador 5
    if (formData?.p4workerName1 !== null
      && formData?.p4workerName2 !== null
      && formData?.p4workerName3 !== null
      && formData?.p4workerName4 !== null
      && formData?.p4workerName5 !== null
      && paramedicApprovedWorker1 === 'SI'
      && paramedicApprovedWorker2 === 'SI'
      && paramedicApprovedWorker3 === 'SI'
      && paramedicApprovedWorker4 === 'SI'
      && paramedicApprovedWorker5 === 'SI'
      && formData?.p4workerName6 === null) {
      paramedicApproveAll()
      toast.success('Todos los trabajadores han sido aprobados', {
        toastId: preventDuplicate13,
      })
    }
    if (formData?.p4workerName1 !== null
      && formData?.p4workerName2 !== null
      && formData?.p4workerName3 !== null
      && formData?.p4workerName4 !== null
      && formData?.p4workerName5 !== null
      && (paramedicApprovedWorker1 === 'NO'
        || paramedicApprovedWorker2 === 'NO'
        || paramedicApprovedWorker3 === 'NO'
        || paramedicApprovedWorker4 === 'NO'
        || paramedicApprovedWorker5 === 'NO')
      && formData?.p4workerName6 === null) {
      paramedicDisapproveAll()
      toast.success('Todos los trabajadores están desaprobados', {
        toastId: preventDuplicate14,
      })
    }
    if (formData?.p4workerName1 !== null
      && formData?.p4workerName2 !== null
      && formData?.p4workerName3 !== null
      && formData?.p4workerName4 !== null
      && formData?.p4workerName5 !== null
      && (paramedicApprovedWorker1 === 'NA'
        || paramedicApprovedWorker2 === 'NA'
        || paramedicApprovedWorker3 === 'NA'
        || paramedicApprovedWorker4 === 'NA'
        || paramedicApprovedWorker5 === 'NA')
      && formData?.p4workerName6 === null) {
      paramedicDisapproveAll()
      toast.success('Todos los trabajadores están desaprobados', {
        toastId: preventDuplicate15,
      })
    }
    // Trabajador 6
    if (formData?.p4workerName1 !== null
      && formData?.p4workerName2 !== null
      && formData?.p4workerName3 !== null
      && formData?.p4workerName4 !== null
      && formData?.p4workerName5 !== null
      && formData?.p4workerName6 !== null
      && paramedicApprovedWorker1 === 'SI'
      && paramedicApprovedWorker2 === 'SI'
      && paramedicApprovedWorker3 === 'SI'
      && paramedicApprovedWorker4 === 'SI'
      && paramedicApprovedWorker5 === 'SI'
      && paramedicApprovedWorker6 === 'SI'
      && formData?.p4workerName7 === null) {
      paramedicApproveAll()
      toast.success('Todos los trabajadores han sido aprobados', {
        toastId: preventDuplicate16,
      })
    }
    if (formData?.p4workerName1 !== null
      && formData?.p4workerName2 !== null
      && formData?.p4workerName3 !== null
      && formData?.p4workerName4 !== null
      && formData?.p4workerName5 !== null
      && formData?.p4workerName6 !== null
      && (paramedicApprovedWorker1 === 'NO'
        || paramedicApprovedWorker2 === 'NO'
        || paramedicApprovedWorker3 === 'NO'
        || paramedicApprovedWorker4 === 'NO'
        || paramedicApprovedWorker5 === 'NO'
        || paramedicApprovedWorker6 === 'NO')
      && formData?.p4workerName7 === null) {
      paramedicDisapproveAll()
      toast.success('Todos los trabajadores están desaprobados', {
        toastId: preventDuplicate17,
      })
    }
    if (formData?.p4workerName1 !== null
      && formData?.p4workerName2 !== null
      && formData?.p4workerName3 !== null
      && formData?.p4workerName4 !== null
      && formData?.p4workerName5 !== null
      && formData?.p4workerName6 !== null
      && (paramedicApprovedWorker1 === 'NA'
        || paramedicApprovedWorker2 === 'NA'
        || paramedicApprovedWorker3 === 'NA'
        || paramedicApprovedWorker4 === 'NA'
        || paramedicApprovedWorker5 === 'NA'
        || paramedicApprovedWorker6 === 'NA')
      && formData?.p4workerName7 === null) {
      paramedicDisapproveAll()
      toast.success('Todos los trabajadores están desaprobados', {
        toastId: preventDuplicate18,
      })
    }
    // Trabajador 7
    if (formData?.p4workerName1 !== null
      && formData?.p4workerName2 !== null
      && formData?.p4workerName3 !== null
      && formData?.p4workerName4 !== null
      && formData?.p4workerName5 !== null
      && formData?.p4workerName6 !== null
      && formData?.p4workerName7 !== null
      && paramedicApprovedWorker1 === 'SI'
      && paramedicApprovedWorker2 === 'SI'
      && paramedicApprovedWorker3 === 'SI'
      && paramedicApprovedWorker4 === 'SI'
      && paramedicApprovedWorker5 === 'SI'
      && paramedicApprovedWorker6 === 'SI'
      && paramedicApprovedWorker7 === 'SI'
      && formData?.p4workerName8 === null) {
      paramedicApproveAll()
      toast.success('Todos los trabajadores han sido aprobados', {
        toastId: preventDuplicate19,
      })
    }
    if (formData?.p4workerName1 !== null
      && formData?.p4workerName2 !== null
      && formData?.p4workerName3 !== null
      && formData?.p4workerName4 !== null
      && formData?.p4workerName5 !== null
      && formData?.p4workerName6 !== null
      && formData?.p4workerName7 !== null
      && (paramedicApprovedWorker1 === 'NO'
        || paramedicApprovedWorker2 === 'NO'
        || paramedicApprovedWorker3 === 'NO'
        || paramedicApprovedWorker4 === 'NO'
        || paramedicApprovedWorker5 === 'NO'
        || paramedicApprovedWorker6 === 'NO'
        || paramedicApprovedWorker7 === 'NO')
      && formData?.p4workerName8 === null) {
      paramedicDisapproveAll()
      toast.success('Todos los trabajadores están desaprobados', {
        toastId: preventDuplicate20,
      })
    }
    if (formData?.p4workerName1 !== null
      && formData?.p4workerName2 !== null
      && formData?.p4workerName3 !== null
      && formData?.p4workerName4 !== null
      && formData?.p4workerName5 !== null
      && formData?.p4workerName6 !== null
      && formData?.p4workerName7 !== null
      && (paramedicApprovedWorker1 === 'NA'
        || paramedicApprovedWorker2 === 'NA'
        || paramedicApprovedWorker3 === 'NA'
        || paramedicApprovedWorker4 === 'NA'
        || paramedicApprovedWorker5 === 'NA'
        || paramedicApprovedWorker6 === 'NA'
        || paramedicApprovedWorker7 === 'NA')
      && formData?.p4workerName8 === null) {
      paramedicDisapproveAll()
      toast.success('Todos los trabajadores están desaprobados', {
        toastId: preventDuplicate21,
      })
    }
    // Trabajador 8
    if (formData?.p4workerName1 !== null
      && formData?.p4workerName2 !== null
      && formData?.p4workerName3 !== null
      && formData?.p4workerName4 !== null
      && formData?.p4workerName5 !== null
      && formData?.p4workerName6 !== null
      && formData?.p4workerName7 !== null
      && formData?.p4workerName8 !== null
      && paramedicApprovedWorker1 === 'SI'
      && paramedicApprovedWorker2 === 'SI'
      && paramedicApprovedWorker3 === 'SI'
      && paramedicApprovedWorker4 === 'SI'
      && paramedicApprovedWorker5 === 'SI'
      && paramedicApprovedWorker6 === 'SI'
      && paramedicApprovedWorker7 === 'SI'
      && paramedicApprovedWorker8 === 'SI'
      && formData?.p4workerName9 === null) {
      paramedicApproveAll()
      toast.success('Todos los trabajadores han sido aprobados', {
        toastId: preventDuplicate22,
      })
    }
    if (formData?.p4workerName1 !== null
      && formData?.p4workerName2 !== null
      && formData?.p4workerName3 !== null
      && formData?.p4workerName4 !== null
      && formData?.p4workerName5 !== null
      && formData?.p4workerName6 !== null
      && formData?.p4workerName7 !== null
      && formData?.p4workerName8 !== null
      && (paramedicApprovedWorker1 === 'NO'
        || paramedicApprovedWorker2 === 'NO'
        || paramedicApprovedWorker3 === 'NO'
        || paramedicApprovedWorker4 === 'NO'
        || paramedicApprovedWorker5 === 'NO'
        || paramedicApprovedWorker6 === 'NO'
        || paramedicApprovedWorker7 === 'NO'
        || paramedicApprovedWorker8 === 'NO')
      && formData?.p4workerName9 === null) {
      paramedicDisapproveAll()
      toast.success('Todos los trabajadores están desaprobados', {
        toastId: preventDuplicate23,
      })
    }
    if (formData?.p4workerName1 !== null
      && formData?.p4workerName2 !== null
      && formData?.p4workerName3 !== null
      && formData?.p4workerName4 !== null
      && formData?.p4workerName5 !== null
      && formData?.p4workerName6 !== null
      && formData?.p4workerName7 !== null
      && formData?.p4workerName8 !== null
      && (paramedicApprovedWorker1 === 'NA'
        || paramedicApprovedWorker2 === 'NA'
        || paramedicApprovedWorker3 === 'NA'
        || paramedicApprovedWorker4 === 'NA'
        || paramedicApprovedWorker5 === 'NA'
        || paramedicApprovedWorker6 === 'NA'
        || paramedicApprovedWorker7 === 'NA'
        || paramedicApprovedWorker8 === 'NA')
      && formData?.p4workerName9 === null) {
      paramedicDisapproveAll()
      toast.success('Todos los trabajadores están desaprobados', {
        toastId: preventDuplicate24,
      })
    }
    // Trabajador 9
    if (formData?.p4workerName1 !== null
      && formData?.p4workerName2 !== null
      && formData?.p4workerName3 !== null
      && formData?.p4workerName4 !== null
      && formData?.p4workerName5 !== null
      && formData?.p4workerName6 !== null
      && formData?.p4workerName7 !== null
      && formData?.p4workerName8 !== null
      && formData?.p4workerName9 !== null
      && paramedicApprovedWorker1 === 'SI'
      && paramedicApprovedWorker2 === 'SI'
      && paramedicApprovedWorker3 === 'SI'
      && paramedicApprovedWorker4 === 'SI'
      && paramedicApprovedWorker5 === 'SI'
      && paramedicApprovedWorker6 === 'SI'
      && paramedicApprovedWorker7 === 'SI'
      && paramedicApprovedWorker8 === 'SI'
      && paramedicApprovedWorker9 === 'SI'
      && formData?.p4workerName10 === null) {
      paramedicApproveAll()
      toast.success('Todos los trabajadores han sido aprobados', {
        toastId: preventDuplicate25,
      })
    }
    if (formData?.p4workerName1 !== null
      && formData?.p4workerName2 !== null
      && formData?.p4workerName3 !== null
      && formData?.p4workerName4 !== null
      && formData?.p4workerName5 !== null
      && formData?.p4workerName6 !== null
      && formData?.p4workerName7 !== null
      && formData?.p4workerName8 !== null
      && formData?.p4workerName9 !== null
      && (paramedicApprovedWorker1 === 'NO'
        || paramedicApprovedWorker2 === 'NO'
        || paramedicApprovedWorker3 === 'NO'
        || paramedicApprovedWorker4 === 'NO'
        || paramedicApprovedWorker5 === 'NO'
        || paramedicApprovedWorker6 === 'NO'
        || paramedicApprovedWorker7 === 'NO'
        || paramedicApprovedWorker8 === 'NO'
        || paramedicApprovedWorker9 === 'NO')
      && formData?.p4workerName10 === null) {
      paramedicDisapproveAll()
      toast.success('Todos los trabajadores están desaprobados', {
        toastId: preventDuplicate26,
      })
    }
    if (formData?.p4workerName1 !== null
      && formData?.p4workerName2 !== null
      && formData?.p4workerName3 !== null
      && formData?.p4workerName4 !== null
      && formData?.p4workerName5 !== null
      && formData?.p4workerName6 !== null
      && formData?.p4workerName7 !== null
      && formData?.p4workerName8 !== null
      && formData?.p4workerName9 !== null
      && (paramedicApprovedWorker1 === 'NA'
        || paramedicApprovedWorker2 === 'NA'
        || paramedicApprovedWorker3 === 'NA'
        || paramedicApprovedWorker4 === 'NA'
        || paramedicApprovedWorker5 === 'NA'
        || paramedicApprovedWorker6 === 'NA'
        || paramedicApprovedWorker7 === 'NA'
        || paramedicApprovedWorker8 === 'NA'
        || paramedicApprovedWorker9 === 'NA')
      && formData?.p4workerName10 === null) {
      paramedicDisapproveAll()
      toast.success('Todos los trabajadores están desaprobados', {
        toastId: preventDuplicate27,
      })
    }
    // Trabajador 10
    if (formData?.p4workerName1 !== null
      && formData?.p4workerName2 !== null
      && formData?.p4workerName3 !== null
      && formData?.p4workerName4 !== null
      && formData?.p4workerName5 !== null
      && formData?.p4workerName6 !== null
      && formData?.p4workerName7 !== null
      && formData?.p4workerName8 !== null
      && formData?.p4workerName9 !== null
      && formData?.p4workerName10 !== null
      && paramedicApprovedWorker1 === 'SI'
      && paramedicApprovedWorker2 === 'SI'
      && paramedicApprovedWorker3 === 'SI'
      && paramedicApprovedWorker4 === 'SI'
      && paramedicApprovedWorker5 === 'SI'
      && paramedicApprovedWorker6 === 'SI'
      && paramedicApprovedWorker7 === 'SI'
      && paramedicApprovedWorker8 === 'SI'
      && paramedicApprovedWorker9 === 'SI'
      && paramedicApprovedWorker10 === 'SI'
      && formData?.p4workerName11 === null) {
      paramedicApproveAll()
      toast.success('Todos los trabajadores han sido aprobados', {
        toastId: preventDuplicate28,
      })
    }
    if (formData?.p4workerName1 !== null
      && formData?.p4workerName2 !== null
      && formData?.p4workerName3 !== null
      && formData?.p4workerName4 !== null
      && formData?.p4workerName5 !== null
      && formData?.p4workerName6 !== null
      && formData?.p4workerName7 !== null
      && formData?.p4workerName8 !== null
      && formData?.p4workerName9 !== null
      && formData?.p4workerName10 !== null
      && (paramedicApprovedWorker1 === 'NO'
        || paramedicApprovedWorker2 === 'NO'
        || paramedicApprovedWorker3 === 'NO'
        || paramedicApprovedWorker4 === 'NO'
        || paramedicApprovedWorker5 === 'NO'
        || paramedicApprovedWorker6 === 'NO'
        || paramedicApprovedWorker7 === 'NO'
        || paramedicApprovedWorker8 === 'NO'
        || paramedicApprovedWorker9 === 'NO'
        || paramedicApprovedWorker10 === 'NO')
      && formData?.p4workerName11 === null) {
      paramedicDisapproveAll()
      toast.success('Todos los trabajadores están desaprobados', {
        toastId: preventDuplicate29,
      })
    }
    if (formData?.p4workerName1 !== null
      && formData?.p4workerName2 !== null
      && formData?.p4workerName3 !== null
      && formData?.p4workerName4 !== null
      && formData?.p4workerName5 !== null
      && formData?.p4workerName6 !== null
      && formData?.p4workerName7 !== null
      && formData?.p4workerName8 !== null
      && formData?.p4workerName9 !== null
      && formData?.p4workerName10 !== null
      && (paramedicApprovedWorker1 === 'NA'
        || paramedicApprovedWorker2 === 'NA'
        || paramedicApprovedWorker3 === 'NA'
        || paramedicApprovedWorker4 === 'NA'
        || paramedicApprovedWorker5 === 'NA'
        || paramedicApprovedWorker6 === 'NA'
        || paramedicApprovedWorker7 === 'NA'
        || paramedicApprovedWorker8 === 'NA'
        || paramedicApprovedWorker9 === 'NA'
        || paramedicApprovedWorker10 === 'NA')
      && formData?.p4workerName11 === null) {
      paramedicDisapproveAll()
      toast.success('Todos los trabajadores están desaprobados', {
        toastId: preventDuplicate30,
      })
    }
    // Trabajador 11
    if (formData?.p4workerName1 !== null
      && formData?.p4workerName2 !== null
      && formData?.p4workerName3 !== null
      && formData?.p4workerName4 !== null
      && formData?.p4workerName5 !== null
      && formData?.p4workerName6 !== null
      && formData?.p4workerName7 !== null
      && formData?.p4workerName8 !== null
      && formData?.p4workerName9 !== null
      && formData?.p4workerName10 !== null
      && formData?.p4workerName11 !== null
      && paramedicApprovedWorker1 === 'SI'
      && paramedicApprovedWorker2 === 'SI'
      && paramedicApprovedWorker3 === 'SI'
      && paramedicApprovedWorker4 === 'SI'
      && paramedicApprovedWorker5 === 'SI'
      && paramedicApprovedWorker6 === 'SI'
      && paramedicApprovedWorker7 === 'SI'
      && paramedicApprovedWorker8 === 'SI'
      && paramedicApprovedWorker9 === 'SI'
      && paramedicApprovedWorker10 === 'SI'
      && paramedicApprovedWorker11 === 'SI'
    ) {
      paramedicApproveAll()
      toast.success('Todos los trabajadores han sido aprobados', {
        toastId: preventDuplicate31,
      })
    }
    if (formData?.p4workerName1 !== null
      && formData?.p4workerName2 !== null
      && formData?.p4workerName3 !== null
      && formData?.p4workerName4 !== null
      && formData?.p4workerName5 !== null
      && formData?.p4workerName6 !== null
      && formData?.p4workerName7 !== null
      && formData?.p4workerName8 !== null
      && formData?.p4workerName9 !== null
      && formData?.p4workerName10 !== null
      && formData?.p4workerName11 !== null
      && (paramedicApprovedWorker1 === 'NO'
        || paramedicApprovedWorker2 === 'NO'
        || paramedicApprovedWorker3 === 'NO'
        || paramedicApprovedWorker4 === 'NO'
        || paramedicApprovedWorker5 === 'NO'
        || paramedicApprovedWorker6 === 'NO'
        || paramedicApprovedWorker7 === 'NO'
        || paramedicApprovedWorker8 === 'NO'
        || paramedicApprovedWorker9 === 'NO'
        || paramedicApprovedWorker10 === 'NO'
        || paramedicApprovedWorker11 === 'NO')
    ) {
      paramedicDisapproveAll()
      toast.success('Todos los trabajadores están desaprobados', {
        toastId: preventDuplicate32,
      })
    }
    if (formData?.p4workerName1 !== null
      && formData?.p4workerName2 !== null
      && formData?.p4workerName3 !== null
      && formData?.p4workerName4 !== null
      && formData?.p4workerName5 !== null
      && formData?.p4workerName6 !== null
      && formData?.p4workerName7 !== null
      && formData?.p4workerName8 !== null
      && formData?.p4workerName9 !== null
      && formData?.p4workerName10 !== null
      && formData?.p4workerName11 !== null
      && (paramedicApprovedWorker1 === 'NA'
        || paramedicApprovedWorker2 === 'NA'
        || paramedicApprovedWorker3 === 'NA'
        || paramedicApprovedWorker4 === 'NA'
        || paramedicApprovedWorker5 === 'NA'
        || paramedicApprovedWorker6 === 'NA'
        || paramedicApprovedWorker7 === 'NA'
        || paramedicApprovedWorker8 === 'NA'
        || paramedicApprovedWorker9 === 'NA'
        || paramedicApprovedWorker10 === 'NA'
        || paramedicApprovedWorker11 === 'NA')
    ) {
      paramedicDisapproveAll()
      toast.success('Todos los trabajadores están desaprobados', {
        toastId: preventDuplicate33,
      })
    }
  }, [formData,
    paramedicApprovedWorker1,
    paramedicApprovedWorker2,
    paramedicApprovedWorker3,
    paramedicApprovedWorker4,
    paramedicApprovedWorker5,
    paramedicApprovedWorker6,
    paramedicApprovedWorker7,
    paramedicApprovedWorker8,
    paramedicApprovedWorker9,
    paramedicApprovedWorker10,
    paramedicApprovedWorker11,
  ])

  if (loading) {
    return <FullPageLoader />
  }

  return (
    <DashboardLayout>
      {formData?.paramedicApprovedAllWorkers
        ? (
          <Typography marginTop="1rem" textAlign="center" variant="h5">
            Todos los trabajadores han sido aprobados.
          </Typography>
        )
        : (
          <Slide direction="right" in mountOnEnter unmountOnExit>
            <Grid sx={{ mt: 4 }} container justifyContent="center" spacing={2}>
              <Grid item xs={10} sm={9} md={5} lg={7}>
                {user?.uid === 'zvRk91O1sXRL7OU2huVUQPzQRZI3'
                  ? (
                    <>
                      <Typography
                        sx={{ mb: 2 }}
                        align="center"
                        variant="h6"
                        color="text.secondary"
                        gutterBottom
                      >
                        LISTADO DE PERSONAL QUE EJECUTA EL TRABAJO
                      </Typography>

                      <TextField
                        sx={{ mb: 2 }}
                        fullWidth
                        label="Nombre del trabajador 1"
                        variant="outlined"
                        disabled
                        value={formData?.p4workerName1}
                      />
                      <Box
                        sx={{
                          display: formData?.p4workerName1
                            ? 'flex' : 'none',
                          justifyContent: 'center',
                          mt: 4,
                          mb: 2,
                        }}
                        ref={paramedicWorker1Ref}
                      >
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend" />
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                            aria-label="trabajador1"
                            name="trabajador1"
                            defaultValue={paramedicApprovedWorker1}
                            value={paramedicApprovedWorker1}
                            onChange={
                              async (event) => {
                                try {
                                  setParamedicApprovedWorker1(
                                    event.target.value,
                                  )
                                  await updateDoc(formRef, {
                                    paramedicApprovedWorker1: event.target.value,
                                    paramedicApprovedWorker1Date: new Date(),

                                  })
                                  toast.success(`${formData?.p4workerName1} actualizado correctamente`)
                                } catch (error) {
                                  toast.error('Error, por favor intente nuevamente')
                                }
                              }
                            }
                          >
                            <FormControlLabel
                              value="SI"
                              control={<Radio />}
                              label="SI"
                            />
                            <FormControlLabel
                              value="NO"
                              control={<Radio />}
                              label="NO"
                            />
                            <FormControlLabel
                              value="NA"
                              control={<Radio />}
                              label="N/A"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      <TextField
                        ref={paramedicWorker2Ref}
                        sx={{
                          display: formData?.p4workerName2
                            ? 'flex' : 'none',
                          mb: 2,
                        }}
                        fullWidth
                        label="Nombre del trabajador 2"
                        variant="outlined"
                        disabled
                        value={formData?.p4workerName2}
                      />
                      <Box sx={{
                        display: formData?.p4workerName2
                          ? 'flex' : 'none',
                        justifyContent: 'center',
                        mt: 4,
                        mb: 2,
                      }}
                      >
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend" />
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                            aria-label="trabajador2"
                            name="trabajador2"
                            defaultValue={paramedicApprovedWorker2}
                            value={paramedicApprovedWorker2}
                            onChange={
                              async (event) => {
                                try {
                                  setParamedicApprovedWorker2(
                                    event.target.value,
                                  )
                                  await updateDoc(formRef, {
                                    paramedicApprovedWorker2: event.target.value,
                                    paramedicApprovedWorker2Date: new Date(),

                                  })
                                  toast.success(`${formData?.p4workerName2} actualizado correctamente`)
                                } catch (error) {
                                  toast.error('Error, por favor intente nuevamente')
                                }
                              }
                            }
                          >
                            <FormControlLabel
                              value="SI"
                              control={<Radio />}
                              label="SI"
                            />
                            <FormControlLabel
                              value="NO"
                              control={<Radio />}
                              label="NO"
                            />
                            <FormControlLabel
                              value="NA"
                              control={<Radio />}
                              label="N/A"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      <TextField
                        ref={paramedicWorker3Ref}
                        sx={{
                          display: formData?.p4workerName3
                            ? 'flex' : 'none',
                          mb: 2,
                        }}
                        fullWidth
                        label="Nombre del trabajador 3"
                        variant="outlined"
                        disabled
                        value={formData?.p4workerName3}
                      />
                      <Box sx={{
                        display: formData?.p4workerName3
                          ? 'flex' : 'none',
                        justifyContent: 'center',
                        mt: 4,
                        mb: 2,
                      }}
                      >
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend" />
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                            aria-label="trabajador3"
                            name="trabajador3"
                            defaultValue={paramedicApprovedWorker3}
                            value={paramedicApprovedWorker3}
                            onChange={
                              async (event) => {
                                try {
                                  setParamedicApprovedWorker3(
                                    event.target.value,
                                  )
                                  await updateDoc(formRef, {
                                    paramedicApprovedWorker3: event.target.value,
                                    paramedicApprovedWorker3Date: new Date(),
                                  })
                                  toast.success(`${formData?.p4workerName3} actualizado correctamente`)
                                } catch (error) {
                                  toast.error('Error, por favor intente nuevamente')
                                }
                              }
                            }
                          >
                            <FormControlLabel
                              value="SI"
                              control={<Radio />}
                              label="SI"
                            />
                            <FormControlLabel
                              value="NO"
                              control={<Radio />}
                              label="NO"
                            />
                            <FormControlLabel
                              value="NA"
                              control={<Radio />}
                              label="N/A"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      <TextField
                        ref={paramedicWorker4Ref}
                        sx={{
                          display: formData?.p4workerName4
                            ? 'flex' : 'none',
                          mb: 2,
                        }}
                        fullWidth
                        label="Nombre del trabajador 4"
                        variant="outlined"
                        disabled
                        value={formData?.p4workerName4}
                      />
                      <Box sx={{
                        display: formData?.p4workerName4
                          ? 'flex' : 'none',
                        justifyContent: 'center',
                        mt: 4,
                        mb: 2,
                      }}
                      >
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend" />
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                            aria-label="trabajador4"
                            name="trabajador4"
                            defaultValue={paramedicApprovedWorker4}
                            value={paramedicApprovedWorker4}
                            onChange={
                              async (event) => {
                                try {
                                  setParamedicApprovedWorker4(
                                    event.target.value,
                                  )
                                  await updateDoc(formRef, {
                                    paramedicApprovedWorker4: event.target.value,
                                    paramedicApprovedWorker4Date: new Date(),
                                  })
                                  toast.success(`${formData?.p4workerName4} actualizado correctamente`)
                                } catch (error) {
                                  toast.error('Error, por favor intente nuevamente')
                                }
                              }
                            }
                          >
                            <FormControlLabel
                              value="SI"
                              control={<Radio />}
                              label="SI"
                            />
                            <FormControlLabel
                              value="NO"
                              control={<Radio />}
                              label="NO"
                            />
                            <FormControlLabel
                              value="NA"
                              control={<Radio />}
                              label="N/A"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      <TextField
                        ref={paramedicWorker5Ref}
                        sx={{
                          display: formData?.p4workerName5
                            ? 'flex' : 'none',
                          mb: 2,
                        }}
                        fullWidth
                        label="Nombre del trabajador 5"
                        variant="outlined"
                        disabled
                        value={formData?.p4workerName5}
                      />
                      <Box sx={{
                        display: formData?.p4workerName5
                          ? 'flex' : 'none',
                        justifyContent: 'center',
                        mt: 4,
                        mb: 2,
                      }}
                      >
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend" />
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                            aria-label="trabajador5"
                            name="trabajador5"
                            defaultValue={paramedicApprovedWorker5}
                            value={paramedicApprovedWorker5}
                            onChange={
                              async (event) => {
                                try {
                                  setParamedicApprovedWorker5(
                                    event.target.value,
                                  )
                                  await updateDoc(formRef, {
                                    paramedicApprovedWorker5: event.target.value,
                                    paramedicApprovedWorker5Date: new Date(),
                                  })
                                  toast.success(`${formData?.p4workerName5} actualizado correctamente`)
                                } catch (error) {
                                  toast.error('Error, por favor intente nuevamente')
                                }
                              }
                            }
                          >
                            <FormControlLabel
                              value="SI"
                              control={<Radio />}
                              label="SI"
                            />
                            <FormControlLabel
                              value="NO"
                              control={<Radio />}
                              label="NO"
                            />
                            <FormControlLabel
                              value="NA"
                              control={<Radio />}
                              label="N/A"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      <TextField
                        ref={paramedicWorker6Ref}
                        sx={{
                          display: formData?.p4workerName6
                            ? 'flex' : 'none',
                          mb: 2,
                        }}
                        fullWidth
                        label="Nombre del trabajador 6"
                        variant="outlined"
                        disabled
                        value={formData?.p4workerName6}
                      />
                      <Box sx={{
                        display: formData?.p4workerName6
                          ? 'flex' : 'none',
                        justifyContent: 'center',
                        mt: 4,
                        mb: 2,
                      }}
                      >
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend" />
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                            aria-label="trabajador6"
                            name="trabajador6"
                            defaultValue={paramedicApprovedWorker6}
                            value={paramedicApprovedWorker6}
                            onChange={
                              async (event) => {
                                try {
                                  setParamedicApprovedWorker6(
                                    event.target.value,
                                  )
                                  await updateDoc(formRef, {
                                    paramedicApprovedWorker6: event.target.value,
                                    paramedicApprovedWorker6Date: new Date(),
                                  })
                                  toast.success(`${formData?.p4workerName6} actualizado correctamente`)
                                } catch (error) {
                                  toast.error('Error, por favor intente nuevamente')
                                }
                              }
                            }
                          >
                            <FormControlLabel
                              value="SI"
                              control={<Radio />}
                              label="SI"
                            />
                            <FormControlLabel
                              value="NO"
                              control={<Radio />}
                              label="NO"
                            />
                            <FormControlLabel
                              value="NA"
                              control={<Radio />}
                              label="N/A"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      <TextField
                        ref={paramedicWorker7Ref}
                        sx={{
                          display: formData?.p4workerName7
                            ? 'flex' : 'none',
                          mb: 2,
                        }}
                        fullWidth
                        label="Nombre del trabajador 7"
                        variant="outlined"
                        disabled
                        value={formData?.p4workerName7}
                      />
                      <Box sx={{
                        display: formData?.p4workerName7
                          ? 'flex' : 'none',
                        justifyContent: 'center',
                        mt: 4,
                        mb: 2,
                      }}
                      >
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend" />
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                            aria-label="trabajador7"
                            name="trabajador7"
                            defaultValue={paramedicApprovedWorker7}
                            value={paramedicApprovedWorker7}
                            onChange={
                              async (event) => {
                                try {
                                  setParamedicApprovedWorker7(
                                    event.target.value,
                                  )
                                  await updateDoc(formRef, {
                                    paramedicApprovedWorker7: event.target.value,
                                    paramedicApprovedWorker7Date: new Date(),
                                  })
                                  toast.success(`${formData?.p4workerName7} actualizado correctamente`)
                                } catch (error) {
                                  toast.error('Error, por favor intente nuevamente')
                                }
                              }
                            }
                          >
                            <FormControlLabel
                              value="SI"
                              control={<Radio />}
                              label="SI"
                            />
                            <FormControlLabel
                              value="NO"
                              control={<Radio />}
                              label="NO"
                            />
                            <FormControlLabel
                              value="NA"
                              control={<Radio />}
                              label="N/A"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      <TextField
                        ref={paramedicWorker8Ref}
                        sx={{
                          display: formData?.p4workerName8
                            ? 'flex' : 'none',
                          mb: 2,
                        }}
                        fullWidth
                        label="Nombre del trabajador 8"
                        variant="outlined"
                        disabled
                        value={formData?.p4workerName8}
                      />
                      <Box sx={{
                        display: formData?.p4workerName8
                          ? 'flex' : 'none',
                        justifyContent: 'center',
                        mt: 4,
                        mb: 2,
                      }}
                      >
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend" />
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                            aria-label="trabajador8"
                            name="trabajador8"
                            defaultValue={paramedicApprovedWorker8}
                            value={paramedicApprovedWorker8}
                            onChange={
                              async (event) => {
                                try {
                                  setParamedicApprovedWorker8(
                                    event.target.value,
                                  )
                                  await updateDoc(formRef, {
                                    paramedicApprovedWorker8: event.target.value,
                                    paramedicApprovedWorker8Date: new Date(),
                                  })
                                  toast.success(`${formData?.p4workerName8} actualizado correctamente`)
                                } catch (error) {
                                  toast.error('Error, por favor intente nuevamente')
                                }
                              }
                            }
                          >
                            <FormControlLabel
                              value="SI"
                              control={<Radio />}
                              label="SI"
                            />
                            <FormControlLabel
                              value="NO"
                              control={<Radio />}
                              label="NO"
                            />
                            <FormControlLabel
                              value="NA"
                              control={<Radio />}
                              label="N/A"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      <TextField
                        ref={paramedicWorker9Ref}
                        sx={{
                          display: formData?.p4workerName9
                            ? 'flex' : 'none',
                          mb: 2,
                        }}
                        fullWidth
                        label="Nombre del trabajador 9"
                        variant="outlined"
                        disabled
                        value={formData?.p4workerName9}
                      />
                      <Box sx={{
                        display: formData?.p4workerName9
                          ? 'flex' : 'none',
                        justifyContent: 'center',
                        mt: 4,
                        mb: 2,
                      }}
                      >
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend" />
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                            aria-label="trabajador9"
                            name="trabajador9"
                            defaultValue={paramedicApprovedWorker9}
                            value={paramedicApprovedWorker9}
                            onChange={
                              async (event) => {
                                try {
                                  setParamedicApprovedWorker9(
                                    event.target.value,
                                  )
                                  await updateDoc(formRef, {
                                    paramedicApprovedWorker9: event.target.value,
                                    paramedicApprovedWorker9Date: new Date(),
                                  })
                                  toast.success(`${formData?.p4workerName9} actualizado correctamente`)
                                } catch (error) {
                                  toast.error('Error, por favor intente nuevamente')
                                }
                              }
                            }
                          >
                            <FormControlLabel
                              value="SI"
                              control={<Radio />}
                              label="SI"
                            />
                            <FormControlLabel
                              value="NO"
                              control={<Radio />}
                              label="NO"
                            />
                            <FormControlLabel
                              value="NA"
                              control={<Radio />}
                              label="N/A"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      <TextField
                        ref={paramedicWorker10Ref}
                        sx={{
                          display: formData?.p4workerName10
                            ? 'flex' : 'none',
                          mb: 2,
                        }}
                        fullWidth
                        label="Nombre del trabajador 10"
                        variant="outlined"
                        disabled
                        value={formData?.p4workerName10}
                      />
                      <Box sx={{
                        display: formData?.p4workerName10
                          ? 'flex' : 'none',
                        justifyContent: 'center',
                        mt: 4,
                        mb: 2,
                      }}
                      >
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend" />
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                            aria-label="trabajador10"
                            name="trabajador10"
                            defaultValue={paramedicApprovedWorker10}
                            value={paramedicApprovedWorker10}
                            onChange={
                              async (event) => {
                                try {
                                  setParamedicApprovedWorker10(
                                    event.target.value,
                                  )
                                  await updateDoc(formRef, {
                                    paramedicApprovedWorker10: event.target.value,
                                    paramedicApprovedWorker10Date: new Date(),
                                  })
                                  toast.success(`${formData?.p4workerName10} actualizado correctamente`)
                                } catch (error) {
                                  toast.error('Error, por favor intente nuevamente')
                                }
                              }
                            }
                          >
                            <FormControlLabel
                              value="SI"
                              control={<Radio />}
                              label="SI"
                            />
                            <FormControlLabel
                              value="NO"
                              control={<Radio />}
                              label="NO"
                            />
                            <FormControlLabel
                              value="NA"
                              control={<Radio />}
                              label="N/A"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      <TextField
                        ref={paramedicWorker11Ref}
                        sx={{
                          display: formData?.p4workerName11
                            ? 'flex' : 'none',
                          mb: 10,
                        }}
                        fullWidth
                        label="Nombre del trabajador 11"
                        variant="outlined"
                        disabled
                        value={formData?.p4workerName11}
                      />
                      <Box sx={{
                        display: formData?.p4workerName11
                          ? 'flex' : 'none',
                        justifyContent: 'center',
                        mt: 4,
                        mb: 2,
                      }}
                      >
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend" />
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                            aria-label="trabajador11"
                            name="trabajador11"
                            defaultValue={paramedicApprovedWorker11}
                            value={paramedicApprovedWorker11}
                            onChange={
                              async (event) => {
                                try {
                                  setParamedicApprovedWorker11(
                                    event.target.value,
                                  )
                                  await updateDoc(formRef, {
                                    paramedicApprovedWorker11: event.target.value,
                                    paramedicApprovedWorker11Date: new Date(),
                                  })
                                  toast.success(`${formData?.p4workerName11} actualizado correctamente`)
                                } catch (error) {
                                  toast.error('Error, por favor intente nuevamente')
                                }
                              }
                            }
                          >
                            <FormControlLabel
                              value="SI"
                              control={<Radio />}
                              label="SI"
                            />
                            <FormControlLabel
                              value="NO"
                              control={<Radio />}
                              label="NO"
                            />
                            <FormControlLabel
                              value="NA"
                              control={<Radio />}
                              label="N/A"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                    </>
                  )
                  : 'Únicamente el paramédico puede ver esta página'}

              </Grid>
            </Grid>
          </Slide>
        )}

    </DashboardLayout>
  )
}

export default ParamedicStep1