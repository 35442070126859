import React from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Box from '@mui/material/Box'
import Slide from '@mui/material/Slide'
import TimePicker from '@mui/lab/TimePicker'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import {
  validate,
} from 'rut.js'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
// import Stack from '@mui/material/Stack'
// import Webcam from 'react-webcam'
import {
  useAppDispatch,
  useAppSelector,
} from '../../app/hooks'
import {
  setp2mutualidad,
  setp2requestingArea,
  setp2pepWorkCoordinator,
  setp2specificWorkArea,
  setp2extensionShow,
  setp2extension,
  setp2rut,
  setp2erpPhone,
  setp2erpName,
  setp2contratSupervisorPhone,
  setP2CompanyName,
  setp2contratSupervisorName,
} from '../../features/form/formSlice'
import { entireStore } from '../../app/store'
import YellowSwitch from '../../common/YellowSwitch'
import useDate from '../../hooks/useDate'
import DashboardLayout from '../layouts/DashboardLayout'
import ColorButton from './ColorButton'
import useNewFormId from '../../hooks/useNewFormId'
import useStorage from '../../hooks/useStorage'
import { db, doc, updateDoc } from '../../config/firebaseConfig'
import useAuth from '../../hooks/useAuth'

const Input = styled('input')({
  display: 'none',
})

const CreateStep2 = () => {
  console.log('CreateStep2')

  const imageTypes = ['image/png', 'image/jpeg', 'image/jpg']

  const [file, setFile] = React.useState<File | null>(null)
  const [fileID, setFileID] = React.useState<String | null>(null)
  const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(false)

  const { newFormId } = useNewFormId()
  const { url, progress, error } = useStorage(file)
  const { user } = useAuth()

  const customIdFileUploadFinish = 'custom-id-file-upload-finish'
  React.useEffect(() => {
    if (progress > 1) {
      setButtonDisabled(true)
    }
    if (progress === 100) {
      setButtonDisabled(false)
      toast.success('La imagen ha sido subida correctamente', {
        toastId: customIdFileUploadFinish,
      })
      console.log('fileid', fileID)

      localStorage.setItem(`${fileID}`, url)
      console.log(url)
    }
    if (error) {
      // error es any
      setButtonDisabled(false)
      toast.error(error)
    }
  }, [progress, url, file])

  const {
    p2startTime,
    setp2startTime,
    p2finishTime,
    setp2finishTime,
  } = useDate()

  const {
    p2companyName,
    p2contratSupervisorName,
    p2contratSupervisorPhone,
    p2erpName,
    p2erpPhone,
    p2rut,
    p2mutualidad,
    p2requestingArea,
    p2pepWorkCoordinator,
    p2specificWorkArea,
    // p2specificWorkAreaImage,
    p2date,
    p2extensionShow,
    p2extension,
  } = useAppSelector(entireStore)
  const dispatch = useAppDispatch()

  const history = useHistory()

  const goToCreateStep2 = () => {
    history.push(`/create/1/${newFormId}`)
  }

  const goToCreateStep3 = async () => {
    if (!p2companyName) {
      toast.error('Por favor escriba el nombre de la empresa')
    } else if (!p2contratSupervisorName) {
      toast.error('Por favor escriba el Nombre Supervisor Contratista')
    } else if (!p2contratSupervisorPhone) {
      toast.error('Por favor seleccione el Teléfono Supervisor Contratista')
    } else if (!p2erpName) {
      toast.error('Por favor ingrese el Nombre ERP')
    } else if (!p2erpPhone) {
      toast.error('Por favor ingrese el Teléfono ERP')
    } else if (!p2rut) {
      toast.error('Por favor ingrese el Rut')
    } else if (!validate(p2rut)) {
      toast.error('Por favor ingrese un Rut correcto')
    } else if (!p2mutualidad) {
      toast.error('Por favor ingrese la Mutualidad')
    } else if (!p2requestingArea) {
      toast.error('Por favor ingrese el Área que solicita el trabajo')
    } else if (!p2pepWorkCoordinator) {
      toast.error('Por favor ingrese el Coordinador de trabajo Pepsico')
    } else if (!p2specificWorkArea) {
      toast.error('Por favor ingrese la Área de trabajo específica')
    } else if (!p2startTime) {
      toast.error('Por favor ingrese la Hora inicial')
    } else if (!p2finishTime) {
      toast.error('Por favor ingrese la Hora final')
    } else if (p2extensionShow && !p2extension) {
      toast.error('Por favor ingrese la Extensión')
    } else {
      const formRef = doc(db, 'forms', newFormId as string)
      try {
        await updateDoc(formRef, {
          paramedicApprovedAllWorkers: false,
          newFormPersonWhoDid: user?.uid,
          newFormp1FilledOut: true,
          newFormp1FilledOutDate: new Date(),
          newFormp2FilledOut: true,
          newFormp2FilledOutDate: new Date(),
          p2companyName,
          p2contratSupervisorName,
          p2contratSupervisorPhone,
          p2erpName,
          p2erpPhone,
          p2rut,
          p2mutualidad,
          p2requestingArea,
          p2pepWorkCoordinator,
          p2specificWorkArea,
          p2date,
          p2startTime,
          p2finishTime,
          p2extensionShow,
          p2extension,
        })
        toast.success('El permiso de trabajo se actualizó correctamente')
        history.push(`/create/3/${newFormId}`)
      } catch (updateError) {
        toast.error(`Error: ${updateError}`)
      }
    }
  }

  return (
    <DashboardLayout>
      <Slide direction="right" in mountOnEnter unmountOnExit>
        <Grid sx={{ mt: 4 }} container justifyContent="center" spacing={2}>
          <Grid item xs={10} sm={9} md={5} lg={7}>
            <Typography
              sx={{ mb: 2 }}
              align="center"
              variant="h6"
              color="text.secondary"
              gutterBottom
            >
              ANTECEDENTES
            </Typography>

            {/* <Grid>
              <Webcam />
            </Grid> */}

            <TextField
              sx={{ mb: 2 }}
              fullWidth
              label="Nombre de la Empresa"
              variant="outlined"
              value={p2companyName}
              onChange={(e) => dispatch(setP2CompanyName(e.target.value))}
            />
            <TextField
              sx={{ mb: 2 }}
              fullWidth
              label="Nombre Supervisor Contratista"
              variant="outlined"
              value={p2contratSupervisorName}
              onChange={(e) => dispatch(setp2contratSupervisorName(e.target.value))}
            />
            <TextField
              sx={{ mb: 2 }}
              fullWidth
              label="Teléfono Supervisor Contratista"
              variant="outlined"
              value={p2contratSupervisorPhone}
              onChange={(e) => dispatch(setp2contratSupervisorPhone(e.target.value))}
            />
            <TextField
              sx={{ mb: 2 }}
              fullWidth
              label="Nombre ERP"
              variant="outlined"
              value={p2erpName}
              onChange={(e) => dispatch(setp2erpName(e.target.value))}
            />
            <TextField
              sx={{ mb: 2 }}
              fullWidth
              label="Teléfono ERP"
              variant="outlined"
              value={p2erpPhone}
              onChange={(e) => dispatch(setp2erpPhone(e.target.value))}
            />
            <TextField
              sx={{ mb: 2 }}
              fullWidth
              label="Rut (con o sin puntos o guión)"
              variant="outlined"
              color={p2rut && validate(p2rut) ? 'success' : 'warning'}
              value={p2rut}
              onChange={(e) => dispatch(setp2rut(e.target.value))}
            />
            <TextField
              sx={{ mb: 2 }}
              fullWidth
              label="Mutualidad"
              variant="outlined"
              value={p2mutualidad}
              onChange={(e) => dispatch(setp2mutualidad(e.target.value))}
            />

            <TextField
              sx={{ mb: 2 }}
              fullWidth
              label="Área que solicita el trabajo"
              variant="outlined"
              value={p2requestingArea}
              onChange={(e) => dispatch(setp2requestingArea(e.target.value))}
            />

            <TextField
              sx={{ mb: 2 }}
              fullWidth
              label="Coordinador de trabajo Pepsico"
              variant="outlined"
              value={p2pepWorkCoordinator}
              onChange={(e) => dispatch(setp2pepWorkCoordinator(e.target.value))}
            />
            <Grid container display="flex">
              <Grid item xs={12} md={6}>
                <TextField
                  sx={{ mb: 2 }}
                  fullWidth
                  label="Área de trabajo específica"
                  variant="outlined"
                  value={p2specificWorkArea}
                  onChange={(e) => dispatch(setp2specificWorkArea(e.target.value))}
                />
              </Grid>
              <Grid item xs={12} md={5} display="flex" alignItems="center" marginLeft={2}>
                { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(e) => {
                      if (e.target.files) {
                        const selectedFile = e?.target?.files[0]
                        console.table(selectedFile)
                        if (selectedFile && imageTypes.includes(selectedFile.type)) {
                          setFile(selectedFile)
                        } else {
                          toast.error('El archivo no es una imagen, por favor intente nuevamente')
                        }
                      }
                    }}
                  />
                  <Button
                    sx={{ bgcolor: '#fbc02d', mb: 2 }}
                    variant="contained"
                    component="span"
                    disabled={buttonDisabled}
                    onClick={() => setFileID('p2specificWorkAreaImage')}
                  >
                    {buttonDisabled
                      ? 'Subiendo imagen'
                      : 'Subir imagen'}
                  </Button>
                </label>
              </Grid>
            </Grid>
            <TextField
              sx={{ mb: 2 }}
              fullWidth
              label="Fecha"
              variant="outlined"
              value={p2date}
              disabled
            />
            <Grid display="flex">
              <Box sx={{ mb: 2 }}>
                <TimePicker
                  label="Hora inicial"
                  value={p2startTime}
                  onChange={(newValue) => {
                    if (newValue) {
                      setp2startTime(newValue)
                    }
                  }}
                  // eslint-disable-next-line
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              <Box sx={{ ml: 1, mb: 2 }}>
                <TimePicker
                  label="Hora final"
                  value={p2finishTime}
                  onChange={(newValue) => {
                    if (newValue) {
                      setp2finishTime(newValue)
                    }
                  }}
                  // eslint-disable-next-line
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </Grid>
            <Box
              sx={{
                display: 'block',
                alignItems: 'center',
                mb: 10,
                '& > :not(style)': { m: 1 },
              }}
            >
              <FormControlLabel
                control={(
                  <YellowSwitch
                    checked={p2extensionShow}
                    onChange={(event) => dispatch(setp2extensionShow(event.target.checked))}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                )}
                label={p2extensionShow ? 'Extension aplica' : 'Extension no aplica'}
              />
              <Box
                component="div"
                sx={{
                  display: p2extensionShow ? 'flex' : 'none',
                }}
              >
                <TextField
                  disabled={!p2extensionShow}
                  // sx={{ mb: 4 }}
                  fullWidth
                  label="Extension"
                  variant="outlined"
                  value={p2extension}
                  onChange={(e) => dispatch(setp2extension(e.target.value))}
                />
              </Box>
            </Box>
            <Grid sx={{ mb: 2 }} container justifyContent="space-between">
              <Grid
                xs={12}
                md={5}
                sx={{ mb: 2 }}
              >
                <ColorButton
                  fullWidth
                  startIcon={<ArrowLeftIcon />}
                  onClick={goToCreateStep2}
                >
                  Ir al inicio
                </ColorButton>
              </Grid>
              <Grid
                xs={12}
                md={5}
              >
                <ColorButton
                  fullWidth
                  endIcon={<ArrowRightIcon />}
                  onClick={goToCreateStep3}
                >
                  Ir a la página siguiente
                </ColorButton>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </DashboardLayout>
  )
}

export default CreateStep2