import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Por favor ingrese un email válido')
    .max(255)
    .required('Por favor ingrese un email'),
  password: Yup.string()
    .min(6, 'La contraseña debe tener al menos 6 caracteres')
    .max(255)
    .required('Por favor ingrese una contraseña'),
})

export default validationSchema