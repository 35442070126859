import React from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Slide from '@mui/material/Slide'
import { useHistory } from 'react-router-dom'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import Button from '@mui/material/Button'
import { toast } from 'react-toastify'
import { styled } from '@mui/material/styles'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { entireStore } from '../../app/store'
import { allFormActions } from '../../features/form/formSlice'
import DashboardLayout from '../layouts/DashboardLayout'
import ColorButton from './ColorButton'
import useNewFormId from '../../hooks/useNewFormId'
import useStorage from '../../hooks/useStorage'
import { db, doc, updateDoc } from '../../config/firebaseConfig'

const CreateStep14 = () => {
  const imageTypes = ['image/png', 'image/jpeg', 'image/jpg']

  const [file, setFile] = React.useState<File | null>(null)
  const [fileID, setFileID] = React.useState<String | null>(null)
  const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(false)

  const { newFormId } = useNewFormId()

  const { url, progress, error } = useStorage(file)

  const customIdFileUploadFinish = 'custom-id-file-upload-finish'
  React.useEffect(() => {
    if (progress > 1) {
      setButtonDisabled(true)
    }
    if (progress === 100) {
      setButtonDisabled(false)
      toast.success('La imagen ha sido subida correctamente', {
        toastId: customIdFileUploadFinish,
      })
      console.log('fileid', fileID)

      localStorage.setItem(`${fileID}`, url)
      console.log(url)
    }
    if (error) {
      setButtonDisabled(false)
      toast.error(error)
    }
  }, [progress, url, file])

  const {
    p14securityGlass,
    p14securityShoes,
    p14earProtector,
    p14securityHeadProtection,
    p14reflectJacket,
    p14uvFilterProtection,
    p14antSuit,
    p14bootsResistant,
    p14faceShield,
    p14soldarMask,
    p14soldarSuit,
    p14leatherColeto,
    p14leatherPolaina,
    p14fourHookHarness,
    p14doubleLitail,
    p14impactAbsorb,
    p14escaladaMosqueton,
    p14filterMask,
    p14Others,
  } = useAppSelector(entireStore)
  const {
    setp14securityGlass,
    setp14securityShoes,
    setp14earProtector,
    setp14securityHeadProtection,
    setp14reflectJacket,
    setp14uvFilterProtection,
    setp14antSuit,
    setp14bootsResistant,
    setp14faceShield,
    setp14soldarMask,
    setp14soldarSuit,
    setp14leatherColeto,
    setp14leatherPolaina,
    setp14fourHookHarness,
    setp14doubleLitail,
    setp14impactAbsorb,
    setp14escaladaMosqueton,
    setp14filterMask,
    setp14Others,
  } = allFormActions
  const dispatch = useAppDispatch()

  const history = useHistory()

  const goToCreateStep13 = () => {
    history.push(`/create/13/${newFormId}`)
  }

  const goToCreateStep15 = async () => {
    if ((p14securityGlass !== 'NA')
      || (p14securityShoes !== 'NA')
      || (p14earProtector !== 'NA')
      || (p14securityHeadProtection !== 'NA')
      || (p14reflectJacket !== 'NA')
      || (p14uvFilterProtection !== 'NA')
      || (p14antSuit !== 'NA')
      || (p14bootsResistant !== 'NA')
      || (p14faceShield !== 'NA')
      || (p14soldarMask !== 'NA')
      || (p14soldarSuit !== 'NA')
      || (p14leatherColeto !== 'NA')
      || (p14leatherPolaina !== 'NA')
      || (p14fourHookHarness !== 'NA')
      || (p14doubleLitail !== 'NA')
      || (p14impactAbsorb !== 'NA')
      || (p14escaladaMosqueton !== 'NA')
      || (p14filterMask !== 'NA')) {
      const formRef = doc(db, 'forms', newFormId as string)
      try {
        await updateDoc(formRef, {
          newFormp14FilledOut: true,
          newFormp14FilledOutDate: new Date(),
          p14securityGlass,
          p14securityShoes,
          p14earProtector,
          p14securityHeadProtection,
          p14reflectJacket,
          p14uvFilterProtection,
          p14antSuit,
          p14bootsResistant,
          p14faceShield,
          p14soldarMask,
          p14soldarSuit,
          p14leatherColeto,
          p14leatherPolaina,
          p14fourHookHarness,
          p14doubleLitail,
          p14impactAbsorb,
          p14escaladaMosqueton,
          p14filterMask,
          p14Others,
        })
        toast.success('El permiso de trabajo se actualizó correctamente')
        history.push(`/create/15/${newFormId}`)
      } catch (updateError) {
        toast.error(`Error: ${updateError}`)
      }
    } else {
      toast.error('Por favor seleccione al menos un equipo de protección personal')
    }
  }

  const Input = styled('input')({
    display: 'none',
  })

  return (
    <DashboardLayout>
      <Slide direction="right" in mountOnEnter unmountOnExit>
        <Grid sx={{ mt: 4 }} container justifyContent="center" spacing={2}>
          <Grid item xs={10} sm={9} md={5} lg={7}>
            <Typography
              sx={{ mb: 2 }}
              align="center"
              variant="h6"
              color="text.secondary"
              gutterBottom
            >
              EQUIPOS DE PROTECCIÓN PERSONAL
            </Typography>

            <Box sx={{
              display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
            }}
            >
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Lentes de seguridad</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="atrapamiento"
                  name="atrapamiento"
                  value={p14securityGlass}
                  onChange={(event) => dispatch(setp14securityGlass(event.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Zapatos de Seguridad</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="aplastamiento"
                  name="aplastamiento"
                  value={p14securityShoes}
                  onChange={(event) => dispatch(setp14securityShoes(event.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Protector Auditivo (Fonos)</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="golpeadorpor"
                  name="golpeadorpor"
                  value={p14earProtector}
                  onChange={(event) => dispatch(setp14earProtector(event.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Casco de Seguridad</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="golpeadorpor"
                  name="golpeadorpor"
                  value={p14securityHeadProtection}
                  onChange={(event) => dispatch(setp14securityHeadProtection(event.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Chaleco Reflectante</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="golpeadorpor"
                  name="golpeadorpor"
                  value={p14reflectJacket}
                  onChange={(event) => dispatch(setp14reflectJacket(event.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Proteccion filtro UV</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="golpeadorpor"
                  name="golpeadorpor"
                  value={p14uvFilterProtection}
                  onChange={(event) => dispatch(setp14uvFilterProtection(event.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Traje Antiácido</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="golpeadorpor"
                  name="golpeadorpor"
                  value={p14antSuit}
                  onChange={(event) => dispatch(setp14antSuit(event.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Botas Seguridad resistente al agua</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="golpeadorpor"
                  name="golpeadorpor"
                  value={p14bootsResistant}
                  onChange={(event) => dispatch(setp14bootsResistant(event.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Careta Facial</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="golpeadorpor"
                  name="golpeadorpor"
                  value={p14faceShield}
                  onChange={(event) => dispatch(setp14faceShield(event.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Máscara de Soldar</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="golpeadorpor"
                  name="golpeadorpor"
                  value={p14soldarMask}
                  onChange={(event) => dispatch(setp14soldarMask(event.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Traje de Soldador</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="golpeadorpor"
                  name="golpeadorpor"
                  value={p14soldarSuit}
                  onChange={(event) => dispatch(setp14soldarSuit(event.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Coleto de Cuero</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="golpeadorpor"
                  name="golpeadorpor"
                  value={p14leatherColeto}
                  onChange={(event) => dispatch(setp14leatherColeto(event.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Polainas de Cuero</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="golpeadorpor"
                  name="golpeadorpor"
                  value={p14leatherPolaina}
                  onChange={(event) => dispatch(setp14leatherPolaina(event.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Árnes de Seguridad con cuatro argollas</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="golpeadorpor"
                  name="golpeadorpor"
                  value={p14fourHookHarness}
                  onChange={(event) => dispatch(setp14fourHookHarness(event.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>

              { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={(e) => {
                    if (e.target.files) {
                      const selectedFile = e?.target?.files[0]
                      console.table(selectedFile)
                      if (selectedFile && imageTypes.includes(selectedFile.type)) {
                        setFile(selectedFile)
                      } else {
                        toast.error('El archivo no es una imagen, por favor intente nuevamente')
                      }
                    }
                  }}
                />
                <Button
                  sx={{ bgcolor: '#fbc02d', mb: 2 }}
                  variant="contained"
                  component="span"
                  disabled={buttonDisabled}
                  onClick={() => setFileID('p14fourHookHarnessImage')}
                >
                  {buttonDisabled
                    ? 'Subiendo imagen'
                    : 'Subir imagen'}
                </Button>
              </label>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Cola de vida (doble)</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="golpeadorpor"
                  name="golpeadorpor"
                  value={p14doubleLitail}
                  onChange={(event) => dispatch(setp14doubleLitail(event.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>

              { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={(e) => {
                    if (e.target.files) {
                      const selectedFile = e?.target?.files[0]
                      console.table(selectedFile)
                      if (selectedFile && imageTypes.includes(selectedFile.type)) {
                        setFile(selectedFile)
                      } else {
                        toast.error('El archivo no es una imagen, por favor intente nuevamente')
                      }
                    }
                  }}
                />
                <Button
                  sx={{ bgcolor: '#fbc02d', mb: 2 }}
                  variant="contained"
                  component="span"
                  disabled={buttonDisabled}
                  onClick={() => setFileID('p14doubleLitailImage')}
                >
                  {buttonDisabled
                    ? 'Subiendo imagen'
                    : 'Subir imagen'}
                </Button>
              </label>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Absorbedor de impacto</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="golpeadorpor"
                  name="golpeadorpor"
                  value={p14impactAbsorb}
                  onChange={(event) => dispatch(setp14impactAbsorb(event.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>

              { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={(e) => {
                    if (e.target.files) {
                      const selectedFile = e?.target?.files[0]
                      console.table(selectedFile)
                      if (selectedFile && imageTypes.includes(selectedFile.type)) {
                        setFile(selectedFile)
                      } else {
                        toast.error('El archivo no es una imagen, por favor intente nuevamente')
                      }
                    }
                  }}
                />
                <Button
                  sx={{ bgcolor: '#fbc02d', mb: 2 }}
                  variant="contained"
                  component="span"
                  disabled={buttonDisabled}
                  onClick={() => setFileID('p14impactAbsorbImage')}
                >
                  {buttonDisabled
                    ? 'Subiendo imagen'
                    : 'Subir imagen'}
                </Button>
              </label>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Mosquetón de escalada</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="golpeadorpor"
                  name="golpeadorpor"
                  value={p14escaladaMosqueton}
                  onChange={(event) => dispatch(setp14escaladaMosqueton(event.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>

              { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={(e) => {
                    if (e.target.files) {
                      const selectedFile = e?.target?.files[0]
                      console.table(selectedFile)
                      if (selectedFile && imageTypes.includes(selectedFile.type)) {
                        setFile(selectedFile)
                      } else {
                        toast.error('El archivo no es una imagen, por favor intente nuevamente')
                      }
                    }
                  }}
                />
                <Button
                  sx={{ bgcolor: '#fbc02d', mb: 2 }}
                  variant="contained"
                  component="span"
                  disabled={buttonDisabled}
                  onClick={() => setFileID('p14escaladaMosquetonImage')}
                >
                  {buttonDisabled
                    ? 'Subiendo imagen'
                    : 'Subir imagen'}
                </Button>
              </label>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                <FormLabel component="legend">Mascarilla con Filtro</FormLabel>
                <RadioGroup
                  sx={{ flex: '1', flexDirection: 'row' }}
                  aria-label="golpeadorpor"
                  name="golpeadorpor"
                  value={p14filterMask}
                  onChange={(event) => dispatch(setp14filterMask(event.target.value))}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="SI" />
                  <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Box>

            <TextField
              sx={{ mb: 10 }}
              fullWidth
              label="Otros : "
              variant="outlined"
              value={p14Others}
              onChange={(e) => dispatch(setp14Others(e.target.value))}
            />
            <Grid sx={{ mb: 2 }} container justifyContent="space-between">
              <Grid
                item
                xs={12}
                md={5}
                sx={{ mb: 2 }}
              >
                <ColorButton
                  fullWidth
                  startIcon={<ArrowLeftIcon />}
                  onClick={goToCreateStep13}
                >
                  Ir a la página anterior
                </ColorButton>
              </Grid>

              <Grid
                item
                xs={12}
                md={5}
              >
                <ColorButton
                  fullWidth
                  endIcon={<ArrowRightIcon />}
                  onClick={goToCreateStep15}
                >
                  Ir a la página siguiente
                </ColorButton>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </DashboardLayout>
  )
}

export default CreateStep14