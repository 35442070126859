import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'
import ResetPassword from '../components/auth/ResetPassword'
import Signin from '../components/auth/SigninScreen'
import CreateStep1 from '../components/createForm/CreateStep1'
import CreateStep2 from '../components/createForm/CreateStep2'
import CreateStep3 from '../components/createForm/CreateStep3'
import CreateStep4 from '../components/createForm/CreateStep4'
import CreateStep5 from '../components/createForm/CreateStep5'
import CreateStep6 from '../components/createForm/CreateStep6'
import CreateStep7 from '../components/createForm/CreateStep7'
import CreateStep8 from '../components/createForm/CreateStep8'
import CreateStep9 from '../components/createForm/CreateStep9'
import CreateStep10 from '../components/createForm/CreateStep10'
import CreateStep11 from '../components/createForm/CreateStep11'
import CreateStep12 from '../components/createForm/CreateStep12'
import CreateStep13 from '../components/createForm/CreateStep13'
import CreateStep14 from '../components/createForm/CreateStep14'
import CreateStep15 from '../components/createForm/CreateStep15'
import CreateStep16 from '../components/createForm/CreateStep16'
import StartPage from '../main/StartPage'
import NewFormId from '../components/createForm/NewFormId'
import ParamedicStep1 from '../components/reviserForm/ParamedicStep1'
import Created from '../components/list/Created'
import ReviseStep1 from '../components/reviserForm/ReviseStep1'
import ReviseStep2 from '../components/reviserForm/ReviseStep2'
import ReviseStep3 from '../components/reviserForm/ReviseStep3'
import ReviseStep4 from '../components/reviserForm/ReviseStep4'
import ReviseStep5 from '../components/reviserForm/ReviseStep5'
import ReviseStep6 from '../components/reviserForm/ReviseStep6'
import ReviseStep7 from '../components/reviserForm/ReviseStep7'
import ReviseStep8 from '../components/reviserForm/ReviseStep8'
import ReviseStep9 from '../components/reviserForm/ReviseStep9'
import ReviseStep10 from '../components/reviserForm/ReviseStep10'
import ReviseStep11 from '../components/reviserForm/ReviseStep11'
import ReviseStep12 from '../components/reviserForm/ReviseStep12'
import ReviseStep13 from '../components/reviserForm/ReviseStep13'
import ReviseStep14 from '../components/reviserForm/ReviseStep14'
import ReviseStep15 from '../components/reviserForm/ReviseStep15'

const Routes = () => (

  // /revision/tsu/1/12345
  // /revision/pep/1/12345
  // /revision/aco/1/12345
  // /revision/qt/1/12345
  // /revision/ta/1/12345
  // /revision/ps/1/12345
  // /revision/ep/1/12345
  // /revision/sass/1/12345

  <Router>
    <Switch>
      <Route path="/revision/:area/15/:id">
        <ReviseStep15 />
      </Route>
      <Route path="/revision/:area/14/:id">
        <ReviseStep14 />
      </Route>
      <Route path="/revision/:area/13/:id">
        <ReviseStep13 />
      </Route>
      <Route path="/revision/:area/12/:id">
        <ReviseStep12 />
      </Route>
      <Route path="/revision/:area/11/:id">
        <ReviseStep11 />
      </Route>
      <Route path="/revision/:area/10/:id">
        <ReviseStep10 />
      </Route>
      <Route path="/revision/:area/9/:id">
        <ReviseStep9 />
      </Route>
      <Route path="/revision/:area/8/:id">
        <ReviseStep8 />
      </Route>
      <Route path="/revision/:area/7/:id">
        <ReviseStep7 />
      </Route>
      <Route path="/revision/:area/6/:id">
        <ReviseStep6 />
      </Route>
      <Route path="/revision/:area/5/:id">
        <ReviseStep5 />
      </Route>
      <Route path="/revision/:area/4/:id">
        <ReviseStep4 />
      </Route>
      <Route path="/revision/:area/3/:id">
        <ReviseStep3 />
      </Route>
      <Route path="/revision/:area/2/:id">
        <ReviseStep2 />
      </Route>
      <Route path="/revision/:area/1/:id">
        <ReviseStep1 />
      </Route>
      <Route path="/paramedic/:id">
        <ParamedicStep1 />
      </Route>
      <Route path="/create/16/:id">
        <CreateStep16 />
      </Route>
      <Route path="/create/15/:id">
        <CreateStep15 />
      </Route>
      <Route path="/create/14/:id">
        <CreateStep14 />
      </Route>
      <Route path="/create/13/:id">
        <CreateStep13 />
      </Route>
      <Route path="/create/12/:id">
        <CreateStep12 />
      </Route>
      <Route path="/create/11/:id">
        <CreateStep11 />
      </Route>
      <Route path="/create/10/:id">
        <CreateStep10 />
      </Route>
      <Route path="/create/9/:id">
        <CreateStep9 />
      </Route>
      <Route path="/create/8/:id">
        <CreateStep8 />
      </Route>
      <Route path="/create/7/:id">
        <CreateStep7 />
      </Route>
      <Route path="/create/6/:id">
        <CreateStep6 />
      </Route>
      <Route path="/create/5/:id">
        <CreateStep5 />
      </Route>
      <Route path="/create/4/:id">
        <CreateStep4 />
      </Route>
      <Route path="/create/3/:id">
        <CreateStep3 />
      </Route>
      <Route path="/create/2/:id">
        <CreateStep2 />
      </Route>
      <Route path="/create/1/:id">
        <CreateStep1 />
      </Route>
      <Route exact path="/created/">
        <Created />
      </Route>
      <Route path="/create/">
        <NewFormId />
      </Route>
      <Route path="/resetpassword">
        <ResetPassword />
      </Route>
      <Route path="/start">
        <StartPage />
      </Route>
      <Route path="/">
        <Signin />
      </Route>
    </Switch>
  </Router>
)

export default Routes