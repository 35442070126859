import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import TextField from '@mui/material/TextField'
import Slide from '@mui/material/Slide'
import TimePicker from '@mui/lab/TimePicker'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import YellowSwitch from '../../common/YellowSwitch'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { entireStore } from '../../app/store'
import { allFormActions } from '../../features/form/formSlice'
import DashboardLayout from '../layouts/DashboardLayout'
import ColorButton from './ColorButton'
import useNewFormId from '../../hooks/useNewFormId'
import { db, doc, updateDoc } from '../../config/firebaseConfig'

const CreateStep12 = () => {
  console.log('CreateStep12')

  const { newFormId } = useNewFormId()

  const {
    p12show,
    p12evaluatorName,
    p12evaluationTime,
    p12areaClear,
    p12materialsWithinTheZone,
    p12dustGeneration,
    p12foodIn,
    p12hasBmp,
  } = useAppSelector(entireStore)
  const {
    setp12show,
    setp12evaluatorName,
    setp12evaluationTime,
    setp12areaClear,
    setp12materialsWithinTheZone,
    setp12dustGeneration,
    setp12foodIn,
    setp12hasBmp,
  } = allFormActions
  const dispatch = useAppDispatch()

  const history = useHistory()

  const goToCreateStep11 = () => {
    history.push(`/create/11/${newFormId}`)
  }

  const goToCreateStep13 = async () => {
    if (p12show && !p12evaluatorName) {
      toast.error('Por favor ingrese el nombre del evaluador')
    } else if (p12show && !p12evaluationTime) {
      toast.error('Por favor ingrese Control en terreno (hora)')
    } else {
      const formRef = doc(db, 'forms', newFormId as string)
      try {
        await updateDoc(formRef, {
          newFormp12FilledOut: true,
          newFormp12FilledOutDate: new Date(),
          p12show,
          p12evaluatorName,
          p12evaluationTime,
          p12areaClear,
          p12materialsWithinTheZone,
          p12dustGeneration,
          p12foodIn,
          p12hasBmp,
        })
        toast.success('El permiso de trabajo se actualizó correctamente')
        history.push(`/create/13/${newFormId}`)
      } catch (updateError) {
        toast.error(`Error: ${updateError}`)
      }
    }
  }

  return (
    <DashboardLayout>
      <Slide direction="right" in mountOnEnter unmountOnExit>
        <Grid sx={{ mt: 4 }} container justifyContent="center" spacing={2}>
          <Grid item xs={10} sm={9} md={5} lg={7}>
            <Typography
              sx={{ mb: 2 }}
              align="center"
              variant="h6"
              color="text.secondary"
              gutterBottom
            >
              BUENAS PRÁCTICAS DE MANUFACTURA
            </Typography>

            <Grid display="flex" justifyContent="center" item xs={12}>
              <FormControlLabel
                control={(
                  <YellowSwitch
                    checked={p12show}
                    onChange={(event) => dispatch(setp12show(event.target.checked))}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                )}
                label={p12show ? 'Esta página aplica' : 'Esta página no aplica'}
              />
            </Grid>

            <Box
              component="div"
              sx={{
                display: p12show ? 'block' : 'none',
              }}
            >
              <Grid display="flex" justifyContent="center" item xs={12}>
                <TextField
                  sx={{ mb: 2, width: 300 }}
                  fullWidth
                  label="Evaluado por : "
                  variant="outlined"
                  value={p12evaluatorName}
                  onChange={(e) => dispatch(setp12evaluatorName(e.target.value))}
                />
              </Grid>
              <Grid display="flex" justifyContent="center">
                <TimePicker
                  label="Control en terreno (hora) : "
                  value={p12evaluationTime}
                  onChange={(newValue) => {
                    dispatch(setp12evaluationTime(newValue))
                  }}
                  // eslint-disable-next-line
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>

              <Box sx={{
                display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
              }}
              >
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">Aislamiento total del área</FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="atrapamiento"
                    name="atrapamiento"
                    value={p12areaClear}
                    onChange={(event) => dispatch(setp12areaClear(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">Materiales dentro de la zona aislada</FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="aplastamiento"
                    name="aplastamiento"
                    value={p12materialsWithinTheZone}
                    onChange={(event) => dispatch(setp12materialsWithinTheZone(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">Generación de polvos o residuos</FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="golpeadorpor"
                    name="golpeadorpor"
                    value={p12dustGeneration}
                    onChange={(event) => dispatch(setp12dustGeneration(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">Ingreso de bebidas o alimentos</FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="golpeadocon"
                    name="golpeadocon"
                    value={p12foodIn}
                    onChange={(event) => dispatch(setp12foodIn(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 10 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">
                    Cumple BMP
                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="caida"
                    name="caida"
                    value={p12hasBmp}
                    onChange={(event) => dispatch(setp12hasBmp(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
            <Grid sx={{ mb: 2 }} container justifyContent="space-between">
              <Grid
                xs={12}
                md={5}
                sx={{ mb: 2 }}
              >
                <ColorButton
                  fullWidth
                  startIcon={<ArrowLeftIcon />}
                  onClick={goToCreateStep11}
                >
                  Ir a la página anterior
                </ColorButton>
              </Grid>
              <Grid
                xs={12}
                md={5}
              >
                <ColorButton
                  fullWidth
                  endIcon={<ArrowRightIcon />}
                  onClick={goToCreateStep13}
                >
                  Ir a la página siguiente
                </ColorButton>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </DashboardLayout>
  )
}

export default CreateStep12