const checkAllApproved = (obj: any) => {
  const fields = [
    'p16workSupervisorApproved1', // 1
    'p16workSupervisorApproved2',
    'p16workSupervisorApproved3',
    'p16workSupervisorApproved4',
    'p16workSupervisorApproved5',
    'p16workSupervisorApproved6',
    'p16workSupervisorApproved7',
    'p16workSupervisorApproved8',
    'p16workSupervisorApproved9',
    'p16workSupervisorApproved10',
    'p16workSupervisorApproved11',
    'p16workSupervisorApproved12',
    'p16workSupervisorApproved13',
    'p16workSupervisorApproved14',
    'p16workSupervisorApproved15',

    'p16pepCoordinatorApproved1', // 2
    'p16pepCoordinatorApproved2',
    'p16pepCoordinatorApproved3',
    'p16pepCoordinatorApproved4',
    'p16pepCoordinatorApproved5',
    'p16pepCoordinatorApproved6',
    'p16pepCoordinatorApproved7',
    'p16pepCoordinatorApproved8',
    'p16pepCoordinatorApproved9',
    'p16pepCoordinatorApproved10',
    'p16pepCoordinatorApproved11',
    'p16pepCoordinatorApproved12',
    'p16pepCoordinatorApproved13',
    'p16pepCoordinatorApproved14',
    'p16pepCoordinatorApproved15',

    'p16areaCoordinatorApproved1', // 3
    'p16areaCoordinatorApproved2',
    'p16areaCoordinatorApproved3',
    'p16areaCoordinatorApproved4',
    'p16areaCoordinatorApproved5',
    'p16areaCoordinatorApproved6',
    'p16areaCoordinatorApproved7',
    'p16areaCoordinatorApproved8',
    'p16areaCoordinatorApproved9',
    'p16areaCoordinatorApproved10',
    'p16areaCoordinatorApproved11',
    'p16areaCoordinatorApproved12',
    'p16areaCoordinatorApproved13',
    'p16areaCoordinatorApproved14',
    'p16areaCoordinatorApproved15',

    'p16qualityApproved1', // 4
    'p16qualityApproved2',
    'p16qualityApproved3',
    'p16qualityApproved4',
    'p16qualityApproved5',
    'p16qualityApproved6',
    'p16qualityApproved7',
    'p16qualityApproved8',
    'p16qualityApproved9',
    'p16qualityApproved10',
    'p16qualityApproved11',
    'p16qualityApproved12',
    'p16qualityApproved13',
    'p16qualityApproved14',
    'p16qualityApproved15',

    'p16technicalAreaApproved1', // 5
    'p16technicalAreaApproved2',
    'p16technicalAreaApproved3',
    'p16technicalAreaApproved4',
    'p16technicalAreaApproved5',
    'p16technicalAreaApproved6',
    'p16technicalAreaApproved7',
    'p16technicalAreaApproved8',
    'p16technicalAreaApproved9',
    'p16technicalAreaApproved10',
    'p16technicalAreaApproved11',
    'p16technicalAreaApproved12',
    'p16technicalAreaApproved13',
    'p16technicalAreaApproved14',
    'p16technicalAreaApproved15',

    'p16patrimonialSecurityApproved1', // 6
    'p16patrimonialSecurityApproved2',
    'p16patrimonialSecurityApproved3',
    'p16patrimonialSecurityApproved4',
    'p16patrimonialSecurityApproved5',
    'p16patrimonialSecurityApproved6',
    'p16patrimonialSecurityApproved7',
    'p16patrimonialSecurityApproved8',
    'p16patrimonialSecurityApproved9',
    'p16patrimonialSecurityApproved10',
    'p16patrimonialSecurityApproved11',
    'p16patrimonialSecurityApproved12',
    'p16patrimonialSecurityApproved13',
    'p16patrimonialSecurityApproved14',
    'p16patrimonialSecurityApproved15',

    'p16externalPrevencionistApproved1', // 7
    'p16externalPrevencionistApproved2',
    'p16externalPrevencionistApproved3',
    'p16externalPrevencionistApproved4',
    'p16externalPrevencionistApproved5',
    'p16externalPrevencionistApproved6',
    'p16externalPrevencionistApproved7',
    'p16externalPrevencionistApproved8',
    'p16externalPrevencionistApproved9',
    'p16externalPrevencionistApproved10',
    'p16externalPrevencionistApproved11',
    'p16externalPrevencionistApproved12',
    'p16externalPrevencionistApproved13',
    'p16externalPrevencionistApproved14',
    'p16externalPrevencionistApproved15',

    'p16sassApproved1', // 8
    'p16sassApproved2',
    'p16sassApproved3',
    'p16sassApproved4',
    'p16sassApproved5',
    'p16sassApproved6',
    'p16sassApproved7',
    'p16sassApproved8',
    'p16sassApproved9',
    'p16sassApproved10',
    'p16sassApproved11',
    'p16sassApproved12',
    'p16sassApproved13',
    'p16sassApproved14',
    'p16sassApproved15',

  ]

  const { entriesAdded } = obj || []

  const diffArray = fields.filter((f) => {
    let res = false
    for (let j = 0; j < entriesAdded.length; j += 1) {
      if (f.includes(entriesAdded[j])) {
        res = true
      }
    }

    return res
  })

  console.log('diffArray: ', diffArray)

  console.log('entriesAdded : ', entriesAdded)

  let allIsApproved = false
  console.log('diffArray.length===>: ', diffArray.length)
  for (let i = 0; i < diffArray.length; i += 1) {
    console.log('i===>: ', i)

    if (obj[diffArray[i]] === false || obj[diffArray[i]] === null) {
      console.log('why did this line run?: with: ', diffArray[i])
      allIsApproved = false
      break
    }

    if (i === diffArray.length - 1) {
      console.log('Now we at the last element==> set true')
      allIsApproved = true
    }
  }
  return allIsApproved
}

export default checkAllApproved