import React from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Slide from '@mui/material/Slide'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import InfoIcon from '@mui/icons-material/Info'
import YellowSwitch from '../../common/YellowSwitch'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { entireStore } from '../../app/store'
import { allFormActions } from '../../features/form/formSlice'
import DashboardLayout from '../layouts/DashboardLayout'
import ColorButton from './ColorButton'
import useNewFormId from '../../hooks/useNewFormId'
import { db, doc, updateDoc } from '../../config/firebaseConfig'

const CreateStep7 = () => {
  console.log('CreateStep7')

  const { newFormId } = useNewFormId()

  const {
    p7Show,
    p7keyNumber,
    p7measure1o2,
    p7measure1h2s,
    p7measure1co,
    p7measure2o2,
    p7measure2h2s,
    p7measure2co,
    p7gasAn,
    p7deviceOfVentForced,
    p7deviceOfAutonBreathing,
    p7comRadio,
    p7landTempInPlace,
    p7equipmentOfAdLight,
    p7requireRescueDevice,
    p7acceptableWeatherCond,
  } = useAppSelector(entireStore)
  const {
    setp7Show,
    setp7keyNumber,
    setp7measure1o2,
    setp7measure1h2s,
    setp7measure1co,
    setp7measure2o2,
    setp7measure2h2s,
    setp7measure2co,
    setp7gasAn,
    setp7deviceOfVentForced,
    setp7deviceOfAutonBreathing,
    setp7comRadio,
    setp7landTempInPlace,
    setp7equipmentOfAdLight,
    setp7requireRescueDevice,
    setp7acceptableWeatherCond,
  } = allFormActions
  const dispatch = useAppDispatch()

  const history = useHistory()

  const goToCreateStep6 = () => {
    history.push(`/create/6/${newFormId}`)
  }

  const goToCreateStep8 = async () => {
    if (p7Show && !p7keyNumber) {
      toast.error('Por favor ingrese la Llave de acceso número')
    } else if (p7Show && !p7measure1o2) {
      toast.error('Por favor ingrese O2 en Medición 1')
    } else if (p7Show && !p7measure1h2s) {
      toast.error('Por favor ingrese H2S en Medición 1')
    } else if (p7Show && !p7measure1co) {
      toast.error('Por favor ingrese CO en Medición 1')
    } else if (p7Show && !p7measure2o2) {
      toast.error('Por favor ingrese O2 en Medición 2')
    } else if (p7Show && !p7measure2h2s) {
      toast.error('Por favor ingrese H2S en Medición 2')
    } else if (p7Show && !p7measure2co) {
      toast.error('Por favor ingrese CO en Medición 2')
    } else {
      // history.push(`/create/8/${newFormId}`)
      const formRef = doc(db, 'forms', newFormId as string)
      try {
        await updateDoc(formRef, {
          newFormp7FilledOut: true,
          newFormp7FilledOutDate: new Date(),
          p7Show,
          p7keyNumber,
          p7measure1o2,
          p7measure1h2s,
          p7measure1co,
          p7measure2o2,
          p7measure2h2s,
          p7measure2co,
          p7gasAn,
          p7deviceOfVentForced,
          p7deviceOfAutonBreathing,
          p7comRadio,
          p7landTempInPlace,
          p7equipmentOfAdLight,
          p7requireRescueDevice,
          p7acceptableWeatherCond,
        })
        toast.success('El permiso de trabajo se actualizó correctamente')
        history.push(`/create/8/${newFormId}`)
      } catch (updateError) {
        toast.error(`Error: ${updateError}`)
      }
    }
  }

  return (
    <DashboardLayout>
      <Slide direction="right" in mountOnEnter unmountOnExit>
        <Grid sx={{ mt: 4 }} container justifyContent="center" spacing={2}>
          <Grid item xs={10} sm={9} md={5} lg={7}>
            <Typography
              sx={{ mb: 2 }}
              align="center"
              variant="h6"
              color="text.secondary"
              gutterBottom
            >
              ESPACIOS CONFINADOS
            </Typography>

            <Grid display="flex" justifyContent="center" item xs={12}>
              <FormControlLabel
                control={(
                  <YellowSwitch
                    checked={p7Show}
                    onChange={(event) => dispatch(setp7Show(event.target.checked))}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                )}
                label={p7Show ? 'Esta página aplica' : 'Esta página no aplica'}
              />
            </Grid>

            <Grid
              container
              display="flex"
              justifyContent="center"
              sx={{ mt: 2, mb: 2 }}

            >
              <Grid
                item
                display="flex"
                xs={9}
                md={7}
              >
                <InfoIcon sx={{ color: '#fbc02d' }} />
                <Typography align="center" sx={{ ml: 1 }}>
                  Si selecciona Aplica,
                  se le enviará
                  un email de aprobación al Paramédico al guardar
                  el permiso de trabajo
                </Typography>
              </Grid>
            </Grid>

            <Box
              component="div"
              sx={{
                display: p7Show ? 'block' : 'none',
              }}
            >
              <TextField
                sx={{ mb: 2, mt: 2 }}
                fullWidth
                label="Llave de acceso número"
                variant="outlined"
                value={p7keyNumber}
                onChange={(e) => dispatch(setp7keyNumber(e.target.value))}
              />
              <Typography>Medición 1:</Typography>
              <Grid display="flex">
                <TextField
                  sx={{ mb: 2 }}
                  fullWidth
                  label="O2"
                  variant="outlined"
                  value={p7measure1o2}
                  onChange={(e) => dispatch(setp7measure1o2(e.target.value))}
                />
                <TextField
                  sx={{ mb: 2, ml: 1 }}
                  fullWidth
                  label="H2S"
                  variant="outlined"
                  value={p7measure1h2s}
                  onChange={(e) => dispatch(setp7measure1h2s(e.target.value))}
                />
                <TextField
                  sx={{ mb: 2, ml: 1 }}
                  fullWidth
                  label="CO"
                  variant="outlined"
                  value={p7measure1co}
                  onChange={(e) => dispatch(setp7measure1co(e.target.value))}
                />
              </Grid>
              <Typography>Medición 2:</Typography>
              <Grid display="flex">
                <TextField
                  sx={{ mb: 2 }}
                  fullWidth
                  label="O2"
                  variant="outlined"
                  value={p7measure2o2}
                  onChange={(e) => dispatch(setp7measure2o2(e.target.value))}
                />
                <TextField
                  sx={{ mb: 2, ml: 1 }}
                  fullWidth
                  label="H2S"
                  variant="outlined"
                  value={p7measure2h2s}
                  onChange={(e) => dispatch(setp7measure2h2s(e.target.value))}
                />
                <TextField
                  sx={{ mb: 2, ml: 1 }}
                  fullWidth
                  label="CO"
                  variant="outlined"
                  value={p7measure2co}
                  onChange={(e) => dispatch(setp7measure2co(e.target.value))}
                />
              </Grid>

              <Grid display="flex" justifyContent="center">
                <Typography>
                  Condiciones Aceptables Oxigeno 19,5 a 23,5%
                </Typography>
              </Grid>
              <Grid display="flex" justifyContent="center">
                <Typography>
                  CO al 0% , H2S al 0%
                </Typography>
              </Grid>
              <Grid display="flex" justifyContent="center">
                <Typography>
                  Temperatura máxima 35°C - LEL máximo 10%
                </Typography>
              </Grid>

              <Box sx={{
                display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
              }}
              >
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">
                    Mantiene analizador de gases?
                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="condicionesclimaticas"
                    name="condicionesclimaticas"
                    value={p7gasAn}
                    onChange={(event) => dispatch(setp7gasAn(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box sx={{
                display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
              }}
              >
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">
                    Dispositivos de ventilación forzada
                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="condicionesclimaticas"
                    name="condicionesclimaticas"
                    value={p7deviceOfVentForced}
                    onChange={(event) => dispatch(setp7deviceOfVentForced(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box sx={{
                display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
              }}
              >
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">
                    Equipos de respiración autónoma
                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="condicionesclimaticas"
                    name="condicionesclimaticas"
                    value={p7deviceOfAutonBreathing}
                    onChange={(event) => dispatch(setp7deviceOfAutonBreathing(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box sx={{
                display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
              }}
              >
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">
                    Radio de comunicación
                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="condicionesclimaticas"
                    name="condicionesclimaticas"
                    value={p7comRadio}
                    onChange={(event) => dispatch(setp7comRadio(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box sx={{
                display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
              }}
              >
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">
                    Puesta tierra temporal en el lugar
                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="condicionesclimaticas"
                    name="condicionesclimaticas"
                    value={p7landTempInPlace}
                    onChange={(event) => dispatch(setp7landTempInPlace(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box sx={{
                display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
              }}
              >
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">
                    Equipo de iluminación adicional
                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="condicionesclimaticas"
                    name="condicionesclimaticas"
                    value={p7equipmentOfAdLight}
                    onChange={(event) => dispatch(setp7equipmentOfAdLight(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box sx={{
                display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
              }}
              >
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">
                    Requiere equipo de rescate
                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="condicionesclimaticas"
                    name="condicionesclimaticas"
                    value={p7requireRescueDevice}
                    onChange={(event) => dispatch(setp7requireRescueDevice(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box sx={{
                display: 'flex', justifyContent: 'center', mt: 4, mb: 10,
              }}
              >
                <FormControl sx={{ display: 'flex', mb: 10 }} component="fieldset">
                  <FormLabel component="legend">
                    Condición climatica aceptable
                  </FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="condicionesclimaticas"
                    name="condicionesclimaticas"
                    value={p7acceptableWeatherCond}
                    onChange={(event) => dispatch(setp7acceptableWeatherCond(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
            <Grid sx={{ mb: 2 }} container justifyContent="space-between">
              <Grid
                xs={12}
                md={5}
                sx={{ mb: 2 }}
              >
                <ColorButton
                  fullWidth
                  startIcon={<ArrowLeftIcon />}
                  onClick={goToCreateStep6}
                >
                  Ir a la página anterior
                </ColorButton>
              </Grid>
              <Grid
                xs={12}
                md={5}
              >
                <ColorButton
                  fullWidth
                  endIcon={<ArrowRightIcon />}
                  onClick={goToCreateStep8}
                >
                  Ir a la página siguiente
                </ColorButton>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </DashboardLayout>
  )
}

export default CreateStep7