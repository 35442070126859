const workSupervisorList = [
  {
    name: '',
    email: '',
    uid: '',
  },
  {
    name: 'Alejandro Lillo',
    email: 'alejandro.lillo@pepsico.com',
    uid: 'I6njXcH9g8P67MHgVa8p8TzFX872',
  },
  {
    name: 'Alvaro Mora',
    email: 'alvaro.mora@pepsico.com',
    uid: 'G87yBlMYL7U4gggOwSVrB7cdW5Q2',
  },
  {
    name: 'Aran Rubilar',
    email: 'aran.rubilar@pepsico.com',
    uid: 'pjs6w7XIdmSS9MPLPAGkkqAAjJw1',
  },
  {
    name: 'Carlos Chavez',
    email: 'operacion@mingatec.com',
    uid: 'icLTRgAK85NyE4dN9ql0ctC0RMQ2',
  },
  {
    uid: 'yTf3DjPXFdc54t7HQ4bn6GmwEqi1',
    email: 'claudio.valencia@inox-man.cl',
    name: 'Claudio Valencia',
  },
  {
    uid: 'NCK5TuVH7BbCop1fWkxG0h8SFPT2',
    email: 'diego.caceres@innovacontrol.cl',
    name: 'Diego Caceres',
  },
  {
    uid: '06hyyRqLEjOj0d27aBP3TcpZ33K2',
    email: 'eubilla@climacor.cl',
    name: 'Eduardo Ubilla',
  },
  {
    uid: 'PEhTHGpi78bvCgulp4gz4qaqg3c2',
    email: 'fabiancifuentes64@gmail.com',
    name: 'Fabian Cifuentes',
  },
  {
    uid: 'sN4ANuuAK8QtlBWMPh7Kp6pdW602',
    email: 'fvargas@genesismi.cl',
    name: 'Fabian Vargas',
  },
  {
    uid: 'ScayJ2znSzbOdGKj5RIPPZhIg6n2',
    email: 'faravena@royalamerica.com',
    name: 'Felipe Aravena',
  },
  {
    uid: 'BLxFE1SaIMcxaQfXEmHR9lrOgoX2',
    email: 'felipe.cofre@inox-man.cl',
    name: 'Felipe Cofre',
  },
  {
    uid: 'AGNgFde5FvSNlFmhXskMlaHQaJx1',
    email: 'fchavezc@anam.cl',
    name: 'Fernando Chavez',
  },
  {
    name: 'Gabriel Patiño',
    email: 'patino-gabriel@aramark.cl',
    uid: 'nRtuOh9nv1fdTmHKNwGdKPFdxDK2',
  },
  {
    uid: 'zly6nfdG1KgMLrkXeQamyhuZAOe2',
    email: 'giovanni.quiroz@inox-man.cl',
    name: 'Giovani Quiros',
  },
  {
    name: 'Gonzalo Perez',
    email: 'gonzalo.perez1@pepsico.com',
    uid: '8AeaQ5m4RdWSojivuQO3m76o1YP2',
  },
  {
    name: 'Gustavo Bracamonte',
    email: 'gustavodioxomberg.bracamonteorellano@pepsico.com',
    uid: 'bmn18uwMnlYp1Ftl69xj0uG9Kje2',
  },
  {
    name: 'Gustavo Gomez',
    email: 'gustavo.gomez@ryggraficas.cl',
    uid: '0aEJwZTgowd1FYLsOjFMEJ8EwZm2',
  },
  {
    uid: 'jrcZ6nbDCwb4IxFWOnkODolf9VH2',
    email: 'hector.lastra@innovacontrol.cl',
    name: 'Hector Lastra',
  },
  {
    name: 'Hector Pedraza',
    email: 'hector.pedraza@cbre.com',
    uid: 'kBoyvePWKIPZ6uiftwUWSY6HhWR2',
  },
  {
    name: 'Hugo Ormeño',
    email: 'hormeno@genesismi.cl',
    uid: 'j1oOTz9HuWerjYLilDUlgRlKCEp1',
  },
  {
    name: 'Italo Vega',
    email: 'italo.vegabriceno@pepsico.com',
    uid: 'ffWVuzRxYAVvLfGLbpO8lX98vo92',
  },
  {
    name: 'Ivan Reyes',
    email: 'ivan.reyes@tecsam.cl',
    uid: 'B0FebmG7kZbmKvvQp2VLpzRsweJ2',
  },
  {
    uid: '8t0lhTJ3yAZw99kiw85oF0NwkE53',
    email: 'ivanvillalonrojas@gmail.com',
    name: 'Ivan Villalon',
  },
  {
    uid: 'JWwjmSjF9bUkB0MDOwqUXXuMBq33',
    email: 'jose.tomas.sepulveda.roza@gmail.com',
    name: 'Jose Sepulveda',
  },
  {
    uid: 'Y9ZWMigA2uQXxeV5QIuDvmFk2Ny1',
    email: 'vallejo-jesus@aramark.cl',
    name: 'Jesus Vallejo',
  },
  {
    name: 'Joan Marchant',
    email: 'jmarchant35@gmail.com',
    uid: 'rzhzsfh6XNV67LbtEcNI6NDpBd63',
  },
  {
    name: 'Jonathan Fuentes',
    email: 'jonathanjosue.fuentesplaza@pepsico.com',
    uid: 'IqLtXijPE8gjUsjsdncwFcVWofP2',
  },
  {
    name: 'Jose Inostroza',
    email: 'jose.inostroza@pepsico.com',
    uid: 'RgTD593CXLcPuM2xo1nRJQW9EpF3',
  },
  {
    name: 'Jose Sanchez',
    email: 'jsanchez@ecoriles.cl',
    uid: 'cqd7V7TKvCRpWsX9RtJgd2RZpgt1',
  },
  {
    uid: 'Yj7fdWumT5UCPR5ruLp7yjRv3sE3',
    email: 'juan.martinez@innovacontrol.cl',
    name: 'Juan Carlos Martinez',
  },
  {
    uid: 'xUYbBFaBuNf1TaVRsArtfiK4wBK2',
    email: 'juan.salvatierra@innovacontrol.cl',
    name: 'Juan Salvatierra',
  },
  {
    uid: 'sRlLX04LTYOkXW76QDMBtBpmCop2',
    email: 'juanabisael92@gmail.com',
    name: 'Juan Vaquero',
  },
  {
    uid: 'kjkPinom6JgGqToPoGcJuOQIQGM2',
    email: 'sosa-lauriany@aramark.cl',
    name: 'Lauriany Sosa',
  },
  {
    name: 'Luis Reyes',
    email: 'lreyes@climacor.cl',
    uid: 'I9UjIopWDVV4i6DuqKgeCMbl86p2',
  },
  {
    name: 'Luis Schmeisser',
    email: 'lschmeisser@icmant.cl',
    uid: 'ufeTnODTUkWRMUdmkkOaN6pgk3I3',
  },
  {
    name: 'Marisela Jimenez',
    email: 'marisela.jimenez@tecsam.cl',
    uid: '0t9yMPQNJHTJoZxZ43x9kJdGDNW2',
  },
  {
    name: 'Nicolas Altamirano',
    email: 'nicolas.altamirano@pepsico.com',
    uid: '8uec78JvtEc4mSZc2kpuVasfWAX2',
  },
  {
    name: 'Nicolas Laurito',
    email: 'nlb@conflutec.cl',
    uid: '8UzV1ms0wyTfAlKv2m4B9j5INf43',
  },
  {
    name: 'Pedro Ormeño Genesismi',
    email: 'pormeno@genesismi.cl',
    uid: 'EBna8aB067T7BqD27WtKIUdTlXr2',
  },
  {
    uid: 'VEaPzZTmUjNDmq9Q2Arw0igtvcp1',
    email: 'pedro.ormeno@icmant.cl',
    name: 'Pedro Ormeño Icmant',
  },
  {
    uid: 'cmIaF4J2L6Z8LSbJsZawRRmeW9A3',
    email: 'preyesventa@gmail.com',
    name: 'Pedro Reyes',
  },
  {
    name: 'Pedro Sepulveda',
    email: 'caldyquem@gmail.com',
    uid: 'hbzu14qVKKXaRnNjlP08NSGqOrw1',
  },
  {
    uid: 'htm50oKeuITHSwaZIQF8Ql6MFrk1',
    email: 'pedromarcelo2@gmail.com',
    name: 'Pedro Vergara',
  },
  {
    uid: 'MqdBXTcV1oMRK8BCX4paKzZCXxG3',
    email: 'rodrigo.faundez@cbre.cl',
    name: 'Rodrigo Faundez',
  },
  {
    name: 'Rodrigo Orellana',
    email: 'rorellana@climacor.cl',
    uid: 'ktBZx9m4jVdjvBqovSY2f801CxC2',
  },
  {
    name: 'Ruben Bonillo',
    email: 'bonillo-ruben@aramark.cl',
    uid: 'HFXCWdA8kOROw01Ye9b5aO3DuqE2',
  },
  {
    name: 'Sebastian Vasquez',
    email: 'sebastian.vasquez@mingatec.com',
    uid: 'sEtcJsHIblVFlS70aSB4PQORC3l1',
  },
  {
    name: 'Sergio Briones',
    email: 'sergiobc41@gmail.com',
    uid: 'KuXUQkvmMiS8iBLG3OQNtLS8pK42',
  },
  {
    name: 'Stefani Cataldo',
    email: 'stefani.cataldo@pepsico.com',
    uid: 'S9J4zoCOZEMFjnhSBb2nOWm7EEB2',
  },
  {
    uid: 'WqsWRxcPs3dHocHP5RuQ9etCzqB3',
    email: 'tomas.aliaga@innovacontrol.cl',
    name: 'Tomas Aliaga',
  },
  {
    name: 'Ulises Reyes',
    email: 'ulises.reyes@ryggraficas.cl',
    uid: 'Q4DRR5nq0SUovd1nisBwuamRQfC3',
  },
  {
    name: 'Victor Cifuentes',
    email: 'cifuentes-victor@aramark.com',
    uid: 'brPxTvXkfnUxURjdzT5gqy8zrr22',
  },
]
export default workSupervisorList