import React from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Slide from '@mui/material/Slide'
import { toast } from 'react-toastify'
import Box from '@mui/material/Box'
import { useHistory } from 'react-router-dom'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { entireStore } from '../../app/store'
import { allFormActions } from '../../features/form/formSlice'
import DashboardLayout from '../layouts/DashboardLayout'
import ColorButton from './ColorButton'
import useNewFormId from '../../hooks/useNewFormId'
import { db, doc, updateDoc } from '../../config/firebaseConfig'

const CreateStep4 = () => {
  console.log('CreateStep4')

  const {
    p4workerName1,
    p4workerName2,
    p4workerName3,
    p4workerName4,
    p4workerName5,
    p4workerName6,
    p4workerName7,
    p4workerName8,
    p4workerName9,
    p4workerName10,
    p4workerName11,
    paramedicApprovedWorker1,
    paramedicApprovedWorker2,
    paramedicApprovedWorker3,
    paramedicApprovedWorker4,
    paramedicApprovedWorker5,
    paramedicApprovedWorker6,
    paramedicApprovedWorker7,
    paramedicApprovedWorker8,
    paramedicApprovedWorker9,
    paramedicApprovedWorker10,
    paramedicApprovedWorker11,
  } = useAppSelector(entireStore)

  const {
    setp4workerName1,
    setp4workerName2,
    setp4workerName3,
    setp4workerName4,
    setp4workerName5,
    setp4workerName6,
    setp4workerName7,
    setp4workerName8,
    setp4workerName9,
    setp4workerName10,
    setp4workerName11,
  } = allFormActions
  const dispatch = useAppDispatch()

  const { newFormId } = useNewFormId()

  const history = useHistory()

  const goToCreateStep3 = () => {
    history.push(`/create/3/${newFormId}`)
  }

  const goToCreateStep5 = async () => {
    if (!p4workerName1) {
      toast.error('Por favor escriba el nombre del trabajador')
    } else {
      const formRef = doc(db, 'forms', newFormId as string)
      try {
        await updateDoc(formRef, {
          newFormIdentifier: newFormId,
          newFormp4FilledOut: true,
          newFormp4FilledOutDate: new Date(),
          p4workerName1,
          p4workerName2,
          p4workerName3,
          p4workerName4,
          p4workerName5,
          p4workerName6,
          p4workerName7,
          p4workerName8,
          p4workerName9,
          p4workerName10,
          p4workerName11,
          paramedicApprovedWorker1,
          paramedicApprovedWorker2,
          paramedicApprovedWorker3,
          paramedicApprovedWorker4,
          paramedicApprovedWorker5,
          paramedicApprovedWorker6,
          paramedicApprovedWorker7,
          paramedicApprovedWorker8,
          paramedicApprovedWorker9,
          paramedicApprovedWorker10,
          paramedicApprovedWorker11,
        })
        toast.success('El permiso de trabajo se actualizó correctamente')
        history.push(`/create/5/${newFormId}`)
      } catch (updateError) {
        toast.error(`Error: ${updateError}`)
      }
    }
  }

  return (
    <DashboardLayout>
      <Slide direction="right" in mountOnEnter unmountOnExit>
        <Grid sx={{ mt: 4 }} container justifyContent="center" spacing={2}>
          <Grid item xs={10} sm={9} md={5} lg={7}>
            <Typography
              sx={{ mb: 2 }}
              align="center"
              variant="h6"
              color="text.secondary"
              gutterBottom
            >
              LISTADO DE PERSONAL QUE EJECUTA EL TRABAJO
            </Typography>
            <TextField
              sx={{ mb: 2 }}
              fullWidth
              label="Nombre del trabajador 1"
              variant="outlined"
              value={p4workerName1}
              onChange={(e) => dispatch(setp4workerName1(e.target.value))}
            />
            <Box
              component="div"
              sx={{
                display: p4workerName1 ? 'block' : 'none',
              }}
            >

              <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="Nombre del trabajador 2"
                variant="outlined"
                value={p4workerName2}
                onChange={(e) => dispatch(setp4workerName2(e.target.value))}
              />

            </Box>
            <Box
              component="div"
              sx={{
                display: p4workerName2 ? 'block' : 'none',
              }}
            >
              <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="Nombre del trabajador 3"
                variant="outlined"
                value={p4workerName3}
                onChange={(e) => dispatch(setp4workerName3(e.target.value))}
              />

            </Box>
            <Box
              component="div"
              sx={{
                display: p4workerName3 ? 'block' : 'none',
              }}
            >

              <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="Nombre del trabajador 4"
                variant="outlined"
                value={p4workerName4}
                onChange={(e) => dispatch(setp4workerName4(e.target.value))}
              />
            </Box>
            <Box
              component="div"
              sx={{
                display: p4workerName4 ? 'block' : 'none',
              }}
            >

              <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="Nombre del trabajador 5"
                variant="outlined"
                value={p4workerName5}
                onChange={(e) => dispatch(setp4workerName5(e.target.value))}
              />
            </Box>
            <Box
              component="div"
              sx={{
                display: p4workerName5 ? 'block' : 'none',
              }}
            >

              <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="Nombre del trabajador 6"
                variant="outlined"
                value={p4workerName6}
                onChange={(e) => dispatch(setp4workerName6(e.target.value))}
              />
            </Box>
            <Box
              component="div"
              sx={{
                display: p4workerName6 ? 'block' : 'none',
              }}
            >

              <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="Nombre del trabajador 7"
                variant="outlined"
                value={p4workerName7}
                onChange={(e) => dispatch(setp4workerName7(e.target.value))}
              />
            </Box>
            <Box
              component="div"
              sx={{
                display: p4workerName7 ? 'block' : 'none',
              }}
            >

              <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="Nombre del trabajador 8"
                variant="outlined"
                value={p4workerName8}
                onChange={(e) => dispatch(setp4workerName8(e.target.value))}
              />
            </Box>
            <Box
              component="div"
              sx={{
                display: p4workerName8 ? 'block' : 'none',
              }}
            >

              <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="Nombre del trabajador 9"
                variant="outlined"
                value={p4workerName9}
                onChange={(e) => dispatch(setp4workerName9(e.target.value))}
              />
            </Box>
            <Box
              component="div"
              sx={{
                display: p4workerName9 ? 'block' : 'none',
              }}
            >

              <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="Nombre del trabajador 10"
                variant="outlined"
                value={p4workerName10}
                onChange={(e) => dispatch(setp4workerName10(e.target.value))}
              />
            </Box>
            <Box
              component="div"
              sx={{
                display: p4workerName10 ? 'block' : 'none',
              }}
            >

              <TextField
                sx={{ mb: 10 }}
                fullWidth
                label="Nombre del trabajador 11"
                variant="outlined"
                value={p4workerName11}
                onChange={(e) => dispatch(setp4workerName11(e.target.value))}
              />
            </Box>
            <Grid sx={{ mb: 2 }} container justifyContent="space-between">
              <Grid
                xs={12}
                md={5}
                sx={{ mb: 2 }}
              >
                <ColorButton
                  fullWidth
                  startIcon={<ArrowLeftIcon />}
                  onClick={goToCreateStep3}
                >
                  Ir a la página anterior
                </ColorButton>
              </Grid>
              <Grid
                xs={12}
                md={5}
              >
                <ColorButton
                  fullWidth
                  endIcon={<ArrowRightIcon />}
                  onClick={goToCreateStep5}
                >
                  Ir a la página siguiente
                </ColorButton>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </DashboardLayout>
  )
}

export default CreateStep4