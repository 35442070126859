import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Slide from '@mui/material/Slide'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import FormControlLabel from '@mui/material/FormControlLabel'
import { toast } from 'react-toastify'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import { useHistory } from 'react-router-dom'
import SaveIcon from '@mui/icons-material/Save'
import FormGroup from '@mui/material/FormGroup'
import Checkbox from '@mui/material/Checkbox'
import workSupervisorList from './workSupervisorList'
import coordinatorList from './coordinatorList'
import areaCoordinatorList from './areaCoordinatorList'
import qualityList from './qualityList'
import technicalAreaList from './technicalAreaList'
import patrimonialSecurityList from './patrimonialSecurityList'
import externPrevencionistList from './externPrevencionistList'
import sassList from './sassList'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { entireStore } from '../../app/store'
import { allFormActions } from '../../features/form/formSlice'
import { doc, updateDoc, db } from '../../config/firebaseConfig'
import DashboardLayout from '../layouts/DashboardLayout'
import ColorButton from './ColorButton'
import useAuth from '../../hooks/useAuth'
import YellowSwitch from '../../common/YellowSwitch'
import useDate from '../../hooks/useDate'
import useNewFormId from '../../hooks/useNewFormId'
import color1ConsoleLog from '../../common/color1ConsoleLog'

const CreateStep16 = () => {
  const { newFormId } = useNewFormId()
  const newFormIdString: string = newFormId as string

  let p2specificWorkAreaImage: string | null
  let p6useofLadderImage: string | null
  let p6useofLadderImages: string | null
  let p6elevatorImage: string | null
  let p6andamTipeEuroImage: string | null
  let p8hasHdsImage: string | null
  let p8extensionElectricalCheckImage: string | null
  let p9eslingasCheckImage: string | null
  let p9truckPrevCheckImage: string | null
  let p11electricalExtensionCheckImage: string | null
  let p13hotWorkFormNumberImage: string | null
  let p13electricalExtensionCheckImage: string | null
  // let p14fourHookHarnessImages: string | null
  let p14doubleLitailImage: string | null
  let p14impactAbsorbImage: string | null
  let p14escaladaMosquetonImage: string | null

  const getLocalStorageImageUrls = () => {
    // 2
    if (localStorage.getItem('p2specificWorkAreaImage')) {
      p2specificWorkAreaImage = localStorage.getItem('p2specificWorkAreaImage')
    }
    // 6
    if (localStorage.getItem('p6useofLadderImage')) {
      p6useofLadderImage = localStorage.getItem('p6useofLadderImage')
      p6useofLadderImages = p6useofLadderImage
    }
    if (localStorage.getItem('p6elevatorImage')) {
      p6elevatorImage = localStorage.getItem('p6elevatorImage')
    }
    if (localStorage.getItem('p6andamTipeEuroImage')) {
      p6andamTipeEuroImage = localStorage.getItem('p6andamTipeEuroImage')
    }
    // 8
    if (localStorage.getItem('p8hasHdsImage')) {
      p8hasHdsImage = localStorage.getItem('p8hasHdsImage')
    }
    if (localStorage.getItem('p8extensionElectricalCheckImage')) {
      p8extensionElectricalCheckImage = localStorage.getItem('p8extensionElectricalCheckImage')
    }
    // 9
    if (localStorage.getItem('p9eslingasCheckImage')) {
      p9eslingasCheckImage = localStorage.getItem('p9eslingasCheckImage')
    }
    if (localStorage.getItem('p9truckPrevCheckImage')) {
      p9truckPrevCheckImage = localStorage.getItem('p9truckPrevCheckImage')
    }
    // 11
    if (localStorage.getItem('p11electricalExtensionCheckImage')) {
      p11electricalExtensionCheckImage = localStorage.getItem('p11electricalExtensionCheckImage')
    }
    // 13
    if (localStorage.getItem('p13hotWorkFormNumberImage')) {
      p13hotWorkFormNumberImage = localStorage.getItem('p13hotWorkFormNumberImage')
    }
    if (localStorage.getItem('p13electricalExtensionCheckImage')) {
      p13electricalExtensionCheckImage = localStorage.getItem('p13electricalExtensionCheckImage')
    }
    // 14
    // if (localStorage.getItem('p14fourHookHarnessImage')) {
    //   p14fourHookHarnessImages = localStorage.getItem('p14fourHookHarnessImage')
    // }
    if (localStorage.getItem('p14doubleLitailImage')) {
      p14doubleLitailImage = localStorage.getItem('p14doubleLitailImage')
    }
    if (localStorage.getItem('p14impactAbsorbImage')) {
      p14impactAbsorbImage = localStorage.getItem('p14impactAbsorbImage')
    }
    if (localStorage.getItem('p14escaladaMosquetonImage')) {
      p14escaladaMosquetonImage = localStorage.getItem('p14escaladaMosquetonImage')
    }
  }

  getLocalStorageImageUrls()

  const history = useHistory()

  const goToCreateStep15 = () => {
    history.push(`/create/15/${newFormId}`)
  }

  const {
    p2companyName,
    p2contratSupervisorName,
    p2contratSupervisorPhone,
    p2erpName,
    p2erpPhone,
    p2rut,
    p2mutualidad,
    p2requestingArea,
    p2pepWorkCoordinator,
    p2specificWorkArea,
    p2date,
    p2extensionShow,
    p2extension,
    p3description1,
    p3description2,
    p3description3,
    p3description4,
    p3description5,
    p3description6,
    p3description7,
    p3controlMeasure1,
    p3controlMeasure2,
    p3controlMeasure3,
    p3controlMeasure4,
    p3controlMeasure5,
    p3controlMeasure6,
    p3controlMeasure7,
    p4workerName1,
    p4workerName1Approved,
    p4workerName2,
    p4workerName2Approved,
    p4workerName3,
    p4workerName3Approved,
    p4workerName4,
    p4workerName4Approved,
    p4workerName5,
    p4workerName5Approved,
    p4workerName6,
    p4workerName6Approved,
    p4workerName7,
    p4workerName7Approved,
    p4workerName8,
    p4workerName8Approved,
    p4workerName9,
    p4workerName9Approved,
    p4workerName10,
    p4workerName10Approved,
    p4workerName11,
    p4workerName11Approved,
    p5atrapam,
    p5aplastam,
    p5golpeadopor,
    p5golpeadocon,
    p5caida,
    p5contConObjCort,
    p5contConObjCal,
    p5proyPartic,
    p5contactEnElectric,
    p5radiacionUv,
    p5contQuimico,
    p5exposicPolvo,
    p5exposicRuido,
    p5atropello,
    p5caidaMatAltura,
    p5estresTerm,
    p5colisionChoque,
    p5deficienciao2,
    p5explosion,
    p5incendio,
    p5derrumbe,
    p5exposicGas,
    p5immersion,
    p5fatigMaterial,
    p6Show,
    p6keyNumber,
    p6weatherConditions,
    p6trainingForHeightWork,
    p6trainingEspecWork,
    p6radioCommunication,
    p6toolBelt,
    p6useOfHookPoint,
    p6useofLadder,
    p6elevator,
    p6andamTipeEuro,
    p6healthCheck,
    p6inlineSliderVertical,
    p6heightJobLowLevel,
    p7Show,
    p7keyNumber,
    p7measure1o2,
    p7measure1h2s,
    p7measure1co,
    p7measure2o2,
    p7measure2h2s,
    p7measure2co,
    p7gasAn,
    p7deviceOfVentForced,
    p7deviceOfAutonBreathing,
    p7comRadio,
    p7landTempInPlace,
    p7equipmentOfAdLight,
    p7requireRescueDevice,
    p7acceptableWeatherCond,
    p8Show,
    p8chemEnvase,
    p8hasHds,
    p8indicateSuspel,
    p8showaGlove,
    p8faceShield,
    p8antSuit,
    p8rubberBoot,
    p8antSpillKit,
    p8extensionElectricalCheck,
    p9Show,
    p9eslingasCheck,
    p9ancPointCheck,
    p9securityPerimeter,
    p9riggerParticipation,
    p9transitAreas,
    p9informBlockAreasToJef,
    p9areaDelimitation,
    p9truckPrevCheck,
    p10show,
    p10chemName1,
    p10chemName1health,
    p10chemName1inflam,
    p10chemName1reactivity,
    p10chemName1specific,
    p10chemName2,
    p10chemName2health,
    p10chemName2inflam,
    p10chemName2reactivity,
    p10chemName2specific,
    p10chemName3,
    p10chemName3health,
    p10chemName3inflam,
    p10chemName3reactivity,
    p10chemName3specific,
    p10chemName4,
    p10chemName4health,
    p10chemName4inflam,
    p10chemName4reactivity,
    p10chemName4specific,
    p10chemName5,
    p10chemName5health,
    p10chemName5inflam,
    p10chemName5reactivity,
    p10chemName5specific,
    p11show,
    p11mask,
    p11cabritillaGlove,
    p11breather,
    p11manualLoadMax25,
    p11useoFSignal,
    p11areaDelimitation,
    p11electricalExtensionCheck,
    p11toolCheck,
    p11lightningToWork,
    p11clearArea,
    p11useOfGrua,
    p12show,
    p12evaluatorName,
    p12evaluationTime,
    p12areaClear,
    p12materialsWithinTheZone,
    p12dustGeneration,
    p12foodIn,
    p12hasBmp,
    p13show,
    p13hotWorkFormNumber,
    p13areaDelimitation,
    p13electricalExtensionCheck,
    p13notificationToBrig,
    p14securityGlass,
    p14securityShoes,
    p14earProtector,
    p14securityHeadProtection,
    p14reflectJacket,
    p14uvFilterProtection,
    p14antSuit,
    p14bootsResistant,
    p14faceShield,
    p14soldarMask,
    p14soldarSuit,
    p14leatherColeto,
    p14leatherPolaina,
    p14fourHookHarness,
    p14doubleLitail,
    p14impactAbsorb,
    p14escaladaMosqueton,
    p14filterMask,
    p14Others,
    p15show,
    p15blockingPersonName,
    p15dateTimeLotoStart,
    p15dateTimeLotoFinish,
    p15blockingEnType,
    p15blockingEnTypeElectrical,
    p15blockingEnTypeHidraulical,
    p15blockingEnMechanical,
    p15blockingEnPotencial,
    p15dangEnergiesControl,
    p15knowEnergySourceMachine,
    p15concLoto,
    p15hasLockIdCardBlockDevice,
    p15dielectricGlove,
    p15dielectricSecurityShoe,
    p15dielectricSecurityGlass,
    p16showWorkSupervisor,
    p16workSupervisor,
    p16workSupervisorEmail,
    p16showPepCoordinator,
    p16pepCoordinator,
    p16pepCoordinatorEmail,
    p16showAreaCoordinator,
    p16areaCoordinator,
    p16areaCoordinatorEmail,
    p16showQuality,
    p16quality,
    p16qualityEmail,
    p16showTechnicalArea,
    p16technicalArea,
    p16technicalAreaEmail,
    p16showPatrimonialSecurity,
    p16patrimonialSecurity,
    p16patrimonialSecurityEmail,
    p16showExternalPrevencionist,
    p16externalPrevencionist,
    p16externalPrevencionistEmail,
    p16showSass,
    p16sass,
    p16paramedic,
    p16sassAllowedList,
    entriesAdded,
    nodeApiBaseUrl,
  } = useAppSelector(entireStore)
  const {
    setp16showWorkSupervisor,
    setp16workSupervisor,
    setp16showPepCoordinator,
    setp16pepCoordinator,
    setp16showAreaCoordinator,
    setp16areaCoordinator,
    setp16showQuality,
    setp16quality,
    setp16showTechnicalArea,
    setp16technicalArea,
    setp16showPatrimonialSecurity,
    setp16patrimonialSecurity,
    setp16showExternalPrevencionist,
    setp16externalPrevencionist,
    setp16sass,
    setp16showSass,
    setp16sassAllowedList,
  } = allFormActions
  const dispatch = useAppDispatch()

  const containerp16showWorkSupervisor = React.useRef(null)
  const containerp16showPepCoordinator = React.useRef(null)
  const containerp16showAreaCoordinator = React.useRef(null)
  const containerp16showQuality = React.useRef(null)
  const containerp16showTechnicalArea = React.useRef(null)
  const containerp16showPatrimonialSecurity = React.useRef(null)
  const containerp16showExternalPrevencionist = React.useRef(null)
  const containerp16showSass = React.useRef(null)

  const { user } = useAuth()
  const all = useAppSelector(entireStore)
  const { p2startTime, p2finishTime } = useDate()

  const saveForm = async () => {
    console.log('user', user?.uid)

    console.log('saving entirestore', all)

    const newFormStoredAt = new Date()
    const newFormp16FilledOut = true
    const newFormp16FilledOutDate = new Date()
    // if all the selections are approved
    const newFormApprovedAll: boolean = false

    // approved pages
    const p16workSupervisorApproved1 = true
    const p16workSupervisorApproved2 = false
    const p16workSupervisorApproved3 = false
    const p16workSupervisorApproved4 = true
    const p16workSupervisorApproved5 = false
    const p16workSupervisorApproved6 = false
    const p16workSupervisorApproved7 = false
    const p16workSupervisorApproved8 = false
    const p16workSupervisorApproved9 = false
    const p16workSupervisorApproved10 = false
    const p16workSupervisorApproved11 = false
    const p16workSupervisorApproved12 = false
    const p16workSupervisorApproved13 = false
    const p16workSupervisorApproved14 = false
    const p16workSupervisorApproved15 = false
    const p16workSupervisorApprovedAt1 = new Date()
    const p16workSupervisorApprovedAt2 = null
    const p16workSupervisorApprovedAt3 = null
    const p16workSupervisorApprovedAt4 = new Date()
    const p16workSupervisorApprovedAt5 = null
    const p16workSupervisorApprovedAt6 = null
    const p16workSupervisorApprovedAt7 = null
    const p16workSupervisorApprovedAt8 = null
    const p16workSupervisorApprovedAt9 = null
    const p16workSupervisorApprovedAt10 = null
    const p16workSupervisorApprovedAt11 = null
    const p16workSupervisorApprovedAt12 = null
    const p16workSupervisorApprovedAt13 = null
    const p16workSupervisorApprovedAt14 = null
    const p16workSupervisorApprovedAt15 = null

    const p16pepCoordinatorApproved1 = true
    const p16pepCoordinatorApproved2 = false
    const p16pepCoordinatorApproved3 = false
    const p16pepCoordinatorApproved4 = true
    const p16pepCoordinatorApproved5 = false
    const p16pepCoordinatorApproved6 = false
    const p16pepCoordinatorApproved7 = false
    const p16pepCoordinatorApproved8 = false
    const p16pepCoordinatorApproved9 = false
    const p16pepCoordinatorApproved10 = false
    const p16pepCoordinatorApproved11 = false
    const p16pepCoordinatorApproved12 = false
    const p16pepCoordinatorApproved13 = false
    const p16pepCoordinatorApproved14 = false
    const p16pepCoordinatorApproved15 = false
    const p16pepCoordinatorApprovedAt1 = new Date()
    const p16pepCoordinatorApprovedAt2 = null
    const p16pepCoordinatorApprovedAt3 = null
    const p16pepCoordinatorApprovedAt4 = new Date()
    const p16pepCoordinatorApprovedAt5 = null
    const p16pepCoordinatorApprovedAt6 = null
    const p16pepCoordinatorApprovedAt7 = null
    const p16pepCoordinatorApprovedAt8 = null
    const p16pepCoordinatorApprovedAt9 = null
    const p16pepCoordinatorApprovedAt10 = null
    const p16pepCoordinatorApprovedAt11 = null
    const p16pepCoordinatorApprovedAt12 = null
    const p16pepCoordinatorApprovedAt13 = null
    const p16pepCoordinatorApprovedAt14 = null
    const p16pepCoordinatorApprovedAt15 = null

    const p16areaCoordinatorApproved1 = true
    const p16areaCoordinatorApproved2 = false
    const p16areaCoordinatorApproved3 = false
    const p16areaCoordinatorApproved4 = true
    const p16areaCoordinatorApproved5 = false
    const p16areaCoordinatorApproved6 = false
    const p16areaCoordinatorApproved7 = false
    const p16areaCoordinatorApproved8 = false
    const p16areaCoordinatorApproved9 = false
    const p16areaCoordinatorApproved10 = false
    const p16areaCoordinatorApproved11 = false
    const p16areaCoordinatorApproved12 = false
    const p16areaCoordinatorApproved13 = false
    const p16areaCoordinatorApproved14 = false
    const p16areaCoordinatorApproved15 = false
    const p16areaCoordinatorApprovedAt1 = new Date()
    const p16areaCoordinatorApprovedAt2 = null
    const p16areaCoordinatorApprovedAt3 = null
    const p16areaCoordinatorApprovedAt4 = new Date()
    const p16areaCoordinatorApprovedAt5 = null
    const p16areaCoordinatorApprovedAt6 = null
    const p16areaCoordinatorApprovedAt7 = null
    const p16areaCoordinatorApprovedAt8 = null
    const p16areaCoordinatorApprovedAt9 = null
    const p16areaCoordinatorApprovedAt10 = null
    const p16areaCoordinatorApprovedAt11 = null
    const p16areaCoordinatorApprovedAt12 = null
    const p16areaCoordinatorApprovedAt13 = null
    const p16areaCoordinatorApprovedAt14 = null
    const p16areaCoordinatorApprovedAt15 = null

    const p16qualityApproved1 = true
    const p16qualityApproved2 = false
    const p16qualityApproved3 = false
    const p16qualityApproved4 = true
    const p16qualityApproved5 = false
    const p16qualityApproved6 = false
    const p16qualityApproved7 = false
    const p16qualityApproved8 = false
    const p16qualityApproved9 = false
    const p16qualityApproved10 = false
    const p16qualityApproved11 = false
    const p16qualityApproved12 = false
    const p16qualityApproved13 = false
    const p16qualityApproved14 = false
    const p16qualityApproved15 = false
    const p16qualityApprovedAt1 = new Date()
    const p16qualityApprovedAt2 = null
    const p16qualityApprovedAt3 = null
    const p16qualityApprovedAt4 = new Date()
    const p16qualityApprovedAt5 = null
    const p16qualityApprovedAt6 = null
    const p16qualityApprovedAt7 = null
    const p16qualityApprovedAt8 = null
    const p16qualityApprovedAt9 = null
    const p16qualityApprovedAt10 = null
    const p16qualityApprovedAt11 = null
    const p16qualityApprovedAt12 = null
    const p16qualityApprovedAt13 = null
    const p16qualityApprovedAt14 = null
    const p16qualityApprovedAt15 = null

    const p16technicalAreaApproved1 = true
    const p16technicalAreaApproved2 = false
    const p16technicalAreaApproved3 = false
    const p16technicalAreaApproved4 = true
    const p16technicalAreaApproved5 = false
    const p16technicalAreaApproved6 = false
    const p16technicalAreaApproved7 = false
    const p16technicalAreaApproved8 = false
    const p16technicalAreaApproved9 = false
    const p16technicalAreaApproved10 = false
    const p16technicalAreaApproved11 = false
    const p16technicalAreaApproved12 = false
    const p16technicalAreaApproved13 = false
    const p16technicalAreaApproved14 = false
    const p16technicalAreaApproved15 = false
    const p16technicalAreaApprovedAt1 = new Date()
    const p16technicalAreaApprovedAt2 = null
    const p16technicalAreaApprovedAt3 = null
    const p16technicalAreaApprovedAt4 = new Date()
    const p16technicalAreaApprovedAt5 = null
    const p16technicalAreaApprovedAt6 = null
    const p16technicalAreaApprovedAt7 = null
    const p16technicalAreaApprovedAt8 = null
    const p16technicalAreaApprovedAt9 = null
    const p16technicalAreaApprovedAt10 = null
    const p16technicalAreaApprovedAt11 = null
    const p16technicalAreaApprovedAt12 = null
    const p16technicalAreaApprovedAt13 = null
    const p16technicalAreaApprovedAt14 = null
    const p16technicalAreaApprovedAt15 = null

    const p16patrimonialSecurityApproved1 = true
    const p16patrimonialSecurityApproved2 = false
    const p16patrimonialSecurityApproved3 = false
    const p16patrimonialSecurityApproved4 = true
    const p16patrimonialSecurityApproved5 = false
    const p16patrimonialSecurityApproved6 = false
    const p16patrimonialSecurityApproved7 = false
    const p16patrimonialSecurityApproved8 = false
    const p16patrimonialSecurityApproved9 = false
    const p16patrimonialSecurityApproved10 = false
    const p16patrimonialSecurityApproved11 = false
    const p16patrimonialSecurityApproved12 = false
    const p16patrimonialSecurityApproved13 = false
    const p16patrimonialSecurityApproved14 = false
    const p16patrimonialSecurityApproved15 = false
    const p16patrimonialSecurityApprovedAt1 = new Date()
    const p16patrimonialSecurityApprovedAt2 = null
    const p16patrimonialSecurityApprovedAt3 = null
    const p16patrimonialSecurityApprovedAt4 = new Date()
    const p16patrimonialSecurityApprovedAt5 = null
    const p16patrimonialSecurityApprovedAt6 = null
    const p16patrimonialSecurityApprovedAt7 = null
    const p16patrimonialSecurityApprovedAt8 = null
    const p16patrimonialSecurityApprovedAt9 = null
    const p16patrimonialSecurityApprovedAt10 = null
    const p16patrimonialSecurityApprovedAt11 = null
    const p16patrimonialSecurityApprovedAt12 = null
    const p16patrimonialSecurityApprovedAt13 = null
    const p16patrimonialSecurityApprovedAt14 = null
    const p16patrimonialSecurityApprovedAt15 = null

    const p16externalPrevencionistApproved1 = true
    const p16externalPrevencionistApproved2 = false
    const p16externalPrevencionistApproved3 = false
    const p16externalPrevencionistApproved4 = true
    const p16externalPrevencionistApproved5 = false
    const p16externalPrevencionistApproved6 = false
    const p16externalPrevencionistApproved7 = false
    const p16externalPrevencionistApproved8 = false
    const p16externalPrevencionistApproved9 = false
    const p16externalPrevencionistApproved10 = false
    const p16externalPrevencionistApproved11 = false
    const p16externalPrevencionistApproved12 = false
    const p16externalPrevencionistApproved13 = false
    const p16externalPrevencionistApproved14 = false
    const p16externalPrevencionistApproved15 = false
    const p16externalPrevencionistApprovedAt1 = new Date()
    const p16externalPrevencionistApprovedAt2 = null
    const p16externalPrevencionistApprovedAt3 = null
    const p16externalPrevencionistApprovedAt4 = new Date()
    const p16externalPrevencionistApprovedAt5 = null
    const p16externalPrevencionistApprovedAt6 = null
    const p16externalPrevencionistApprovedAt7 = null
    const p16externalPrevencionistApprovedAt8 = null
    const p16externalPrevencionistApprovedAt9 = null
    const p16externalPrevencionistApprovedAt10 = null
    const p16externalPrevencionistApprovedAt11 = null
    const p16externalPrevencionistApprovedAt12 = null
    const p16externalPrevencionistApprovedAt13 = null
    const p16externalPrevencionistApprovedAt14 = null
    const p16externalPrevencionistApprovedAt15 = null

    const p16sassApproved1 = true
    const p16sassApproved2 = false
    const p16sassApproved3 = false
    const p16sassApproved4 = true
    const p16sassApproved5 = false
    const p16sassApproved6 = false
    const p16sassApproved7 = false
    const p16sassApproved8 = false
    const p16sassApproved9 = false
    const p16sassApproved10 = false
    const p16sassApproved11 = false
    const p16sassApproved12 = false
    const p16sassApproved13 = false
    const p16sassApproved14 = false
    const p16sassApproved15 = false
    const p16sassApprovedAt1 = new Date()
    const p16sassApprovedAt2 = null
    const p16sassApprovedAt3 = null
    const p16sassApprovedAt4 = new Date()
    const p16sassApprovedAt5 = null
    const p16sassApprovedAt6 = null
    const p16sassApprovedAt7 = null
    const p16sassApprovedAt8 = null
    const p16sassApprovedAt9 = null
    const p16sassApprovedAt10 = null
    const p16sassApprovedAt11 = null
    const p16sassApprovedAt12 = null
    const p16sassApprovedAt13 = null
    const p16sassApprovedAt14 = null
    const p16sassApprovedAt15 = null
    console.log('p6useofLadderImages: ', p6useofLadderImages)

    const formWithAllValues = {
      entriesAdded,
      // imagenes
      p2specificWorkAreaImage,
      p6useofLadderImage: p6useofLadderImages === undefined ? null : p6useofLadderImages,
      p6elevatorImage,
      p6andamTipeEuroImage,
      p8hasHdsImage,
      p8extensionElectricalCheckImage,
      p9eslingasCheckImage,
      p9truckPrevCheckImage,
      p11electricalExtensionCheckImage,
      p13hotWorkFormNumberImage,
      p13electricalExtensionCheckImage,
      p14fourHookHarnessImage: localStorage.getItem('p14fourHookHarnessImage') === undefined ? null : localStorage.getItem('p14fourHookHarnessImage'),
      p14doubleLitailImage,
      p14impactAbsorbImage,
      p14escaladaMosquetonImage,
      //
      newFormStoredAt,
      newFormp16FilledOut,
      newFormp16FilledOutDate,
      newFormApprovedAll,
      p2companyName,
      p2contratSupervisorName,
      p2contratSupervisorPhone,
      p2erpName,
      p2erpPhone,
      p2rut,
      p2mutualidad,
      p2requestingArea,
      p2pepWorkCoordinator,
      p2specificWorkArea,
      p2date,
      p2startTime,
      p2finishTime,
      p2extensionShow,
      p2extension,
      p3description1,
      p3description2,
      p3description3,
      p3description4,
      p3description5,
      p3description6,
      p3description7,
      p3controlMeasure1,
      p3controlMeasure2,
      p3controlMeasure3,
      p3controlMeasure4,
      p3controlMeasure5,
      p3controlMeasure6,
      p3controlMeasure7,
      p4workerName1,
      p4workerName1Approved,
      p4workerName2,
      p4workerName2Approved,
      p4workerName3,
      p4workerName3Approved,
      p4workerName4,
      p4workerName4Approved,
      p4workerName5,
      p4workerName5Approved,
      p4workerName6,
      p4workerName6Approved,
      p4workerName7,
      p4workerName7Approved,
      p4workerName8,
      p4workerName8Approved,
      p4workerName9,
      p4workerName9Approved,
      p4workerName10,
      p4workerName10Approved,
      p4workerName11,
      p4workerName11Approved,
      p5atrapam,
      p5aplastam,
      p5golpeadopor,
      p5golpeadocon,
      p5caida,
      p5contConObjCort,
      p5contConObjCal,
      p5proyPartic,
      p5contactEnElectric,
      p5radiacionUv,
      p5contQuimico,
      p5exposicPolvo,
      p5exposicRuido,
      p5atropello,
      p5caidaMatAltura,
      p5estresTerm,
      p5colisionChoque,
      p5deficienciao2,
      p5explosion,
      p5incendio,
      p5derrumbe,
      p5exposicGas,
      p5immersion,
      p5fatigMaterial,
      p6Show,
      p6keyNumber,
      p6weatherConditions,
      p6trainingForHeightWork,
      p6trainingEspecWork,
      p6radioCommunication,
      p6toolBelt,
      p6useOfHookPoint,
      p6useofLadder,
      p6elevator,
      p6andamTipeEuro,
      p6healthCheck,
      p6inlineSliderVertical,
      p6heightJobLowLevel,
      p7Show,
      p7keyNumber,
      p7measure1o2,
      p7measure1h2s,
      p7measure1co,
      p7measure2o2,
      p7measure2h2s,
      p7measure2co,
      p7gasAn,
      p7deviceOfVentForced,
      p7deviceOfAutonBreathing,
      p7comRadio,
      p7landTempInPlace,
      p7equipmentOfAdLight,
      p7requireRescueDevice,
      p7acceptableWeatherCond,
      p8Show,
      p8chemEnvase,
      p8hasHds,
      p8indicateSuspel,
      p8showaGlove,
      p8faceShield,
      p8antSuit,
      p8rubberBoot,
      p8antSpillKit,
      p8extensionElectricalCheck,
      p9Show,
      p9eslingasCheck,
      p9ancPointCheck,
      p9securityPerimeter,
      p9riggerParticipation,
      p9transitAreas,
      p9informBlockAreasToJef,
      p9areaDelimitation,
      p9truckPrevCheck,
      p10show,
      p10chemName1,
      p10chemName1health,
      p10chemName1inflam,
      p10chemName1reactivity,
      p10chemName1specific,
      p10chemName2,
      p10chemName2health,
      p10chemName2inflam,
      p10chemName2reactivity,
      p10chemName2specific,
      p10chemName3,
      p10chemName3health,
      p10chemName3inflam,
      p10chemName3reactivity,
      p10chemName3specific,
      p10chemName4,
      p10chemName4health,
      p10chemName4inflam,
      p10chemName4reactivity,
      p10chemName4specific,
      p10chemName5,
      p10chemName5health,
      p10chemName5inflam,
      p10chemName5reactivity,
      p10chemName5specific,
      p11show,
      p11mask,
      p11cabritillaGlove,
      p11breather,
      p11manualLoadMax25,
      p11useoFSignal,
      p11areaDelimitation,
      p11electricalExtensionCheck,
      p11toolCheck,
      p11lightningToWork,
      p11clearArea,
      p11useOfGrua,
      p12show,
      p12evaluatorName,
      p12evaluationTime,
      p12areaClear,
      p12materialsWithinTheZone,
      p12dustGeneration,
      p12foodIn,
      p12hasBmp,
      p13show,
      p13hotWorkFormNumber,
      p13areaDelimitation,
      p13electricalExtensionCheck,
      p13notificationToBrig,
      p14securityGlass,
      p14securityShoes,
      p14earProtector,
      p14securityHeadProtection,
      p14reflectJacket,
      p14uvFilterProtection,
      p14antSuit,
      p14bootsResistant,
      p14faceShield,
      p14soldarMask,
      p14soldarSuit,
      p14leatherColeto,
      p14leatherPolaina,
      p14fourHookHarness,
      p14doubleLitail,
      p14impactAbsorb,
      p14escaladaMosqueton,
      p14filterMask,
      p14Others,
      p15show,
      p15blockingPersonName,
      p15dateTimeLotoStart,
      p15dateTimeLotoFinish,
      p15blockingEnType,
      p15blockingEnTypeElectrical,
      p15blockingEnTypeHidraulical,
      p15blockingEnMechanical,
      p15blockingEnPotencial,
      p15dangEnergiesControl,
      p15knowEnergySourceMachine,
      p15concLoto,
      p15hasLockIdCardBlockDevice,
      p15dielectricGlove,
      p15dielectricSecurityShoe,
      p15dielectricSecurityGlass,
      p16showWorkSupervisor,
      p16workSupervisor,
      p16workSupervisorEmail,
      p16workSupervisorApproved1,
      p16workSupervisorApproved2,
      p16workSupervisorApproved3,
      p16workSupervisorApproved4,
      p16workSupervisorApproved5,
      p16workSupervisorApproved6,
      p16workSupervisorApproved7,
      p16workSupervisorApproved8,
      p16workSupervisorApproved9,
      p16workSupervisorApproved10,
      p16workSupervisorApproved11,
      p16workSupervisorApproved12,
      p16workSupervisorApproved13,
      p16workSupervisorApproved14,
      p16workSupervisorApproved15,
      p16workSupervisorApprovedAt1,
      p16workSupervisorApprovedAt2,
      p16workSupervisorApprovedAt3,
      p16workSupervisorApprovedAt4,
      p16workSupervisorApprovedAt5,
      p16workSupervisorApprovedAt6,
      p16workSupervisorApprovedAt7,
      p16workSupervisorApprovedAt8,
      p16workSupervisorApprovedAt9,
      p16workSupervisorApprovedAt10,
      p16workSupervisorApprovedAt11,
      p16workSupervisorApprovedAt12,
      p16workSupervisorApprovedAt13,
      p16workSupervisorApprovedAt14,
      p16workSupervisorApprovedAt15,
      p16showPepCoordinator,
      p16pepCoordinator,
      p16pepCoordinatorEmail,
      p16pepCoordinatorApproved1,
      p16pepCoordinatorApproved2,
      p16pepCoordinatorApproved3,
      p16pepCoordinatorApproved4,
      p16pepCoordinatorApproved5,
      p16pepCoordinatorApproved6,
      p16pepCoordinatorApproved7,
      p16pepCoordinatorApproved8,
      p16pepCoordinatorApproved9,
      p16pepCoordinatorApproved10,
      p16pepCoordinatorApproved11,
      p16pepCoordinatorApproved12,
      p16pepCoordinatorApproved13,
      p16pepCoordinatorApproved14,
      p16pepCoordinatorApproved15,
      p16pepCoordinatorApprovedAt1,
      p16pepCoordinatorApprovedAt2,
      p16pepCoordinatorApprovedAt3,
      p16pepCoordinatorApprovedAt4,
      p16pepCoordinatorApprovedAt5,
      p16pepCoordinatorApprovedAt6,
      p16pepCoordinatorApprovedAt7,
      p16pepCoordinatorApprovedAt8,
      p16pepCoordinatorApprovedAt9,
      p16pepCoordinatorApprovedAt10,
      p16pepCoordinatorApprovedAt11,
      p16pepCoordinatorApprovedAt12,
      p16pepCoordinatorApprovedAt13,
      p16pepCoordinatorApprovedAt14,
      p16pepCoordinatorApprovedAt15,
      p16showAreaCoordinator,
      p16areaCoordinator,
      p16areaCoordinatorEmail,
      p16areaCoordinatorApproved1,
      p16areaCoordinatorApproved2,
      p16areaCoordinatorApproved3,
      p16areaCoordinatorApproved4,
      p16areaCoordinatorApproved5,
      p16areaCoordinatorApproved6,
      p16areaCoordinatorApproved7,
      p16areaCoordinatorApproved8,
      p16areaCoordinatorApproved9,
      p16areaCoordinatorApproved10,
      p16areaCoordinatorApproved11,
      p16areaCoordinatorApproved12,
      p16areaCoordinatorApproved13,
      p16areaCoordinatorApproved14,
      p16areaCoordinatorApproved15,
      p16areaCoordinatorApprovedAt1,
      p16areaCoordinatorApprovedAt2,
      p16areaCoordinatorApprovedAt3,
      p16areaCoordinatorApprovedAt4,
      p16areaCoordinatorApprovedAt5,
      p16areaCoordinatorApprovedAt6,
      p16areaCoordinatorApprovedAt7,
      p16areaCoordinatorApprovedAt8,
      p16areaCoordinatorApprovedAt9,
      p16areaCoordinatorApprovedAt10,
      p16areaCoordinatorApprovedAt11,
      p16areaCoordinatorApprovedAt12,
      p16areaCoordinatorApprovedAt13,
      p16areaCoordinatorApprovedAt14,
      p16areaCoordinatorApprovedAt15,
      p16showQuality,
      p16quality,
      p16qualityEmail,
      p16qualityApproved1,
      p16qualityApproved2,
      p16qualityApproved3,
      p16qualityApproved4,
      p16qualityApproved5,
      p16qualityApproved6,
      p16qualityApproved7,
      p16qualityApproved8,
      p16qualityApproved9,
      p16qualityApproved10,
      p16qualityApproved11,
      p16qualityApproved12,
      p16qualityApproved13,
      p16qualityApproved14,
      p16qualityApproved15,
      p16qualityApprovedAt1,
      p16qualityApprovedAt2,
      p16qualityApprovedAt3,
      p16qualityApprovedAt4,
      p16qualityApprovedAt5,
      p16qualityApprovedAt6,
      p16qualityApprovedAt7,
      p16qualityApprovedAt8,
      p16qualityApprovedAt9,
      p16qualityApprovedAt10,
      p16qualityApprovedAt11,
      p16qualityApprovedAt12,
      p16qualityApprovedAt13,
      p16qualityApprovedAt14,
      p16qualityApprovedAt15,
      p16showTechnicalArea,
      p16technicalArea,
      p16technicalAreaEmail,
      p16technicalAreaApproved1,
      p16technicalAreaApproved2,
      p16technicalAreaApproved3,
      p16technicalAreaApproved4,
      p16technicalAreaApproved5,
      p16technicalAreaApproved6,
      p16technicalAreaApproved7,
      p16technicalAreaApproved8,
      p16technicalAreaApproved9,
      p16technicalAreaApproved10,
      p16technicalAreaApproved11,
      p16technicalAreaApproved12,
      p16technicalAreaApproved13,
      p16technicalAreaApproved14,
      p16technicalAreaApproved15,
      p16technicalAreaApprovedAt1,
      p16technicalAreaApprovedAt2,
      p16technicalAreaApprovedAt3,
      p16technicalAreaApprovedAt4,
      p16technicalAreaApprovedAt5,
      p16technicalAreaApprovedAt6,
      p16technicalAreaApprovedAt7,
      p16technicalAreaApprovedAt8,
      p16technicalAreaApprovedAt9,
      p16technicalAreaApprovedAt10,
      p16technicalAreaApprovedAt11,
      p16technicalAreaApprovedAt12,
      p16technicalAreaApprovedAt13,
      p16technicalAreaApprovedAt14,
      p16technicalAreaApprovedAt15,
      p16showPatrimonialSecurity,
      p16patrimonialSecurity,
      p16patrimonialSecurityEmail,
      p16patrimonialSecurityApproved1,
      p16patrimonialSecurityApproved2,
      p16patrimonialSecurityApproved3,
      p16patrimonialSecurityApproved4,
      p16patrimonialSecurityApproved5,
      p16patrimonialSecurityApproved6,
      p16patrimonialSecurityApproved7,
      p16patrimonialSecurityApproved8,
      p16patrimonialSecurityApproved9,
      p16patrimonialSecurityApproved10,
      p16patrimonialSecurityApproved11,
      p16patrimonialSecurityApproved12,
      p16patrimonialSecurityApproved13,
      p16patrimonialSecurityApproved14,
      p16patrimonialSecurityApproved15,
      p16patrimonialSecurityApprovedAt1,
      p16patrimonialSecurityApprovedAt2,
      p16patrimonialSecurityApprovedAt3,
      p16patrimonialSecurityApprovedAt4,
      p16patrimonialSecurityApprovedAt5,
      p16patrimonialSecurityApprovedAt6,
      p16patrimonialSecurityApprovedAt7,
      p16patrimonialSecurityApprovedAt8,
      p16patrimonialSecurityApprovedAt9,
      p16patrimonialSecurityApprovedAt10,
      p16patrimonialSecurityApprovedAt11,
      p16patrimonialSecurityApprovedAt12,
      p16patrimonialSecurityApprovedAt13,
      p16patrimonialSecurityApprovedAt14,
      p16patrimonialSecurityApprovedAt15,
      p16showExternalPrevencionist,
      p16externalPrevencionist,
      p16externalPrevencionistEmail,
      p16externalPrevencionistApproved1,
      p16externalPrevencionistApproved2,
      p16externalPrevencionistApproved3,
      p16externalPrevencionistApproved4,
      p16externalPrevencionistApproved5,
      p16externalPrevencionistApproved6,
      p16externalPrevencionistApproved7,
      p16externalPrevencionistApproved8,
      p16externalPrevencionistApproved9,
      p16externalPrevencionistApproved10,
      p16externalPrevencionistApproved11,
      p16externalPrevencionistApproved12,
      p16externalPrevencionistApproved13,
      p16externalPrevencionistApproved14,
      p16externalPrevencionistApproved15,
      p16externalPrevencionistApprovedAt1,
      p16externalPrevencionistApprovedAt2,
      p16externalPrevencionistApprovedAt3,
      p16externalPrevencionistApprovedAt4,
      p16externalPrevencionistApprovedAt5,
      p16externalPrevencionistApprovedAt6,
      p16externalPrevencionistApprovedAt7,
      p16externalPrevencionistApprovedAt8,
      p16externalPrevencionistApprovedAt9,
      p16externalPrevencionistApprovedAt10,
      p16externalPrevencionistApprovedAt11,
      p16externalPrevencionistApprovedAt12,
      p16externalPrevencionistApprovedAt13,
      p16externalPrevencionistApprovedAt14,
      p16externalPrevencionistApprovedAt15,
      p16showSass,
      p16sass,
      p16sassApproved1,
      p16sassApproved2,
      p16sassApproved3,
      p16sassApproved4,
      p16sassApproved5,
      p16sassApproved6,
      p16sassApproved7,
      p16sassApproved8,
      p16sassApproved9,
      p16sassApproved10,
      p16sassApproved11,
      p16sassApproved12,
      p16sassApproved13,
      p16sassApproved14,
      p16sassApproved15,
      p16sassApprovedAt1,
      p16sassApprovedAt2,
      p16sassApprovedAt3,
      p16sassApprovedAt4,
      p16sassApprovedAt5,
      p16sassApprovedAt6,
      p16sassApprovedAt7,
      p16sassApprovedAt8,
      p16sassApprovedAt9,
      p16sassApprovedAt10,
      p16sassApprovedAt11,
      p16sassApprovedAt12,
      p16sassApprovedAt13,
      p16sassApprovedAt14,
      p16sassApprovedAt15,
      p16sassAllowedList,
      p16paramedic,
    }

    try {
      console.log('%cformWithAllValues : %s', color1ConsoleLog, formWithAllValues)
      console.log('%cformWithAllValues p16sassAllowedList : %s', color1ConsoleLog, formWithAllValues.p16sassAllowedList)
      console.log('%cformWithAllValues entriesAdded : %s', color1ConsoleLog, formWithAllValues.entriesAdded)
      console.log('%newFormIdString : %s', color1ConsoleLog, newFormIdString)

      const approveformRef = doc(db, 'forms', newFormIdString)
      await updateDoc(approveformRef, formWithAllValues)

      if (p16workSupervisorEmail) {
        const workSupervisorRes = await fetch(`${nodeApiBaseUrl}/sendtotsu`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            formId: newFormIdString, p16workSupervisorEmail,
          }),
        })
        const jsonworkSupervisorRes = await workSupervisorRes.json()
        if (jsonworkSupervisorRes) {
          toast.success('El email al Supervisor del Trabajo se ha enviado correctamente')
        } else {
          toast.error('El email al Supervisor del Trabajo no se ha enviado')
        }
      }
      if (p16pepCoordinatorEmail) {
        const pepCoordinatorRes = await fetch(`${nodeApiBaseUrl}/sendtopep`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            formId: newFormIdString, p16pepCoordinatorEmail,
          }),
        })
        const jsonpepCoordinatorRes = await pepCoordinatorRes.json()
        if (jsonpepCoordinatorRes) {
          toast.success('El email al Coordinador PepsiCo se ha enviado correctamente')
        } else {
          toast.error('El email al Coordinador PepsiCo no se ha enviado')
        }
      }
      if (p16areaCoordinatorEmail) {
        const areaCoordinatorRes = await fetch(`${nodeApiBaseUrl}/sendtoaco`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            formId: newFormIdString, p16areaCoordinatorEmail,
          }),
        })
        const jsonareaCoordinatorRes = await areaCoordinatorRes.json()
        if (jsonareaCoordinatorRes) {
          toast.success('El email al Coordinador de Área se ha enviado correctamente')
        } else {
          toast.error('El email al Coordinador de Área no se ha enviado')
        }
      }
      if (p16qualityEmail) {
        const qualityRes = await fetch(`${nodeApiBaseUrl}/sendtoqt`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            formId: newFormIdString, p16qualityEmail,
          }),
        })
        const jsonqualityRes = await qualityRes.json()
        if (jsonqualityRes) {
          toast.success('El email a la persona asignada a Calidad se ha enviado correctamente')
        } else {
          toast.error('El email a la persona asignada a Calidad no se ha enviado')
        }
      }
      if (p16technicalAreaEmail) {
        const technicalAreaRes = await fetch(`${nodeApiBaseUrl}/sendtota`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            formId: newFormIdString, p16technicalAreaEmail,
          }),
        })
        const jsontechnicalAreaRes = await technicalAreaRes.json()
        if (jsontechnicalAreaRes) {
          toast.success('El email a la persona asignada a V°B° Area Técnica se ha enviado correctamente')
        } else {
          toast.error('El email a la persona asignada a V°B° Area Técnica no se ha enviado')
        }
      }
      if (p16patrimonialSecurityEmail) {
        const patrimonialSecurityRes = await fetch(`${nodeApiBaseUrl}/sendtops`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            formId: newFormIdString, p16patrimonialSecurityEmail,
          }),
        })
        const jsonpatrimonialSecurityRes = await patrimonialSecurityRes.json()
        if (jsonpatrimonialSecurityRes) {
          toast.success('El email a la persona asignada a Seguridad Patrimonial se ha enviado correctamente')
        } else {
          toast.error('El email a la persona asignada a Seguridad Patrimonial no se ha enviado')
        }
      }
      if (p16externalPrevencionistEmail) {
        const externalPrevencionistRes = await fetch(`${nodeApiBaseUrl}/sendtoep`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            formId: newFormIdString, p16externalPrevencionistEmail,
          }),
        })
        const jsonexternalPrevencionistRes = await externalPrevencionistRes.json()
        if (jsonexternalPrevencionistRes) {
          toast.success('El email al Prevencionista Externo se ha enviado correctamente')
        } else {
          toast.error('El email al Prevencionista Externo no se ha enviado')
        }
      }

      // Email 1
      if (p16sassAllowedList.includes('khqteUe99hZcFHGr2hTWAPqcjwD2')) {
        const email1Res = await fetch(`${nodeApiBaseUrl}/sendtoemail1`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            formId: newFormIdString,
            p16sassEmail1: 'alvarodev72013941@outlook.com',
          }),
        })
        const jsonemail1Res = await email1Res.json()
        if (jsonemail1Res) {
          toast.success('El email al email1 se ha enviado correctamente')
        } else {
          toast.error('El email al email1 no se ha enviado')
        }
      }
      // Aaron Calderon
      if (p16sassAllowedList.includes('j5ZHSzwXm0XHRfLGy5Grz6TwiuA2')) {
        const aaronCalderonRes = await fetch(`${nodeApiBaseUrl}/sendtoaaroncalderon`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            formId: newFormIdString,
            p16sassAaronCalderonEmail: 'aaron.calderon.contractor@pepsico.com',
          }),
        })
        const jsonaaronCalderonRes = await aaronCalderonRes.json()
        if (jsonaaronCalderonRes) {
          toast.success('El email a Aaron Calderon se ha enviado correctamente')
        } else {
          toast.error('El email a Aaron Calderon no se ha enviado')
        }
      }
      // Cesar Salas
      if (p16sassAllowedList.includes('0g85M2QTKBSfgIm19MfFK6msdl63')) {
        const cesarSalasRes = await fetch(`${nodeApiBaseUrl}/sendtocesarsalas`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            formId: newFormIdString,
            p16sassCesarSalasEmail: 'cesarenrique.salascarrero@pepsico.com',
          }),
        })
        const jsoncesarSalasRes = await cesarSalasRes.json()
        if (jsoncesarSalasRes) {
          toast.success('El email a Cesar Salas se ha enviado correctamente')
        } else {
          toast.error('El email a Cesar Salas no se ha enviado')
        }
      }
      // Diego Neira
      if (p16sassAllowedList.includes('qNaGcdkV74ft1PptYcBrSqCWB0P2')) {
        const diegoNeiraRes = await fetch(`${nodeApiBaseUrl}/sendtodiegoneira`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            formId: newFormIdString,
            p16sassDiegoNeiraEmail: 'diegoignacio.neirarocuant@pepsico.com',
          }),
        })
        const jsondiegoNeiraRes = await diegoNeiraRes.json()
        if (jsondiegoNeiraRes) {
          toast.success('El email a Diego Neira se ha enviado correctamente')
        } else {
          toast.error('El email a Diego Neira no se ha enviado')
        }
      }
      // Gonzalo Perez
      if (p16sassAllowedList.includes('YGDBJ0xeCrh6G2nid5I7i5YCAYY2')) {
        const gonzaloPerezRes = await fetch(`${nodeApiBaseUrl}/sendtogonzaloperez`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            formId: newFormIdString,
            p16sassGonzaloPerezEmail: 'gonzalo.perez1@pepsico.com',
          }),
        })
        const jsongonzaloPerezRes = await gonzaloPerezRes.json()
        if (jsongonzaloPerezRes) {
          toast.success('El email a Gonzalo Perez se ha enviado correctamente')
        } else {
          toast.error('El email a Gonzalo Perez no se ha enviado')
        }
      }
      // Jaime Fonseca
      if (p16sassAllowedList.includes('ziObrv7ctgUsomFvC95GIscvjEb2')) {
        const jaimeFonsecaRes = await fetch(`${nodeApiBaseUrl}/sendtojaimefonseca`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            formId: newFormIdString,
            p16sassJaimeFonsecaEmail: 'jaimesebastian.fonsecamarchant@pepsico.com',
          }),
        })
        const jsonjaimeFonsecaRes = await jaimeFonsecaRes.json()
        if (jsonjaimeFonsecaRes) {
          toast.success('El email a Jaime Fonseca se ha enviado correctamente')
        } else {
          toast.error('El email a Jaime Fonseca no se ha enviado')
        }
      }
      // John Cabrera
      if (p16sassAllowedList.includes('MYXW4y4JAUXqIMLleZP12PEb0O63')) {
        const johnCabreraRes = await fetch(`${nodeApiBaseUrl}/sendtojohncabrera`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            formId: newFormIdString,
            p16sassJohnCabreraEmail: 'drjohncabrera@gmail.com',
          }),
        })
        const jsonjohnCabreraRes = await johnCabreraRes.json()
        if (jsonjohnCabreraRes) {
          toast.success('El email a John Cabrera se ha enviado correctamente')
        } else {
          toast.error('El email a John Cabrera no se ha enviado')
        }
      }
      // Lucia Mardones
      if (p16sassAllowedList.includes('QJxTXd7mHghpL1crOfxvFm1NZmh1')) {
        const luciaMardonesRes = await fetch(`${nodeApiBaseUrl}/sendtoluciamardones`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            formId: newFormIdString,
            p16sassLuciaMardonesEmail: 'lucia.mardones@pepsico.com',
          }),
        })
        const jsonluciaMardonesRes = await luciaMardonesRes.json()
        if (jsonluciaMardonesRes) {
          toast.success('El email a Lucia Mardones se ha enviado correctamente')
        } else {
          toast.error('El email a Lucia Mardones no se ha enviado')
        }
      }
      // Mariana Lemus
      if (p16sassAllowedList.includes('SJ59ObQ38mZZ5kXUmPN7l1b04N02')) {
        const marianaLemusRes = await fetch(`${nodeApiBaseUrl}/sendtomarianalemus`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            formId: newFormIdString,
            p16sassMarianaLemusEmail: 'mariana.lemus@pepsico.com',
          }),
        })
        const jsonmarianaLemusRes = await marianaLemusRes.json()
        if (jsonmarianaLemusRes) {
          toast.success('El email a Mariana Lemus se ha enviado correctamente')
        } else {
          toast.error('El email a Mariana Lemus no se ha enviado')
        }
      }
      // Marlite Torres
      if (p16sassAllowedList.includes('GtDdJPjZ8sUZR1vdXk8htSG8ycN2')) {
        const marliteTorresRes = await fetch(`${nodeApiBaseUrl}/sendtomarlitetorres`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            formId: newFormIdString,
            p16sassMarliteTorresEmail: 'marlite.torres@pepsico.com',
          }),
        })
        const jsonmarliteTorresRes = await marliteTorresRes.json()
        if (jsonmarliteTorresRes) {
          toast.success('El email a Marlite Torres se ha enviado correctamente')
        } else {
          toast.error('El email a Marlite Torres no se ha enviado')
        }
      }
      // Matias Lira
      if (p16sassAllowedList.includes('D6iXBu9P0kYeWZlzSir3Ohww1Ff2')) {
        const matiasLiraRes = await fetch(`${nodeApiBaseUrl}/sendtomatiaslira`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            formId: newFormIdString,
            p16sassMatiasLiraEmail: 'matias.lirawatkins@pepsico.com',
          }),
        })
        const jsonmatiasLiraRes = await matiasLiraRes.json()
        if (jsonmatiasLiraRes) {
          toast.success('El email a Matias Lira se ha enviado correctamente')
        } else {
          toast.error('El email a Matias Lira no se ha enviado')
        }
      }
      // Nicolas Altamirano
      if (p16sassAllowedList.includes('FI98jy4JtsQu9pBevQniWf5p3ct2')) {
        const nicolasAltamiranoRes = await fetch(`${nodeApiBaseUrl}/sendtonicolasaltamirano`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            formId: newFormIdString,
            p16sassNicolasAltamiranoEmail: 'naltamirano.l@gmail.com',
          }),
        })
        const jsonnicolasAltamiranoRes = await nicolasAltamiranoRes.json()
        if (jsonnicolasAltamiranoRes) {
          toast.success('El email a Nicolas Altamirano se ha enviado correctamente')
        } else {
          toast.error('El email a Nicolas Altamirano no se ha enviado')
        }
      }
      // Romina Seguel
      if (p16sassAllowedList.includes('QMpkgWpdgeeWNpHxk5PjdhCfwxJ3')) {
        const rominaSeguelRes = await fetch(`${nodeApiBaseUrl}/sendtorominaseguel`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            formId: newFormIdString,
            p16sassRominaSeguelEmail: 'romina.seguel.contractor@pepsico.com',
          }),
        })
        const jsonrominaSeguelRes = await rominaSeguelRes.json()
        if (jsonrominaSeguelRes) {
          toast.success('El email a Romina Seguel se ha enviado correctamente')
        } else {
          toast.error('El email a Romina Seguel no se ha enviado')
        }
      }
      // Stefani Cataldo
      if (p16sassAllowedList.includes('9MvTXZMycHc0mfRPhsfHVkMrMAw1')) {
        const stefaniCataldoRes = await fetch(`${nodeApiBaseUrl}/sendtostefanicataldo`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            formId: newFormIdString,
            p16sassStefaniCataldoEmail: 's.cataldoramos@gmail.com',
          }),
        })
        const jsonstefaniCataldoRes = await stefaniCataldoRes.json()
        if (jsonstefaniCataldoRes) {
          toast.success('El email a Stefani Cataldo se ha enviado correctamente')
        } else {
          toast.error('El email a Stefani Cataldo no se ha enviado')
        }
      }
      // Yesenia Morales
      if (p16sassAllowedList.includes('aOVTsxZNwQW6QNRyFgojO074iwC2')) {
        const yeseniaMoralesRes = await fetch(`${nodeApiBaseUrl}/sendtoyeseniamorales`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            formId: newFormIdString,
            p16sassYeseniaMoralesEmail: 'yesenia.morales@pepsico.com',
          }),
        })
        const jsonyeseniaMoralesRes = await yeseniaMoralesRes.json()
        if (jsonyeseniaMoralesRes) {
          toast.success('El email a Yesenia Morales se ha enviado correctamente')
        } else {
          toast.error('El email a Yesenia Morales no se ha enviado')
        }
      }

      if ((p6Show) || (p7Show)) {
        const paramedicResponse = await fetch(
          `${nodeApiBaseUrl}/sendtoparamedic`,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ formId: newFormIdString }),
          },
        )
        const responseParamedicMessage = await paramedicResponse.json()
        if (responseParamedicMessage) {
          toast.success('El email al paramédico se ha enviado correctamente')
        }
      }

      toast.success('El permiso de trabajo se ha almacenado correctamente')
      history.push('/')
    } catch (error) {
      console.log('Error al almacenar el permiso de trabajo : ', error)
      toast.error('El permiso de trabajo no se ha almacenado')
    }
  }

  const checkIfThereIsASelectionInSelects = () => {
    if (!p16workSupervisor
      && !p16pepCoordinator
      && !p16areaCoordinator
      && !p16quality
      && !p16technicalArea
      && !p16patrimonialSecurity
      && !p16externalPrevencionist
      && !p16sass && !(p16sassAllowedList.length > 0)) {
      toast.error('Por favor realice al menos una selección')
    } else {
      saveForm()
    }
  }

  return (
    <DashboardLayout>
      <Slide direction="right" in mountOnEnter unmountOnExit>
        <Grid sx={{ mt: 4 }} container justifyContent="center">
          <Grid item xs={10} sm={9} md={5} lg={7}>
            <Typography
              sx={{ mb: 4 }}
              align="center"
              variant="h6"
              color="text.secondary"
              gutterBottom
            >
              NOMBRE Y FIRMA DE CONTROL OBLIGATORIO
            </Typography>

            <Box sx={{ minWidth: 120, mb: 4 }}>
              <Grid display="flex" justifyContent="center" item xs={12} marginBottom={2}>
                <FormControlLabel
                  control={(
                    <YellowSwitch
                      checked={p16showWorkSupervisor}
                      onChange={(event) => dispatch(setp16showWorkSupervisor(event.target.checked))}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  )}
                  label={p16showWorkSupervisor ? 'La selección de Supervisor del Trabajo aplica' : 'La selección de Supervisor del Trabajo no aplica'}
                />
              </Grid>

              <Slide direction="up" in={p16showWorkSupervisor} container={containerp16showWorkSupervisor.current}>
                <Box
                  component="div"
                  sx={{
                    display: p16showWorkSupervisor ? 'flex' : 'none',
                    justifyContent: 'center',
                  }}
                  ref={containerp16showWorkSupervisor}
                >
                  <Grid item display="flex" justifyContent="center" xs={10} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="work-supervisor">Supervisor del Trabajo</InputLabel>
                      <Select
                        labelId="work-supervisor"
                        id="work-supervisor"
                        value={p16workSupervisor}
                        label="Supervisor del Trabajo"
                        onChange={(event) => dispatch(setp16workSupervisor(event.target.value))}
                      >
                        {workSupervisorList.map((workSupervisorPerson) => (
                          <MenuItem
                            key={workSupervisorPerson?.uid}
                            value={workSupervisorPerson?.uid}
                          >
                            {workSupervisorPerson?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Box>
              </Slide>

            </Box>

            <Box sx={{ minWidth: 120, mb: 4 }}>
              <Grid display="flex" justifyContent="center" item xs={12} marginBottom={2}>
                <FormControlLabel
                  control={(
                    <YellowSwitch
                      checked={p16showPepCoordinator}
                      onChange={(event) => dispatch(setp16showPepCoordinator(event.target.checked))}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  )}
                  label={p16showPepCoordinator ? 'La selección de Coordinador PepsiCo aplica' : 'La selección de Coordinador PepsiCo no aplica'}
                />
              </Grid>
              <Slide direction="up" in={p16showPepCoordinator} container={containerp16showPepCoordinator.current}>
                <Box
                  component="div"
                  sx={{
                    display: p16showPepCoordinator ? 'flex' : 'none',
                    justifyContent: 'center',
                  }}
                  ref={containerp16showPepCoordinator}
                >
                  <Grid item display="flex" justifyContent="center" xs={10} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="coordinator">Coordinador PepsiCo</InputLabel>
                      <Select
                        labelId="coordinator"
                        id="coordinator"
                        value={p16pepCoordinator}
                        label="Coordinador PepsiCo"
                        onChange={(event) => dispatch(setp16pepCoordinator(event.target.value))}
                      >
                        {coordinatorList.map((coordinatorPerson) => (
                          <MenuItem key={coordinatorPerson?.uid} value={coordinatorPerson?.uid}>
                            {coordinatorPerson?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Box>
              </Slide>
            </Box>

            <Box sx={{ minWidth: 120, mb: 4 }}>
              <Grid display="flex" justifyContent="center" item xs={12} marginBottom={2}>
                <FormControlLabel
                  control={(
                    <YellowSwitch
                      checked={p16showAreaCoordinator}
                      onChange={
                        (event) => dispatch(setp16showAreaCoordinator(event.target.checked))
                      }
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  )}
                  label={p16showAreaCoordinator ? 'La selección de Coordinador de Área aplica' : 'La selección de Coordinador de Área no aplica'}
                />
              </Grid>

              <Box
                component="div"
                sx={{
                  display: p16showAreaCoordinator ? 'flex' : 'none',
                  justifyContent: 'center',
                }}
                ref={containerp16showAreaCoordinator}
              >
                <Slide direction="up" in={p16showAreaCoordinator} container={containerp16showAreaCoordinator.current}>
                  <Grid item display="flex" justifyContent="center" xs={10} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="area-coordinator">Coordinador de Área</InputLabel>
                      <Select
                        labelId="area-coordinator"
                        id="area-coordinator"
                        value={p16areaCoordinator}
                        label="Coordinador de Área"
                        onChange={(event) => dispatch(setp16areaCoordinator(event.target.value))}
                      >
                        {areaCoordinatorList.map((areaCoordinatorPerson) => (
                          <MenuItem
                            key={areaCoordinatorPerson?.uid}
                            value={areaCoordinatorPerson?.uid}
                          >
                            {areaCoordinatorPerson?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Slide>
              </Box>
            </Box>

            <Box sx={{ minWidth: 120, mb: 4 }}>
              <Grid display="flex" justifyContent="center" item xs={12} marginBottom={2}>
                <FormControlLabel
                  control={(
                    <YellowSwitch
                      checked={p16showQuality}
                      onChange={(event) => dispatch(setp16showQuality(event.target.checked))}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  )}
                  label={p16showQuality ? 'La selección de Calidad aplica' : 'La selección de Calidad no aplica'}
                />
              </Grid>

              <Box
                component="div"
                sx={{
                  display: p16showQuality ? 'flex' : 'none',
                  justifyContent: 'center',
                }}
                ref={containerp16showQuality}
              >
                <Slide direction="up" in={p16showQuality} container={containerp16showQuality.current}>
                  <Grid item display="flex" justifyContent="center" xs={10} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="quality-label">Calidad</InputLabel>
                      <Select
                        labelId="quality-label"
                        id="quality"
                        value={p16quality}
                        label="Calidad"
                        onChange={(event) => dispatch(setp16quality(event.target.value))}
                      >
                        {qualityList.map((qualityPerson) => (
                          <MenuItem key={qualityPerson?.uid} value={qualityPerson?.uid}>
                            {qualityPerson?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Slide>
              </Box>
            </Box>

            <Box sx={{ minWidth: 120, mb: 4 }}>
              <Grid display="flex" justifyContent="center" item xs={12} marginBottom={2}>
                <FormControlLabel
                  control={(
                    <YellowSwitch
                      checked={p16showTechnicalArea}
                      onChange={(event) => dispatch(setp16showTechnicalArea(event.target.checked))}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  )}
                  label={p16showTechnicalArea ? 'La selección de V°B° Area Técnica aplica' : 'La selección de V°B° Area Técnica no aplica'}
                />
              </Grid>
              <Box
                component="div"
                sx={{
                  display: p16showTechnicalArea ? 'flex' : 'none',
                  justifyContent: 'center',
                }}
                ref={containerp16showAreaCoordinator}
              >
                <Slide direction="up" in={p16showTechnicalArea} container={containerp16showTechnicalArea.current}>
                  <Grid item display="flex" justifyContent="center" xs={10} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="technical-area-label">V°B° Area Técnica</InputLabel>
                      <Select
                        labelId="technical-area-label"
                        id="technical-area"
                        value={p16technicalArea}
                        label="V°B° Area Técnica"
                        onChange={(event) => dispatch(setp16technicalArea(event.target.value))}
                      >
                        {technicalAreaList.map((technicalAreaPerson) => (
                          <MenuItem
                            key={technicalAreaPerson?.uid}
                            value={technicalAreaPerson?.uid}
                          >
                            {technicalAreaPerson?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Slide>
              </Box>
            </Box>

            <Box sx={{ minWidth: 120, mb: 4 }}>
              <Grid display="flex" justifyContent="center" item xs={12} marginBottom={2}>
                <FormControlLabel
                  control={(
                    <YellowSwitch
                      checked={p16showPatrimonialSecurity}
                      onChange={(event) => dispatch(setp16showPatrimonialSecurity(
                        event.target.checked,
                      ))}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  )}
                  label={p16showPatrimonialSecurity ? 'La selección de Seguridad Patrimonial aplica' : 'La selección de Seguridad Patrimonial no aplica'}
                />
              </Grid>
              <Box
                component="div"
                sx={{
                  display: p16showPatrimonialSecurity ? 'flex' : 'none',
                  justifyContent: 'center',
                }}
                ref={containerp16showPatrimonialSecurity}
              >
                <Slide direction="up" in={p16showPatrimonialSecurity} container={containerp16showPatrimonialSecurity.current}>
                  <Grid item display="flex" justifyContent="center" xs={10} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="patrimonial-security-label">Seguridad Patrimonial</InputLabel>
                      <Select
                        labelId="patrimonial-security-label"
                        id="patrimonial-security"
                        value={p16patrimonialSecurity}
                        label="Seguridad Patrimonial"
                        onChange={
                          (event) => dispatch(setp16patrimonialSecurity(event.target.value))
                        }
                      >
                        {patrimonialSecurityList.map((patrimonialSecurityPerson) => (
                          <MenuItem
                            key={patrimonialSecurityPerson?.uid}
                            value={patrimonialSecurityPerson?.uid}
                          >
                            {patrimonialSecurityPerson?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Slide>
              </Box>
            </Box>

            <Box sx={{ minWidth: 120, mb: 4 }}>
              <Grid display="flex" justifyContent="center" item xs={12} marginBottom={2}>
                <FormControlLabel
                  control={(
                    <YellowSwitch
                      checked={p16showExternalPrevencionist}
                      onChange={(event) => dispatch(setp16showExternalPrevencionist(
                        event.target.checked,
                      ))}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  )}
                  label={p16showExternalPrevencionist ? 'La selección de Prevencionista Externo aplica' : 'La selección de Prevencionista Externo no aplica'}
                />
              </Grid>
              <Box
                component="div"
                sx={{
                  display: p16showExternalPrevencionist ? 'flex' : 'none',
                  justifyContent: 'center',
                }}
                ref={containerp16showExternalPrevencionist}
              >
                <Slide direction="up" in={p16showExternalPrevencionist} container={containerp16showExternalPrevencionist.current}>
                  <Grid item display="flex" justifyContent="center" xs={10} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="extern-prevencionist-label">Prevencionista Externo</InputLabel>
                      <Select
                        labelId="extern-prevencionist-label"
                        id="extern-prevencionist"
                        value={p16externalPrevencionist}
                        label="Seguridad Patrimonial"
                        onChange={
                          (event) => dispatch(setp16externalPrevencionist(event.target.value))
                        }
                      >
                        {externPrevencionistList.map((externPrevencionistPerson) => (
                          <MenuItem
                            key={externPrevencionistPerson?.uid}
                            value={externPrevencionistPerson?.uid}
                          >
                            {externPrevencionistPerson?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Slide>
              </Box>
            </Box>

            <Box sx={{ minWidth: 120, mb: 4 }}>
              <Grid display="flex" justifyContent="center" item xs={12} marginBottom={2}>
                <FormControlLabel
                  control={(
                    <YellowSwitch
                      checked={p16showSass}
                      onChange={(event) => dispatch(setp16showSass(event.target.checked))}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  )}
                  label={p16showSass ? 'La selección de SASS aplica' : 'La selección de SASS no aplica'}
                />
              </Grid>
              <Box
                component="div"
                sx={{
                  display: p16showSass ? 'flex' : 'none',
                  justifyContent: 'center',
                }}
                ref={containerp16showSass}
              >
                <Slide direction="up" in={p16showSass} container={containerp16showSass.current}>
                  <Grid item display="flex" justifyContent="center" xs={10} md={6}>
                    <FormGroup>
                      {sassList.map((sassPerson) => (
                        <FormControlLabel
                          key={sassPerson?.uid}
                          control={(
                            <Checkbox
                              checked={p16sassAllowedList.includes(sassPerson?.uid)}
                              name={sassPerson?.name}
                              value={sassPerson?.uid}
                              onChange={(event) => {
                                dispatch(
                                  setp16sassAllowedList(
                                    event.target.value,
                                  ),
                                )
                                dispatch(setp16sass('p16sass'))
                              }}
                            />
                          )}
                          label={sassPerson?.name}
                        />
                      ))}
                    </FormGroup>
                  </Grid>
                </Slide>
              </Box>
            </Box>
            <Grid sx={{ mb: 2 }} container justifyContent="space-between">
              <Grid
                item
                xs={12}
                md={5}
                sx={{ mb: 2 }}
              >
                <ColorButton
                  fullWidth
                  startIcon={<ArrowLeftIcon />}
                  onClick={goToCreateStep15}
                >
                  Ir a la página anterior
                </ColorButton>
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
              >
                <ColorButton
                  fullWidth
                  endIcon={<SaveIcon />}
                  onClick={checkIfThereIsASelectionInSelects}
                >
                  Guardar permiso de trabajo
                </ColorButton>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </DashboardLayout>
  )
}

export default CreateStep16