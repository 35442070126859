import React from 'react'
import { Children } from '../types'
import {
  doc, addDoc, updateDoc, collection, db,
} from '../config/firebaseConfig'
import useAuth from '../hooks/useAuth'

type NewFormIdContextT = {
  newFormId: string | null;
  setNewFormId: React.Dispatch<React.SetStateAction<string | null>>;
  formIdActivator: boolean;
  setFormIdActivator: React.Dispatch<React.SetStateAction<boolean>>;
}
const defaultData: NewFormIdContextT = {
  newFormId: null,
  setNewFormId: () => { },
  formIdActivator: false,
  setFormIdActivator: () => { },
}

const NewFormIdContext = React.createContext<NewFormIdContextT>(defaultData)

const NewFormIdProvider = ({ children }: Children) => {
  const [
    newFormId, setNewFormId,
  ] = React.useState<string | null>(null)
  const [
    formIdActivator, setFormIdActivator,
  ] = React.useState<boolean>(false)

  const { user } = useAuth()

  React.useEffect(() => {
    (async () => {
      if (formIdActivator) {
        const docRef = await addDoc(collection(db, 'forms'), {
          newFormIdentifier: '',
          newFormCreatedAt: new Date(),
          newFormPersonEmailWhoDid: user?.email,
          newFormp1FilledOut: true,
          newFormp2FilledOut: false,
          newFormp3FilledOut: false,
          newFormp4FilledOut: false,
          newFormp5FilledOut: false,
          newFormp6FilledOut: false,
          newFormp7FilledOut: false,
          newFormp8FilledOut: false,
          newFormp9FilledOut: false,
          newFormp10FilledOut: false,
          newFormp11FilledOut: false,
          newFormp12FilledOut: false,
          newFormp13FilledOut: false,
          newFormp14FilledOut: false,
          newFormp15FilledOut: false,
          newFormp16FilledOut: false,
          paramedicApprovedAllWorkers: false,
          peopleApprovedAllPages: false,
        })
        console.log('El identificador del nuevo permiso de trabajo : ', docRef.id)
        setNewFormId(docRef.id)
        const newIdentifierRef = doc(db, 'forms', docRef.id)
        await updateDoc(newIdentifierRef, {
          newFormIdentifier: docRef.id,
        })
        setFormIdActivator(false)
      }
    })()
  }, [formIdActivator])

  return (
    // eslint-disable-next-line
    <NewFormIdContext.Provider value={{
      newFormId,
      setNewFormId,
      formIdActivator,
      setFormIdActivator,
    }}
    >
      {children}
    </NewFormIdContext.Provider>
  )
}

export { NewFormIdProvider, NewFormIdContext }