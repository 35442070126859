import React from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Button, { ButtonProps } from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { yellow } from '@mui/material/colors'
import Slide from '@mui/material/Slide'
import Box from '@mui/material/Box'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import YellowSwitch from '../../common/YellowSwitch'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { entireStore } from '../../app/store'
import { allFormActions } from '../../features/form/formSlice'
import DashboardLayout from '../layouts/DashboardLayout'
// import ColorButton from './ColorButton'
import useNewFormId from '../../hooks/useNewFormId'
import { db, doc, updateDoc } from '../../config/firebaseConfig'

const CreateStep10 = () => {
  console.log('CreateStep10')

  const { newFormId } = useNewFormId()

  const {
    p10show,
    p10chemName1,
    p10chemName1health,
    p10chemName1inflam,
    p10chemName1reactivity,
    p10chemName1specific,
    p10chemName2,
    p10chemName2health,
    p10chemName2inflam,
    p10chemName2reactivity,
    p10chemName2specific,
    p10chemName3,
    p10chemName3health,
    p10chemName3inflam,
    p10chemName3reactivity,
    p10chemName3specific,
    p10chemName4,
    p10chemName4health,
    p10chemName4inflam,
    p10chemName4reactivity,
    p10chemName4specific,
    p10chemName5,
    p10chemName5health,
    p10chemName5inflam,
    p10chemName5reactivity,
    p10chemName5specific,

  } = useAppSelector(entireStore)
  const {
    setp10show,
    setp10chemName1,
    setp10chemName1health,
    setp10chemName1inflam,
    setp10chemName1reactivity,
    setp10chemName1specific,
    setp10chemName2,
    setp10chemName2health,
    setp10chemName2inflam,
    setp10chemName2reactivity,
    setp10chemName2specific,
    setp10chemName3,
    setp10chemName3health,
    setp10chemName3inflam,
    setp10chemName3reactivity,
    setp10chemName3specific,
    setp10chemName4,
    setp10chemName4health,
    setp10chemName4inflam,
    setp10chemName4reactivity,
    setp10chemName4specific,
    setp10chemName5,
    setp10chemName5health,
    setp10chemName5inflam,
    setp10chemName5reactivity,
    setp10chemName5specific,

  } = allFormActions
  const dispatch = useAppDispatch()

  // eslint-disable-next-line
  const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: 'white',
    backgroundColor: '#fbc02d',
    '&:hover': {
      backgroundColor: yellow[700],
    },
  }))

  const history = useHistory()

  const goToCreateStep9 = () => {
    history.push(`/create/9/${newFormId}`)
  }

  const goToCreateStep11 = async () => {
    if (p10show && !p10chemName1) {
      toast.error('Por favor ingrese el nombre del químico 1 a utilizar')
    } else {
      // history.push(`/create/11/${newFormId}`)
      const formRef = doc(db, 'forms', newFormId as string)
      try {
        await updateDoc(formRef, {
          newFormp10FilledOut: true,
          newFormp10FilledOutDate: new Date(),
          p10show,
          p10chemName1,
          p10chemName1health,
          p10chemName1inflam,
          p10chemName1reactivity,
          p10chemName1specific,
          p10chemName2,
          p10chemName2health,
          p10chemName2inflam,
          p10chemName2reactivity,
          p10chemName2specific,
          p10chemName3,
          p10chemName3health,
          p10chemName3inflam,
          p10chemName3reactivity,
          p10chemName3specific,
          p10chemName4,
          p10chemName4health,
          p10chemName4inflam,
          p10chemName4reactivity,
          p10chemName4specific,
          p10chemName5,
          p10chemName5health,
          p10chemName5inflam,
          p10chemName5reactivity,
          p10chemName5specific,
        })
        toast.success('El permiso de trabajo se actualizó correctamente')
        history.push(`/create/11/${newFormId}`)
      } catch (updateError) {
        toast.error(`Error: ${updateError}`)
      }
    }
  }

  return (
    <DashboardLayout>
      <Slide direction="right" in mountOnEnter unmountOnExit>
        <Grid sx={{ mt: 4 }} container justifyContent="center" spacing={2}>
          <Grid item xs={10} sm={9} md={5} lg={7}>

            <Typography
              sx={{ mb: 2 }}
              align="center"
              variant="h6"
              color="text.secondary"
              gutterBottom
            >
              USO DE QUÍMICOS
            </Typography>

            <Grid display="flex" justifyContent="center" item xs={12}>
              <FormControlLabel
                control={(
                  <YellowSwitch
                    checked={p10show}
                    onChange={(event) => dispatch(setp10show(event.target.checked))}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                )}
                label={p10show ? 'Esta página aplica' : 'Esta página no aplica'}
              />
            </Grid>

            <Box
              component="div"
              sx={{
                display: p10show ? 'block' : 'none',
              }}
            >
              <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="1 - Nombre del quimico a utilizar"
                variant="outlined"
                value={p10chemName1}
                onChange={(e) => dispatch(setp10chemName1(e.target.value))}
              />
              <Grid display="flex">
                <TextField
                  sx={{ mb: 2 }}
                  fullWidth
                  label="Salud"
                  variant="outlined"
                  value={p10chemName1health}
                  onChange={(e) => dispatch(setp10chemName1health(e.target.value))}
                />
                <TextField
                  sx={{ mb: 2, ml: 2 }}
                  fullWidth
                  label="Inflamable"
                  variant="outlined"
                  value={p10chemName1inflam}
                  onChange={(e) => dispatch(setp10chemName1inflam(e.target.value))}
                />
              </Grid>
              <Grid display="flex">
                <TextField
                  sx={{ mb: 2 }}
                  fullWidth
                  label="Reactividad"
                  variant="outlined"
                  value={p10chemName1reactivity}
                  onChange={(e) => dispatch(setp10chemName1reactivity(e.target.value))}
                />
                <TextField
                  sx={{ mb: 2, ml: 2 }}
                  fullWidth
                  label="Específico"
                  variant="outlined"
                  value={p10chemName1specific}
                  onChange={(e) => dispatch(setp10chemName1specific(e.target.value))}
                />
              </Grid>

              <TextField
                sx={{ mt: 4, mb: 2 }}
                fullWidth
                label="2 - Nombre del quimico a utilizar"
                variant="outlined"
                value={p10chemName2}
                onChange={(e) => dispatch(setp10chemName2(e.target.value))}
              />
              <Grid display="flex">
                <TextField
                  sx={{ mb: 2 }}
                  fullWidth
                  label="Salud"
                  variant="outlined"
                  value={p10chemName2health}
                  onChange={(e) => dispatch(setp10chemName2health(e.target.value))}
                />
                <TextField
                  sx={{ mb: 2, ml: 2 }}
                  fullWidth
                  label="Inflamable"
                  variant="outlined"
                  value={p10chemName2inflam}
                  onChange={(e) => dispatch(setp10chemName2inflam(e.target.value))}
                />
              </Grid>
              <Grid display="flex">
                <TextField
                  sx={{ mb: 2 }}
                  fullWidth
                  label="Reactividad"
                  variant="outlined"
                  value={p10chemName2reactivity}
                  onChange={(e) => dispatch(setp10chemName2reactivity(e.target.value))}
                />
                <TextField
                  sx={{ mb: 2, ml: 2 }}
                  fullWidth
                  label="Específico"
                  variant="outlined"
                  value={p10chemName2specific}
                  onChange={(e) => dispatch(setp10chemName2specific(e.target.value))}
                />
              </Grid>

              <TextField
                sx={{ mt: 4, mb: 2 }}
                fullWidth
                label="3 - Nombre del quimico a utilizar"
                variant="outlined"
                value={p10chemName3}
                onChange={(e) => dispatch(setp10chemName3(e.target.value))}
              />
              <Grid display="flex">
                <TextField
                  sx={{ mb: 2 }}
                  fullWidth
                  label="Salud"
                  variant="outlined"
                  value={p10chemName3health}
                  onChange={(e) => dispatch(setp10chemName3health(e.target.value))}
                />
                <TextField
                  sx={{ mb: 2, ml: 2 }}
                  fullWidth
                  label="Inflamable"
                  variant="outlined"
                  value={p10chemName3inflam}
                  onChange={(e) => dispatch(setp10chemName3inflam(e.target.value))}
                />
              </Grid>
              <Grid display="flex">
                <TextField
                  sx={{ mb: 2 }}
                  fullWidth
                  label="Reactividad"
                  variant="outlined"
                  value={p10chemName3reactivity}
                  onChange={(e) => dispatch(setp10chemName3reactivity(e.target.value))}
                />
                <TextField
                  sx={{ mb: 2, ml: 2 }}
                  fullWidth
                  label="Específico"
                  variant="outlined"
                  value={p10chemName3specific}
                  onChange={(e) => dispatch(setp10chemName3specific(e.target.value))}
                />
              </Grid>

              <TextField
                sx={{ mt: 4, mb: 2 }}
                fullWidth
                label="4 - Nombre del quimico a utilizar"
                variant="outlined"
                value={p10chemName4}
                onChange={(e) => dispatch(setp10chemName4(e.target.value))}
              />
              <Grid display="flex">
                <TextField
                  sx={{ mb: 2 }}
                  fullWidth
                  label="Salud"
                  variant="outlined"
                  value={p10chemName4health}
                  onChange={(e) => dispatch(setp10chemName4health(e.target.value))}
                />
                <TextField
                  sx={{ mb: 2, ml: 2 }}
                  fullWidth
                  label="Inflamable"
                  variant="outlined"
                  value={p10chemName4inflam}
                  onChange={(e) => dispatch(setp10chemName4inflam(e.target.value))}
                />
              </Grid>
              <Grid display="flex">
                <TextField
                  sx={{ mb: 2 }}
                  fullWidth
                  label="Reactividad"
                  variant="outlined"
                  value={p10chemName4reactivity}
                  onChange={(e) => dispatch(setp10chemName4reactivity(e.target.value))}
                />
                <TextField
                  sx={{ mb: 2, ml: 2 }}
                  fullWidth
                  label="Específico"
                  variant="outlined"
                  value={p10chemName4specific}
                  onChange={(e) => dispatch(setp10chemName4specific(e.target.value))}
                />
              </Grid>

              <TextField
                sx={{ mt: 4, mb: 2 }}
                fullWidth
                label="5 - Nombre del quimico a utilizar"
                variant="outlined"
                value={p10chemName5}
                onChange={(e) => dispatch(setp10chemName5(e.target.value))}
              />
              <Grid display="flex">
                <TextField
                  sx={{ mb: 2 }}
                  fullWidth
                  label="Salud"
                  variant="outlined"
                  value={p10chemName5health}
                  onChange={(e) => dispatch(setp10chemName5health(e.target.value))}
                />
                <TextField
                  sx={{ mb: 2, ml: 2 }}
                  fullWidth
                  label="Inflamable"
                  variant="outlined"
                  value={p10chemName5inflam}
                  onChange={(e) => dispatch(setp10chemName5inflam(e.target.value))}
                />
              </Grid>
              <Grid display="flex" sx={{ mb: 10 }}>
                <TextField
                  sx={{ mb: 2 }}
                  fullWidth
                  label="Reactividad"
                  variant="outlined"
                  value={p10chemName5reactivity}
                  onChange={(e) => dispatch(setp10chemName5reactivity(e.target.value))}
                />
                <TextField
                  sx={{ mb: 2, ml: 2 }}
                  fullWidth
                  label="Específico"
                  variant="outlined"
                  value={p10chemName5specific}
                  onChange={(e) => dispatch(setp10chemName5specific(e.target.value))}
                />
              </Grid>

            </Box>
            <Grid sx={{ mb: 2 }} container justifyContent="space-between">
              <Grid
                xs={12}
                md={5}
                sx={{ mb: 2 }}
              >
                <ColorButton
                  fullWidth
                  startIcon={<ArrowLeftIcon />}
                  onClick={goToCreateStep9}
                >
                  Ir a la página anterior
                </ColorButton>
              </Grid>
              <Grid
                xs={12}
                md={5}
              >
                <ColorButton
                  fullWidth
                  endIcon={<ArrowRightIcon />}
                  onClick={goToCreateStep11}
                >
                  Ir a la página siguiente
                </ColorButton>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </DashboardLayout>
  )
}

export default CreateStep10