import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Slide from '@mui/material/Slide'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { useParams, useHistory } from 'react-router-dom'
import Button from '@mui/material/Button'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import CheckIcon from '@mui/icons-material/Check'
import { toast } from 'react-toastify'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import ColorButton from '../../common/ColorButton'
import DashboardLayout from '../layouts/DashboardLayout'
import getFirestoreDocById from '../../common/getFirestoreDocById'
import { UrlParams } from '../../types'
import { doc, updateDoc, db } from '../../config/firebaseConfig'
import useAuth from '../../hooks/useAuth'
import color1ConsoleLog from '../../common/color1ConsoleLog'
import FullPageLoader from '../../common/FullPageLoader'

const ReviseStep14 = () => {
  console.log('ReviseStep14')

  const { user } = useAuth()
  console.log('%cuser : %s', color1ConsoleLog, user?.uid)

  const { id, area } = useParams<UrlParams>()
  console.log('%cThe id is : %s', color1ConsoleLog, id)
  console.log('%cThe area is : %s', color1ConsoleLog, area)

  const history = useHistory()

  const goToReviseStep13 = () => {
    history.push(`/revision/${area}/13/${id}`)
  }

  const goToReviseStep15 = () => {
    history.push(`/revision/${area}/15/${id}`)
  }

  const [formData, setFormData]: any = useState()
  const [loading, setLoading] = useState(true)
  const [isAllowed, setIsAllowed] = useState(false)
  const [key, setKey] = useState('')
  // eslint-disable-next-line
  const [areaUrlParam, setAreaUrlParam] = useState('')
  const [approvedAt, setApprovedAt] = useState('')

  useEffect(() => {
    (async () => {
      if (id) {
        const res = await getFirestoreDocById(id)
        if (res) {
          setFormData(res)
          if (area === 'tsu') {
            console.log('res?.p16workSupervisor', res?.p16workSupervisor)
            console.log('%cp16workSupervisor has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16workSupervisorApproved14')
            setAreaUrlParam('tsu')
            setApprovedAt('p16workSupervisorApprovedAt14')
          }
          if (area === 'pep') {
            console.log('%cp16pepCoordinator has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16pepCoordinatorApproved14')
            setAreaUrlParam('pep')
            setApprovedAt('p16pepCoordinatorApprovedAt14')
          }
          if (area === 'aco') {
            console.log('%cp16areaCoordinator has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16areaCoordinatorApproved14')
            setAreaUrlParam('aco')
            setApprovedAt('p16areaCoordinatorApprovedAt14')
          }
          if (area === 'qt') {
            console.log('%cp16quality has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16qualityApproved14')
            setAreaUrlParam('qt')
            setApprovedAt('p16qualityApprovedAt14')
          }
          if (area === 'ta') {
            console.log('%cp16technicalArea has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16technicalAreaApproved14')
            setAreaUrlParam('ta')
            setApprovedAt('p16technicalAreaApprovedAt14')
          }
          if (area === 'ps') {
            console.log('%cp16patrimonialSecurity has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16patrimonialSecurityApproved14')
            setAreaUrlParam('ps')
            setApprovedAt('p16patrimonialSecurityApprovedAt14')
          }
          if (area === 'ep') {
            console.log('%cp16externalPrevencionist has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16externalPrevencionistApproved14')
            setAreaUrlParam('ep')
            setApprovedAt('p16externalPrevencionistApprovedAt14')
          }
          if (area === 'sass') {
            console.log('%cp16sass has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16sassApproved14')
            setAreaUrlParam('sass')
            setApprovedAt('p16sassApprovedAt14')
          }
          setLoading(false)
        } else {
          setLoading(false)
        }
      }
    })()
  }, [])

  if (loading) {
    <Typography>Cargando</Typography>
  }
  console.log('formData step 14', formData)

  const approve = async (e: any) => {
    e.preventDefault()
    const approveformRef = doc(db, 'forms', id)
    const obj: any = {}
    obj[key] = true
    obj[approvedAt] = new Date()
    try {
      await updateDoc(approveformRef, obj)
      console.log('approved', key, approvedAt)
      toast.success('La aprobación se actualizó correctamente')
    } catch (updateError) {
      toast.error(`Error: ${updateError}`)
    }
  }

  const disapprove = async (e: any) => {
    e.preventDefault()
    const approveformRef = doc(db, 'forms', id)
    const obj: any = {}
    obj[key] = false
    obj[approvedAt] = new Date()
    try {
      await updateDoc(approveformRef, obj)
      console.log('disapproved', key, approvedAt)
      toast.success('La desaprobación se actualizó correctamente')
    } catch (updateError) {
      toast.error(`Error: ${updateError}`)
    }
  }

  return (
    <DashboardLayout>
      {loading ? <FullPageLoader /> : (
        <Slide direction="right" in mountOnEnter unmountOnExit>
          <Grid sx={{ mt: 4 }} container justifyContent="center" spacing={2}>
            <Grid item xs={10} sm={9} md={5} lg={7}>
              {
                (formData?.p16workSupervisor === user?.uid)
                  || (formData?.p16pepCoordinator === user?.uid)
                  || (formData?.p16areaCoordinator === user?.uid)
                  || (formData?.p16quality === user?.uid)
                  || (formData?.p16technicalArea === user?.uid)
                  || (formData?.p16patrimonialSecurity === user?.uid)
                  || (formData?.p16externalPrevencionist === user?.uid)
                  || (formData?.p16sass === user?.uid)
                  || (formData?.p16sassAllowedList?.includes(user?.uid))
                  ? (
                    <>
                      <Typography
                        sx={{ mb: 2 }}
                        align="center"
                        variant="h6"
                        color="text.secondary"
                        gutterBottom
                      >
                        EQUIPOS DE PROTECCIÓN PERSONAL
                      </Typography>

                      <Box sx={{
                        display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                      }}
                      >
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend">Lentes de seguridad</FormLabel>
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row' }}
                            aria-label="atrapamiento"
                            name="atrapamiento"
                            value={formData?.p14securityGlass}
                          >
                            <FormControlLabel value="SI" control={<Radio checked={formData?.p14securityGlass === 'SI'} />} label="SI" />
                            <FormControlLabel value="NO" control={<Radio checked={formData?.p14securityGlass === 'NO'} />} label="NO" />
                            <FormControlLabel value="NA" control={<Radio checked={formData?.p14securityGlass === 'NA'} />} label="N/A" />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend">Zapatos de Seguridad</FormLabel>
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row' }}
                            aria-label="aplastamiento"
                            name="aplastamiento"
                            value={formData?.p14securityShoes}
                          >
                            <FormControlLabel value="SI" control={<Radio checked={formData?.p14securityShoes === 'SI'} />} label="SI" />
                            <FormControlLabel value="NO" control={<Radio checked={formData?.p14securityShoes === 'NO'} />} label="NO" />
                            <FormControlLabel value="NA" control={<Radio checked={formData?.p14securityShoes === 'NA'} />} label="N/A" />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend">Protector Auditivo (Fonos)</FormLabel>
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row' }}
                            aria-label="golpeadorpor"
                            name="golpeadorpor"
                            value={formData?.p14earProtector}
                          >
                            <FormControlLabel value="SI" control={<Radio checked={formData?.p14earProtector === 'SI'} />} label="SI" />
                            <FormControlLabel value="NO" control={<Radio checked={formData?.p14earProtector === 'NO'} />} label="NO" />
                            <FormControlLabel value="NA" control={<Radio checked={formData?.p14earProtector === 'NA'} />} label="N/A" />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend">Casco de Seguridad</FormLabel>
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row' }}
                            aria-label="golpeadorpor"
                            name="golpeadorpor"
                            value={formData?.p14securityHeadProtection}
                          >
                            <FormControlLabel value="SI" control={<Radio checked={formData?.p14securityHeadProtection === 'SI'} />} label="SI" />
                            <FormControlLabel value="NO" control={<Radio checked={formData?.p14securityHeadProtection === 'NO'} />} label="NO" />
                            <FormControlLabel value="NA" control={<Radio checked={formData?.p14securityHeadProtection === 'NA'} />} label="N/A" />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend">Chaleco Reflectante</FormLabel>
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row' }}
                            aria-label="golpeadorpor"
                            name="golpeadorpor"
                            value={formData?.p14reflectJacket}
                          >
                            <FormControlLabel value="SI" control={<Radio checked={formData?.p14reflectJacket === 'SI'} />} label="SI" />
                            <FormControlLabel value="NO" control={<Radio checked={formData?.p14reflectJacket === 'NO'} />} label="NO" />
                            <FormControlLabel value="NA" control={<Radio checked={formData?.p14reflectJacket === 'NA'} />} label="N/A" />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend">Proteccion filtro UV</FormLabel>
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row' }}
                            aria-label="golpeadorpor"
                            name="golpeadorpor"
                            value={formData?.p14uvFilterProtection}
                          >
                            <FormControlLabel value="SI" control={<Radio checked={formData?.p14uvFilterProtection === 'SI'} />} label="SI" />
                            <FormControlLabel value="NO" control={<Radio checked={formData?.p14uvFilterProtection === 'NO'} />} label="NO" />
                            <FormControlLabel value="NA" control={<Radio checked={formData?.p14uvFilterProtection === 'NA'} />} label="N/A" />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend">Traje Antiácido</FormLabel>
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row' }}
                            aria-label="golpeadorpor"
                            name="golpeadorpor"
                            value={formData?.p14antSuit}
                          >
                            <FormControlLabel value="SI" control={<Radio checked={formData?.p14antSuit === 'SI'} />} label="SI" />
                            <FormControlLabel value="NO" control={<Radio checked={formData?.p14antSuit === 'NO'} />} label="NO" />
                            <FormControlLabel value="NA" control={<Radio checked={formData?.p14antSuit === 'NA'} />} label="N/A" />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend">Botas Seguridad resistente al agua</FormLabel>
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row' }}
                            aria-label="golpeadorpor"
                            name="golpeadorpor"
                            value={formData?.p14bootsResistant}
                          >
                            <FormControlLabel value="SI" control={<Radio checked={formData?.p14bootsResistant === 'SI'} />} label="SI" />
                            <FormControlLabel value="NO" control={<Radio checked={formData?.p14bootsResistant === 'NO'} />} label="NO" />
                            <FormControlLabel value="NA" control={<Radio checked={formData?.p14bootsResistant === 'NA'} />} label="N/A" />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend">Careta Facial</FormLabel>
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row' }}
                            aria-label="golpeadorpor"
                            name="golpeadorpor"
                            value={formData?.p14faceShield}
                          >
                            <FormControlLabel value="SI" control={<Radio checked={formData?.p14faceShield === 'SI'} />} label="SI" />
                            <FormControlLabel value="NO" control={<Radio checked={formData?.p14faceShield === 'NO'} />} label="NO" />
                            <FormControlLabel value="NA" control={<Radio checked={formData?.p14faceShield === 'NA'} />} label="N/A" />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend">Máscara de Soldar</FormLabel>
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row' }}
                            aria-label="golpeadorpor"
                            name="golpeadorpor"
                            value={formData?.p14soldarMask}
                          >
                            <FormControlLabel value="SI" control={<Radio checked={formData?.p14soldarMask === 'SI'} />} label="SI" />
                            <FormControlLabel value="NO" control={<Radio checked={formData?.p14soldarMask === 'NO'} />} label="NO" />
                            <FormControlLabel value="NA" control={<Radio checked={formData?.p14soldarMask === 'NA'} />} label="N/A" />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend">Traje de Soldador</FormLabel>
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row' }}
                            aria-label="golpeadorpor"
                            name="golpeadorpor"
                            value={formData?.p14soldarSuit}
                          >
                            <FormControlLabel value="SI" control={<Radio checked={formData?.p14soldarSuit === 'SI'} />} label="SI" />
                            <FormControlLabel value="NO" control={<Radio checked={formData?.p14soldarSuit === 'NO'} />} label="NO" />
                            <FormControlLabel value="NA" control={<Radio checked={formData?.p14soldarSuit === 'NA'} />} label="N/A" />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend">Coleto de Cuero</FormLabel>
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row' }}
                            aria-label="golpeadorpor"
                            name="golpeadorpor"
                            value={formData?.p14leatherColeto}
                          >
                            <FormControlLabel value="SI" control={<Radio checked={formData?.p14leatherColeto === 'SI'} />} label="SI" />
                            <FormControlLabel value="NO" control={<Radio checked={formData?.p14leatherColeto === 'NO'} />} label="NO" />
                            <FormControlLabel value="NA" control={<Radio checked={formData?.p14leatherColeto === 'NA'} />} label="N/A" />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend">Polainas de Cuero</FormLabel>
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row' }}
                            aria-label="golpeadorpor"
                            name="golpeadorpor"
                            value={formData?.p14leatherPolaina}
                          >
                            <FormControlLabel value="SI" control={<Radio checked={formData?.p14leatherPolaina === 'SI'} />} label="SI" />
                            <FormControlLabel value="NO" control={<Radio checked={formData?.p14leatherPolaina === 'NO'} />} label="NO" />
                            <FormControlLabel value="NA" control={<Radio checked={formData?.p14leatherPolaina === 'NA'} />} label="N/A" />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend">Árnes de Seguridad con cuatro argollas</FormLabel>
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row' }}
                            aria-label="golpeadorpor"
                            name="golpeadorpor"
                            value={formData?.p14fourHookHarness}
                          >
                            <FormControlLabel value="SI" control={<Radio checked={formData?.p14fourHookHarness === 'SI'} />} label="SI" />
                            <FormControlLabel value="NO" control={<Radio checked={formData?.p14fourHookHarness === 'NO'} />} label="NO" />
                            <FormControlLabel value="NA" control={<Radio checked={formData?.p14fourHookHarness === 'NA'} />} label="N/A" />
                          </RadioGroup>
                        </FormControl>

                      </Box>

                      {(formData?.p14fourHookHarnessImage?.length > 1 && formData?.p14fourHookHarnessImage !== 'none')
                        && (
                          <Card sx={{ maxWidth: 345, mt: 2, mb: 4 }}>
                            <CardMedia
                              component="img"
                              height="140"
                              image={
                                formData?.p14fourHookHarnessImage
                                && formData?.p14fourHookHarnessImage
                              }
                              alt="arnes de seguridad con cuatro argollas"
                            />
                            <CardContent>
                              <Typography gutterBottom variant="subtitle1" component="div">
                                Árnes de Seguridad con cuatro argollas
                              </Typography>
                            </CardContent>
                          </Card>
                        )}

                      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend">Cola de vida (doble)</FormLabel>
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row' }}
                            aria-label="golpeadorpor"
                            name="golpeadorpor"
                            value={formData?.p14doubleLitail}
                          >
                            <FormControlLabel value="SI" control={<Radio checked={formData?.p14doubleLitail === 'SI'} />} label="SI" />
                            <FormControlLabel value="NO" control={<Radio checked={formData?.p14doubleLitail === 'NO'} />} label="NO" />
                            <FormControlLabel value="NA" control={<Radio checked={formData?.p14doubleLitail === 'NA'} />} label="N/A" />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      {(formData?.p14doubleLitailImage?.length > 1 && formData?.p14doubleLitailImage !== 'none')
                        && (
                          <Card sx={{ maxWidth: 345, mt: 2, mb: 4 }}>
                            <CardMedia
                              component="img"
                              height="140"
                              image={
                                formData?.p14doubleLitailImage
                                && formData?.p14doubleLitailImage
                              }
                              alt="cola de vida (doble)"
                            />
                            <CardContent>
                              <Typography gutterBottom variant="subtitle1" component="div">
                                Cola de vida (doble)
                              </Typography>
                            </CardContent>
                          </Card>
                        )}

                      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend">Absorbedor de impacto</FormLabel>
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row' }}
                            aria-label="golpeadorpor"
                            name="golpeadorpor"
                            value={formData?.p14impactAbsorb}
                          >
                            <FormControlLabel value="SI" control={<Radio checked={formData?.p14impactAbsorb === 'SI'} />} label="SI" />
                            <FormControlLabel value="NO" control={<Radio checked={formData?.p14impactAbsorb === 'NO'} />} label="NO" />
                            <FormControlLabel value="NA" control={<Radio checked={formData?.p14impactAbsorb === 'NA'} />} label="N/A" />
                          </RadioGroup>
                        </FormControl>

                      </Box>

                      {(formData?.p14impactAbsorbImage?.length > 1 && formData?.p14impactAbsorbImage !== 'none')
                        && (
                          <Card sx={{ maxWidth: 345, mt: 2, mb: 4 }}>
                            <CardMedia
                              component="img"
                              height="140"
                              image={
                                formData?.p14impactAbsorbImage
                                && formData?.p14impactAbsorbImage
                              }
                              alt="absorbedor de impacto"
                            />
                            <CardContent>
                              <Typography gutterBottom variant="subtitle1" component="div">
                                Absorbedor de impacto
                              </Typography>
                            </CardContent>
                          </Card>
                        )}

                      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend">Mosquetón de escalada</FormLabel>
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row' }}
                            aria-label="golpeadorpor"
                            name="golpeadorpor"
                            value={formData?.p14escaladaMosqueton}
                          >
                            <FormControlLabel value="SI" control={<Radio checked={formData?.p14escaladaMosqueton === 'SI'} />} label="SI" />
                            <FormControlLabel value="NO" control={<Radio checked={formData?.p14escaladaMosqueton === 'NO'} />} label="NO" />
                            <FormControlLabel value="NA" control={<Radio checked={formData?.p14escaladaMosqueton === 'NA'} />} label="N/A" />
                          </RadioGroup>
                        </FormControl>

                      </Box>

                      {(formData?.p14escaladaMosquetonImage?.length > 1 && formData?.p14escaladaMosquetonImage !== 'none')
                        && (
                          <Card sx={{ maxWidth: 345, mt: 2, mb: 4 }}>
                            <CardMedia
                              component="img"
                              height="140"
                              image={
                                formData?.p14escaladaMosquetonImage
                                && formData?.p14escaladaMosquetonImage
                              }
                              alt="mosquetón de escalada"
                            />
                            <CardContent>
                              <Typography gutterBottom variant="subtitle1" component="div">
                                Mosquetón de escalada
                              </Typography>
                            </CardContent>
                          </Card>
                        )}

                      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                          <FormLabel component="legend">Mascarilla con Filtro</FormLabel>
                          <RadioGroup
                            sx={{ flex: '1', flexDirection: 'row' }}
                            aria-label="golpeadorpor"
                            name="golpeadorpor"
                            value={formData?.p14filterMask}
                          >
                            <FormControlLabel value="SI" control={<Radio checked={formData?.p14filterMask === 'SI'} />} label="SI" />
                            <FormControlLabel value="NO" control={<Radio checked={formData?.p14filterMask === 'NO'} />} label="NO" />
                            <FormControlLabel value="NA" control={<Radio checked={formData?.p14filterMask === 'NA'} />} label="N/A" />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      <TextField
                        sx={{ mb: 10 }}
                        fullWidth
                        label="Otros : "
                        variant="outlined"
                        value={formData?.p14Others}
                      />

                      <Grid
                        display={isAllowed ? 'flex' : 'none'}
                        justifyContent="space-evenly"
                        sx={{ mt: 4 }}
                      >
                        <Button
                          startIcon={<HighlightOffIcon />}
                          sx={{ mb: 10, bgcolor: '#db1414' }}
                          color="error"
                          variant="contained"
                          onClick={(e) => disapprove(e)}
                        >
                          Desaprobar
                        </Button>
                        <Button
                          startIcon={<CheckIcon />}
                          sx={{ mb: 10, bgcolor: '#42b50e' }}
                          color="success"
                          variant="contained"
                          onClick={(e) => approve(e)}
                        >
                          Aprobar
                        </Button>
                      </Grid>

                      <Grid sx={{ mb: 2 }} container justifyContent="space-between">
                        <Grid
                          xs={12}
                          md={5}
                          sx={{ mb: 2 }}
                        >
                          <ColorButton
                            fullWidth
                            startIcon={<ArrowLeftIcon />}
                            onClick={goToReviseStep13}
                          >
                            Ir a la página anterior
                          </ColorButton>
                        </Grid>
                        <Grid
                          xs={12}
                          md={5}
                        >
                          <ColorButton
                            fullWidth
                            endIcon={<ArrowRightIcon />}
                            onClick={goToReviseStep15}
                          >
                            Ir a la página siguiente
                          </ColorButton>
                        </Grid>

                      </Grid>
                    </>
                  )
                  : 'Por favor inicie sesión para visualizar su documento a revisar'
              }

              {user ? (
                <Typography color="green">Usted ha iniciado sesión.</Typography>
              )
                : (
                  <Typography color="red" sx={{ mt: 2 }}>Usted no ha iniciado sesión</Typography>
                )}

            </Grid>
          </Grid>
        </Slide>
      )}

    </DashboardLayout>
  )
}

export default ReviseStep14