import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Slide from '@mui/material/Slide'
import { useParams, useHistory } from 'react-router-dom'
import Button from '@mui/material/Button'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import CheckIcon from '@mui/icons-material/Check'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { toast } from 'react-toastify'
import DashboardLayout from '../layouts/DashboardLayout'
// import allUsers from '../../common/allUsers'
import { UrlParams } from '../../types'
import useAuth from '../../hooks/useAuth'
import ColorButton from '../../common/ColorButton'
import {
  doc, updateDoc, db,
} from '../../config/firebaseConfig'
import getFirestoreDocById from '../../common/getFirestoreDocById'
import color1ConsoleLog from '../../common/color1ConsoleLog'
import FullPageLoader from '../../common/FullPageLoader'

const ReviseStep5 = () => {
  console.log('ReviseStep5')

  const { user } = useAuth()
  console.log('%cuser : %s', color1ConsoleLog, user?.uid)

  // const userJob = allUsers.find((obj) => obj?.userKey === user?.uid)

  const isPersonAllowedToSelect = () => false

  React.useEffect(() => {
    console.log('isPersonAllowedToSelect', isPersonAllowedToSelect())
  }, [])

  const { id, area } = useParams<UrlParams>()
  console.log('%cThe id is : %s', color1ConsoleLog, id)
  console.log('%cThe area is : %s', color1ConsoleLog, area)

  const [formData, setFormData]: any = React.useState()
  const [loading, setLoading] = React.useState(true)
  const [isAllowed, setIsAllowed] = React.useState(false)
  const [key, setKey] = React.useState('')
  // eslint-disable-next-line
  const [areaUrlParam, setAreaUrlParam] = React.useState('')
  const [approvedAt, setApprovedAt] = React.useState('')

  React.useEffect(() => {
    (async () => {
      if (id) {
        const res = await getFirestoreDocById(id)
        if (res) {
          setFormData(res)
          if (area === 'tsu') {
            console.log('res?.p16workSupervisor', res?.p16workSupervisor)
            console.log('%cp16workSupervisor has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16workSupervisorApproved5')
            setAreaUrlParam('tsu')
            setApprovedAt('p16workSupervisorApprovedAt5')
          }
          if (area === 'pep') {
            console.log('%cp16pepCoordinator has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16pepCoordinatorApproved5')
            setAreaUrlParam('pep')
            setApprovedAt('p16pepCoordinatorApprovedAt5')
          }
          if (area === 'aco') {
            console.log('%cp16areaCoordinator has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16areaCoordinatorApproved5')
            setAreaUrlParam('aco')
            setApprovedAt('p16areaCoordinatorApprovedAt5')
          }
          if (area === 'qt') {
            console.log('%cp16quality has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16qualityApproved5')
            setAreaUrlParam('qt')
            setApprovedAt('p16qualityApprovedAt5')
          }
          if (area === 'ta') {
            console.log('%cp16technicalArea has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16technicalAreaApproved5')
            setAreaUrlParam('ta')
            setApprovedAt('p16technicalAreaApprovedAt5')
          }
          if (area === 'ps') {
            console.log('%cp16patrimonialSecurity has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16patrimonialSecurityApproved5')
            setAreaUrlParam('ps')
            setApprovedAt('p16patrimonialSecurityApprovedAt5')
          }
          if (area === 'ep') {
            console.log('%cp16externalPrevencionist has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16externalPrevencionistApproved5')
            setAreaUrlParam('ep')
            setApprovedAt('p16externalPrevencionistApprovedAt5')
          }
          if (area === 'sass') {
            console.log('%cp16sass has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16sassApproved5')
            setAreaUrlParam('sass')
            setApprovedAt('p16sassApprovedAt5')
          }
          setLoading(false)
        } else {
          setLoading(false)
        }
      }
    })()
  }, [])

  if (loading) {
    <Typography>Cargando</Typography>
  }
  console.log('formData p5', formData)

  const history = useHistory()

  const goToReviseStep4 = () => {
    history.push(`/revision/${area}/4/${id}`)
  }
  const goToReviseStep6 = () => {
    history.push(`/revision/${area}/6/${id}`)
  }

  const approve = async (e: any) => {
    e.preventDefault()
    const approveformRef = doc(db, 'forms', id)
    const obj: any = {}
    obj[key] = true
    obj[approvedAt] = new Date()
    try {
      await updateDoc(approveformRef, obj)
      console.log('approved', key, approvedAt)
      toast.success('La aprobación se actualizó correctamente')
    } catch (updateError) {
      toast.error(`Error: ${updateError}`)
    }
  }

  const disapprove = async (e: any) => {
    e.preventDefault()
    const approveformRef = doc(db, 'forms', id)
    const obj: any = {}
    obj[key] = false
    obj[approvedAt] = new Date()
    try {
      await updateDoc(approveformRef, obj)
      console.log('disapproved', key, approvedAt)
      toast.success('La desaprobación se actualizó correctamente')
    } catch (updateError) {
      toast.error(`Error: ${updateError}`)
    }
  }

  return (
    <DashboardLayout>
      {loading ? <FullPageLoader /> : (
        <Slide direction="right" in mountOnEnter unmountOnExit>
          <Grid sx={{ mt: 4 }} container justifyContent="center" spacing={2}>
            <Grid item xs={10} sm={9} md={5} lg={7}>
              {(formData?.p16workSupervisor === user?.uid)
                || (formData?.p16pepCoordinator === user?.uid)
                || (formData?.p16areaCoordinator === user?.uid)
                || (formData?.p16quality === user?.uid)
                || (formData?.p16technicalArea === user?.uid)
                || (formData?.p16patrimonialSecurity === user?.uid)
                || (formData?.p16externalPrevencionist === user?.uid)
                || (formData?.p16sass === user?.uid)
                || (formData?.p16sassAllowedList?.includes(user?.uid))
                ? (
                  <>
                    <Typography
                      sx={{ mb: 2 }}
                      align="center"
                      variant="h6"
                      color="text.secondary"
                      gutterBottom
                    >
                      RIESGOS ASOCIADOS
                    </Typography>

                    <Box sx={{
                      display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                    }}
                    >
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Atrapamiento</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="atrapamiento"
                          name="atrapamiento"
                          value={formData?.p5atrapam}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5atrapam === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5atrapam === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5atrapam === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Aplastamiento</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="aplastamiento"
                          name="aplastamiento"
                          value={formData?.p5aplastam}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5aplastam === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5aplastam === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5aplastam === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Golpeado por</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="golpeadorpor"
                          name="golpeadorpor"
                          value={formData?.p5golpeadopor}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5golpeadopor === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5golpeadopor === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5golpeadopor === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Golpeado con</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="golpeadocon"
                          name="golpeadocon"
                          value={formData?.p5golpeadocon}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5golpeadocon === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5golpeadocon === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5golpeadocon === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Caída (mismo y distinto nivel)</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="caida"
                          name="caida"
                          value={formData?.p5caida}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5caida === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5caida === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5caida === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">
                          Contacto con objetos cortantes
                        </FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="contactoconobjetoscortantes"
                          name="contactoconobjetoscortantes"
                          value={formData?.p5contConObjCort}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5contConObjCort === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5contConObjCort === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5contConObjCort === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">
                          Contacto con objetos calientes
                        </FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="contactoconobjetoscalientes"
                          name="contactoconobjetoscalientes"
                          value={formData?.p5contConObjCal}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5contConObjCal === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5contConObjCal === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5contConObjCal === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">
                          Proyección de particulas
                        </FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="proyecciondeparticulas"
                          name="proyecciondeparticulas"
                          value={formData?.p5proyPartic}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5proyPartic === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5proyPartic === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5proyPartic === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">
                          Contacto con energía eléctrica
                        </FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="contactoconenergiaelectrica"
                          name="contactoconenergiaelectrica"
                          value={formData?.p5contactEnElectric}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5contactEnElectric === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5contactEnElectric === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5contactEnElectric === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Radiación UV</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="radiacionuv"
                          name="radiacionuv"
                          value={formData?.p5radiacionUv}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5radiacionUv === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5radiacionUv === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5radiacionUv === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Contacto con químicos</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="contactoconquimicos"
                          name="contactoconquimicos"
                          value={formData?.p5contQuimico}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5contQuimico === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5contQuimico === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5contQuimico === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Exposición a Polvo</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="exposicionapolvo"
                          name="exposicionapolvo"
                          value={formData?.p5exposicPolvo}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5exposicPolvo === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5exposicPolvo === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5exposicPolvo === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Exposición a Ruido</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="exposicionaruido"
                          name="exposicionaruido"
                          value={formData?.p5exposicRuido}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5exposicRuido === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5exposicRuido === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5exposicRuido === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Atropello</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="atropello"
                          name="atropello"
                          value={formData?.p5atropello}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5atropello === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5atropello === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5atropello === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Caída de materiales de altura</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="caidadematerialesdealtura"
                          name="caidadematerialesdealtura"
                          value={formData?.p5caidaMatAltura}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5caidaMatAltura === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5caidaMatAltura === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5caidaMatAltura === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Estrés Térmico</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="estrestermico"
                          name="estrestermico"
                          value={formData?.p5estresTerm}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5estresTerm === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5estresTerm === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5estresTerm === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Colisión / Choque</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="colisionchoque"
                          name="colisionchoque"
                          value={formData?.p5colisionChoque}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5colisionChoque === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5colisionChoque === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5colisionChoque === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Deficiencia de O2</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="deficienciade02"
                          name="deficienciade02"
                          value={formData?.p5deficienciao2}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5deficienciao2 === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5deficienciao2 === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5deficienciao2 === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Explosión</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="explosion"
                          name="explosion"
                          value={formData?.p5explosion}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5explosion === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5explosion === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5explosion === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Incendio</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="incendio"
                          name="incendio"
                          value={formData?.p5incendio}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5incendio === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5incendio === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5incendio === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Derrumbe / Desprendimiento</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="derrumbedesprendimiento"
                          name="derrumbedesprendimiento"
                          value={formData?.p5derrumbe}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5derrumbe === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5derrumbe === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5derrumbe === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Exposición a Gases</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="exposicionagases"
                          name="exposicionagases"
                          value={formData?.p5exposicGas}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5exposicGas === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5exposicGas === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5exposicGas === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Inmersión</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="inmersion"
                          name="inmersion"
                          value={formData?.p5immersion}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5immersion === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5immersion === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5immersion === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 10 }} component="fieldset">
                        <FormLabel component="legend">Fatiga de materiales</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="fatigademateriales"
                          name="fatigademateriales"
                          value={formData?.p5fatigMaterial}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5fatigMaterial === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5fatigMaterial === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5fatigMaterial === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Grid
                      display={isAllowed ? 'flex' : 'none'}
                      justifyContent="space-evenly"
                      sx={{ mt: 4 }}
                    >
                      <Button
                        startIcon={<HighlightOffIcon />}
                        sx={{ mb: 10, bgcolor: '#db1414' }}
                        color="error"
                        variant="contained"
                        onClick={(e) => disapprove(e)}
                      >
                        Desaprobar
                      </Button>
                      <Button
                        startIcon={<CheckIcon />}
                        sx={{ mb: 10, bgcolor: '#42b50e' }}
                        color="success"
                        variant="contained"
                        onClick={(e) => approve(e)}
                      >
                        Aprobar
                      </Button>
                    </Grid>

                    <Grid sx={{ mb: 2 }} container justifyContent="space-between">
                      <Grid
                        xs={12}
                        md={5}
                        sx={{ mb: 2 }}
                      >
                        <ColorButton
                          fullWidth
                          startIcon={<ArrowLeftIcon />}
                          onClick={goToReviseStep4}
                        >
                          Ir a la página anterior
                        </ColorButton>
                      </Grid>
                      <Grid
                        xs={12}
                        md={5}
                      >
                        <ColorButton
                          fullWidth
                          endIcon={<ArrowRightIcon />}
                          onClick={goToReviseStep6}
                        >
                          Ir a la página siguiente
                        </ColorButton>
                      </Grid>

                    </Grid>
                  </>
                )
                : 'Por favor inicie sesión para visualizar su documento a revisar'}

              {user ? (
                <Typography color="green">Usted ha iniciado sesión.</Typography>
              )
                : (
                  <Typography color="red" sx={{ mt: 2 }}>Usted no ha iniciado sesión</Typography>
                )}

            </Grid>
          </Grid>
        </Slide>
      )}

    </DashboardLayout>
  )
}

export default ReviseStep5