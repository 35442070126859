import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import allUsers from '../../common/allUsers'

export interface FormState {
  // name: string;
  // descriptions: string[];
  nodeApiBaseUrl: string;
  p2companyName: string;
  p2contratSupervisorName: string;
  p2contratSupervisorPhone: string;
  p2erpName: string;
  p2erpPhone: string;
  p2rut: string;
  p2mutualidad: string;
  p2requestingArea: string;
  p2pepWorkCoordinator: string;
  p2specificWorkArea: string;
  // imagen
  // p2specificWorkAreaImage: string;
  p2date: string;
  p2extensionShow: boolean;
  p2extension: string;
  p3description1: string;
  p3description2: string;
  p3description3: string;
  p3description4: string;
  p3description5: string;
  p3description6: string;
  p3description7: string;
  p3controlMeasure1: string;
  p3controlMeasure2: string;
  p3controlMeasure3: string;
  p3controlMeasure4: string;
  p3controlMeasure5: string;
  p3controlMeasure6: string;
  p3controlMeasure7: string;
  p4workerName1: string;
  p4workerName1Approved: string;
  p4workerName2: string;
  p4workerName2Approved: string;
  p4workerName3: string;
  p4workerName3Approved: string;
  p4workerName4: string;
  p4workerName4Approved: string;
  p4workerName5: string;
  p4workerName5Approved: string;
  p4workerName6: string;
  p4workerName6Approved: string;
  p4workerName7: string;
  p4workerName7Approved: string;
  p4workerName8: string;
  p4workerName8Approved: string;
  p4workerName9: string;
  p4workerName9Approved: string;
  p4workerName10: string;
  p4workerName10Approved: string;
  p4workerName11: string;
  p4workerName11Approved: string;
  p5atrapam: string;
  p5aplastam: string;
  p5golpeadopor: string;
  p5golpeadocon: string;
  p5caida: string;
  p5contConObjCort: string;
  p5contConObjCal: string;
  p5proyPartic: string;
  p5contactEnElectric: string;
  p5radiacionUv: string;
  p5contQuimico: string;
  p5exposicPolvo: string;
  p5exposicRuido: string;
  p5atropello: string;
  p5caidaMatAltura: string;
  p5estresTerm: string;
  p5colisionChoque: string;
  p5deficienciao2: string;
  p5explosion: string;
  p5incendio: string;
  p5derrumbe: string;
  p5exposicGas: string;
  p5immersion: string;
  p5fatigMaterial: string;
  p6Show: boolean;
  p6keyNumber: string;
  p6weatherConditions: string;
  p6trainingForHeightWork: string;
  p6trainingEspecWork: string;
  p6radioCommunication: string;
  p6toolBelt: string;
  p6useOfHookPoint: string;
  p6useofLadder: string;
  // imagen
  // p6useofLadderImage: string;
  p6elevator: string;
  // imagen
  // p6elevatorImage: string;
  p6andamTipeEuro: string;
  // imagen
  // p6andamTipeEuroImage: string;
  p6healthCheck: string;
  p6inlineSliderVertical: string;
  p6heightJobLowLevel: string;
  p7Show: boolean;
  p7keyNumber: string;
  p7measure1o2: string;
  p7measure1h2s: string;
  p7measure1co: string;
  p7measure2o2: string;
  p7measure2h2s: string;
  p7measure2co: string;
  p7gasAn: string;
  p7deviceOfVentForced: string;
  p7deviceOfAutonBreathing: string;
  p7comRadio: string;
  p7landTempInPlace: string;
  p7equipmentOfAdLight: string;
  p7requireRescueDevice: string;
  p7acceptableWeatherCond: string;
  p8Show: boolean;
  p8chemEnvase: string;
  p8hasHds: string;
  // imagen
  // p8hasHdsImage: string;
  p8indicateSuspel: string;
  p8showaGlove: string;
  p8faceShield: string;
  p8antSuit: string;
  p8rubberBoot: string;
  p8antSpillKit: string;
  p8extensionElectricalCheck: string;
  // imagen
  // p8extensionElectricalCheckImage: string;
  p9Show: boolean;
  p9eslingasCheck: string;
  // imagen
  // p9eslingasCheckImage: string;
  p9ancPointCheck: string;
  p9securityPerimeter: string;
  p9riggerParticipation: string;
  p9transitAreas: string;
  p9informBlockAreasToJef: string;
  p9areaDelimitation: string;
  p9truckPrevCheck: string;
  // image
  // p9truckPrevCheckImage: string;
  p10show: boolean;
  p10chemName1: string;
  p10chemName1health: string;
  p10chemName1inflam: string;
  p10chemName1reactivity: string;
  p10chemName1specific: string;
  p10chemName2: string;
  p10chemName2health: string;
  p10chemName2inflam: string;
  p10chemName2reactivity: string;
  p10chemName2specific: string;
  p10chemName3: string;
  p10chemName3health: string;
  p10chemName3inflam: string;
  p10chemName3reactivity: string;
  p10chemName3specific: string;
  p10chemName4: string;
  p10chemName4health: string;
  p10chemName4inflam: string;
  p10chemName4reactivity: string;
  p10chemName4specific: string;
  p10chemName5: string;
  p10chemName5health: string;
  p10chemName5inflam: string;
  p10chemName5reactivity: string;
  p10chemName5specific: string;
  p11show: boolean;
  p11mask: string;
  p11cabritillaGlove: string;
  p11breather: string;
  p11manualLoadMax25: string;
  p11useoFSignal: string;
  p11areaDelimitation: string;
  p11electricalExtensionCheck: string;
  // imagen
  // p11electricalExtensionCheckImage: string;
  p11toolCheck: string;
  p11lightningToWork: string;
  p11clearArea: string;
  p11useOfGrua: string;
  p12show: boolean;
  p12evaluatorName: string;
  p12evaluationTime: Date | null;
  p12areaClear: string;
  p12materialsWithinTheZone: string;
  p12dustGeneration: string;
  p12foodIn: string;
  p12hasBmp: string;
  p13show: boolean;
  p13hotWorkFormNumber: string;
  // imagen
  // p13hotWorkFormNumberImage: string;
  p13areaDelimitation: string;
  p13electricalExtensionCheck: string;
  // imagen
  // p13electricalExtensionCheckImage: string;
  p13notificationToBrig: string;
  p14securityGlass: string;
  p14securityShoes: string;
  p14earProtector: string;
  p14securityHeadProtection: string;
  p14reflectJacket: string;
  p14uvFilterProtection: string;
  p14antSuit: string;
  p14bootsResistant: string;
  p14faceShield: string;
  p14soldarMask: string;
  p14soldarSuit: string;
  p14leatherColeto: string;
  p14leatherPolaina: string;
  p14fourHookHarness: string;
  // imagen
  // p14fourHookHarnessImage: string;
  p14doubleLitail: string;
  // imagen
  // p14doubleLitailImage: string;
  p14impactAbsorb: string;
  // imagen
  // p14impactAbsorbImage: string;
  p14escaladaMosqueton: string;
  // imagen
  // p14escaladaMosquetonImage: string;
  p14filterMask: string;
  p14Others: string;
  p15show: boolean;
  p15blockingPersonName: string;
  p15dateTimeLotoStart: Date | null;
  p15dateTimeLotoFinish: Date | null;
  p15blockingEnType: string;
  p15blockingEnTypeElectrical: boolean;
  p15blockingEnTypeHidraulical: boolean;
  p15blockingEnMechanical: boolean;
  p15blockingEnPotencial: boolean;
  p15dangEnergiesControl: string;
  p15knowEnergySourceMachine: string;
  p15concLoto: string;
  p15hasLockIdCardBlockDevice: string;
  p15dielectricGlove: string;
  p15dielectricSecurityShoe: string;
  p15dielectricSecurityGlass: string;
  p16showWorkSupervisor: boolean;
  p16workSupervisor: string;
  p16workSupervisorEmail: string;

  p16showPepCoordinator: boolean;
  p16pepCoordinator: string;
  p16pepCoordinatorEmail: string;

  p16showAreaCoordinator: boolean;
  p16areaCoordinator: string;
  p16areaCoordinatorEmail: string;

  p16showQuality: boolean;
  p16quality: string;
  p16qualityEmail: string;

  p16showTechnicalArea: boolean;
  p16technicalArea: string;
  p16technicalAreaEmail: string;

  p16showPatrimonialSecurity: boolean;
  p16patrimonialSecurity: string;
  p16patrimonialSecurityEmail: string;

  p16showExternalPrevencionist: boolean;
  p16externalPrevencionist: string;
  p16externalPrevencionistEmail: string;

  p16showSass: boolean;
  p16sass: string;
  p16sassEmail: string;
  p16sassAllowedList: string[];

  p16paramedic: string;
  // newObject: { uid: string, username: string };
  paramedicSentp4Email: boolean;
  paramedicApprovedWorker1: string;
  paramedicApprovedWorker2: string;
  paramedicApprovedWorker3: string;
  paramedicApprovedWorker4: string;
  paramedicApprovedWorker5: string;
  paramedicApprovedWorker6: string;
  paramedicApprovedWorker7: string;
  paramedicApprovedWorker8: string;
  paramedicApprovedWorker9: string;
  paramedicApprovedWorker10: string;
  paramedicApprovedWorker11: string;
  entriesAdded: string[];
}

const initialState: FormState = {
  // newObject: { uid: '', username: '' },
  // name: '',
  // descriptions: [],
  nodeApiBaseUrl: 'https://node-email-hseqtech.herokuapp.com',
  p2companyName: '',
  p2contratSupervisorName: '',
  p2contratSupervisorPhone: '',
  p2erpName: '',
  p2erpPhone: '',
  p2rut: '',
  p2mutualidad: '',
  p2requestingArea: '',
  p2pepWorkCoordinator: '',
  p2specificWorkArea: '',
  p2date: `${new Date().getUTCDate()}-${new Date().getUTCMonth() + 1}-${new Date().getUTCFullYear()}`,
  p2extensionShow: true,
  p2extension: '',
  p3description1: '',
  p3description2: '',
  p3description3: '',
  p3description4: '',
  p3description5: '',
  p3description6: '',
  p3description7: '',
  p3controlMeasure1: '',
  p3controlMeasure2: '',
  p3controlMeasure3: '',
  p3controlMeasure4: '',
  p3controlMeasure5: '',
  p3controlMeasure6: '',
  p3controlMeasure7: '',
  p4workerName1: '',
  p4workerName1Approved: 'NA',
  p4workerName2: '',
  p4workerName2Approved: 'NA',
  p4workerName3: '',
  p4workerName3Approved: 'NA',
  p4workerName4: '',
  p4workerName4Approved: 'NA',
  p4workerName5: '',
  p4workerName5Approved: 'NA',
  p4workerName6: '',
  p4workerName6Approved: 'NA',
  p4workerName7: '',
  p4workerName7Approved: 'NA',
  p4workerName8: '',
  p4workerName8Approved: 'NA',
  p4workerName9: '',
  p4workerName9Approved: 'NA',
  p4workerName10: '',
  p4workerName10Approved: 'NA',
  p4workerName11: '',
  p4workerName11Approved: 'NA',
  p5atrapam: 'NA',
  p5aplastam: 'NA',
  p5golpeadopor: 'NA',
  p5golpeadocon: 'NA',
  p5caida: 'NA',
  p5contConObjCort: 'NA',
  p5contConObjCal: 'NA',
  p5proyPartic: 'NA',
  p5contactEnElectric: 'NA',
  p5radiacionUv: 'NA',
  p5contQuimico: 'NA',
  p5exposicPolvo: 'NA',
  p5exposicRuido: 'NA',
  p5atropello: 'NA',
  p5caidaMatAltura: 'NA',
  p5estresTerm: 'NA',
  p5colisionChoque: 'NA',
  p5deficienciao2: 'NA',
  p5explosion: 'NA',
  p5incendio: 'NA',
  p5derrumbe: 'NA',
  p5exposicGas: 'NA',
  p5immersion: 'NA',
  p5fatigMaterial: 'NA',
  p6Show: true,
  p6keyNumber: '',
  p6weatherConditions: 'NA',
  p6trainingForHeightWork: 'NA',
  p6trainingEspecWork: 'NA',
  p6radioCommunication: 'NA',
  p6toolBelt: 'NA',
  p6useOfHookPoint: 'NA',
  p6useofLadder: 'NA',
  p6elevator: 'NA',
  p6andamTipeEuro: 'NA',
  p6healthCheck: 'NA',
  p6inlineSliderVertical: 'NA',
  p6heightJobLowLevel: 'NA',
  p7Show: true,
  p7keyNumber: '',
  p7measure1o2: '',
  p7measure1h2s: '',
  p7measure1co: '',
  p7measure2o2: '',
  p7measure2h2s: '',
  p7measure2co: '',
  p7gasAn: 'NA',
  p7deviceOfVentForced: 'NA',
  p7deviceOfAutonBreathing: 'NA',
  p7comRadio: 'NA',
  p7landTempInPlace: 'NA',
  p7equipmentOfAdLight: 'NA',
  p7requireRescueDevice: 'NA',
  p7acceptableWeatherCond: 'NA',
  p8Show: true,
  p8chemEnvase: 'NA',
  p8hasHds: 'NA',
  p8indicateSuspel: 'NA',
  p8showaGlove: 'NA',
  p8faceShield: 'NA',
  p8antSuit: 'NA',
  p8rubberBoot: 'NA',
  p8antSpillKit: 'NA',
  p8extensionElectricalCheck: 'NA',
  p9Show: true,
  p9eslingasCheck: 'NA',
  p9ancPointCheck: 'NA',
  p9securityPerimeter: 'NA',
  p9riggerParticipation: 'NA',
  p9transitAreas: 'NA',
  p9informBlockAreasToJef: 'NA',
  p9areaDelimitation: 'NA',
  p9truckPrevCheck: 'NA',
  p10show: true,
  p10chemName1: '',
  p10chemName1health: '',
  p10chemName1inflam: '',
  p10chemName1reactivity: '',
  p10chemName1specific: '',
  p10chemName2: '',
  p10chemName2health: '',
  p10chemName2inflam: '',
  p10chemName2reactivity: '',
  p10chemName2specific: '',
  p10chemName3: '',
  p10chemName3health: '',
  p10chemName3inflam: '',
  p10chemName3reactivity: '',
  p10chemName3specific: '',
  p10chemName4: '',
  p10chemName4health: '',
  p10chemName4inflam: '',
  p10chemName4reactivity: '',
  p10chemName4specific: '',
  p10chemName5: '',
  p10chemName5health: '',
  p10chemName5inflam: '',
  p10chemName5reactivity: '',
  p10chemName5specific: '',
  p11show: true,
  p11mask: 'NA',
  p11cabritillaGlove: 'NA',
  p11breather: 'NA',
  p11manualLoadMax25: 'NA',
  p11useoFSignal: 'NA',
  p11areaDelimitation: 'NA',
  p11electricalExtensionCheck: 'NA',
  p11toolCheck: 'NA',
  p11lightningToWork: 'NA',
  p11clearArea: 'NA',
  p11useOfGrua: 'NA',
  p12show: true,
  p12evaluatorName: '',
  p12evaluationTime: null,
  p12areaClear: 'NA',
  p12materialsWithinTheZone: 'NA',
  p12dustGeneration: 'NA',
  p12foodIn: 'NA',
  p12hasBmp: 'NA',
  p13show: true,
  p13hotWorkFormNumber: '',
  p13areaDelimitation: 'NA',
  p13electricalExtensionCheck: 'NA',
  p13notificationToBrig: 'NA',
  p14securityGlass: 'NA',
  p14securityShoes: 'NA',
  p14earProtector: 'NA',
  p14securityHeadProtection: 'NA',
  p14reflectJacket: 'NA',
  p14uvFilterProtection: 'NA',
  p14antSuit: 'NA',
  p14bootsResistant: 'NA',
  p14faceShield: 'NA',
  p14soldarMask: 'NA',
  p14soldarSuit: 'NA',
  p14leatherColeto: 'NA',
  p14leatherPolaina: 'NA',
  p14fourHookHarness: 'NA',
  p14doubleLitail: 'NA',
  p14impactAbsorb: 'NA',
  p14escaladaMosqueton: 'NA',
  p14filterMask: 'NA',
  p14Others: '',
  p15show: true,
  p15blockingPersonName: '',
  p15dateTimeLotoStart: null,
  p15dateTimeLotoFinish: null,
  p15blockingEnType: '',
  p15blockingEnTypeElectrical: false,
  p15blockingEnTypeHidraulical: false,
  p15blockingEnMechanical: false,
  p15blockingEnPotencial: false,
  p15dangEnergiesControl: 'NA',
  p15knowEnergySourceMachine: 'NA',
  p15concLoto: 'NA',
  p15hasLockIdCardBlockDevice: 'NA',
  p15dielectricGlove: 'NA',
  p15dielectricSecurityShoe: 'NA',
  p15dielectricSecurityGlass: 'NA',
  p16showWorkSupervisor: false,
  p16workSupervisor: '',
  p16workSupervisorEmail: '',

  p16showPepCoordinator: false,
  p16pepCoordinator: '',
  p16pepCoordinatorEmail: '',

  p16showAreaCoordinator: false,
  p16areaCoordinator: '',
  p16areaCoordinatorEmail: '',

  p16showQuality: false,
  p16quality: '',
  p16qualityEmail: '',

  p16showTechnicalArea: false,
  p16technicalArea: '',
  p16technicalAreaEmail: '',

  p16showPatrimonialSecurity: false,
  p16patrimonialSecurity: '',
  p16patrimonialSecurityEmail: '',

  p16showExternalPrevencionist: false,
  p16externalPrevencionist: '',
  p16externalPrevencionistEmail: '',

  p16showSass: false,
  p16sass: '',
  p16sassEmail: '',
  p16sassAllowedList: [],

  p16paramedic: '',
  paramedicSentp4Email: false,
  paramedicApprovedWorker1: 'NA',
  paramedicApprovedWorker2: 'NA',
  paramedicApprovedWorker3: 'NA',
  paramedicApprovedWorker4: 'NA',
  paramedicApprovedWorker5: 'NA',
  paramedicApprovedWorker6: 'NA',
  paramedicApprovedWorker7: 'NA',
  paramedicApprovedWorker8: 'NA',
  paramedicApprovedWorker9: 'NA',
  paramedicApprovedWorker10: 'NA',
  paramedicApprovedWorker11: 'NA',
  entriesAdded: [],
}

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setP2CompanyName: (state, action: PayloadAction<string>) => {
      state.p2companyName = action.payload
    },
    setp2contratSupervisorName: (state, action: PayloadAction<string>) => {
      state.p2contratSupervisorName = action.payload
    },
    setp2contratSupervisorPhone: (state, action: PayloadAction<string>) => {
      state.p2contratSupervisorPhone = action.payload
    },
    setp2erpName: (state, action: PayloadAction<string>) => {
      state.p2erpName = action.payload
    },
    setp2erpPhone: (state, action: PayloadAction<string>) => {
      state.p2erpPhone = action.payload
    },
    setp2rut: (state, action: PayloadAction<string>) => {
      state.p2rut = action.payload
    },
    setp2mutualidad: (state, action: PayloadAction<string>) => {
      state.p2mutualidad = action.payload
    },
    setp2requestingArea: (state, action: PayloadAction<string>) => {
      state.p2requestingArea = action.payload
    },
    setp2pepWorkCoordinator: (state, action: PayloadAction<string>) => {
      state.p2pepWorkCoordinator = action.payload
    },
    setp2specificWorkArea: (state, action: PayloadAction<string>) => {
      state.p2specificWorkArea = action.payload
    },
    setp2date: (state, action: PayloadAction<string>) => {
      state.p2date = action.payload
    },
    setp2extensionShow: (state, action: PayloadAction<boolean>) => {
      state.p2extensionShow = action.payload
    },
    setp2extension: (state, action: PayloadAction<string>) => {
      state.p2extension = action.payload
    },
    setp3description1: (state, action: PayloadAction<string>) => {
      state.p3description1 = action.payload
    },
    setp3description2: (state, action: PayloadAction<string>) => {
      state.p3description2 = action.payload
    },
    setp3description3: (state, action: PayloadAction<string>) => {
      state.p3description3 = action.payload
    },
    setp3description4: (state, action: PayloadAction<string>) => {
      state.p3description4 = action.payload
    },
    setp3description5: (state, action: PayloadAction<string>) => {
      state.p3description5 = action.payload
    },
    setp3description6: (state, action: PayloadAction<string>) => {
      state.p3description6 = action.payload
    },
    setp3description7: (state, action: PayloadAction<string>) => {
      state.p3description7 = action.payload
    },
    setp3controlMeasure1: (state, action: PayloadAction<string>) => {
      state.p3controlMeasure1 = action.payload
    },
    setp3controlMeasure2: (state, action: PayloadAction<string>) => {
      state.p3controlMeasure2 = action.payload
    },
    setp3controlMeasure3: (state, action: PayloadAction<string>) => {
      state.p3controlMeasure3 = action.payload
    },
    setp3controlMeasure4: (state, action: PayloadAction<string>) => {
      state.p3controlMeasure4 = action.payload
    },
    setp3controlMeasure5: (state, action: PayloadAction<string>) => {
      state.p3controlMeasure5 = action.payload
    },
    setp3controlMeasure6: (state, action: PayloadAction<string>) => {
      state.p3controlMeasure6 = action.payload
    },
    setp3controlMeasure7: (state, action: PayloadAction<string>) => {
      state.p3controlMeasure7 = action.payload
    },

    setp4workerName1: (state, action: PayloadAction<string>) => {
      state.p4workerName1 = action.payload
    },
    setp4workerName2: (state, action: PayloadAction<string>) => {
      state.p4workerName2 = action.payload
    },
    setp4workerName3: (state, action: PayloadAction<string>) => {
      state.p4workerName3 = action.payload
    },
    setp4workerName4: (state, action: PayloadAction<string>) => {
      state.p4workerName4 = action.payload
    },
    setp4workerName5: (state, action: PayloadAction<string>) => {
      state.p4workerName5 = action.payload
    },
    setp4workerName6: (state, action: PayloadAction<string>) => {
      state.p4workerName6 = action.payload
    },
    setp4workerName7: (state, action: PayloadAction<string>) => {
      state.p4workerName7 = action.payload
    },
    setp4workerName8: (state, action: PayloadAction<string>) => {
      state.p4workerName8 = action.payload
    },
    setp4workerName9: (state, action: PayloadAction<string>) => {
      state.p4workerName9 = action.payload
    },
    setp4workerName10: (state, action: PayloadAction<string>) => {
      state.p4workerName10 = action.payload
    },
    setp4workerName11: (state, action: PayloadAction<string>) => {
      state.p4workerName11 = action.payload
    },
    setp4workerName1Approved: (state, action: PayloadAction<string>) => {
      state.p4workerName1Approved = action.payload
    },
    setp4workerName2Approved: (state, action: PayloadAction<string>) => {
      state.p4workerName2Approved = action.payload
    },
    setp4workerName3Approved: (state, action: PayloadAction<string>) => {
      state.p4workerName3Approved = action.payload
    },
    setp4workerName4Approved: (state, action: PayloadAction<string>) => {
      state.p4workerName4Approved = action.payload
    },
    setp4workerName5Approved: (state, action: PayloadAction<string>) => {
      state.p4workerName5Approved = action.payload
    },
    setp4workerName6Approved: (state, action: PayloadAction<string>) => {
      state.p4workerName6Approved = action.payload
    },
    setp4workerName7Approved: (state, action: PayloadAction<string>) => {
      state.p4workerName7Approved = action.payload
    },
    setp4workerName8Approved: (state, action: PayloadAction<string>) => {
      state.p4workerName8Approved = action.payload
    },
    setp4workerName9Approved: (state, action: PayloadAction<string>) => {
      state.p4workerName9Approved = action.payload
    },
    setp4workerName10Approved: (state, action: PayloadAction<string>) => {
      state.p4workerName10Approved = action.payload
    },
    setp4workerName11Approved: (state, action: PayloadAction<string>) => {
      state.p4workerName11Approved = action.payload
    },

    setp5atrapam: (state, action: PayloadAction<string>) => {
      state.p5atrapam = action.payload
    },
    setp5aplastam: (state, action: PayloadAction<string>) => {
      state.p5aplastam = action.payload
    },
    setp5golpeadopor: (state, action: PayloadAction<string>) => {
      state.p5golpeadopor = action.payload
    },
    setp5golpeadocon: (state, action: PayloadAction<string>) => {
      state.p5golpeadocon = action.payload
    },
    setp5caida: (state, action: PayloadAction<string>) => {
      state.p5caida = action.payload
    },
    setp5contConObjCort: (state, action: PayloadAction<string>) => {
      state.p5contConObjCort = action.payload
    },
    setp5contConObjCal: (state, action: PayloadAction<string>) => {
      state.p5contConObjCal = action.payload
    },
    setp5proyPartic: (state, action: PayloadAction<string>) => {
      state.p5proyPartic = action.payload
    },
    setp5contactEnElectric: (state, action: PayloadAction<string>) => {
      state.p5contactEnElectric = action.payload
    },
    setp5radiacionUv: (state, action: PayloadAction<string>) => {
      state.p5radiacionUv = action.payload
    },
    setp5contQuimico: (state, action: PayloadAction<string>) => {
      state.p5contQuimico = action.payload
    },
    setp5exposicPolvo: (state, action: PayloadAction<string>) => {
      state.p5exposicPolvo = action.payload
    },
    setp5exposicRuido: (state, action: PayloadAction<string>) => {
      state.p5exposicRuido = action.payload
    },
    setp5atropello: (state, action: PayloadAction<string>) => {
      state.p5atropello = action.payload
    },
    setp5caidaMatAltura: (state, action: PayloadAction<string>) => {
      state.p5caidaMatAltura = action.payload
    },
    setp5estresTerm: (state, action: PayloadAction<string>) => {
      state.p5estresTerm = action.payload
    },
    setp5colisionChoque: (state, action: PayloadAction<string>) => {
      state.p5colisionChoque = action.payload
    },
    setp5deficienciao2: (state, action: PayloadAction<string>) => {
      state.p5deficienciao2 = action.payload
    },
    setp5explosion: (state, action: PayloadAction<string>) => {
      state.p5explosion = action.payload
    },
    setp5incendio: (state, action: PayloadAction<string>) => {
      state.p5incendio = action.payload
    },
    setp5derrumbe: (state, action: PayloadAction<string>) => {
      state.p5derrumbe = action.payload
    },
    setp5exposicGas: (state, action: PayloadAction<string>) => {
      state.p5exposicGas = action.payload
    },
    setp5immersion: (state, action: PayloadAction<string>) => {
      state.p5immersion = action.payload
    },
    setp5fatigMaterial: (state, action: PayloadAction<string>) => {
      state.p5fatigMaterial = action.payload
    },
    setp6Show: (state, action: PayloadAction<boolean>) => {
      state.p6Show = action.payload
    },
    setp6keyNumber: (state, action: PayloadAction<string>) => {
      state.p6keyNumber = action.payload
    },
    setp6weatherConditions: (state, action: PayloadAction<string>) => {
      state.p6weatherConditions = action.payload
    },
    setp6trainingForHeightWork: (state, action: PayloadAction<string>) => {
      state.p6trainingForHeightWork = action.payload
    },
    setp6trainingEspecWork: (state, action: PayloadAction<string>) => {
      state.p6trainingEspecWork = action.payload
    },
    setp6radioCommunication: (state, action: PayloadAction<string>) => {
      state.p6radioCommunication = action.payload
    },
    setp6toolBelt: (state, action: PayloadAction<string>) => {
      state.p6toolBelt = action.payload
    },
    setp6useOfHookPoint: (state, action: PayloadAction<string>) => {
      state.p6useOfHookPoint = action.payload
    },
    setp6useofLadder: (state, action: PayloadAction<string>) => {
      state.p6useofLadder = action.payload
    },
    setp6elevator: (state, action: PayloadAction<string>) => {
      state.p6elevator = action.payload
    },
    setp6andamTipeEuro: (state, action: PayloadAction<string>) => {
      state.p6andamTipeEuro = action.payload
    },
    setp6healthCheck: (state, action: PayloadAction<string>) => {
      state.p6healthCheck = action.payload
    },
    setp6inlineSliderVertical: (state, action: PayloadAction<string>) => {
      state.p6inlineSliderVertical = action.payload
    },
    setp6heightJobLowLevel: (state, action: PayloadAction<string>) => {
      state.p6heightJobLowLevel = action.payload
    },
    setp7Show: (state, action: PayloadAction<boolean>) => {
      state.p7Show = action.payload
    },
    setp7keyNumber: (state, action: PayloadAction<string>) => {
      state.p7keyNumber = action.payload
    },
    setp7measure1o2: (state, action: PayloadAction<string>) => {
      state.p7measure1o2 = action.payload
    },
    setp7measure1h2s: (state, action: PayloadAction<string>) => {
      state.p7measure1h2s = action.payload
    },
    setp7measure1co: (state, action: PayloadAction<string>) => {
      state.p7measure1co = action.payload
    },
    setp7measure2o2: (state, action: PayloadAction<string>) => {
      state.p7measure2o2 = action.payload
    },
    setp7measure2h2s: (state, action: PayloadAction<string>) => {
      state.p7measure2h2s = action.payload
    },
    setp7measure2co: (state, action: PayloadAction<string>) => {
      state.p7measure2co = action.payload
    },
    setp7gasAn: (state, action: PayloadAction<string>) => {
      state.p7gasAn = action.payload
    },
    setp7deviceOfVentForced: (state, action: PayloadAction<string>) => {
      state.p7deviceOfVentForced = action.payload
    },
    setp7deviceOfAutonBreathing: (state, action: PayloadAction<string>) => {
      state.p7deviceOfAutonBreathing = action.payload
    },
    setp7comRadio: (state, action: PayloadAction<string>) => {
      state.p7comRadio = action.payload
    },
    setp7landTempInPlace: (state, action: PayloadAction<string>) => {
      state.p7landTempInPlace = action.payload
    },
    setp7equipmentOfAdLight: (state, action: PayloadAction<string>) => {
      state.p7equipmentOfAdLight = action.payload
    },
    setp7requireRescueDevice: (state, action: PayloadAction<string>) => {
      state.p7requireRescueDevice = action.payload
    },
    setp7acceptableWeatherCond: (state, action: PayloadAction<string>) => {
      state.p7acceptableWeatherCond = action.payload
    },
    setp8Show: (state, action: PayloadAction<boolean>) => {
      state.p8Show = action.payload
    },
    setp8chemEnvase: (state, action: PayloadAction<string>) => {
      state.p8chemEnvase = action.payload
    },
    setp8hasHds: (state, action: PayloadAction<string>) => {
      state.p8hasHds = action.payload
    },
    setp8indicateSuspel: (state, action: PayloadAction<string>) => {
      state.p8indicateSuspel = action.payload
    },
    setp8showaGlove: (state, action: PayloadAction<string>) => {
      state.p8showaGlove = action.payload
    },
    setp8faceShield: (state, action: PayloadAction<string>) => {
      state.p8faceShield = action.payload
    },
    setp8antSuit: (state, action: PayloadAction<string>) => {
      state.p8antSuit = action.payload
    },
    setp8rubberBoot: (state, action: PayloadAction<string>) => {
      state.p8rubberBoot = action.payload
    },
    setp8antSpillKit: (state, action: PayloadAction<string>) => {
      state.p8antSpillKit = action.payload
    },
    setp8extensionElectricalCheck: (state, action: PayloadAction<string>) => {
      state.p8extensionElectricalCheck = action.payload
    },
    setp9Show: (state, action: PayloadAction<boolean>) => {
      state.p9Show = action.payload
    },
    setp9eslingasCheck: (state, action: PayloadAction<string>) => {
      state.p9eslingasCheck = action.payload
    },
    setp9ancPointCheck: (state, action: PayloadAction<string>) => {
      state.p9ancPointCheck = action.payload
    },
    setp9securityPerimeter: (state, action: PayloadAction<string>) => {
      state.p9securityPerimeter = action.payload
    },
    setp9riggerParticipation: (state, action: PayloadAction<string>) => {
      state.p9riggerParticipation = action.payload
    },
    setp9transitAreas: (state, action: PayloadAction<string>) => {
      state.p9transitAreas = action.payload
    },
    setp9informBlockAreasToJef: (state, action: PayloadAction<string>) => {
      state.p9informBlockAreasToJef = action.payload
    },
    setp9areaDelimitation: (state, action: PayloadAction<string>) => {
      state.p9areaDelimitation = action.payload
    },
    setp9truckPrevCheck: (state, action: PayloadAction<string>) => {
      state.p9truckPrevCheck = action.payload
    },
    setp10show: (state, action: PayloadAction<boolean>) => {
      state.p10show = action.payload
    },
    setp10chemName1: (state, action: PayloadAction<string>) => {
      state.p10chemName1 = action.payload
    },
    setp10chemName1health: (state, action: PayloadAction<string>) => {
      state.p10chemName1health = action.payload
    },
    setp10chemName1inflam: (state, action: PayloadAction<string>) => {
      state.p10chemName1inflam = action.payload
    },
    setp10chemName1reactivity: (state, action: PayloadAction<string>) => {
      state.p10chemName1reactivity = action.payload
    },
    setp10chemName1specific: (state, action: PayloadAction<string>) => {
      state.p10chemName1specific = action.payload
    },
    setp10chemName2: (state, action: PayloadAction<string>) => {
      state.p10chemName2 = action.payload
    },
    setp10chemName2health: (state, action: PayloadAction<string>) => {
      state.p10chemName2health = action.payload
    },
    setp10chemName2inflam: (state, action: PayloadAction<string>) => {
      state.p10chemName2inflam = action.payload
    },
    setp10chemName2reactivity: (state, action: PayloadAction<string>) => {
      state.p10chemName2reactivity = action.payload
    },
    setp10chemName2specific: (state, action: PayloadAction<string>) => {
      state.p10chemName2specific = action.payload
    },
    setp10chemName3: (state, action: PayloadAction<string>) => {
      state.p10chemName3 = action.payload
    },
    setp10chemName3health: (state, action: PayloadAction<string>) => {
      state.p10chemName3health = action.payload
    },
    setp10chemName3inflam: (state, action: PayloadAction<string>) => {
      state.p10chemName3inflam = action.payload
    },
    setp10chemName3reactivity: (state, action: PayloadAction<string>) => {
      state.p10chemName3reactivity = action.payload
    },
    setp10chemName3specific: (state, action: PayloadAction<string>) => {
      state.p10chemName3specific = action.payload
    },
    setp10chemName4: (state, action: PayloadAction<string>) => {
      state.p10chemName4 = action.payload
    },
    setp10chemName4health: (state, action: PayloadAction<string>) => {
      state.p10chemName4health = action.payload
    },
    setp10chemName4inflam: (state, action: PayloadAction<string>) => {
      state.p10chemName4inflam = action.payload
    },
    setp10chemName4reactivity: (state, action: PayloadAction<string>) => {
      state.p10chemName4reactivity = action.payload
    },
    setp10chemName4specific: (state, action: PayloadAction<string>) => {
      state.p10chemName4specific = action.payload
    },
    setp10chemName5: (state, action: PayloadAction<string>) => {
      state.p10chemName5 = action.payload
    },
    setp10chemName5health: (state, action: PayloadAction<string>) => {
      state.p10chemName5health = action.payload
    },
    setp10chemName5inflam: (state, action: PayloadAction<string>) => {
      state.p10chemName5inflam = action.payload
    },
    setp10chemName5reactivity: (state, action: PayloadAction<string>) => {
      state.p10chemName5reactivity = action.payload
    },
    setp10chemName5specific: (state, action: PayloadAction<string>) => {
      state.p10chemName5specific = action.payload
    },
    setp11show: (state, action: PayloadAction<boolean>) => {
      state.p11show = action.payload
    },
    setp11mask: (state, action: PayloadAction<string>) => {
      state.p11mask = action.payload
    },
    setp11cabritillaGlove: (state, action: PayloadAction<string>) => {
      state.p11cabritillaGlove = action.payload
    },
    setp11breather: (state, action: PayloadAction<string>) => {
      state.p11breather = action.payload
    },
    setp11manualLoadMax25: (state, action: PayloadAction<string>) => {
      state.p11manualLoadMax25 = action.payload
    },
    setp11useoFSignal: (state, action: PayloadAction<string>) => {
      state.p11useoFSignal = action.payload
    },
    setp11areaDelimitation: (state, action: PayloadAction<string>) => {
      state.p11areaDelimitation = action.payload
    },
    setp11electricalExtensionCheck: (state, action: PayloadAction<string>) => {
      state.p11electricalExtensionCheck = action.payload
    },
    setp11toolCheck: (state, action: PayloadAction<string>) => {
      state.p11toolCheck = action.payload
    },
    setp11lightningToWork: (state, action: PayloadAction<string>) => {
      state.p11lightningToWork = action.payload
    },
    setp11clearArea: (state, action: PayloadAction<string>) => {
      state.p11clearArea = action.payload
    },
    setp11useOfGrua: (state, action: PayloadAction<string>) => {
      state.p11useOfGrua = action.payload
    },
    setp12show: (state, action: PayloadAction<boolean>) => {
      state.p12show = action.payload
    },
    setp12evaluatorName: (state, action: PayloadAction<string>) => {
      state.p12evaluatorName = action.payload
    },
    setp12evaluationTime: (state, action: PayloadAction<Date | null>) => {
      state.p12evaluationTime = action.payload
    },
    setp12areaClear: (state, action: PayloadAction<string>) => {
      state.p12areaClear = action.payload
    },
    setp12materialsWithinTheZone: (state, action: PayloadAction<string>) => {
      state.p12materialsWithinTheZone = action.payload
    },
    setp12dustGeneration: (state, action: PayloadAction<string>) => {
      state.p12dustGeneration = action.payload
    },
    setp12foodIn: (state, action: PayloadAction<string>) => {
      state.p12foodIn = action.payload
    },
    setp12hasBmp: (state, action: PayloadAction<string>) => {
      state.p12hasBmp = action.payload
    },
    setp13show: (state, action: PayloadAction<boolean>) => {
      state.p13show = action.payload
    },
    setp13hotWorkFormNumber: (state, action: PayloadAction<string>) => {
      state.p13hotWorkFormNumber = action.payload
    },
    setp13areaDelimitation: (state, action: PayloadAction<string>) => {
      state.p13areaDelimitation = action.payload
    },
    setp13electricalExtensionCheck: (state, action: PayloadAction<string>) => {
      state.p13electricalExtensionCheck = action.payload
    },
    setp13notificationToBrig: (state, action: PayloadAction<string>) => {
      state.p13notificationToBrig = action.payload
    },
    setp14securityGlass: (state, action: PayloadAction<string>) => {
      state.p14securityGlass = action.payload
    },
    setp14securityShoes: (state, action: PayloadAction<string>) => {
      state.p14securityShoes = action.payload
    },
    setp14earProtector: (state, action: PayloadAction<string>) => {
      state.p14earProtector = action.payload
    },
    setp14securityHeadProtection: (state, action: PayloadAction<string>) => {
      state.p14securityHeadProtection = action.payload
    },
    setp14reflectJacket: (state, action: PayloadAction<string>) => {
      state.p14reflectJacket = action.payload
    },
    setp14uvFilterProtection: (state, action: PayloadAction<string>) => {
      state.p14uvFilterProtection = action.payload
    },
    setp14antSuit: (state, action: PayloadAction<string>) => {
      state.p14antSuit = action.payload
    },
    setp14bootsResistant: (state, action: PayloadAction<string>) => {
      state.p14bootsResistant = action.payload
    },
    setp14faceShield: (state, action: PayloadAction<string>) => {
      state.p14faceShield = action.payload
    },
    setp14soldarMask: (state, action: PayloadAction<string>) => {
      state.p14soldarMask = action.payload
    },
    setp14soldarSuit: (state, action: PayloadAction<string>) => {
      state.p14soldarSuit = action.payload
    },
    setp14leatherColeto: (state, action: PayloadAction<string>) => {
      state.p14leatherColeto = action.payload
    },
    setp14leatherPolaina: (state, action: PayloadAction<string>) => {
      state.p14leatherPolaina = action.payload
    },
    setp14fourHookHarness: (state, action: PayloadAction<string>) => {
      state.p14fourHookHarness = action.payload
    },
    setp14doubleLitail: (state, action: PayloadAction<string>) => {
      state.p14doubleLitail = action.payload
    },
    setp14impactAbsorb: (state, action: PayloadAction<string>) => {
      state.p14impactAbsorb = action.payload
    },
    setp14escaladaMosqueton: (state, action: PayloadAction<string>) => {
      state.p14escaladaMosqueton = action.payload
    },
    setp14filterMask: (state, action: PayloadAction<string>) => {
      state.p14filterMask = action.payload
    },
    setp14Others: (state, action: PayloadAction<string>) => {
      state.p14Others = action.payload
    },
    setp15show: (state, action: PayloadAction<boolean>) => {
      state.p15show = action.payload
    },
    setp15blockingPersonName: (state, action: PayloadAction<string>) => {
      state.p15blockingPersonName = action.payload
    },
    setp15dateTimeLotoStart: (state, action: PayloadAction<Date | null>) => {
      state.p15dateTimeLotoStart = action.payload
    },
    setp15dateTimeLotoFinish: (state, action: PayloadAction<Date | null>) => {
      state.p15dateTimeLotoFinish = action.payload
    },
    setp15blockingEnType: (state, action: PayloadAction<string>) => {
      state.p15blockingEnType = action.payload
    },
    setp15blockingEnTypeElectrical: (state, action: PayloadAction<boolean>) => {
      state.p15blockingEnTypeElectrical = action.payload
    },
    setp15blockingEnTypeHidraulical: (state, action: PayloadAction<boolean>) => {
      state.p15blockingEnTypeHidraulical = action.payload
    },
    setp15blockingEnMechanical: (state, action: PayloadAction<boolean>) => {
      state.p15blockingEnMechanical = action.payload
    },
    setp15blockingEnPotencial: (state, action: PayloadAction<boolean>) => {
      state.p15blockingEnPotencial = action.payload
    },
    setp15dangEnergiesControl: (state, action: PayloadAction<string>) => {
      state.p15dangEnergiesControl = action.payload
    },
    setp15knowEnergySourceMachine: (state, action: PayloadAction<string>) => {
      state.p15knowEnergySourceMachine = action.payload
    },
    setp15concLoto: (state, action: PayloadAction<string>) => {
      state.p15concLoto = action.payload
    },
    setp15hasLockIdCardBlockDevice: (state, action: PayloadAction<string>) => {
      state.p15hasLockIdCardBlockDevice = action.payload
    },
    setp15dielectricGlove: (state, action: PayloadAction<string>) => {
      state.p15dielectricGlove = action.payload
    },
    setp15dielectricSecurityShoe: (state, action: PayloadAction<string>) => {
      state.p15dielectricSecurityShoe = action.payload
    },
    setp15dielectricSecurityGlass: (state, action: PayloadAction<string>) => {
      state.p15dielectricSecurityGlass = action.payload
    },
    setp16showWorkSupervisor: (state, action: PayloadAction<boolean>) => {
      state.p16showWorkSupervisor = action.payload
      if (!state.p16showWorkSupervisor) {
        state.p16workSupervisor = ''
        state.p16workSupervisorEmail = ''
        if (state.entriesAdded.includes('p16workSupervisor')) {
          state.entriesAdded.splice(state.entriesAdded.indexOf('p16workSupervisor'), 1)
        }
      }
    },
    setp16showPepCoordinator: (state, action: PayloadAction<boolean>) => {
      state.p16showPepCoordinator = action.payload
      if (!state.p16showPepCoordinator) {
        state.p16pepCoordinator = ''
        state.p16pepCoordinatorEmail = ''
        if (state.entriesAdded.includes('p16pepCoordinator')) {
          state.entriesAdded.splice(state.entriesAdded.indexOf('p16pepCoordinator'), 1)
        }
      }
    },
    setp16showAreaCoordinator: (state, action: PayloadAction<boolean>) => {
      state.p16showAreaCoordinator = action.payload
      if (!state.p16showAreaCoordinator) {
        state.p16areaCoordinator = ''
        state.p16areaCoordinatorEmail = ''
        if (state.entriesAdded.includes('p16areaCoordinator')) {
          state.entriesAdded.splice(state.entriesAdded.indexOf('p16areaCoordinator'), 1)
        }
      }
    },
    setp16showQuality: (state, action: PayloadAction<boolean>) => {
      state.p16showQuality = action.payload
      if (!state.p16showQuality) {
        state.p16quality = ''
        state.p16qualityEmail = ''
        if (state.entriesAdded.includes('p16quality')) {
          state.entriesAdded.splice(state.entriesAdded.indexOf('p16quality'), 1)
        }
      }
    },
    setp16showTechnicalArea: (state, action: PayloadAction<boolean>) => {
      state.p16showTechnicalArea = action.payload
      if (!state.p16showTechnicalArea) {
        state.p16technicalArea = ''
        state.p16technicalAreaEmail = ''
        if (state.entriesAdded.includes('p16technicalArea')) {
          state.entriesAdded.splice(state.entriesAdded.indexOf('p16technicalArea'), 1)
        }
      }
    },
    setp16showPatrimonialSecurity: (state, action: PayloadAction<boolean>) => {
      state.p16showPatrimonialSecurity = action.payload
      if (!state.p16showPatrimonialSecurity) {
        state.p16patrimonialSecurity = ''
        state.p16patrimonialSecurityEmail = ''
        if (state.entriesAdded.includes('p16patrimonialSecurity')) {
          state.entriesAdded.splice(state.entriesAdded.indexOf('p16patrimonialSecurity'), 1)
        }
      }
    },
    setp16showExternalPrevencionist: (state, action: PayloadAction<boolean>) => {
      state.p16showExternalPrevencionist = action.payload
      if (!state.p16showExternalPrevencionist) {
        state.p16externalPrevencionist = ''
        state.p16externalPrevencionistEmail = ''
        if (state.entriesAdded.includes('p16externalPrevencionist')) {
          state.entriesAdded.splice(state.entriesAdded.indexOf('p16externalPrevencionist'), 1)
        }
      }
    },
    setp16showSass: (state, action: PayloadAction<boolean>) => {
      state.p16showSass = action.payload
      if (!state.p16showSass) {
        state.p16sass = ''
        state.p16sassAllowedList = []
        state.p16sassEmail = ''
        if (state.entriesAdded.includes('p16sass')) {
          state.entriesAdded.splice(state.entriesAdded.indexOf('p16sass'), 1)
        }
      }
    },
    // it sets the user id
    setp16workSupervisor: (state, action: PayloadAction<string>) => {
      state.p16workSupervisor = ''
      state.p16workSupervisor = action.payload
      const found1 = allUsers.find((element) => element?.userKey === action.payload)
      state.p16workSupervisorEmail = found1?.email!

      if (state.entriesAdded.includes('p16workSupervisor')) return
      let arr = []
      arr = [...state.entriesAdded, 'p16workSupervisor']
      state.entriesAdded = arr
    },
    setp16pepCoordinator: (state, action: PayloadAction<string>) => {
      state.p16pepCoordinator = ''
      state.p16pepCoordinator = action.payload
      console.log('action.payload from formslice', action.payload)
      const found2 = allUsers.find((element) => element?.userKey === action.payload)
      state.p16pepCoordinatorEmail = found2?.email!
      console.log('found from formslice', found2)
      console.log('found email from formslice', found2?.email)

      if (state.entriesAdded.includes('p16pepCoordinator')) return
      let arr = []
      arr = [...state.entriesAdded, 'p16pepCoordinator']
      state.entriesAdded = arr
    },
    setp16areaCoordinator: (state, action: PayloadAction<string>) => {
      state.p16areaCoordinator = ''
      state.p16areaCoordinator = action.payload
      const found3 = allUsers.find((element) => element?.userKey === action.payload)
      state.p16areaCoordinatorEmail = found3?.email!

      if (state.entriesAdded.includes('p16areaCoordinator')) return
      let arr = []
      arr = [...state.entriesAdded, 'p16areaCoordinator']
      state.entriesAdded = arr
    },
    setp16quality: (state, action: PayloadAction<string>) => {
      state.p16quality = ''
      state.p16quality = action.payload
      const found4 = allUsers.find((element) => element?.userKey === action.payload)
      state.p16qualityEmail = found4?.email!

      if (state.entriesAdded.includes('p16quality')) return
      let arr = []
      arr = [...state.entriesAdded, 'p16quality']
      state.entriesAdded = arr
    },
    setp16technicalArea: (state, action: PayloadAction<string>) => {
      state.p16technicalArea = ''
      state.p16technicalArea = action.payload
      const found5 = allUsers.find((element) => element?.userKey === action.payload)
      state.p16technicalAreaEmail = found5?.email!

      if (state.entriesAdded.includes('p16technicalArea')) return
      let arr = []
      arr = [...state.entriesAdded, 'p16technicalArea']
      state.entriesAdded = arr
    },
    setp16patrimonialSecurity: (state, action: PayloadAction<string>) => {
      state.p16patrimonialSecurity = ''
      state.p16patrimonialSecurity = action.payload
      const found6 = allUsers.find((element) => element?.userKey === action.payload)
      state.p16patrimonialSecurityEmail = found6?.email!

      if (state.entriesAdded.includes('p16patrimonialSecurity')) return
      let arr = []
      arr = [...state.entriesAdded, 'p16patrimonialSecurity']
      state.entriesAdded = arr
    },
    setp16externalPrevencionist: (state, action: PayloadAction<string>) => {
      state.p16externalPrevencionist = ''
      state.p16externalPrevencionist = action.payload
      const found7 = allUsers.find((element) => element?.userKey === action.payload)
      state.p16externalPrevencionistEmail = found7?.email!

      if (state.entriesAdded.includes('p16externalPrevencionist')) return
      let arr = []
      arr = [...state.entriesAdded, 'p16externalPrevencionist']
      state.entriesAdded = arr
    },
    setp16sass: (state, action: PayloadAction<string>) => {
      state.p16sass = ''
      state.p16sass = action.payload
      const found8 = allUsers.find((element) => element?.userKey === action.payload)
      state.p16sassEmail = found8?.email!

      if (state.entriesAdded.includes('p16sass')) return
      let arr = []
      arr = [...state.entriesAdded, 'p16sass']
      state.entriesAdded = arr
    },
    setp16sassAllowedList: (state, action: PayloadAction<string>) => {
      if (state.p16sassAllowedList.includes(action.payload)) {
        state.p16sassAllowedList.splice(state.p16sassAllowedList.indexOf(action.payload), 1)
      } else {
        state.p16sassAllowedList.push(action.payload)
      }
    },

    setp16paramedic: (state, action: PayloadAction<string>) => {
      state.p16paramedic = action.payload
    },
    setparamedicSentp4Email: (state, action: PayloadAction<boolean>) => {
      state.paramedicSentp4Email = action.payload
    },
    setparamedicApprovedWorker1: (state, action: PayloadAction<string>) => {
      state.paramedicApprovedWorker1 = action.payload
    },
    setparamedicApprovedWorker2: (state, action: PayloadAction<string>) => {
      state.paramedicApprovedWorker2 = action.payload
    },
    setparamedicApprovedWorker3: (state, action: PayloadAction<string>) => {
      state.paramedicApprovedWorker3 = action.payload
    },
    setparamedicApprovedWorker4: (state, action: PayloadAction<string>) => {
      state.paramedicApprovedWorker4 = action.payload
    },
    setparamedicApprovedWorker5: (state, action: PayloadAction<string>) => {
      state.paramedicApprovedWorker5 = action.payload
    },
    setparamedicApprovedWorker6: (state, action: PayloadAction<string>) => {
      state.paramedicApprovedWorker6 = action.payload
    },
    setparamedicApprovedWorker7: (state, action: PayloadAction<string>) => {
      state.paramedicApprovedWorker7 = action.payload
    },
    setparamedicApprovedWorker8: (state, action: PayloadAction<string>) => {
      state.paramedicApprovedWorker8 = action.payload
    },
    setparamedicApprovedWorker9: (state, action: PayloadAction<string>) => {
      state.paramedicApprovedWorker9 = action.payload
    },
    setparamedicApprovedWorker10: (state, action: PayloadAction<string>) => {
      state.paramedicApprovedWorker10 = action.payload
    },
    setparamedicApprovedWorker11: (state, action: PayloadAction<string>) => {
      state.paramedicApprovedWorker11 = action.payload
    },

    // not being used
    // setName: (state, action: PayloadAction<string>) => {
    //   state.name = action.payload
    // },
    // setDescriptions: (state, action: PayloadAction<string>) => {
    //   const description = action.payload
    //   state.descriptions.push(description)
    // },
    clearForm: (): FormState => ({ ...initialState }),
  },
})

export const {
  setp2mutualidad,
  setp2requestingArea, setp2pepWorkCoordinator,
  setp2date,
  setp2specificWorkArea,
  setp2extensionShow,
  setp2extension,
  setp2rut,
  setp2erpPhone,
  setp2erpName,
  setp2contratSupervisorPhone,
  setP2CompanyName,
  setp2contratSupervisorName,
  clearForm,
} = formSlice.actions

export const allFormActions = formSlice.actions

export default formSlice.reducer