import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Button, { ButtonProps } from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { yellow } from '@mui/material/colors'
import { useHistory } from 'react-router-dom'
import Slide from '@mui/material/Slide'
import { toast } from 'react-toastify'
import Box from '@mui/material/Box'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { entireStore } from '../../app/store'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { allFormActions } from '../../features/form/formSlice'
import DashboardLayout from '../layouts/DashboardLayout'
import useNewFormId from '../../hooks/useNewFormId'
import { db, doc, updateDoc } from '../../config/firebaseConfig'

const CreateStep3 = () => {
  console.log('CreateStep3')

  const { newFormId } = useNewFormId()

  const {
    setp3description1, setp3description2,
    setp3description3,
    setp3description4,
    setp3description5,
    setp3description6,
    setp3description7,
    setp3controlMeasure1,
    setp3controlMeasure2,
    setp3controlMeasure3,
    setp3controlMeasure4,
    setp3controlMeasure5,
    setp3controlMeasure6,
    setp3controlMeasure7,
  } = allFormActions

  const {
    p3description1,
    p3description2,
    p3description3,
    p3description4,
    p3description5,
    p3description6,
    p3description7,
    p3controlMeasure1,
    p3controlMeasure2,
    p3controlMeasure3,
    p3controlMeasure4,
    p3controlMeasure5,
    p3controlMeasure6,
    p3controlMeasure7,
  } = useAppSelector(entireStore)
  const dispatch = useAppDispatch()

  const history = useHistory()

  const goToCreateStep2 = () => {
    history.push(`/create/2/${newFormId}`)
  }

  const goToCreateStep4 = async () => {
    if (!p3description1) {
      toast.error('Por favor escriba la Descripción del trabajo 1')
    } else if (!p3controlMeasure1) {
      toast.error('Por favor escriba la Medida de control 1')
    } else {
      const formRef = doc(db, 'forms', newFormId as string)
      try {
        await updateDoc(formRef, {
          newFormp3FilledOut: true,
          newFormp3FilledOutDate: new Date(),
          p3description1,
          p3description2,
          p3description3,
          p3description4,
          p3description5,
          p3description6,
          p3description7,
          p3controlMeasure1,
          p3controlMeasure2,
          p3controlMeasure3,
          p3controlMeasure4,
          p3controlMeasure5,
          p3controlMeasure6,
          p3controlMeasure7,
        })
        toast.success('El permiso de trabajo se actualizó correctamente')
        history.push(`/create/4/${newFormId}`)
      } catch (updateError) {
        toast.error(`Error: ${updateError}`)
      }
      history.push(`/create/4/${newFormId}`)
    }
  }

  // eslint-disable-next-line
  const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: 'white',
    backgroundColor: '#fbc02d',
    '&:hover': {
      backgroundColor: yellow[700],
    },
  }))

  return (
    <DashboardLayout>
      <Slide direction="right" in mountOnEnter unmountOnExit>
        <Grid sx={{ mt: 4 }} container justifyContent="center" spacing={2}>
          <Grid item xs={10} sm={9} md={5} lg={7}>
            <Typography
              sx={{ mb: 2 }}
              align="center"
              variant="h6"
              color="text.secondary"
              gutterBottom
            >
              ANÁLISIS SEGURO DE TRABAJO
            </Typography>
            <TextField
              sx={{ mb: 2 }}
              fullWidth
              label="1 - Descripción del trabajo (paso a paso)"
              variant="outlined"
              value={p3description1}
              onChange={(e) => dispatch(setp3description1(e.target.value))}
            />

            <TextField
              sx={{ mb: 2 }}
              fullWidth
              label="1 - Medidas de control para cada paso"
              variant="outlined"
              value={p3controlMeasure1}
              onChange={(e) => dispatch(setp3controlMeasure1(e.target.value))}
            />

            <Box
              component="div"
              sx={{
                display: p3description1 && p3controlMeasure1 ? 'block' : 'none',
              }}
            >
              <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="2 - Descripción del trabajo (paso a paso)"
                variant="outlined"
                value={p3description2}
                onChange={(e) => dispatch(setp3description2(e.target.value))}
              />

              <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="2 - Medidas de control para cada paso"
                variant="outlined"
                value={p3controlMeasure2}
                onChange={(e) => dispatch(setp3controlMeasure2(e.target.value))}
              />
            </Box>

            <Box
              component="div"
              sx={{
                display: p3description2 && p3controlMeasure2 ? 'block' : 'none',
              }}
            >
              <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="3 - Descripción del trabajo (paso a paso)"
                variant="outlined"
                value={p3description3}
                onChange={(e) => dispatch(setp3description3(e.target.value))}
              />

              <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="3 - Medidas de control para cada paso"
                variant="outlined"
                value={p3controlMeasure3}
                onChange={(e) => dispatch(setp3controlMeasure3(e.target.value))}
              />
            </Box>

            <Box
              component="div"
              sx={{
                display: p3description3 && p3controlMeasure3 ? 'block' : 'none',
              }}
            >
              <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="4 - Descripción del trabajo (paso a paso)"
                variant="outlined"
                value={p3description4}
                onChange={(e) => dispatch(setp3description4(e.target.value))}
              />

              <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="4 - Medidas de control para cada paso"
                variant="outlined"
                value={p3controlMeasure4}
                onChange={(e) => dispatch(setp3controlMeasure4(e.target.value))}
              />
            </Box>

            <Box
              component="div"
              sx={{
                display: p3description4 && p3controlMeasure4 ? 'block' : 'none',
              }}
            >
              <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="5 - Descripción del trabajo (paso a paso)"
                variant="outlined"
                value={p3description5}
                onChange={(e) => dispatch(setp3description5(e.target.value))}
              />

              <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="5 - Medidas de control para cada paso"
                variant="outlined"
                value={p3controlMeasure5}
                onChange={(e) => dispatch(setp3controlMeasure5(e.target.value))}
              />
            </Box>

            <Box
              component="div"
              sx={{
                display: p3description5 && p3controlMeasure5 ? 'block' : 'none',
              }}
            >
              <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="6 - Descripción del trabajo (paso a paso)"
                variant="outlined"
                value={p3description6}
                onChange={(e) => dispatch(setp3description6(e.target.value))}
              />

              <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="6 - Medidas de control para cada paso"
                variant="outlined"
                value={p3controlMeasure6}
                onChange={(e) => dispatch(setp3controlMeasure6(e.target.value))}
              />
            </Box>

            <Box
              component="div"
              sx={{
                display: p3description6 && p3controlMeasure6 ? 'block' : 'none',
              }}
            >
              <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="7 - Descripción del trabajo (paso a paso)"
                variant="outlined"
                value={p3description7}
                onChange={(e) => dispatch(setp3description7(e.target.value))}
              />

              <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="7 - Medidas de control para cada paso"
                variant="outlined"
                value={p3controlMeasure7}
                onChange={(e) => dispatch(setp3controlMeasure7(e.target.value))}
              />
            </Box>
            <Grid sx={{ mt: 4, mb: 2 }} container justifyContent="space-between">
              <Grid
                xs={12}
                md={5}
                sx={{ mb: 2 }}
              >
                <ColorButton
                  fullWidth
                  startIcon={<ArrowLeftIcon />}
                  onClick={goToCreateStep2}
                >
                  Ir a la página anterior
                </ColorButton>
              </Grid>
              <Grid
                xs={12}
                md={5}
              >
                <ColorButton
                  fullWidth
                  endIcon={<ArrowRightIcon />}
                  onClick={goToCreateStep4}
                >
                  Ir a la página siguiente
                </ColorButton>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </DashboardLayout>
  )
}

export default CreateStep3