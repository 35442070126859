import { useEffect, useState } from 'react'
import {
  storage, getDownloadURL, ref, uploadBytesResumable,
} from '../config/firebaseConfig'

const useStorage = (file: File | null) => {
  const [progress, setProgress] = useState<number>(0)
  const [error, setError] = useState<any>(null)
  const [url, setUrl] = useState<string>('')

  useEffect(() => {
    if (file) {
      const storageRef = ref(storage, `forms/${file.name}`)
      const uploadTask = uploadBytesResumable(storageRef, file)
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const snapshotProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          setProgress(snapshotProgress)
        },
        (storageError) => {
          setError(storageError)
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setUrl(downloadURL)
          })
        },
      )
    }
  }, [file])

  return { progress, url, error }
}

export default useStorage