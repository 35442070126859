const qualityList = [
  {
    email: '',
    rolName: '',
    name: '',
    company: '',
    uid: '',
  },
  {
    email: 'PERLAANDREA.URRIOLAARAOS@pepsico.com',
    rolName: 'quality',
    name: 'Perla Urriola',
    company: 'swer17wA10eNmlIhbQUv',
    uid: '6gfxV69mafVc7GdyRiFB8zynP4q2',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    name: 'Ricardo Torres',
    email: 'RICARDO.TORRESVARGAS@pepsico.com',
    rolName: 'quality',
    uid: 'IaNcFdGdZCcxAkUMQT3ckHwdncR2',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'Florines.Meneses.Contractor@pepsico.com',
    rolName: 'quality',
    uid: 'iOAWngGgJdaG4tU9lVUVqqjLUtG2',
    name: 'Florines Meneses',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    name: 'Pamela Orellana',
    uid: 'nE1qYxIwBzbhaMg6epcgTCAFdS33',
    rolName: 'quality',
    email: 'PAMELA.ORELLANA@pepsico.com',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    rolName: 'quality',
    uid: 'ouSUVBC3C5czyyOAAt3e8PGGqfe2',
    name: 'Raul Osorio',
    email: 'RAULALEXANDER.OSORIOMAESTRE@pepsico.com',
  },
  {
    rolName: 'quality',
    company: 'swer17wA10eNmlIhbQUv',
    email: 'MAVILACATALINA.SUAREZCONSTANZO1@pepsico.com',
    uid: '3JC1Ep8GfhXVKeOHxn2Ne71gNZl1',

    name: 'Mavila Suarez',
  },
  {
    rolName: 'quality',
    name: 'Leidy Sanchez',
    uid: '5OfCMAFtEyW01Cr0ndjyjmoWX9w2',
    company: 'swer17wA10eNmlIhbQUv',
    email: 'LeidyLeomary.SanchezRamirez@pepsico.com',
  },
  {
    uid: '7Clb78z0gWNujQ5srEhFeNSuIfH3',
    name: 'Antonio Castillo',
    email: 'antonio.castillo@pepsico.com',
    rolName: 'quality',
    company: 'swer17wA10eNmlIhbQUv',
  },
  {
    uid: 'Ekfo996qD0XkjGKfZLoxFzCw1t93',
    email: 'ANDREADELCARMEN.LULIONCASTILLO@pepsico.com',
    name: 'Andrea Lulion',
    company: 'swer17wA10eNmlIhbQUv',

    rolName: 'quality',
  },
  {
    rolName: 'quality',
    uid: 'SdCDlBmpICP45QlBfkZm9GwIviu1',

    name: 'William Merino',
    company: 'swer17wA10eNmlIhbQUv',
    email: 'William.Merino@pepsico.com',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',

    rolName: 'quality',
    email: 'HEINSTEINWROWER.BARRERACONTRERAS@pepsico.com',
    name: 'Heinstein Barrera',
    uid: 'MgkZCadkhhSNuktRzm5E8EjXVI82',
  },
  {
    rolName: 'quality',
    name: 'Jose Pulido',
    email: 'JOSEANGEL.PULIDOCOLINA1@pepsico.com',
    company: 'swer17wA10eNmlIhbQUv',
    uid: '4U24kze4rUcBalGvyxMsZkrv43e2',
  },
  {
    rolName: 'quality',

    email: 'Nicolas.Oportus.Contractor@pepsico.com',
    uid: 'Vam4mXggRDY4PSgZKbftbUj02sH2',
    company: 'swer17wA10eNmlIhbQUv',
    name: 'Nicolas Oporto',
  },
  {
    email: 'juan.abarca@pepsico.com',
    uid: '4OiGdF1Qh0e43iuR0Goo220vhZy2',
    rolName: 'quality',
    name: 'Juan Abarca',
    company: 'swer17wA10eNmlIhbQUv',
  },
  {
    name: 'Carolina Anabalon',
    rolName: 'quality',

    uid: '59BYsQcLZ4ZQDRp5FwYK9tFDZR63',
    email: 'Carolina.Anabalon.Contractor@pepsico.com',
    company: 'swer17wA10eNmlIhbQUv',
  },
  {
    rolName: 'quality',
    email: 'JoseGregorio.PachecoSalcedo@pepsico.com',
    uid: '5DPEM2eri2Pt060SiEbTQGvCuYt1',
    company: 'swer17wA10eNmlIhbQUv',

    name: 'Jose Pacheco',
  },
  {
    rolName: 'quality',

    company: 'swer17wA10eNmlIhbQUv',
    name: 'Nicolas Cartagena',
    uid: 'CrzL0C2JkdSsh720Mopw771QD1x2',
    email: 'nicolas.cartagena@pepsico.com',
  },
  {
    email: 'paola.candia@pepsico.com',
    rolName: 'quality',
    company: 'swer17wA10eNmlIhbQUv',
    name: 'Paola Candia ',

    uid: '19SW2tpbZthzALUnPQN5wF7WAeC2',
  },
  {
    name: 'Mauricio Luna',
    uid: 'XEd0jE4dlePvkJL1OTbgrqAL3hB2',
    rolName: 'quality',

    company: 'swer17wA10eNmlIhbQUv',
    email: 'mauricio.luna@pepsico.com',
  },
  {
    uid: 'evo4EneEIyfAWNwklCJi01IZWo62',
    company: 'swer17wA10eNmlIhbQUv',
    name: 'Nicolas Lagos',

    email: 'Nicolas.Lagos.Contractor@pepsico.com',
    rolName: 'quality',
  },
  {
    name: 'Orlando Castillo',
    rolName: 'quality',
    company: 'swer17wA10eNmlIhbQUv',

    email: 'ORLANDOJOSE.CASTILLOMATUTE@pepsico.com',
    uid: 'HWrjQ2CXQHc21wiIABzFfojigaP2',
  },
]
export default qualityList