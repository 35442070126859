import React from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Slide from '@mui/material/Slide'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import YellowSwitch from '../../common/YellowSwitch'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { entireStore } from '../../app/store'
import { allFormActions } from '../../features/form/formSlice'
import DashboardLayout from '../layouts/DashboardLayout'
import ColorButton from './ColorButton'
import useNewFormId from '../../hooks/useNewFormId'
import useStorage from '../../hooks/useStorage'
import { db, doc, updateDoc } from '../../config/firebaseConfig'

const CreateStep13 = () => {
  console.log('CreateStep13')

  const imageTypes = ['image/png', 'image/jpeg', 'image/jpg']

  const [file, setFile] = React.useState<File | null>(null)
  const [fileID, setFileID] = React.useState<String | null>(null)
  const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(false)

  const { newFormId } = useNewFormId()

  const { url, progress, error } = useStorage(file)

  const customIdFileUploadFinish = 'custom-id-file-upload-finish'
  React.useEffect(() => {
    if (progress > 1) {
      setButtonDisabled(true)
    }
    if (progress === 100) {
      setButtonDisabled(false)
      toast.success('La imagen ha sido subida correctamente', {
        toastId: customIdFileUploadFinish,
      })
      console.log('fileid', fileID)

      localStorage.setItem(`${fileID}`, url)
      console.log(url)
    }
    if (error) {
      // error es any
      setButtonDisabled(false)
      toast.error(error)
    }
  }, [progress, url, file])

  const {
    p13show,
    p13hotWorkFormNumber,
    p13areaDelimitation,
    p13electricalExtensionCheck,
    p13notificationToBrig,
  } = useAppSelector(entireStore)
  const {
    setp13show,
    setp13hotWorkFormNumber,
    setp13areaDelimitation,
    setp13electricalExtensionCheck,
    setp13notificationToBrig,
  } = allFormActions
  const dispatch = useAppDispatch()

  const history = useHistory()

  const goToCreateStep12 = () => {
    history.push(`/create/12/${newFormId}`)
  }

  const goToCreateStep14 = async () => {
    if (p13show && !p13hotWorkFormNumber) {
      toast.error('Por favor ingrese el Permiso de Trabajo en Caliente Nº')
    } else {
      // history.push(`/create/14/${newFormId}`)
      const formRef = doc(db, 'forms', newFormId as string)
      try {
        await updateDoc(formRef, {
          newFormp13FilledOut: true,
          newFormp13FilledOutDate: new Date(),
          p13show,
          p13hotWorkFormNumber,
          p13areaDelimitation,
          p13electricalExtensionCheck,
          p13notificationToBrig,
        })
        toast.success('El permiso de trabajo se actualizó correctamente')
        history.push(`/create/14/${newFormId}`)
      } catch (updateError) {
        toast.error(`Error: ${updateError}`)
      }
    }
  }

  const Input = styled('input')({
    display: 'none',
  })

  return (
    <DashboardLayout>
      <Slide direction="right" in mountOnEnter unmountOnExit>
        <Grid sx={{ mt: 4 }} container justifyContent="center" spacing={2}>
          <Grid item xs={10} sm={9} md={5} lg={7}>
            <Typography
              sx={{ mb: 2 }}
              align="center"
              variant="h6"
              color="text.secondary"
              gutterBottom
            >
              TRABAJO EN CALIENTE
            </Typography>

            <Grid display="flex" justifyContent="center" item xs={12}>
              <FormControlLabel
                control={(
                  <YellowSwitch
                    checked={p13show}
                    onChange={(event) => dispatch(setp13show(event.target.checked))}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                )}
                label={p13show ? 'Esta página aplica' : 'Esta página no aplica'}
              />
            </Grid>

            <Box
              component="div"
              sx={{
                display: p13show ? 'block' : 'none',
              }}
            >
              <Grid display="flex" justifyContent="center" item xs={12}>
                <TextField
                  sx={{ mb: 2, width: 300 }}
                  fullWidth
                  label="Permiso de Trabajo en Caliente Nº : "
                  variant="outlined"
                  value={p13hotWorkFormNumber}
                  onChange={(e) => dispatch(setp13hotWorkFormNumber(e.target.value))}
                />

                { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(e) => {
                      if (e.target.files) {
                        const selectedFile = e?.target?.files[0]
                        console.table(selectedFile)
                        if (selectedFile && imageTypes.includes(selectedFile.type)) {
                          setFile(selectedFile)
                        } else {
                          toast.error('El archivo no es una imagen, por favor intente nuevamente')
                        }
                      }
                    }}
                  />
                  <Button
                    sx={{ bgcolor: '#fbc02d', mb: 2 }}
                    variant="contained"
                    component="span"
                    disabled={buttonDisabled}
                    onClick={() => setFileID('p13hotWorkFormNumberImage')}
                  >
                    {buttonDisabled
                      ? 'Subiendo imagen'
                      : 'Subir imagen'}
                  </Button>
                </label>
              </Grid>

              <Box sx={{
                display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
              }}
              >
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">Delimitación del área</FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="atrapamiento"
                    name="atrapamiento"
                    value={p13areaDelimitation}
                    onChange={(event) => dispatch(setp13areaDelimitation(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">Extensiones eléctricas chequeadas</FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="aplastamiento"
                    name="aplastamiento"
                    value={p13electricalExtensionCheck}
                    onChange={
                      (event) => dispatch(setp13electricalExtensionCheck(event.target.value))
                    }
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>

                { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(e) => {
                      if (e.target.files) {
                        const selectedFile = e?.target?.files[0]
                        console.table(selectedFile)
                        if (selectedFile && imageTypes.includes(selectedFile.type)) {
                          setFile(selectedFile)
                        } else {
                          toast.error('El archivo no es una imagen, por favor intente nuevamente')
                        }
                      }
                    }}
                  />
                  <Button
                    sx={{ bgcolor: '#fbc02d', mb: 2 }}
                    variant="contained"
                    component="span"
                    disabled={buttonDisabled}
                    onClick={() => setFileID('p13electricalExtensionCheckImage')}
                  >
                    {buttonDisabled
                      ? 'Subiendo imagen'
                      : 'Subir imagen'}
                  </Button>
                </label>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 10 }}>
                <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                  <FormLabel component="legend">Aviso a Brigada</FormLabel>
                  <RadioGroup
                    sx={{ flex: '1', flexDirection: 'row' }}
                    aria-label="golpeadorpor"
                    name="golpeadorpor"
                    value={p13notificationToBrig}
                    onChange={(event) => dispatch(setp13notificationToBrig(event.target.value))}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    <FormControlLabel value="NA" control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
            <Grid sx={{ mb: 2 }} container justifyContent="space-between">
              <Grid
                item
                xs={12}
                md={5}
                sx={{ mb: 2 }}
              >
                <ColorButton
                  fullWidth
                  startIcon={<ArrowLeftIcon />}
                  onClick={goToCreateStep12}
                >
                  Ir a la página anterior
                </ColorButton>
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
              >
                <ColorButton
                  fullWidth
                  endIcon={<ArrowRightIcon />}
                  onClick={goToCreateStep14}
                >
                  Ir a la página siguiente
                </ColorButton>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </DashboardLayout>
  )
}

export default CreateStep13