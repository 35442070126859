import { CircularProgress, Container } from '@mui/material'

const FullPageLoader = () => {
  console.log('FullPageLoader ')
  return (
    <Container
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
    >
      <CircularProgress sx={{ color: '#fbc02d' }} />
    </Container>
  )
}

export default FullPageLoader