import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Slide from '@mui/material/Slide'
import { useParams, useHistory } from 'react-router-dom'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import Button from '@mui/material/Button'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import CheckIcon from '@mui/icons-material/Check'
import { toast } from 'react-toastify'
import { UrlParams } from '../../types'
import useAuth from '../../hooks/useAuth'
// import allUsers from '../../common/allUsers'
import DashboardLayout from '../layouts/DashboardLayout'
import ColorButton from '../../common/ColorButton'
import { doc, updateDoc, db } from '../../config/firebaseConfig'
import getFirestoreDocById from '../../common/getFirestoreDocById'
import color1ConsoleLog from '../../common/color1ConsoleLog'
import FullPageLoader from '../../common/FullPageLoader'

const ReviseStep4 = () => {
  console.log('ReviseStep4')

  const { user } = useAuth()
  console.log('%cuser : %s', color1ConsoleLog, user?.uid)

  // const userJob = allUsers.find((obj) => obj?.userKey === user?.uid)

  // console.log('isfound', userJob?.rolName)

  const isPersonAllowedToSelect = () => false

  useEffect(() => {
    console.log('isPersonAllowedToSelect', isPersonAllowedToSelect())
  }, [])

  const { id, area } = useParams<UrlParams>()
  console.log('%cThe id is : %s', color1ConsoleLog, id)
  console.log('%cThe area is : %s', color1ConsoleLog, area)

  const [formData, setFormData]: any = useState()
  const [loading, setLoading] = useState(true)
  // eslint-disable-next-line
  const [isAllowed, setIsAllowed] = useState(false)
  const [key, setKey] = useState('')
  // eslint-disable-next-line
  const [areaUrlParam, setAreaUrlParam] = useState('')
  const [approvedAt, setApprovedAt] = useState('')

  useEffect(() => {
    (async () => {
      if (id) {
        const res = await getFirestoreDocById(id)
        if (res) {
          setFormData(res)
          if (area === 'tsu') {
            console.log('res?.p16workSupervisor', res?.p16workSupervisor)
            console.log('%cp16workSupervisor has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16workSupervisorApproved4')
            setAreaUrlParam('tsu')
            setApprovedAt('p16workSupervisorApprovedAt4')
          }
          if (area === 'pep') {
            console.log('%cp16pepCoordinator has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16pepCoordinatorApproved4')
            setAreaUrlParam('pep')
            setApprovedAt('p16pepCoordinatorApprovedAt4')
          }
          if (area === 'aco') {
            console.log('%cp16areaCoordinator has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16areaCoordinatorApproved4')
            setAreaUrlParam('aco')
            setApprovedAt('p16areaCoordinatorApprovedAt4')
          }
          if (area === 'qt') {
            console.log('%cp16quality has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16qualityApproved4')
            setAreaUrlParam('qt')
            setApprovedAt('p16qualityApprovedAt4')
          }
          if (area === 'ta') {
            console.log('%cp16technicalArea has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16technicalAreaApproved4')
            setAreaUrlParam('ta')
            setApprovedAt('p16technicalAreaApprovedAt4')
          }
          if (area === 'ps') {
            console.log('%cp16patrimonialSecurity has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16patrimonialSecurityApproved4')
            setAreaUrlParam('ps')
            setApprovedAt('p16patrimonialSecurityApprovedAt4')
          }
          if (area === 'ep') {
            console.log('%cp16externalPrevencionist has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16externalPrevencionistApproved4')
            setAreaUrlParam('ep')
            setApprovedAt('p16externalPrevencionistApprovedAt4')
          }
          if (area === 'sass') {
            console.log('%cp16sass has id', color1ConsoleLog)
            setIsAllowed(true)
            setKey('p16sassApproved4')
            setAreaUrlParam('sass')
            setApprovedAt('p16sassApprovedAt4')
          }
          setLoading(false)
        } else {
          setLoading(false)
        }
      }
    })()
  }, [])

  if (loading) {
    <Typography>Cargando</Typography>
  }

  console.log('formData step 4', formData)

  const history = useHistory()

  const goToReviseStep3 = () => {
    history.push(`/revision/${area}/3/${id}`)
  }
  const goToReviseStep5 = () => {
    history.push(`/revision/${area}/5/${id}`)
  }

  const approve = async (e: any) => {
    e.preventDefault()
    const approveformRef = doc(db, 'forms', id)
    const obj: any = {}
    obj[key] = true
    obj[approvedAt] = new Date()
    try {
      await updateDoc(approveformRef, obj)
      console.log('approved', key, approvedAt)
      toast.success('La aprobación se actualizó correctamente')
    } catch (updateError) {
      toast.error(`Error: ${updateError}`)
    }
  }

  const disapprove = async (e: any) => {
    e.preventDefault()
    const approveformRef = doc(db, 'forms', id)
    const obj: any = {}
    obj[key] = false
    obj[approvedAt] = new Date()
    try {
      await updateDoc(approveformRef, obj)
      console.log('disapproved', key, approvedAt)
      toast.success('La desaprobación se actualizó correctamente')
    } catch (updateError) {
      toast.error(`Error: ${updateError}`)
    }
  }

  return (
    <DashboardLayout>
      {loading ? <FullPageLoader /> : (
        <Slide direction="right" in mountOnEnter unmountOnExit>
          <Grid sx={{ mt: 4 }} container justifyContent="center" spacing={2}>
            <Grid item xs={10} sm={9} md={5} lg={7}>
              {
                (formData?.p16workSupervisor === user?.uid)
                  || (formData?.p16pepCoordinator === user?.uid)
                  || (formData?.p16areaCoordinator === user?.uid)
                  || (formData?.p16quality === user?.uid)
                  || (formData?.p16technicalArea === user?.uid)
                  || (formData?.p16patrimonialSecurity === user?.uid)
                  || (formData?.p16externalPrevencionist === user?.uid)
                  || (formData?.p16sass === user?.uid)
                  || (formData?.p16sassAllowedList?.includes(user?.uid))
                  ? (
                    <>
                      <Typography
                        sx={{ mb: 2 }}
                        align="center"
                        variant="h6"
                        color="text.secondary"
                        gutterBottom
                      >
                        LISTADO DE PERSONAL QUE EJECUTA EL TRABAJO
                      </Typography>
                      <TextField
                        sx={{ mb: 2 }}
                        fullWidth
                        label="Nombre del trabajador 1"
                        variant="outlined"
                        value={formData?.p4workerName1}
                        InputLabelProps={{ shrink: true }}
                      />

                      <TextField
                        style={
                          formData?.p4workerName2
                            ? { display: 'flex' }
                            : { display: 'none' }
                        }
                        sx={{ mb: 2 }}
                        fullWidth
                        label="Nombre del trabajador 2"
                        variant="outlined"
                        value={formData?.p4workerName2}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        style={
                          formData?.p4workerName3
                            ? { display: 'flex' }
                            : { display: 'none' }
                        }
                        sx={{ mb: 2 }}
                        fullWidth
                        label="Nombre del trabajador 3"
                        variant="outlined"
                        value={formData?.p4workerName3}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        style={
                          formData?.p4workerName4
                            ? { display: 'flex' }
                            : { display: 'none' }
                        }
                        sx={{ mb: 2 }}
                        fullWidth
                        label="Nombre del trabajador 4"
                        variant="outlined"
                        value={formData?.p4workerName4}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        style={
                          formData?.p4workerName5
                            ? { display: 'flex' }
                            : { display: 'none' }
                        }
                        sx={{ mb: 2 }}
                        fullWidth
                        label="Nombre del trabajador 5"
                        variant="outlined"
                        value={formData?.p4workerName5}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        style={
                          formData?.p4workerName6
                            ? { display: 'flex' }
                            : { display: 'none' }
                        }
                        sx={{ mb: 2 }}
                        fullWidth
                        label="Nombre del trabajador 6"
                        variant="outlined"
                        value={formData?.p4workerName6}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        style={
                          formData?.p4workerName7
                            ? { display: 'flex' }
                            : { display: 'none' }
                        }
                        sx={{ mb: 2 }}
                        fullWidth
                        label="Nombre del trabajador 7"
                        variant="outlined"
                        value={formData?.p4workerName7}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        style={
                          formData?.p4workerName8
                            ? { display: 'flex' }
                            : { display: 'none' }
                        }
                        sx={{ mb: 2 }}
                        fullWidth
                        label="Nombre del trabajador 8"
                        variant="outlined"
                        value={formData?.p4workerName8}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        style={
                          formData?.p4workerName9
                            ? { display: 'flex' }
                            : { display: 'none' }
                        }
                        sx={{ mb: 2 }}
                        fullWidth
                        label="Nombre del trabajador 9"
                        variant="outlined"
                        value={formData?.p4workerName9}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        style={
                          formData?.p4workerName10
                            ? { display: 'flex' }
                            : { display: 'none' }
                        }
                        sx={{ mb: 2 }}
                        fullWidth
                        label="Nombre del trabajador 10"
                        variant="outlined"
                        value={formData?.p4workerName10}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        style={
                          formData?.p4workerName11
                            ? { display: 'flex' }
                            : { display: 'none' }
                        }
                        sx={{ mb: 10 }}
                        fullWidth
                        label="Nombre del trabajador 11"
                        variant="outlined"
                        value={formData?.p4workerName11}
                        InputLabelProps={{ shrink: true }}
                      />

                      <Grid
                        display="none"
                        justifyContent="space-evenly"
                        sx={{ mt: 4 }}
                      >
                        <Button
                          startIcon={<HighlightOffIcon />}
                          sx={{ mb: 10, bgcolor: '#db1414' }}
                          color="error"
                          variant="contained"
                          onClick={(e) => disapprove(e)}
                        >
                          Desaprobar
                        </Button>
                        <Button
                          startIcon={<CheckIcon />}
                          sx={{ mb: 10, bgcolor: '#42b50e' }}
                          color="success"
                          variant="contained"
                          onClick={(e) => approve(e)}
                        >
                          Aprobar
                        </Button>
                      </Grid>

                      <Grid sx={{ mb: 2 }} container justifyContent="space-between">
                        <Grid
                          xs={12}
                          md={5}
                          sx={{ mb: 2 }}
                        >
                          <ColorButton
                            fullWidth
                            startIcon={<ArrowLeftIcon />}
                            onClick={goToReviseStep3}
                          >
                            Ir a la página anterior
                          </ColorButton>
                        </Grid>
                        <Grid
                          xs={12}
                          md={5}
                        >
                          <ColorButton
                            fullWidth
                            endIcon={<ArrowRightIcon />}
                            onClick={goToReviseStep5}
                          >
                            Ir a la página siguiente
                          </ColorButton>
                        </Grid>

                      </Grid>
                    </>
                  )
                  : 'Por favor inicie sesión para visualizar su documento a revisar'
              }

              {user ? (
                <Typography color="green">Usted ha iniciado sesión.</Typography>
              )
                : (
                  <Typography color="red" sx={{ mt: 2 }}>Usted no ha iniciado sesión</Typography>
                )}

            </Grid>
          </Grid>
        </Slide>
      )}

    </DashboardLayout>
  )
}

export default ReviseStep4